<template>
  <hsc-window-style-white>
    <hsc-window
      title="Lista kierowców"
      position-hint="center"
      :close-button="true"
      :is-open.sync="showOccupancy"
      class="floating-popup"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <v-card max-width="550px">
        <v-data-table
          class="drivers-occupancy-table"
          :items="parsedDriversList"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          fixed-header
          height="360px"
        >
          <template #[`item.available`]="{ item }">
            <div
              class="d-inline-block select-status"
              :class="`select-status--${item.available.color}`"
            >
              {{ item.available.text }}
            </div>
          </template>
        </v-data-table>
      </v-card>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import humps from 'humps'

const headers = [
  { text: 'Kierowca', value: 'name' },
  { text: 'Pozostało kursów', value: 'unfinishedCoursesCount' },
  { text: 'Liczba kursów', value: 'allCoursesCount' },
  { text: 'Odległość od bazy', value: 'distanceToDepartment' },
  { text: 'Dostępność', value: 'available', align: 'right' }
]

export default {
  data: () => ({
    headers,
    dialogZIndex: 10,
  }),
  computed: {
    ...mapState({
      isOccupancyDialogOpen: state => state.driversOccupancy.isOccupancyDialogOpen,
      driversList: state => state.driversOccupancy.drivers,
      department: state => state.core.department,
      token: state => state.auth.token
    }),
    parsedDriversList () {
      return [...this.driversList]
        .sort((a, b) => {
          return b.available - a.available ||
          a.unfinishedCoursesCount - b.unfinishedCoursesCount ||
          a.distanceToDepartment - b.distanceToDepartment
        })
        .map(driver => ({
          ...driver,
          name: `${driver.firstName} ${driver.lastName}`,
          distanceToDepartment: driver.distanceToDepartment / 1000 + ' km',
          available: {
            text: driver.available ? 'Wolny' : 'Zajęty',
            color: driver.available ? 'green' : 'red'
          }
        }))
    },
    showOccupancy: {
      get () {
        return this.isOccupancyDialogOpen
      },
      set (isShown) {
        this.setOccupancyVisibility(isShown)
        this.updateDialogIndex()
      }
    }
  },
  watch: {
    department () {
      this.updateOccupancyData()
    }
  },
  channels: {
    DriversSummaryChannel: { received (data) { this.updateDriversOccupancy(data) } }
  },
  beforeDestroy () {
    this.$cable.unsubscribe('DriversSummaryChannel')
  },
  methods: {
    ...mapActions({
      setOccupancyVisibility: 'driversOccupancy/setOccupancyVisibility',
      getDriversOccupancy: 'driversOccupancy/getDriversOccupancy',
      updateDriversOccupancy: 'driversOccupancy/updateDriversOccupancy',
      updateDialogIndex: function (dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      },
    }),
    updateOccupancyData () {
      this.getDriversOccupancy()
      this.$cable.unsubscribe('DriversSummaryChannel')
      const params = {
        channel: 'DriversSummaryChannel',
        departmentId: this.department.id,
      }
      this.$cable.subscribe(humps.decamelizeKeys(params))
    }
  }
}
</script>
