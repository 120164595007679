import actions from '../../actions'
import { formatValue } from '../../../utils/typesEnum'
import { getPaymentOrigin } from '../../../utils'

const addressName = address => address.name || address.formattedAddress
const vehicleLicensePlate = value => ((value?.vehicle?.licensePlate || '') + (value?.trailer?.licensePlate ? '/' + value?.trailer?.licensePlate : '')) || '-'

const isTransport = order => order?.courses?.[0]?.courseType === 'Transport'
const isNotTransport = order => order?.courses?.[0]?.courseType !== 'Transport'
const isCompanyClient = order => order?.client?.clientType === 'Firma'
const isNotCompanyClient = order => !isCompanyClient(order)
const isFromWordpress = order => !!order?.wordpressId
const isTransferPayment = order => order?.payment?.paymentType === 'Przelew'
const isTrailer = course => !!course?.driver?.trailer?.licensePlate
const isCarrier = () => !isTrailer()
const isNotInvoiceHidden = invoice => invoice?.hidden !== true
const isInvoiceHidden = invoice => !isNotInvoiceHidden(invoice)
const hasMiddleman = order => !!order?.middleman
const clientIsPayer = order => order?.middleman && order?.client?.id === order?.payer?.id
const middlemanIsPayer = order => order?.middleman && order?.middleman?.id === order?.payer?.id
const clientIsPayerWithoutMiddleman = order => !order?.middleman && order?.client?.id === order?.payer?.id
const hasInvoiceStatus = order => !!order.invoiceStatus
const isCashPayment = order => ['Gotówka przy podstawieniu', 'Gotówka przy zabraniu'].includes(order?.payment?.paymentType)
const isCashDuringLeaveCourse = order => order?.payment?.paymentType === 'Gotówka przy podstawieniu'
const isCashDruingPickupCourse = order => order?.payment?.paymentType === 'Gotówka przy zabraniu'
const isRemotePayment = order => ['Przelew', 'Karta', 'Blik', 'Online'].includes(order?.payment?.paymentType)
const isOfficePayment = order => order?.payment?.paymentType === 'Zapłacono w biurze'
const isDebrisTypeAggregate = order => !!order.debrisType.aggregate

export default {
  header: [
    { ...actions.checkPrice },
    { ...actions.addNewSwap },
    { ...actions.addNewPickup },
    { ...actions.addNewOrder, create: true }
  ],
  table: {
    maximumDateRange: 1096,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'refNumber',
      sortDesc: true
    },
    filters: [
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: [], propPath: 'clientId', multiple: true },
      { name: 'addressIds', text: 'Lokalizacja', options: 'addresses', filterBy: '', nameGetter: addressName, propPath: 'addressId', multiple: true, disabled: true },
      { name: 'courseTypes', text: 'Typ kursu', options: 'ordersCourseTypes', filterBy: [], checkCourseType: true, multiple: true },
      { name: 'paymentTypes', text: 'Typ płatności', options: 'paymentTypes', filterBy: [], propPath: 'paymentType', multiple: true },
      { name: 'settlementTypes', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: [], propPath: 'settlementType', multiple: true },
      { name: 'paymentStatuses', text: 'Status płatności', options: 'paymentStatuses', filterBy: [], propPath: 'paymentStatus', multiple: true },
      { name: 'origins', text: 'Pochodzenie', options: 'orderOrigins', filterBy: [], propPath: 'parsedOrigin', multiple: true },
      { name: 'containerTypeIds', text: 'Zamówiony typ kontenera', options: 'containerTypes', filterBy: [], propPath: 'containerTypeId', multiple: true },
      { name: 'clientBdoTypes', text: 'Logika BDO', options: 'allBdoTypes', filterBy: [], multiple: true, hide: true },
      { name: 'bdoCardStatuses', text: 'Status KPO', options: 'bdoCardStatuses', filterBy: [], multiple: true, hide: true },
      { name: 'withoutInvoices', text: 'Bez dokumentu', filterBy: '', options: '', falseValue: '', propPath: 'withoutDocuments', switch: true },
      { name: 'done', filterBy: '', propPath: 'done', hide: true },
      { name: 'withInvoiceStatus', filterBy: '', propPath: 'withInvoiceStatus', hide: true },
    ],
    parameters: [
      { name: 'select', text: '', show: true, value: 'select', sortable: false },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'refNumber', show: true },
      { name: 'clientName', text: 'Klient', value: 'clientName', clientLabel: true, show: true },
      { name: 'middlemanName', text: 'Pośrednik', value: 'middlemanName', show: true },
      { name: 'bdoNumber', text: 'Nr BDO', value: 'clientBdoNumber', show: true },
      { name: 'kpoNumber', text: 'Nr KPO', value: 'pickupCourseBdoNumber', show: true },
      { name: 'clientBdoType', text: 'Logika BDO', value: 'clientBdoType', show: true, tabs: ['openOrders', 'ordersCompleted', 'allOrders'] },
      { name: 'pickupCourseBdoStatus', text: 'Status KPO', value: 'pickupCourseBdoStatus', show: true, tabs: ['ordersCompleted'] },
      { name: 'origin', text: 'Pochodzenie', value: 'parsedOrigin', show: true },
      { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveCourseDueDate', show: true, isDateField: true },
      { name: 'pickupDate', text: 'Data zabrania', value: 'pickupCourseDueDate', show: true, isDateField: true },
      { name: 'dueDate', text: 'Data transportu', value: 'otherCourseDueDate', show: true, isDateField: true },
      { name: 'container', text: 'Kontener', value: 'containerWithType', show: true },
      { name: 'reportedDebrisType', text: 'Raportowany typ odpadu', value: 'reportedDebrisTypeDisplayName', show: true },
      { name: 'reportedWeight', text: 'Raportowana waga [T]', value: 'reportedWeight', show: true },
      { name: 'containerType', text: 'Zamówiony kontener', value: 'orderContainerTypeName', show: true },
      { name: 'address', text: 'Lokalizacja', value: 'addressDisplayName', show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', show: true },
      { name: 'settlementType', text: 'Typ dokumentu', value: 'settlementType', show: true },
      { name: 'invoiceNumber', text: 'Nr dokumentu', value: 'invoiceNumber', show: true },
      { name: 'totalGrossValueWithDiscount', text: 'Do zapłaty', value: 'totalGrossValueWithDiscount', show: true },
      { name: 'paidValue', text: 'Rozliczono', value: 'paidValue', show: true },
      { name: 'documentStatus', text: 'Status dokumentu', value: 'withoutDocuments', sortable: false, show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', show: true },
      { name: 'blocked', text: 'Blokada', value: 'clientBlocked', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.blockClient, icon: 'blockClient' },
      { ...actions.unblockClient, icon: 'unblockClient' },
      { ...actions.generateInvoice, icon: 'wygeneruj_fakture' }
    ],
    menu: [
      { ...actions.editOrder },
      { ...actions.deleteOrder },
      { ...actions.generateInvoice },
      { ...actions.generatePaymentLink },
      { ...actions.generateCustomServiceInvoice },
      { ...actions.blockClient },
      { ...actions.unblockClient },
      { ...actions.editClient, idPath: 'clientId' }
    ],
    daterangeCriteria: ['otherCourseDueDate', 'leaveCourseDueDate', 'pickupCourseDueDate']
  },
  details: {
    title: 'Zlecenie nr {{refNumber}}; {{swapOrderRefNumber}}',
    actions: [
      { ...actions.editOrderDetails },
      { ...actions.deleteOrder, redirect: true },
      { ...actions.blockClient },
      { ...actions.unblockClient },
      { ...actions.generateInvoice, filled: true },
      { ...actions.generateCustomServiceInvoice, filled: true }
    ],
    sections: [
      {
        conditionalFields: {
          reportedDebrisTypeName: { name: 'reportedDebrisTypeName', value: 'reportedDebrisType.displayName', col: 3 },
        },
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'orders',
        conditionalWarnings: [
          {
            name: 'compareContainers',
            condition: 'isNotEqual',
            targetA: 'containerType.id',
            targetB: 'container.containerType.id',
            errorMessage: 'Wybrany kontener jest inny niż zamówiony'
          },
          {
            name: 'compareDebrisType',
            condition: 'isNotEqual',
            targetA: 'reportedDebrisType.id',
            targetB: 'debrisType.id',
            errorMessage: 'Wybrany typ odpadu jest inny niż raportowany'
          },
        ],
        get fields() {
          return [
            { name: 'orderId', text: 'Nr zlecenia', value: 'refNumber', type: 'textField', col: 3, header: true },
            { name: 'createdAt', text: 'Data dodania zlecenia', value: 'createdAt', type: 'textField', col: 3, header: true },
            { name: 'orderTemplateId ', value: 'orderTemplateId', type: 'textField', to: 'defaultSingleView', idPath: 'orderTemplateId', routeParams: { tableName: 'orderTemplates' }, col: 3, editable: false, text: 'Nr zamówienia', condition: isFromWordpress, header: true },
            { name: 'containerTypeId', text: 'Zamówiony typ kontenera', value: 'containerType.name', type: 'selectFromItems', options: 'containerTypes', editable: true, col: 3 },
            { name: 'containerId', text: 'Nr kontenera', value: 'container.refNumberWithType', type: 'containerId', to: 'defaultSingleView', idPath: 'container.id', routeParams: { tableName: 'containers' }, col: 3, editable: true },
            { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'textField', condition: isNotTransport, col: 3 },
            { name: 'aggregateTypeId', text: 'Typ kruszywa', value: 'aggregateType.displayName', type: 'selectDebrisType', editable: true, rules: ['required'], condition: isTransport, col: 3 },
            { name: 'debrisTypeId', text: 'Kod odpadu', value: 'debrisType.code', type: 'selectDebrisType', editable: true, rules: ['required'], condition: isNotTransport, col: 3 },
            { name: 'bdoStatus', text: 'Status KPO/KPOK', value: 'bdoStatus', type: 'textField', col: 3 },
            { name: 'reportedWeight', text: 'Raportowana waga [T]', value: 'reportedWeight', col: 3, type: 'textField', editable: true, rules: ['number', { name: 'lowerThan', arg: 1001 }], condition: isNotTransport },
            { ...this.conditionalFields.reportedDebrisTypeName, text: 'Raportowany typ odpadu', condition: isNotTransport },
            { ...this.conditionalFields.reportedDebrisTypeName, text: 'Raportowany typ kruszywa', condition: isTransport },
            { name: 'reportedDebrisTypeId', text: 'Raportowany kod odpadu', value: 'reportedDebrisType.code', type: 'selectDebrisType', col: 3, editable: true, condition: isNotTransport },
            { name: 'aggregateAmount', text: 'Liczba ton kruszywa [T]', value: 'aggregateAmount', type: 'textField', col: 3, editable: true, condition: isTransport, rules: ['integer', { name: 'lowerThan', arg: 1001 }] },
            { name: 'bdoNumber', text: 'Nr KPO/KPOK', value: 'bdoNumber', type: 'textField', col: 3, editable: false, rules: ['required'] },
            { name: 'coordinatesType', text: 'Współrzędne kontenera', value: 'coordinatesType', type: 'textField', col: 3, editable: false },
            { name: 'userNotes', text: 'Notatka ogólna', value: 'userNotes', type: 'textArea', editable: true, col: 6 },
            { name: 'containerNotes', text: 'Notatka do kontenera', value: 'containerNotes', type: 'textArea', editable: true, col: 6 },
          ]
        }
      },
      {
        name: 'address',
        text: 'Szczegóły adresu',
        type: 'left',
        endpoint: 'orders',
        fields: [
          { name: 'orderAddress', text: 'Adres zlecenia', value: 'address.formattedAddress', type: 'selectClientOrderAddress', col: 3, header: true, editable: true },
          { name: 'departmentId', text: 'Oddział', value: 'address.department.name', type: 'selectDepartment', col: 3, editable: true, header: true },
          { name: 'commune', text: 'Gmina', value: 'address.commune.name', type: 'textField', col: 3, header: true },
          { name: 'addressNotes', text: 'Notatka do lokalizacji', value: 'address.notes', type: 'textArea', col: 3 },
          { name: 'addressPhoneNumber', text: 'Numer telefonu', value: 'address.phoneNumber', type: 'addressPhoneNumber', col: 3, editable: true, rules: ['phoneNumber'] },
          { name: 'email', text: 'Adres email', value: 'address.email', type: 'textField', col: 3 },
          { name: 'addressName', text: 'Nazwa lokalizacji', value: 'address.name', type: 'textField', col: 3 },
          { name: 'addressRemarks', text: 'Uwagi dla spedytora', value: 'address.remarks', type: 'textArea', col: 3 },
          { name: 'phoneNumber', text: 'Dodatkowy numer telefonu', value: 'phoneNumber', type: 'textField', col: 3, editable: true, rules: ['phoneNumber'] },
          { name: 'invoiceNotes', text: 'Notatka do faktury', value: 'address.invoiceNotes', type: 'textField', col: 6 },
        ]
      },
      {
        conditionalFields: {
          settlementType: { name: 'settlementType', text: 'Typ dokumentu', value: 'payment.settlementType', type: 'selectFromFilters', col: 3, editable: true, rules: ['required'], header: true },
        },
        name: 'payment',
        text: 'Szczegóły płatności',
        type: 'left',
        endpoint: 'payments',
        idPath: 'payment.id',
        tableName: 'orders',
        get fields() {
          return [
            { ...this.conditionalFields.settlementType, options: 'companySettlementTypes', condition: isCompanyClient },
            { ...this.conditionalFields.settlementType, options: 'individualSettlementTypes', condition: isNotCompanyClient, },
            { name: 'paymentType', text: 'Typ płatności', value: 'payment.paymentType', type: 'paymentType', col: 3, editable: true, rules: ['required'], header: true },
            { name: 'paymentStatus', text: 'Status płatności', value: 'payment.paymentStatus', type: 'selectFromFilters', options: 'paymentStatuses', col: 3, editable: true, rules: ['required'], header: true },
            { name: 'debrisNetValue', text: 'Wartość kontenera netto', value: 'payment.debrisNetValue', type: 'netGrossPrice', priceLabel: 'debris', editable: true, col: 3 },
            { name: 'debrisGrossValue', text: 'Wartość kontenera brutto', value: 'payment.debrisGrossValue', type: 'netGrossPrice', priceLabel: 'debris', editable: true, gross: true, col: 3 },
            { name: 'additionalNetValue', text: 'Wartość netto usług', value: 'payment.additionalNetValue', type: 'netGrossPrice', priceLabel: 'additional', col: 3 },
            { name: 'additionalGrossValue', text: 'Wartość brutto usług', value: 'payment.additionalGrossValue', type: 'netGrossPrice', priceLabel: 'additional', gross: true, col: 3 },
            { name: 'vatPercentage', text: 'Vat', value: 'payment.vatPercentage', type: 'textField', col: 3, editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 101 }], unit: ' [%]' },
            { name: 'totalGrossValue', text: 'Do zapłaty', value: 'payment.totalGrossValue', type: 'textField', col: 3 },
            { name: 'discount', value: 'payment.discount', type: 'textField', col: 3, editable: true, rules: ['required', 'money', { name: 'lowerThan', arg: 10000001 }], unit: ' [zł]', text: 'Rabat netto', condition: isNotTransport },
            { name: 'discountJustification', value: 'payment.discountJustification', type: 'textField', col: 3, editable: true, text: 'Uzasadnienie rabatu', condition: isNotTransport },
            { name: 'unitDebrisNetValue', text: 'Cena netto kruszywa za tonę', type: 'unitPrice', value: 'payment.unitDebrisNetValue', editable: true, col: 3, condition: isTransport },
            { name: 'unitDebrisGrossValue', text: 'Cena brutto kruszywa za tonę', type: 'unitPrice', value: 'payment.unitDebrisGrossValue', editable: true, col: 3, condition: isTransport },
            { name: 'transportNetValue', text: 'Cena netto transportu', value: 'payment.transportNetValue', type: 'netGrossPrice', priceLabel: 'transport', editable: true, col: 3, condition: isTransport },
            { name: 'transportGrossValue', text: 'Cena brutto transportu', value: 'payment.transportGrossValue', type: 'netGrossPrice', priceLabel: 'transport', editable: true, gross: true, col: 3, condition: isTransport },
            { name: 'paidValue', text: 'Rozliczono', value: 'payment.paidValue', type: 'paidValue', col: 3, editable: true, condition: (value) => isOfficePayment(value), dialogComponent: 'EditCodDeposits', dialogSize: '800px' },
            { name: 'paidValue', text: 'Rozliczono', value: 'payment.paidValue', type: 'paidValue', col: 3, editable: (value) => !isCashPayment(value), condition: (value) => !isOfficePayment(value) },
            { name: 'paymentOrigin', text: 'Źródło ceny', value: 'payment.origin', type: 'textField', col: 3, editable: false, valueGetter: value => getPaymentOrigin(value) },
            { name: 'withoutDocuments', text: 'Status dokumentu', value: 'withoutDocuments', valueGetter: value => value ? 'Oczekuje' : 'Wystawiono', type: '', options: '', col: 3, editable: false },
            { name: 'paymentDueDays', text: 'Termin przelewu', value: 'payment.paymentDueDays', type: 'paymentType', col: 3, editable: true, condition: isTransferPayment },
            { name: 'payerName', value: '', valueGetter: value => `${value?.payer?.id === value?.client?.id ? 'Klient' : 'Pośrednik'} - ${value?.payer?.name}`, type: 'selectOrderPayer', col: 3, editable: true, text: 'Płatnik', fieldEndpoint: 'orders', fieldIdPath: 'id', condition: hasMiddleman },
          ]
        }
      },
      {
        name: 'courses',
        text: 'Szczegóły kursu',
        type: 'left',
        endpoint: 'courses',
        headerActions: [{ ...actions.resendTextConfirmation }, { ...actions.generateDigitalSignature }],
        dataPath: 'courses[0]',
        component: 'DetailsSectionCourses',
        fields: [
          { name: 'codDepositsSum', text: 'Rozliczono', value: 'codDeposits', type: '', col: 3, header: true, valueGetter: value => formatValue(value.reduce((acc, obj) => acc + obj.depositedAmount, 0), 'price'), editable: value => isCashPayment(value) && !isCashDruingPickupCourse(value) && !isRemotePayment(value) && !isOfficePayment(value), dialogComponent: 'EditCodDeposits', dialogSize: '800px', isCourseDeoposits: true },
          { name: 'courseType', text: 'Typ kursu', value: 'courseType', type: 'textField', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'dueDate', type: 'date', col: 3, editable: { path: 'courseStatus', value: 'Zaplanuj' }, rules: ['required'] },
          { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', type: 'textField', extraClass: 'course-status', col: 3 },
          { name: 'timeSlotWithNotes', text: 'Przedział godzinowy', value: 'timeSlot', type: 'timeSlot', col: 3, editable: true, rules: ['required'] },
          { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName', type: 'textField', col: 3 },
          { name: 'userNotes', text: 'Notatka jednorazowa', value: 'userNotes', type: 'textArea', col: 3, editable: true },
          { name: 'driverNotes', text: 'Notatka kierowcy', value: 'driverNotes', type: 'textArea', col: 3 },
          { name: 'courseId', text: 'Nr kursu', value: 'id', type: 'textField', col: 3 },
          { name: 'receiverData', text: 'Dane przyjmującego', value: 'receiverData', col: 3 },
          { name: 'signatureStatus', text: 'Status potwierdzenia SMS', value: 'signatureStatus', type: 'textField', col: 3 },
          { name: 'trailerLicencePlate', text: 'Numer rejestracyjny przyczepy', value: 'driver.vehicle.trailer.licensePlate', col: 3, condition: isTrailer },
          { name: 'carrierLicensePlate', text: 'Numer rejestracyjny pojazdu', value: '', valueGetter: vehicleLicensePlate, col: 3, condition: isCarrier },
          { name: 'kpoNumber', text: 'Nr KPO', value: 'bdoCard.cardNumber', type: 'containerId', to: 'defaultSingleView', idPath: 'bdoCard.id', routeParams: { tableName: 'bdoCards' }, col: 3, editable: false, condition: isDebrisTypeAggregate },
        ]

      },
      {
        name: 'courses',
        text: 'Szczegóły kursu',
        type: 'left',
        endpoint: 'courses',
        condition: order => order?.courses?.length > 1,
        headerActions: [{ ...actions.resendTextConfirmation }, { ...actions.generateDigitalSignature }],
        dataPath: 'courses[1]',
        component: 'DetailsSectionCourses',
        fields: [
          { name: 'codDepositsSum', text: 'Rozliczono', value: 'codDeposits', type: '', col: 3, header: true, valueGetter: value => formatValue(value.reduce((acc, obj) => acc + obj.depositedAmount, 0), 'price'), editable: value => isCashPayment(value) && !isCashDuringLeaveCourse(value) && !isRemotePayment(value) && !isOfficePayment(value), dialogComponent: 'EditCodDeposits', dialogSize: '800px', isCourseDeoposits: true },
          { name: 'courseType', text: 'Typ kursu', value: 'courseType', type: 'textField', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'dueDate', type: 'date', col: 3, editable: { path: 'courseStatus', value: 'Zaplanuj' }, rules: ['required'] },
          { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', type: 'textField', extraClass: 'course-status', col: 3 },
          { name: 'timeSlotWithNotes', text: 'Przedział godzinowy', value: 'timeSlot', type: 'timeSlot', col: 3, editable: true, rules: ['required'] },
          { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName', type: 'textField', col: 3 },
          { name: 'userNotes', text: 'Notatka jednorazowa', value: 'userNotes', type: 'textArea', col: 3, editable: true },
          { name: 'driverNotes', text: 'Notatka kierowcy', value: 'driverNotes', type: 'textArea', col: 3 },
          { name: 'courseId', text: 'Nr kursu', value: 'id', type: 'textField', col: 3 },
          { name: 'receiverData', text: 'Dane przyjmującego', value: 'receiverData', col: 9 },
          { name: 'signatureStatus', text: 'Status potwierdzenia SMS', value: 'signatureStatus', type: 'textField', col: 3 },
          { name: 'trailerLicencePlate', text: 'Numer rejestracyjny przyczepy', value: 'driver.vehicle.trailer.licensePlate', col: 3, condition: isTrailer },
          { name: 'carrierLicensePlate', text: 'Numer rejestracyjny pojazdu', value: '', valueGetter: vehicleLicensePlate, col: 3, condition: isCarrier },
          { name: 'kpoNumber', text: 'Nr KPO', value: 'bdoCard.cardNumber', type: 'containerId', to: 'defaultSingleView', idPath: 'bdoCard.id', routeParams: { tableName: 'bdoCards' }, col: 3, editable: false },
        ]
      },
      {
        name: 'paymenkLinks',
        text: 'Historia płatności',
        type: 'left',
        component: 'DetailsSectionPaymentByLink',
        multiple: 'payment.linkSettlements',
        headerActions: [{ ...actions.generatePaymentLink }],
        fields: [
          { name: 'phoneNumbers', text: 'Numery telefonów', value: '', type: 'textField', col: 3, valueGetter: value => value?.paymentLink?.phoneNumbers.join(', ') || '', condition: link => link?.paymentLink?.paymentLinkType === 'text' },
          { name: 'token', text: 'Token płatności', value: 'paymentLink.token', type: 'textField', col: 5, condition: link => link?.paymentLink?.paymentLinkType === 'mail' },
          { name: 'sessionId', text: 'ID płatności', value: 'paymentLink.sessionId', type: 'textField', col: 7, condition: link => link?.paymentLink?.paymentLinkType === 'mail' },
          { name: 'url', text: 'Link', value: 'paymentLink.url', type: 'textField', col: 12, condition: link => link?.paymentLink?.paymentLinkType === 'mail' },
          { name: 'allOrdersValue', text: 'Pełna kwota płatności', value: 'paymentLink.amount', type: 'textField', col: 3 },
          { name: 'currentOrderValue', text: 'Kwota z aktualnego zlecenia', value: 'amount', type: 'textField', col: 3 },
          { name: 'orders', text: 'Zawarte zlecenia', value: 'paymentLink.orders', type: 'textField', valueGetter: value => value.map(v => v.refNumber).join(', '), col: 6, condition: link => link?.paymentLink?.paymentLinkType === 'mail' },
          { name: 'isPaid', text: 'Opłacone', value: 'paymentLink.paid', type: 'textField', valueGetter: value => value ? 'Tak' : 'Nie', col: 3, condition: link => link?.paymentLink?.paymentLinkType === 'mail' },
          { name: 'emails', text: 'E-maile', value: 'paymentLink.emails', type: 'textField', valueGetter: value => value.join(', '), col: 9, condition: link => link?.paymentLink?.paymentLinkType === 'mail' },
        ]
      },
      {
        name: 'customServices',
        text: 'Usługi',
        type: 'left',
        multiple: 'customServices',
        condition: order => order?.customServices?.length,
        component: 'DetailsSectionList',
        fields: [
          { name: 'name', value: 'name', text: 'Nazwa usługi', type: 'textField', col: 2 },
          { name: 'invoiceNumber', value: 'invoiceNumber', text: 'Nr faktury', type: 'textField', col: 2 },
          { name: 'issueDate', value: 'invoiceIssueDate', text: 'Data wystawienia', type: 'date', col: 8 },
          { name: 'netValue', value: 'netValue', text: 'Cena netto', type: 'textField', col: 2 },
          { name: 'grossValue', value: 'grossValue', text: 'Cena brutto', type: 'textField', col: 2 },
          { name: 'quantity', value: 'quantity', text: 'Ilość', type: 'textField', col: 2 },
          { name: 'totalNetValue', value: 'totalNetValue', text: 'Wartość netto', type: 'textField', col: 2 },
          { name: 'totalGrossValue', value: 'totalGrossValue', text: 'Wartość brutto', type: 'textField', col: 2 },
        ]
      },
      {
        name: 'client',
        text: 'Dane klienta',
        type: 'right',
        endpoint: 'client',
        fields: [
          { name: 'clientName', text: 'Nazwa klienta', value: 'client.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'client.id', label: 'FV', col: 12, condition: clientIsPayer },
          { name: 'clientName', text: 'Nazwa klienta', value: 'client.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'client.id', col: 12, condition: middlemanIsPayer },
          { name: 'clientName', text: 'Nazwa klienta', value: 'client.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'client.id', col: 12, condition: clientIsPayerWithoutMiddleman },
          { name: 'department', text: 'Oddział', value: 'address.department.name', type: 'textField', col: 6 },
          { name: 'bdoType', text: 'Logika BDO', value: 'client.bdoType', type: 'textField', col: 6 },
          { name: 'formattedAddress', text: 'Adres lokalizacji', value: 'address.formattedAddress', type: 'textField', col: 6 },
          { name: 'email', text: 'Email', value: 'client.email', type: 'textField', col: 6 },
          { name: 'phoneNumber', text: 'Telefon', value: 'client.phoneNumber', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'client.bdoNumber', type: 'textField', col: 6 },
          { name: 'notes', text: 'Notatka do klienta', value: 'client.notes', type: 'textField', col: 12 },
        ]
      },
      {
        name: 'middleman',
        condition: order => order?.middleman,
        text: 'Dane pośrednika',
        type: 'right',
        fields: [
          { name: 'clientName', text: 'Nazwa pośrednika', value: 'middleman.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'middleman.id', label: 'FV', col: 12, condition: middlemanIsPayer },
          { name: 'clientName', text: 'Nazwa pośrednika', value: 'middleman.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'middleman.id', col: 12, condition: clientIsPayer },
          { name: 'bdoType', text: 'Logika BDO', value: 'middleman.bdoType', type: 'textField', col: 6 },
          { name: 'email', text: 'Email', value: 'middleman.email', type: 'textField', col: 6 },
          { name: 'phoneNumber', text: 'Telefon', value: 'middleman.phoneNumber', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'middleman.bdoNumber', type: 'textField', col: 6 },
          { name: 'notes', text: 'Notatka do pośrednika', value: 'middleman.notes', type: 'textField', col: 12 },
        ]
      },
      {
        name: 'invoices',
        text: 'Dokumenty',
        type: 'right',
        multiple: 'invoices',
        headerActions: [{ ...actions.sendInvoicesFromSingleOrder, tableName: 'invoices', condition: 'hasSelectedItems' }, { ...actions.deleteInvoiceStatus, condition: hasInvoiceStatus }],
        component: 'DetailsSectionList',
        conditionalWarnings: [
          {
            name: 'checkInvoiceStatus',
            condition: 'exists',
            targetA: 'invoiceStatus',
          },
        ],
        fields: [
          { name: 'invoiceNumber', value: 'invoiceNumber', text: 'Numer dokumentu', type: 'textField', to: 'singleInvoice', col: 4, warning: { condition: isInvoiceHidden, text: 'Zlecenie zostało odpięte od faktury' } },
          { name: 'paymentDueDate', value: 'paymentDueDate', text: 'Termin płatności', type: 'textField', col: 3, condition: isNotInvoiceHidden },
          { name: 'paid', value: 'paid', text: 'Zapłacono', type: 'textField', col: 3, condition: isNotInvoiceHidden },
          { name: 'select', value: 'select', text: '', type: '', col: 2, condition: isNotInvoiceHidden },
        ]
      },
      { name: 'files', text: 'Załączniki', multiple: 'files', type: 'right', component: 'DetailsSectionFiles' },
      { name: 'reports', text: 'Raporty', multiple: 'reports', type: 'right', component: 'DetailsSectionReports' },
      { name: 'kpo', text: 'Karta KPO/KPOK', multiple: 'bdoFile', type: 'right', component: 'DetailsSectionKPO' },
      { name: 'operationHistory', text: 'Historia operacji', multiple: 'history', type: 'right', component: 'DetailsSectionOperationHistory' }
    ],
    emptyOrderSections: [
      {
        name: 'emptyOrder',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'containerType', text: 'Zamówiony typ kontenera', value: 'containerWithType', type: 'textField', col: 3 },
          { name: 'phoneNumber', text: 'Telefon', value: 'address.phoneNumber', type: 'textField', col: 3 },
          { name: 'formattedAddress', text: 'Adres lokalizacji', value: 'address.formattedAddress', type: 'textField', col: 6 }
        ]
      },
      {
        name: 'course',
        text: 'Szczegóły kursu',
        type: 'left',
        fields: [
          { name: 'courseType', text: 'Typ kursu', value: 'swapCourse.courseType', type: 'textField', to: 'singleClient', idPath: 'client.id', col: 3 },
          { name: 'courseId', text: 'Nr kursu', value: 'swapCourse.id', type: 'textField', col: 3 },
          { name: 'courseStatus', text: 'Status kursu', value: 'swapCourse.courseStatus', type: 'textField', extraClass: 'course-status', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'swapCourse.dueDate', type: 'date', col: 3 },
          { name: 'timeSlot', text: 'Przedział godzinowy', value: 'swapCourse.timeSlot', type: 'timeSlot', col: 3 },
          { name: 'driverFullName', text: 'Kierowca', value: 'swapCourse.driver.fullName', type: 'textField', col: 3 },
          { name: 'userNotes', text: 'Notatka jednorazowa', value: 'swapCourse.userNotes', type: 'textArea', col: 3 },
          { name: 'driverNotes', text: 'Notatka kierowcy', value: 'swapCourse.driverNotes', type: 'textArea', col: 3 }
        ]
      },
      {
        name: 'client',
        text: 'Dane klienta',
        type: 'right',
        fields: [
          { name: 'clientName', text: 'Nazwa klienta', value: 'client.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'client.id', col: 12 },
          { name: 'bdoType', text: 'Logika BDO', value: 'client.bdoType', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'client.bdoNumber', type: 'textField', col: 6 },
          { name: 'settlementType', text: 'Typ dokumentu', value: 'client.settlementType', type: 'textField', col: 6 },
          { name: 'blocked', text: 'Zablokowany', value: 'client.blocked', type: 'textField', col: 6 }
        ]
      },
      {
        name: 'kpo',
        text: 'Karta KPO/KPOK',
        multiple: 'bdoFile',
        type: 'right',
        component: 'DetailsSectionKPO'
      },
    ]
  }
}
