import Base from './base'
import get from 'lodash/get'
import { getCoursesStatistics } from '../utils'

const mapItemMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, name } = filter
    const filterValue = filterBy.value ?? filterBy

    if (['idleDays', 'containerIdleDays'].includes(name)) {
      const daysCount = get(item, propPath)

      if (daysCount <= 7) return filterValue === 'less_than_week'
      else if (daysCount <= 30) return filterValue === 'week_to_month'
      return filterValue === 'above_month'
    }

    return typeof filterValue === 'object' && Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      // filter can be either a String or object with name and value
      : get(item, propPath) === filterValue
  })
}

const getters = {
  getStatistics: (state) => getCoursesStatistics(state.items)
}

const map = (tableName) => {
  const module = new Base(tableName, mapItemMatchesFiltersCriteria)
  module.getters = {
    ...module.getters,
    ...getters
  }
  return module
}

export default map
