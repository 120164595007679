<template>
  <v-tooltip
    bottom
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        small
        :disabled="!text"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-text="infoIcon" />
      </v-btn>
    </template>
    {{ text }}
  </v-tooltip>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    infoIcon () {
      return this.text
        ? 'mdi-information-outline'
        : 'mdi-information-off-outline'
    }
  }
}
</script>
