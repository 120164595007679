<template>
  <div
    class="details details--full mt-80px"
    v-if="configured"
  >
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="entity"
      />
    </DetailsToolbar>
    <div class="px-4">
      <SectionsWrapper
        :details="details"
        :data="entity"
      >
        <template #default="{ detail, slotData }">
          <component
            :is="detail.component || 'DetailsSection'"
            :detail="getSectionFields(detail)"
            :data="slotData"
            :list="nestedSection(detail.multiple)"
          />
        </template>
      </SectionsWrapper>
    </div>
  </div>
</template>

<script>
import { getTableOptions } from '../../utils'
import SectionsWrapper from './Sections/SectionsWrapper'
import DetailsSectionCourses from './Sections//DetailsSectionCourses'
import DetailsSectionCoursesTable from './Sections/DetailsSectionCoursesTable'
import DetailsSectionMessagesHistory from './Sections/DetailsSectionMessagesHistory'
import DetailsSectionOperationHistory from './Sections/DetailsSectionOperationHistory.vue'
import DetailsSectionInvoiceItems from './Sections/DetailsSectionInvoiceItems'
import DetailsSectionKPOPdf from './Sections/DetailsSectionKPOPdf'
import DetailsDebrisTypesDepartments from './Sections/DetailsDebrisTypesDepartments'

import { mapState, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import singleViewMixin from '../../mixins/singleViewMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'

export default {
  components: {
    SectionsWrapper,
    DetailsSectionCourses,
    DetailsSectionCoursesTable,
    DetailsSectionMessagesHistory,
    DetailsSectionOperationHistory,
    DetailsSectionInvoiceItems,
    DetailsSectionKPOPdf,
    DetailsDebrisTypesDepartments
  },
  provide () {
    return {
      notWebSocketHandled: true
    }
  },
  mixins: [
    navigateBackMixin, // prevRoute,
    singleViewMixin, // ActionButtons, DetailsToolbar, DetailsSection, DetailsSectionList, getDetailsActions,
    // getDetailsTitle, isBlocked, details, configured, nestedSection, detailsColumnSize, setDetails, getSectionFields, nestedSection
  ],
  data: () => ({
    prevRoute: null,
  }),
  computed: {
    ...mapState({
      entity: state => state.singleView.entity, // singleViewMixin purpose
    }),
    tableName () {
      return this.$route.params.tableName
    },
  },
  created () {
    this.setupSingleView()
  },
  watch: {
    tableName () {
      this.setupSingleView()
    }
  },
  beforeDestroy () {
    this.clearSingleView()
  },
  methods: {
    ...mapActions({
      getSingleViewEntity: 'singleView/getSingleViewEntity',
      clearSingleView: 'singleView/clearSingleViewEntity',
    }),
    setupSingleView() {
      const { tableName, id } = this.$route.params
      const { endpoint } = getTableOptions(this.tableName).specialFlag || {}
      this.getSingleViewEntity({ tableName: endpoint || tableName, id })
        .then(() => {
          this.setDetails()
        })
        .catch(() => {
          if (isEmpty(this.entity)) {
            this.$router.back()
          } else {
            this.setDetails()
          }
        })
    }
  }
}
</script>
