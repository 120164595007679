<template>
  <v-expansion-panel>
    <template v-if="editMode">
      <v-card>
        <v-card-title>
          <v-text-field
            outlined
            v-model="newValues.topic"
            placeholder="Wpisz temat"
            hide-details
            class="topic-input"
          />
          <v-btn
            outlined
            color="primary"
            class="base-hover ml-4"
            @click="closeEditMode"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="primary"
            class="base-hover ml-4"
            :disabled="!newValues.topic || !newValues.text"
            @click="saveHint"
          >
            Zapisz
          </v-btn>
        </v-card-title>

        <v-card-text>
          <tiptap-vuetify
            v-model="newValues.text"
            :extensions="extensions"
          />
        </v-card-text>
      </v-card>
    </template>

    <template v-else>
      <v-expansion-panel-header>
        <template #default="{open}">
          <div class="d-flex justify-space-between align-center">
            <b>{{ hint.topic }}</b>
            <div
              v-if="open"
              class="d-flex justify-end"
            >
              <v-btn
                color="primary"
                class="base-hover mr-3"
                small
                icon
                title="Edytuj"
                @click="editMode=true"
              >
                <Icon
                  name="edytuj"
                  size="small"
                />
              </v-btn>
              <v-btn
                color="error"
                class="base-hover mr-3"
                small
                icon
                title="Usuń"
                @click="openConfirmationModal"
              >
                <Icon
                  name="usun"
                  size="small"
                />
              </v-btn>
            </div>
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        v-html="safeHintText"
        class="px-6 py-4"
      />
    </template>
  </v-expansion-panel>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import { mapActions } from 'vuex'

const extensions = [
  History, Blockquote, Link, Underline, Strike, Italic, ListItem, BulletList, OrderedList,
  [Heading, { options: { levels: [1, 2, 3] } }], Bold, Code, HorizontalRule, Paragraph, HardBreak
]

export default {
  props: {
    hint: {
      type: Object,
      required: true
    }
  },
  components: { TiptapVuetify },
  data: () => ({
    extensions,
    editMode: false,
    newValues: {
      text: null,
      topic: null
    }
  }),
  computed: {
    safeHintText () {
      return this.hint.text?.replace(/<script.*>.*<\/script>/ims, ' ')
    }
  },
  watch: {
    editMode (isEditMode) {
      this.$emit('editMode', isEditMode)
    }
  },
  mounted () {
    if (Object.keys(this.hint).length === 0) this.editMode = true
    this.newValues = { ...this.hint }
  },
  methods: {
    ...mapActions({
      createHint: 'hints/createHint',
      updateHint: 'hints/updateHint',
      setDialog: 'layout/setDialog',
      closeDialog: 'layout/closeDialog',
      deleteHint: function (dispatch) {
        return dispatch('hints/deleteHint', this.hint.id)
          .then(() => { this.closeDialog() })
      },
    }),
    closeEditMode () {
      this.editMode = false
      this.$emit('close')
    },
    saveHint () {
      if (this.hint.id) {
        this.updateHint({
          id: this.hint.id,
          params: this.newValues
        }).then(() => {
          this.editMode = false
        })
      } else {
        this.createHint(this.newValues)
          .then(() => {
            this.$emit('close')
          })
      }
    },
    openConfirmationModal () {
      this.setDialog({
        data: {
          component: 'ConfirmationDialog',
          text: 'Usuń wskazówkę',
          description: 'Czy na pewno chcesz usunąć wskazówkę?',
          callback: this.deleteHint
        },
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-input.v-text-field::v-deep .v-input__slot {
  margin-bottom: 0px !important;
}
</style>
