<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      drivers: state => state.drivers.items,
    }),
    parsedDrivers () {
      return this.drivers.map(driver => ({
        text: driver.fullName,
        value: driver.id,
        disabled: this.hasNotTerminal(driver)
      }))
    },
  },
  methods: {
    hasNotTerminal (driver) {
      return !driver.terminal && 'Brak terminala'
    },
  }
}
</script>
