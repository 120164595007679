import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hsc-window-style-white',[_c('hsc-window',{staticClass:"floating-popup",style:(("z-index: " + _vm.dialogZIndex)),attrs:{"title":"Notatka do połączenia","position-hint":"-60 / -30","close-button":true,"is-open":_vm.showModal},on:{"update:isOpen":function($event){_vm.showModal=$event},"update:is-open":function($event){_vm.showModal=$event},"move-start":_vm.updateDialogIndex}},[_c(VCard,{attrs:{"min-width":"400px","color":"white"}},[_c(VForm,{ref:"addCallEntryForm",staticClass:"custom-form",attrs:{"id":"addCallEntryForm"},on:{"submit":function($event){$event.preventDefault();return _vm.addCallEntry.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VCardText,{staticClass:"custom-form pt-6"},[(!_vm.contactId)?_c(VTextField,{staticClass:"mb-6",attrs:{"rules":[_vm.rules.required],"placeholder":"Wpisz imię i nazwisko kontaktu","label":"Imię i nazwisko kontaktu","outlined":"","hide-details":""},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}}):_vm._e(),_c(VTextarea,{attrs:{"rules":[_vm.rules.required],"outlined":"","rows":3,"auto-grow":"","label":"Notatki","hide-details":"","placeholder":"Wpisz notatki do rozmowy"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1),_c(VCardActions,[_c(VBtn,{staticClass:"base-hover ml-auto",attrs:{"color":"primary","form":"addCallEntryForm","disabled":!_vm.isFormValid,"type":"submit"}},[_vm._v(" Stwórz wpis ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }