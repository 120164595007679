import { dateStringFormat } from './index'

const dateValues = [
  'courseDeliveryDate',
  'courseDepartureDate',
  'courseDeliveryAt',
  'createdAt',
  'dueDate',
  'doneDate',
  'leaveDate',
  'rentalDate',
  'pickupDate',
  'issueDate',
  'saleDate',
  'paymentDate',
  'exportDate',
  'nextRealisationDate',
  'revisedAt',
  'startDate',
  'endDate',
  'paymentDueDate',
  'contractDate',
]
const priceValues = [
  'discount',
  'transportNetValue',
  'transportGrossValue',
  'debrisNetValue',
  'debrisGrossValue',
  'unitDebrisNetValue',
  'unitDebrisGrossValue',
  'additionalNetValue',
  'additionalGrossValue',
  'paidValue',
  'purchaseLimit',
  'purchaseLimitRemaining',
  'vatValue',
  'netValue',
  'grossValue',
  'totalNetValue',
  'totalGrossValue',
  'depositedAmount',
  'requiredAmount',
  'allOrdersValue',
  'currentOrderValue',
  'totalGrossValueWithDiscount',
  'paymentTotalGrossValueWithDiscount'
]
const booleanValues = [
  'bLicense',
  'cLicense',
  'ceLicense',
  'otherLicense',
  'officePayment',
  'blocked',
  'done',
  'paid',
  'visibleForDriver',
  'anyContainer',
  'signatureRequired',
  'isMiddleman'
]

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
]

const percentageValues = ['vatPercentage', 'vat']
const dateWithTimeValues = [
  'plannedTransportTime',
  'realTransportTime',
  'depositedAt',
  'blockedAt',
  'unblockedAt',
  'callStart',
  'confirmationGeneratedAt',
  'approvedAt',
]
const weightValues = ['maxWeight']
const daysValues = ['paymentDueDate']

const origins = {
  client_app: 'Aplikacja klienta',
  desktop: 'Spedytor',
  template: 'Sklep',
}

const labelValue = name => {
  if (dateValues.includes(name)) return 'date'
  if (dateWithTimeValues.includes(name)) return 'dateWithTime'
  if (priceValues.includes(name)) return 'price'
  if (booleanValues.includes(name)) return 'boolean'
  if (percentageValues.includes(name)) return 'percentage'
  if (weightValues.includes(name)) return 'weight'
  if (daysValues.includes(name)) return 'days'
  return name
}

const formatValue = (value, typeName, emptyValue = '-', entity) => {
  if (value === undefined || value === null) return emptyValue
  const typesEnum = {
    date: dateStringFormat(value),
    dateWithTime: dateStringFormat(value, true),
    price: (value / 100).toFixed(2) + ' zł',
    boolean: value ? 'Tak' : 'Nie',
    maximumDistance: value / 1000 + ' km',
    percentage: value + '%',
    weight: value / 1000 + ' T',
    active: value ? 'aktywny' : 'nieaktywny',
    volume: value / 1000 + ' m³',
    timeSlot: `${value[0]} - ${value[1]}`,
    timeSlotWithNotes: `${value[0]} - ${value[1]} ${entity?.timeNotes ? `(${entity.timeNotes})` : ''}`,
    blocked: value ? 'blokada' : emptyValue,
    days: value ? `${value} ${value === 1 ? 'dzień' : 'dni'}` : emptyValue,
    origin: origins[value],
    fileSize: function() {
      if (value === 0) return '0 Bytes'
      const k = 1024
      const sizes = ['Bytes', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(value) / Math.log(1024))
      return `${parseFloat((value / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
    },
  }
  return typeName === 'fileSize' ? typesEnum.fileSize() : typesEnum[labelValue(typeName)] || value
}

const mapArray = (objectsArray, { formatLargeNumbers = false } = {}) => {
  return objectsArray?.length
    ? objectsArray.map(object => {
      const tmpObject = { ...object }
      for (const key in object) {
        const isPriceValue = labelValue(key) === 'price'
        const tempValue = formatValue(object[key], labelValue(key))
        tmpObject[key] = isPriceValue && formatLargeNumbers ? formatLargeNumber(tempValue) : tempValue
      }
      return tmpObject
    })
    : []
}

const formatLargeNumber = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export { dateValues, priceValues, booleanValues, percentageValues, dateWithTimeValues, formatValue, mapArray, months, formatLargeNumber }
