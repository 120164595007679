import isLogisticsApp from '../../const/isLogisticsApp'
import { clientOrders as logisticsAppClientOrders, clientBDO, clientInvoices } from './clientTables'
import aggregatePrices from './administration/pricing/aggregatePrices'
import aggregateTypes from './administration/warehouse/aggregateTypes'
import alerts from './alerts'
import assigningBdoCards from './bdoCards/assigningBdoCards'
import bdoCards from './bdoCards/bdoCards'
import businessPlaces from './administration/warehouse/businessPlaces'
import clientAppOrders from './clientAppOrders'
import clients from './administration/clients'
import clientsReports from './clientsReports'
import codDeposits from './codDeposits'
import communes from './administration/warehouse/communes'
import containers from './containers/containers'
import containersHistory from './containers/containersHistory'
import containersMap from './map/containersMap'
import containerTypes from './containers/containerTypes'
import courses from './courses/courses'
import coursesMap from './map/coursesMap'
import coursesWithoutCards from './courses/coursesWithoutCards'
import debrisPrices from './administration/pricing/debrisPrices'
import debrisTypes from './administration/warehouse/debrisTypes'
import departments from './administration/warehouse/departments'
import drivers from './administration/warehouse/drivers'
import trailers from './administration/warehouse/trailers'
import driversLocation from './map/driversLocation'
import callEntries from './callEntries'
import invoiceItemTemplates from './administration/invoiceItemTemplates'
import invoices from './invoices'
import internalBdoCards from './bdoCards/internalBdoCards'
import bdoCardTemplates from './bdoCards/bdoCardTemplates'
import orders from './orders/orders'
import orderSchedules from './orders/orderSchedules'
import ordersCompleted from './orders/ordersCompleted'
import orderTemplates from './orderTemplates'
import prospects from './prospects'
import mainReport from './reports/mainReport'
import incomeReport from './reports/incomeReport'
import revenueReport from './reports/revenueReport'
import revisionIndex from './bdoCards/revisionIndex'
import routes from './courses/routes'
import routesCompleted from './courses/routesCompleted'
import roles from './administration/roles'
import settings from './settings'
import tasks from './tasks'
import terminals from './administration/warehouse/terminals'
import transportPrices from './administration/pricing/transportPrices'
import users from './administration/users'
import vehicles from './administration/warehouse/vehicles'
import customReport from './customReport'
import logCRMActiveUsers from './logCRMActiveUsers'

const logisticsConfigs = {
  aggregatePrices,
  aggregateTypes,
  alerts,
  assigningBdoCards,
  bdoCards,
  bdoCardTemplates,
  businessPlaces,
  clientAppOrders,
  clientBDO,
  clientInvoices,
  clientOrders: logisticsAppClientOrders,
  clients,
  clientsReports,
  codDeposits,
  communes,
  containers,
  containersHistory,
  containersMap,
  courses,
  coursesMap,
  coursesWithoutCards,
  debrisPrices,
  drivers,
  driversLocation,
  incomeReport,
  internalBdoCards,
  callEntries,
  invoiceItemTemplates,
  invoices,
  logCRMActiveUsers,
  mainReport,
  orders,
  orderSchedules,
  ordersCompleted,
  orderTemplates,
  prospects,
  revenueReport,
  revisionIndex,
  routes,
  routesCompleted,
  roles,
  settings,
  tasks,
  terminals,
  transportPrices,
  trailers,
  users,
  customReport,
  vehicles
}

const clientConfigs = {}

const sharedConfigs = {
  containerTypes,
  debrisTypes,
  departments
}

export default {
  ...(isLogisticsApp ? logisticsConfigs : clientConfigs),
  ...sharedConfigs
}
