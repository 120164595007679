import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"px-6 py-4 w-100 d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('b',[_vm._v(_vm._s(_vm.title))]),(Object.keys(_vm.summary).length)?_c('div',{staticClass:"d-flex align-center text-subtitle-1 ml-4"},_vm._l((_vm.summary),function(data){return _c('div',{key:data.key,staticClass:"px-2"},[_c('span',[_vm._v(_vm._s(data.label)+": ")]),_c('b',[_vm._v(_vm._s(data.value))])])}),0):_vm._e()]),_c('div',[_vm._t("head"),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('toggle-fullscreen')}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen')+" ")])],1)],2)]),_c(VDivider),_c('div',{staticClass:"chart-wrapper",class:[_vm.padding],style:({
      height: _vm.isFullscreen ? '800px' : '',
      minHeight: _vm.isFullscreen ? 'calc(100vh - 400px)' : ''
    })},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }