import actions from '../../actions'
import router from '../../../router/index'

const hasOrder = container => !!container?.order

export default {
  header: [
    { ...actions.addNewContainer, create: true }
  ],
  table: {
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [
      { name: 'containerTypeIds', text: 'Typ kontenera', options: 'containerTypes', filterBy: [], multiple: true, propPath: 'containerTypeId' },
      { name: 'onBase', text: 'Pozycja', options: '', filterBy: '', threeWaySwitch: true, trueLabel: 'Baza/W trasie', falseLabel: 'U klienta', propPath: 'onBase' },
      { name: 'active', text: 'Tylko aktywne', options: '', filterBy: '', switch: true, falseValue: '', propPath: 'active' },
    ],
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'refNumber', text: 'Nr kontenera', value: 'refNumber', show: true },
      { name: 'serialNumber', text: 'Nr seryjny', value: 'serialNumber', show: true },
      { name: 'containerType', text: 'Typ kontenera', value: 'containerTypeName', show: true },
      { name: 'location', text: 'Lokalizacja', value: 'addressFormattedAddress', show: true },
      { name: 'coordinatesType', text: 'Współrzędne kontenera', value: 'orderCoordinatesType', show: true },
      { name: 'orderRefNumber', text: 'Nr zlecenia', value: 'orderRefNumber', show: true },
      { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveDate', show: true },
      { name: 'pickupDate', text: 'Data zabrania', value: 'pickupDate', show: true },
      { name: 'reserved', text: 'Zarezerwowany', value: 'reserved', show: true },
      { name: 'client', text: 'Klient', value: 'clientName', show: true },
      { name: 'phoneNumber', text: 'Telefon kontaktowy', value: 'addressPhoneNumber', show: true },
      { name: 'containerNotes', text: 'Notatki nt. kontenera', value: 'orderContainerNotes', show: true },
      { name: 'notes', text: 'Dodatkowe informacje', value: 'notes', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.findAssignedCourses, icon: 'search-glass' },
      { ...actions.deleteContainer, icon: 'usun' },
    ],
    menu: [
      { ...actions.findAssignedCourses },
      { ...actions.deleteContainer },
      { ...actions.pickupContainer, idPath: 'item.orderId' },
      { ...actions.swapContainer, idPath: 'item.orderId' },
      { ...actions.navigateToClient, callback: (item) => router.push({ name: 'singleClient', params: { id: item.clientId } }) },
      { ...actions.updateContainerNotes },
    ]
  },
  details: {
    title: 'Kontener nr {{refNumber}}',
    actions: [
      { ...actions.deleteContainer, redirect: true },
      { ...actions.updateContainerNotes },
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'containers',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'refNumber', text: 'Nr kontenera', value: 'refNumber', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'serialNumber', text: 'Nr seryjny', value: 'serialNumber', type: 'textField', col: 3, editable: true },
          { name: 'containerTypeId', text: 'Typ kontenera', value: 'containerType.name', type: 'selectFromItems', options: 'containerTypes', col: 3, editable: true, rules: ['required'] },
          { name: 'location', text: 'Lokalizacja', value: 'order.address.formattedAddress', type: 'textField', col: 3, condition: hasOrder },
          { name: 'coordinatesType', text: 'Współrzędne kontenera', value: 'order.coordinatesType', type: 'textField', col: 3, condition: hasOrder },
          { name: 'orderRefNumber', text: 'Nr zlecenia', value: 'order.refNumber', to: 'singleOrder', idPath: 'order.id', type: 'textField', col: 3, condition: hasOrder },
          { name: 'client', text: 'Klient', value: 'order.client.name', to: 'singleClient', idPath: 'order.client.id', type: 'textField', col: 3, condition: hasOrder },
          { name: 'leaveDate', text: 'Data podstawienia', value: 'order.leaveDate', type: 'textField', col: 3, condition: hasOrder },
          { name: 'pickupDate', text: 'Data zabrania', value: 'order.pickupDate', type: 'textField', col: 3, condition: hasOrder },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'departmentId', text: 'Oddział', value: 'department.name', options: 'departments', type: 'selectFromItems', col: 3, editable: true },
          { name: 'notes', text: 'Dodatkowe informacje', value: 'notes', type: 'textArea', col: 9, editable: true },
        ]
      }
    ]
  }
}
