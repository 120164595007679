import api from '../api/v1'
import { Incident } from './../models'
import debounce from 'lodash/debounce'

export default {
  namespaced: true,
  state: {
    isProcessing: false,
    entity: new Incident()
  },
  mutations: {
    SET_INCIDENT_PROCESSING (state, status) {
      state.isProcessing = status
    },
    SET_SINGLE_INCIDENT (state, client) {
      state.entity = client
    }
  },
  actions: {
    setIncidentProcessing ({ commit }, status) {
      commit('SET_INCIDENT_PROCESSING', status)
    },
    setSingleIncident: debounce(({ commit }, incident) => {
      commit('SET_SINGLE_INCIDENT', new Incident(incident))
    }, 1000, { leading: true, trailing: true }),
    getSingleIncident ({ commit }, id) {
      commit('SET_INCIDENT_PROCESSING', true)
      api.getIncident(id)
        .then((res) => {
          commit('SET_SINGLE_INCIDENT', new Incident(res.data))
          commit('SET_INCIDENT_PROCESSING', false)
        })
    },
    clearSingleIncident ({ commit }) {
      commit('SET_SINGLE_INCIDENT', new Incident())
    }
  }
}
