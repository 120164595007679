export default {
  table: {
    isWebSocketHandled: true,
    maximumDateRange: 31,
    specialFlag: {
      endpoint: 'callEntries',
    },
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    filters: [
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: [], propPath: 'client.id' },
      { name: 'clientType', text: 'Typ klienta', options: 'clientTypes', filterBy: [], propPath: 'client.clientType' },
      { name: 'courseType', text: 'Typ kursu', options: 'courseTypes', filterBy: [], propPath: 'courses[].courseType' },
      { name: 'userIds', text: 'Spedytor', options: 'users', filterBy: [], propPath: 'user.id', multiple: true },
      { name: 'direction', text: 'Kierunek połączenia', options: 'callEntryDirections', filterBy: [], propPath: 'direction' },
      { name: 'received', text: 'Odebrano', threeWaySwitch: true, propPath: 'received' },
      { name: 'prospect', text: 'Prospekt', threeWaySwitch: true, propPath: 'prospect' },
    ],
    parameters: [
      { name: 'expand', text: '', show: true, value: 'expand', sortable: false, width: 50 },
      { name: 'id', text: 'Nr', value: 'id', show: true, sortable: false, width: 40 },
      { name: 'client', text: 'Klient', value: 'clientName', show: true, sortable: false, width: 200, maxLength: 20 },
      { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber', show: true, sortable: false, width: 120 },
      { name: 'callStart', text: 'Czas rozpoczęcia', value: 'callStart', show: true, sortable: false, width: 150 },
      { name: 'callDuration', text: 'Długość połączenia [s]', value: 'duration', show: true, sortable: false, width: 150 },
      { name: 'callDirection', text: 'Kierunek połączenia', value: 'direction', show: true, sortable: false, width: 150 },
      { name: 'callProspect', text: 'Prospekt', value: 'prospect', show: true, sortable: false, width: 100 },
      { name: 'callReceived', text: 'Odebrano', value: 'received', show: true, sortable: false, width: 100 },
      { name: 'notes', text: 'Notatka', value: 'notes', show: true, sortable: false, width: 100 },
      { name: 'callEntryCourseType', text: 'Akcja', value: 'courseType', show: true, sortable: false, maxLength: 40, width: 300 },
      { name: 'refNumber', text: 'Numer zlecenia', value: 'refNumber', show: true, sortable: false, maxLength: 15, width: 150 },
      { name: 'userFullName', text: 'Spedytor', value: 'userFullName', show: true, sortable: false, width: 150, maxLength: 15 },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false, width: 100 }
    ],
    daterangeCriteria: ['createdAt'],
  },
}
