<template>
  <div class="filters">
    <div
      class="filters__inputs justify-end"
      :style="tableTopWidth"
    >
      <TableModeFilter
        v-if="tableModeFilter"
        :table-name="tableName"
        v-bind="tableModeFilterProps"
      />
      <SearchBar
        v-if="searchBar"
        :table-name="tableName"
        v-bind="searchBarProps"
      />
      <WeekDaysPicker
        v-if="weekDaysPicker"
        :table-name="tableName"
        v-bind="weekDaysPickerProps"
      />
      <DateRangePicker
        v-if="dateRangePicker"
        :table-name="tableName"
        v-bind="dateRangePickerProps"
      />
      <ShowFiltersButton
        v-if="showFiltersButton"
        :table-name="tableName"
        :style="[!dateRangePicker ? { marginLeft: '20px' } : {}]"
        v-bind="showFiltersButtonProps"
      />
    </div>
    <TableFilters
      v-if="tableFilters"
      :table-name="tableName"
      v-bind="tableFiltersProps"
    />
  </div>
</template>

<script>
import TableModeFilter from './TableModeFilter'
import SearchBar from './SearchBar'
import DateRangePicker from './DateRangePicker'
import TableFilters from '../Table/TableFilters'
import ShowFiltersButton from './ShowFiltersButton'
import WeekDaysPicker from './WeekDaysPicker'
import { mapState } from 'vuex'

export default {
  components: {
    TableModeFilter,
    SearchBar,
    DateRangePicker,
    TableFilters,
    ShowFiltersButton,
    WeekDaysPicker,
  },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    tableModeFilter: {
      type: Boolean,
      deault: false
    },
    tableModeFilterProps: {
      type: Object,
      required: false
    },
    searchBar: {
      type: Boolean,
      deault: false
    },
    searchBarProps: {
      type: Object,
      required: false
    },
    weekDaysPicker: {
      type: Boolean,
      deault: false
    },
    weekDaysPickerProps: {
      type: Object,
      required: false
    },
    dateRangePicker: {
      type: Boolean,
      deault: false
    },
    dateRangePickerProps: {
      type: Object,
      required: false
    },
    tableFilters: {
      type: Boolean,
      deault: false
    },
    tableFiltersProps: {
      type: Object,
      required: false
    },
    showFiltersButton: {
      type: Boolean,
      deault: false
    },
    showFiltersButtonProps: {
      type: Object,
      required: false
    },
  },
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar
    }),
    tableTopWidth () {
      return 'width: calc(100% - ' + (this.sidebar.size > 0 ? 528 : 0) + 'px)'
    },
  },
}
</script>
