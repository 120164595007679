<template>
  <div class="py-4">
    <ExpandedReportTable
      v-model="hideDriversTable"
      :data="revenue.driver.data || []"
      :max-height="driversMaxHeight"
      title="Płatności w trasie"
      mode="drivers"
      class="mb-4"
    />

    <ExpandedReportTable
      v-model="hideUsersTable"
      :data="revenue.user.data || []"
      :max-height="usersMaxHeight"
      title="Płatności w biurze"
      mode="users"
    />
  </div>
</template>

<script>
import ExpandedReportTable from './Tables/ExpandedReportTable.vue'
import { mapState } from 'vuex'

const EXPANDED_TABLE_OFFSET = 140 // table header, footer(46), margin, view padding

export default {
  components: {
    ExpandedReportTable
  },
  data: () => ({
    hideDriversTable: false,
    hideUsersTable: false,
    viewHeight: 800,
  }),
  computed: {
    ...mapState({
      revenue: state => state.revenueReport
    }),
    driversMaxHeight() {
      return this.setMaxHeight(this.hideUsersTable)
    },
    usersMaxHeight() {
      return this.setMaxHeight(this.hideDriversTable)
    },
    isAnyFilterSelected () {
      return this.$store.getters['tables/isAnyFilterSelected']('reports')
    },
    isFiltersBarShown () {
      return this.$store.state.reports?.showFilters
    },
  },
  watch: {
    isAnyFilterSelected () {
      this.$nextTick(() => { this.getViewOffset() })
    },
    isFiltersBarShown () {
      this.$nextTick(() => { this.getViewOffset() })
    },
  },
  mounted() {
    this.getViewOffset()
  },
  methods: {
    getViewOffset() {
      const { header, tableTop } = this.$parent.$refs
      const headerHeight = header?.$el.clientHeight
      const tableTopHeight = tableTop?.clientHeight
      this.viewHeight = window.innerHeight - (headerHeight + tableTopHeight)
    },
    setMaxHeight(hidden) {
      if (this.viewHeight < 500) return 250
      const availableHeight = hidden
        ? this.viewHeight - (EXPANDED_TABLE_OFFSET - 46) // tableHeader is still shown
        : this.viewHeight / 2
      return availableHeight - EXPANDED_TABLE_OFFSET
    }
  }
}
</script>
