import actions from '../actions'
const debrisName = (type) => `${type.code} - ${type.name}`

export default {
  header: [],
  table: {
    maximumDateRange: 1096,
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    filters: [
      { name: 'transformed', text: '', options: '', filterBy: '', propPath: 'transformed', hide: true },
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'client.id' },
      { name: 'debrisTypeIds', text: 'Typ odpadu', options: 'debrisTypes', nameGetter: debrisName, filterBy: [], multiple: true, propPath: 'debrisType.id' },
      { name: 'containerTypeIds', text: 'Typ kontenera', options: 'containerTypes', filterBy: [], multiple: true, propPath: 'containerType.id' },
      { name: 'anyContainer', text: 'Dowolny kontener', options: '', filterBy: '', falseValue: '', switch: true, propPath: 'anyContainer' },
    ],
    parameters: [
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', show: true },
      { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveDate', show: true },
      { name: 'pickupDate', text: 'Data zabrania', value: 'pickupDate', show: true },
      { name: 'formattedAddress', text: 'Adres', value: 'address.formattedAddress', show: true },
      { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', show: true },
      { name: 'debrisType', text: 'Typ odpadu', value: 'debrisType.displayName', show: true },
      { name: 'clientName', text: 'Klient', value: 'contact.client.name', clientLabel: true, show: true },
      { name: 'contactPhoneNumber', text: 'Numer kontaktowy', value: 'contact.phoneNumber', show: true, sortable: false },
      { name: 'contactName', text: 'Osoba kontaktowa', value: 'contact', paramGetter: 'getFullName', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.transformOrder, icon: 'invoices_inactive' }
    ],
    menu: [
      { ...actions.transformOrder }
    ]
  },
  details: {
    title: 'Zamówienie',
    actions: [
      { ...actions.transformOrder, filled: true, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'client', text: 'Klient', value: 'client.name', type: 'textField', col: 6 },
          { name: 'clientPhoneNumber', text: 'Numer telefonu', value: 'client.phoneNumber', type: 'textField', col: 3 },
          { name: 'address', text: 'Adres zlecenia', value: 'address.formattedAddress', type: 'textField', col: 3 },
          { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', type: 'textField', col: 3 },
          { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', type: 'textField', col: 3 },
          { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'textField', col: 3 },
          { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'debrisType.code', type: 'textField', col: 3 },
          { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveDate', type: 'textField', col: 3 },
          { name: 'pickupDate', text: 'Data zabrania', value: 'pickupDate', type: 'textField', col: 3 },
          { name: 'contactName', text: 'Osoba kontaktowa', value: 'contact.name', type: 'textField', col: 3 },
          { name: 'contactPhoneNumber', text: 'Numer kontaktowy', value: 'contact.phoneNumber', type: 'textField', col: 3 },
          { name: 'anyContainer', text: 'Dowolny kontener', value: 'anyContainer', type: 'textField', col: 3 },
        ]
      },
      {
        name: 'swapInfo',
        text: 'Wymieniane zlecenie',
        type: 'right',
        fields: [
          { name: 'refNumber', text: 'Numer referencyjny', value: 'swapOrder.refNumber', to: 'singleOrder', idPath: 'swapOrder.id', type: 'textField', col: 6 },
          { name: 'containerWithType', text: 'Kontener', value: 'swapOrder.containerWithType', type: 'textField', col: 3 },
        ]
      },
      {
        name: 'leaveInfo',
        text: 'Powiązane zlecenie',
        type: 'right',
        fields: [
          { name: 'refNumber', text: 'Numer referencyjny', value: 'order.refNumber', to: 'singleOrder', idPath: 'order.id', type: 'textField', col: 6 },
          { name: 'containerWithType', text: 'Kontener', value: 'order.containerWithType', type: 'textField', col: 3 },
        ]
      },
      {
        name: 'courseInfo',
        text: 'Powiązany kurs',
        type: 'right',
        fields: [
          { name: 'id', text: 'ID kursu', value: 'course.id', col: 6 },
          { name: 'courseType', text: 'Typ kursu', value: 'course.courseType', col: 6 },
          { name: 'courseStatus', text: 'Status kursu', value: 'course.courseStatus', col: 6 },
          { name: 'containerWithType', text: 'Kontener', value: 'course.containerWithType', col: 3 },
        ]
      },
    ]
  }
}
