import Base from './base'
import api from '../api/v1'

const base = new Base('callEntries')

const actions = {
  getCounters ({ commit, rootGetters }) {
    const params = rootGetters['tables/getTableParameters']('callEntries').params
    api.getCallEntriesCounters(params)
      .then((resp) => {
        console.log('resp: ', resp)
        commit('SET_COUNTERS', resp.data)
      })
  },
}

base.actions = {
  ...base.actions,
  ...actions
}

export default base
