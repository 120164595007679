<template>
  <div
    v-if="filter"
    class="input--select container-standing pl-6"
  >
    <v-autocomplete
      outlined
      hide-details
      clearable
      :items="filterOptions"
      item-text="value"
      item-value="name"
      :placeholder="filter.text"
      v-model="filter.filterBy"
      append-icon="mdi-chevron-down"
      dense
      @change="changeFilters()"
    >
      <template #prepend-inner>
        <Icon
          name="time_standing"
          size="small"
          class="mr-2"
        />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  props: {
    allFilters: {
      type: Array,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
  },
  computed: {
    filter () {
      const filterName = this.tableName === 'containersMap' ? 'idleDays' : 'containerIdleDays'
      return this.allFilters.find((item) => item.name === filterName)
    },
    filterOptions () {
      return this.filter?.options || []
    }
  },
  methods: {
    changeFilters () {
      this.$emit('changeFilters', { filters: this.allFilters, tableName: this.tableName })
    }
  }
}
</script>
