<template>
  <v-card
    width="300"
    elevation="0"
  >
    <v-card-text class="pb-0">
      <v-row>
        <v-col class="py-0">
          <div class="font-weight-bold">
            Baza
          </div>
          <div class="pt-1 pb-2">
            {{ department.name }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <div class="d-flex align-center">
            <Icon
              name="location"
              size="small"
              class="mr-1"
            />
            {{ department.address }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        text
      >
        Dodaj bazę do adresu
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    department: {
      type: Object,
      required: true
    }
  }
}
</script>
