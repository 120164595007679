<template>
  <div
    v-shortkey="navigateTabsShortkeys"
    @shortkey="navigateTabs"
  >
    <v-tabs
      class="table-tabs"
      v-model="activeTab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.value"
        :value="tab.value"
        :data-testid="tab.text"
        :disabled="disabled"
        @click="changeTab(tab)"
      >
        {{ tab.text }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { navigateTabsShortkeys, shortkeysNavigateBoundaries } from '../../const/shortKeys'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    currentTab: { // manually set initial value
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      required: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      navigateTabsShortkeys,
      activeTab: 0,
      routeName: undefined,
    }
  },
  computed: {
    ...mapState({
      isSidebarOpen: state => state.layout.sidebar.size,
      savedTab (state) {
        return state.tabs.tabs[this.routeName]
      }
    })
  },
  watch: {
    currentTab: {
      handler(val) {
        this.activeTab = val
      },
      immediate: true
    }
  },
  mounted () {
    this.routeName = this.$route.name
    // it's stored because in beforeDestroy hook it's already changed
    if (this.persistent) {
      let index = this.savedTab || 0
      if (index >= this.tabs.length) index = 0
      this.changeTab(this.tabs[index])
    }
  },
  beforeDestroy () {
    if (this.persistent) this.saveTab({ view: this.routeName, tabValue: this.activeTab })
  },
  methods: {
    ...mapActions({
      saveTab: 'tabs/saveTab',
    }),
    changeTab (chosenTab) {
      const index = this.tabs.findIndex(tab => tab.value === chosenTab.value)
      this.activeTab = index
      this.$emit('goTo', chosenTab)
    },
    navigateTabs (event) {
      if (!this.isSidebarOpen) {
        const { activeTab, tabs } = this
        const index = shortkeysNavigateBoundaries(
          event.srcKey,
          activeTab,
          tabs,
          ['shiftl'],
          ['shiftr']
        )
        this.changeTab(tabs[index])
      }
    },
  }
}
</script>
