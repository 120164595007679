<template>
  <v-row class="pagination">
    <v-col
      cols="6"
      lg="4"
      offset-lg="4"
    >
      <v-pagination
        :value="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="5"
        :disabled="isProcessing"
        @input="changePage"
      />
    </v-col>

    <v-col
      cols="6"
      lg="4"
    >
      <div
        v-if="!hidePageOptions"
        class="pagination__settings"
      >
        Pozycji na stronę
        <v-select
          :value="pagination.itemsPerPage"
          :items="itemsPerPageOptions"
          solo
          dense
          hide-details
          class="pagination__select"
          @change="changeItemsPerPage"
        />
        {{ itemsShown }} z {{ pagination.itemsLength }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 20, 50, 100, 150, { text: 'Wszystkie', value: 100000 }]
    },
    hidePageOptions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pagination () {
      return this.$store.state.tables[this.tableName]?.pagination || {}
    },
    isProcessing () {
      return this.$store.state[this.tableName]?.isProcessing
    },
    itemsShown () {
      const { itemsLength, page, pageCount, itemsPerPage } = this.pagination
      const from = (page - 1) * itemsPerPage + 1
      const to = page === pageCount ? itemsLength : page * itemsPerPage
      return `${from}-${to}`
    },
  },
  methods: {
    ...mapActions({
      setTablePagination: 'tables/setTablePagination',
    }),
    changePage (page) {
      const pagination = { ...this.pagination, page }
      this.setTablePagination({ pagination, tableName: this.tableName })
    },
    changeItemsPerPage (itemsPerPage) {
      const pagination = { ...this.pagination, itemsPerPage }
      this.setTablePagination({ pagination, tableName: this.tableName })
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  &__settings {
    display:flex;
    align-items:center;
    justify-content: flex-end;
    padding-right: 40px;
    font-size: 14px;
  }
  &__select {
    max-width: 130px;
    margin: 0 16px;
  }
}
</style>
