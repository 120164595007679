<template>
  <v-navigation-drawer
    :value="sidebar.size > 0"
    clipped
    absolute
    right
    temporary
    :hide-overlay="!sidebar.showOverlay"
    stateless
    :width="sidebar.size > 1? '100%' : '420'"
    class="sidebar"
    :class="{'sidebar--full' : sidebar.size>1}"
  >
    <slot v-if="sidebar.size" />
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // TODO replace numbers in sidebar size with names in all app.
  // TODO (hidden-0, preview-1, full-2)
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar
    })
  }
}
</script>
