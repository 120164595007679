import api from '../api/v1'

export default {
  namespaced: true,
  state: {
    isProcessing: false,
    entity: {}
  },
  mutations: {
    SET_PROSPECT_PROCESSING (state, status) {
      state.isProcessing = status
    },
    SET_SINGLE_PROSPECT (state, prospect) {
      state.entity = prospect
    }
  },
  actions: {
    getSingleProspect ({ commit }, id) {
      commit('SET_PROSPECT_PROCESSING', true)
      return api.getSingleProspect(id)
        .then((resp) => {
          commit('SET_SINGLE_PROSPECT', resp.data)
        })
        .finally(() => {
          commit('SET_PROSPECT_PROCESSING', false)
        })
    },
    clearSingleProspect ({ commit }) {
      commit('SET_SINGLE_PROSPECT', {})
    },
    addProspectNote ({ state, commit, dispatch }, params) {
      commit('SET_PROSPECT_PROCESSING', true)
      return api.addProspectNote(params)
        .then(() => {
          dispatch('getSingleProspect', state.entity.id)
        })
        .finally(() => {
          commit('SET_PROSPECT_PROCESSING', false)
        })
    },
  }
}
