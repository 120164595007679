<template>
  <div class="details details--full">
    <Header
      ref="header"
      :title="$route.meta.title"
      :buttons="buttons"
      class="px-5"
    />
    <Tabs
      :current-tab="currentTab"
      :tabs="tabs"
      @goTo="changeDepartment"
    />
    <div class="px-4">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="detail-section__wrapper">
            <div class="detail-section__header">
              Transport
            </div>
            <div class="detail-section__content">
              <Table
                disable-personalization
                disable-pagination
                :max-height="230"
                table-name="transportPrices"
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div class="detail-section__wrapper">
            <div class="detail-section__header">
              Kruszywa
            </div>
            <div class="detail-section__content">
              <Table
                disable-personalization
                disable-pagination
                :max-height="230"
                table-name="aggregatePrices"
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <div class="detail-section__wrapper">
            <div class="detail-section__header">
              Odpady
            </div>
            <div class="detail-section__content">
              <Table
                disable-personalization
                disable-pagiantion
                :max-height="debrisMaxHeight"
                table-name="debrisPrices"
                :overwritten-items="debrisItems"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Tabs from '../../../components/Layout/Tabs'
import Table from '../../../components/Table/Table'
import actions from '../../../const/actions'
import { getMultipleTableParameters } from '../../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Header,
    Tabs,
    Table
  },
  data () {
    return {
      currentTab: 0,
      buttons: [{ ...actions.addNewPrice, create: true }]
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      departments: state => state.departments.items,
      containerTypes: state => state.containerTypes.items,
      debrisPrices: state => state.debrisPrices.items,
    }),
    tabs () {
      return this.departments.map((item, index) => ({ value: index, text: item.name }))
    },
    debrisMaxHeight () {
      return (window.innerHeight - 80) - 490 // 490 for sumarized top view
    },
    debrisItems () {
      let items = this.debrisPrices || []
      items = items.map((item) => {
        const getPrice = (price) => price?.[0] || null
        const prices = this.containerTypes.map((container) => {
          const price = getPrice(item.prices.filter((el) => el.containerTypeId === container.id))
          return {
            ...container,
            containerTypeId: container.id,
            id: price ? price.id : null,
            price: price ? price.price : null
          }
        })
        return { ...item, prices }
      })
      return items
    }
  },
  watch: {
    debrisItems (items) {
      if (items.length) this.appendTableParameters()
    }
  },
  created () {
    this.currentTab = this.departments.findIndex((d) => d.id === this.department.id)
    this.changeDepartment({ value: this.currentTab })
  },
  beforeDestroy () {
    this.setActiveDepartment()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'containerTypes') {
      this.getContainerTypes({ params: { departmentId: this.departmentId } })
    }
    next()
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      setTableFilters: 'tables/setTableFilters',
      appendTableConfig: 'tables/appendTableConfig',
      setActiveDepartment: 'price/setActiveDepartment'
    }),
    changeDepartment ({ value: index }) {
      const filters = [{ name: 'departmentId', filterBy: this.departments[index].id }]
      this.getContainerTypes({ params: { departmentId: this.departments[index].id } })
        .then(() => {
          this.setActiveDepartment(this.departments[index].id)
          this.setTableFilters({ filters, tableName: 'transportPrices' })
          this.setTableFilters({ filters, tableName: 'aggregatePrices' })
          this.setTableFilters({ filters, tableName: 'debrisPrices' })
        })
    },
    appendTableParameters () {
      // function for addding containerTypes to headers
      const config = this.$store.getters['tables/getTableConfig']('debrisPrices')
      const params = config.parameters.filter((param) => param.multiple)
      if (params.length) {
        this.appendTableConfig({
          tableName: 'debrisPrices',
          parameters: getMultipleTableParameters(params, this.debrisItems)
        })
      }
    }
  }
}
</script>
