<template>
  <svg
    class="icon"
    :class="{ 'icon-spin': spin }"
    :style="{
      color: color ? color : 'transparent',
      width: getSize(),
      height: getSize()
    }"
  >
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'big'
    },
    color: {
      type: String,
      required: false
    },
    spin: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getSize () {
      switch (this.size) {
        case 'small':
          return '16px'
        case 'medium':
          return '20px'
        case 'big':
          return '26px'
        case 'bigger':
          return '36px'
        default:
          return '26px'
      }
    }
  }
}
</script>
