import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showFilters)?_c('div',{staticClass:"filters__options"},[_vm._l((_vm.filters),function(filter,index){return [(filter.options || filter.switch || filter.threeWaySwitch)?_c('div',{key:filter.name,staticClass:"input--select px-3 pt-4",class:{'input--multiselect':filter.multiple, 'input--switch':filter.switch }},[(filter.switch)?[_c('div',{staticClass:"input-label"},[_vm._v(" "+_vm._s(filter.text)+" ")]),_c(VSwitch,{attrs:{"false-value":filter.falseValue !== undefined ? filter.falseValue : false,"true-value":{value: filter.trueValue || true, name: filter.text},"hide-details":""},on:{"change":function($event){return _vm.changeFilters()}},model:{value:(filter.filterBy),callback:function ($$v) {_vm.$set(filter, "filterBy", $$v)},expression:"filter.filterBy"}})]:(filter.threeWaySwitch)?_c(VAutocomplete,_vm._b({attrs:{"items":[
            {value: true, name: filter.trueLabel || 'Tak'},
            {value: false, name: filter.falseLabel || 'Nie'}
          ]},on:{"change":function($event){return _vm.changeFilters(index)}},model:{value:(filter.filterBy),callback:function ($$v) {_vm.$set(filter, "filterBy", $$v)},expression:"filter.filterBy"}},'v-autocomplete',_vm.autocompleteProps(filter),false)):_c(VAutocomplete,_vm._b({attrs:{"items":filter.options,"search-input":_vm.searchQuery[index]},on:{"update:searchInput":function($event){return _vm.$set(_vm.searchQuery, index, $event)},"update:search-input":function($event){return _vm.$set(_vm.searchQuery, index, $event)},"change":function($event){return _vm.debouncedChangeFilters(index)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var idx = ref.index;
          var item = ref.item;
return [(filter.filterBy.length < 2 || !filter.multiple)?_c('span',{staticClass:"input--long mr-1 ml-1"},[_vm._v(" "+_vm._s(item.name)+" ")]):(idx === 0)?_c('span',{staticClass:"mr-1 ml-1"},[_vm._v(" "+_vm._s(_vm.filtersDeclension(filter.filterBy.length))+" ")]):_vm._e()]}}],null,true),model:{value:(filter.filterBy),callback:function ($$v) {_vm.$set(filter, "filterBy", $$v)},expression:"filter.filterBy"}},'v-autocomplete',_vm.autocompleteProps(filter),false))],2):_vm._e()]})],2):_vm._e(),(_vm.filters.length)?_c('div',{staticClass:"filters__selected-row"},[_c('div',{staticClass:"filters__selected"},[_vm._l((_vm.filters),function(filter){return [(Array.isArray(filter.filterBy))?_vm._l((filter.filterBy),function(singleFilter){return _c(VChip,_vm._b({key:singleFilter.name,attrs:{"value":singleFilter.value || singleFilter},on:{"click:close":function($event){return _vm.unpinMultipleFilter(filter.name, singleFilter)}}},'v-chip',_vm.chipProps(filter),false),[_vm._v(" "+_vm._s(_vm.chipLabel(singleFilter))+" ")])}):(filter.filterBy)?_c(VChip,_vm._b({key:filter.name,attrs:{"value":filter.filterBy},on:{"click:close":function($event){filter.filterBy = ''},"update:active":function($event){return _vm.changeFilters()}}},'v-chip',_vm.chipProps(filter),false),[_vm._v(" "+_vm._s(_vm.chipLabel(filter.filterBy))+" ")]):_vm._e()]})],2),(_vm.isAnyFilterSelected(_vm.tableName))?_c('div',[_c(VBtn,{staticClass:"mr-2 my-1",attrs:{"outlined":_vm.filtersEnabled,"color":"primary","dark":"","hide-details":"","small":""},on:{"click":function($event){_vm.filtersEnabled = !_vm.filtersEnabled}}},[_vm._v(" "+_vm._s(_vm.filtersEnabled ? 'Dezaktywuj' : 'Aktywuj')+" ")]),(_vm.isAnyFilterSelected(_vm.tableName))?_c(VBtn,{staticClass:"mr-4 my-1",attrs:{"color":"primary","dark":"","hide-details":"","small":""},on:{"click":function($event){return _vm.clearTableFilters({ tableName: _vm.tableName })}}},[_vm._v(" Wyczyść ")]):_vm._e()],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }