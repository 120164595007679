<template>
  <div>
    <v-row>
      <v-col
        v-for="type in types"
        :key="type.label"
      >
        <v-card class="report-card">
          <div>
            <div class="d-flex">
              <div
                class="report-card__count"
                v-text="getOrderTypeCount(type.key)"
              />
              <span
                v-if="type.sumKey"
                class="report-card__sum ml-2"
              >({{ getOrderTypeCount(type.sumKey) / 1000 }} {{ type.sumUnit }})</span>
            </div>
            <div
              class="report-card__label"
              v-text="type.label"
            />
          </div>
          <div class="report-card__icon-wrapper">
            <img
              :src="require(`../../assets/icons/${type.icon}_green.svg`)"
              :alt="type.label"
            >
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        v-if="!fullscreenChart || isChartFullscreen('DriversRoutes')"
      >
        <DriversRoutesChart
          :data="statistics"
          :is-fullscreen="isChartFullscreen('DriversRoutes')"
          @toggle-fullscreen="toggleFullscreen('DriversRoutes')"
        />
      </v-col>

      <v-col
        v-if="!fullscreenChart || isChartFullscreen('DriversCourses')"
        cols="12"
        :md="isChartFullscreen('DriversCourses') ? 12 : 6"
      >
        <DriversCoursesChart
          :data="statistics"
          :is-fullscreen="isChartFullscreen('DriversCourses')"
          class="h-100"
          @toggle-fullscreen="toggleFullscreen('DriversCourses')"
        />
      </v-col>

      <v-col
        v-if="!fullscreenChart || isChartFullscreen('CoursesCount')"
        cols="12"
        :md="isChartFullscreen('CoursesCount') ? 12 : 6"
      >
        <CoursesCountChart
          :course-types="doughnutChartData"
          :is-fullscreen="isChartFullscreen('CoursesCount')"
          class="h-100"
          @toggle-fullscreen="toggleFullscreen('CoursesCount')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DriversCoursesChart from './Charts/DriversCourses'
import DriversRoutesChart from './Charts/DriversRoutes'
import CoursesCountChart from './Charts/CoursesCount'
import { mapState } from 'vuex'

export default {
  components: {
    DriversCoursesChart,
    DriversRoutesChart,
    CoursesCountChart
  },
  data () {
    return {
      types: [
        { icon: 'substitution', label: 'Podstawienie', key: 'leaveCount' },
        { icon: 'take_away', label: 'Zabranie', key: 'pickupCount' },
        { icon: 'exchange', label: 'Wymiana', key: 'swapCount' },
        { icon: 'substitution', label: 'Łącznie podstawień', key: 'totalLeaveCount', sumKey: 'totalLeaveVolume', sumUnit: 'm3' },
        { icon: 'take_away', label: 'Łącznie zabrań', key: 'totalPickupCount', sumKey: 'totalPickupVolume', sumUnit: 'm3' },
        { icon: 'aggregate_transport', label: 'Transport', key: 'transportCount' },
      ],
      fullscreenChart: ''
    }
  },
  computed: {
    ...mapState({
      statistics: state => state.mainReport
    }),
    doughnutChartData() {
      return this.statistics.courseTypesCount?.data
        .map(type => ({ key: type.label, value: type.value }))
        .filter(
          type =>
            ![
              'Pętla',
              'Łącznie podstawień',
              'Łącznie zabrań',
              'Łączna objętość zabrań',
              'Łączna objętość podstawień'
            ].includes(type.key)
        )
    }
  },
  methods: {
    getOrderTypeCount (key) {
      const courseType = this.statistics.courseTypesCount?.data.find(type => type.key === key)
      return courseType ? courseType.value : 0
    },
    toggleFullscreen(chartTitle) {
      this.fullscreenChart = this.isChartFullscreen(chartTitle) ? '' : chartTitle
    },
    isChartFullscreen(chartTitle) {
      return this.fullscreenChart === chartTitle
    }
  }
}
</script>
