import actions from '../actions'
const debrisName = type => `${type.code} - ${type.name}`

export default {
  header: [],
  table: {
    maximumDateRange: 1096,
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    filters: [
      { name: 'transformed', text: '', options: '', filterBy: '', propPath: 'transformed', hide: true },
      {
        name: 'debrisTypeId',
        text: 'Typ odpadu',
        options: 'debrisTypes',
        nameGetter: debrisName,
        filterBy: '',
        propPath: 'debrisType.id'
      },
      {
        name: 'containerTypeId',
        text: 'Typ kontenera',
        options: 'containerTypes',
        filterBy: '',
        propPath: 'containerType.id'
      }
    ],
    parameters: [
      { name: 'externalId', text: 'Nr Wordpress', value: 'externalId', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'clientName', text: 'Klient', value: 'clientName', clientLabel: true, show: true },
      { name: 'clientPhoneNumber', text: 'Numer telefonu', value: 'clientPhoneNumber', show: true },
      { name: 'recipientName', text: 'Odbiorca', value: 'recipientName', show: true },
      { name: 'recipientStreet', text: 'Adres', value: 'recipientStreet', show: true },
      { name: 'recipientCity', text: 'Miasto', value: 'recipientCity', show: true },
      { name: 'clientEmail', text: 'E-mail', value: 'clientEmail', show: true },
      { name: 'startDate', text: 'Data podstawienia', value: 'startDate', show: true },
      { name: 'pixelStatus', text: 'Status pixel', value: 'pixelStatus', show: true },
      { name: 'endDate', text: 'Data zabrania', value: 'endDate', show: true },
      { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', show: true },
      { name: 'debrisType', text: 'Typ odpadu', value: 'debrisType.displayName', show: true },
      { name: 'personalInvoice', text: 'Faktura imienna', value: 'nameInvoice', show: true, sortable: false },
      { name: 'hasInvoice', text: 'Faktura', value: 'invoiceNip', show: true, sortable: false },
      { name: 'invoiceNip', text: 'NIP faktury', value: 'invoiceNip', show: true, sortable: false },
      { name: 'quantity', text: 'Liczba zleceń', value: 'quantity', show: true },
      { name: 'price', text: 'Cena', value: 'price', show: true },
      { name: 'info', text: 'Wiadomość', value: 'message', show: true, sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [{ ...actions.newOrderFromTemplate, icon: 'invoices_inactive' }],
    menu: [{ ...actions.newOrderFromTemplate }]
  },
  details: {
    title: 'Zamówienie',
    actions: [
      { ...actions.editOrderTemplatePixelStatus },
      { ...actions.newOrderFromTemplate, filled: true, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'client', text: 'Klient', value: 'clientName', type: 'textField', col: 3 },
          { name: 'clientPhoneNumber', text: 'Numer telefonu', value: 'clientPhoneNumber', type: 'textField', col: 3 },
          { name: 'clientEmail', text: 'E-mail', value: 'clientEmail', type: '', col: 3 },
          { name: 'externalId', text: 'Nr zamówienia Wordpress', value: 'externalId', type: 'textField', col: 3 },
          { name: 'id', text: 'Nr zamówienia', value: 'id', type: 'textField', col: 3 },
          { name: 'clientBdoNumber', text: 'Numer BDO', value: 'clientBdoNumber', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'startDate', text: 'Data podstawienia', value: 'startDate', type: 'textField', col: 3 },
          { name: 'endDate', text: 'Data zabrania', value: 'endDate', type: 'textField', col: 3 },
          { name: 'pixelStatus', text: 'Status pixel', value: 'pixelStatus', col: 3 }
        ]
      },
      {
        name: 'order',
        text: 'Informacje o zleceniu',
        type: 'left',
        fields: [
          { name: 'recipient', text: 'Odbiorca', value: 'recipientName', type: 'textField', col: 3 },
          { name: 'recipientStreet', text: 'Adres', value: 'recipientStreet', type: 'textField', col: 3 },
          { name: 'recipientCity', text: 'Miasto', value: 'recipientCity', type: 'textField', col: 3 },
          { name: 'quantity', text: 'Liczba zleceń', value: 'quantity', type: 'textField', col: 3 },
          { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', type: 'textField', col: 3 },
          { name: 'debrisType', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'textField', col: 3 },
          { name: 'price', text: 'Cena', value: 'price', type: 'textField', col: 3 },
          { name: 'info', text: 'Wiadomość', value: 'message', type: 'textArea', col: 12 }
        ]
      },
      {
        name: 'invoice',
        text: 'Informacje o dokumencie płatniczym',
        type: 'left',
        fields: [
          { name: 'invoiceName', text: 'Nabywca', value: 'invoiceName', type: 'textField', col: 3 },
          { name: 'invoiceStreet', text: 'Ulica', value: 'invoiceStreet', type: 'textField', col: 3 },
          { name: 'invoiceCity', text: 'Miasto', value: 'invoiceCity', type: 'textField', col: 3 },
          { name: 'invoicePostCode', text: 'Kod pocztowy', value: 'invoicePostCode', type: 'textField', col: 3 },
          { name: 'invoiceNip', text: 'Nip', value: 'invoiceNip', type: 'textField', col: 3 }
        ]
      },
      {
        name: 'orders',
        text: 'Powiązane zlecenia',
        multiple: 'orders',
        type: 'right',
        component: 'DetailsSectionList',
        fields: [
          {
            name: 'refNumber',
            text: 'Numer referencyjny',
            value: 'refNumber',
            type: 'textField',
            to: 'singleOrder',
            col: 12
          }
        ]
      }
    ]
  }
}
