<template>
  <div class="d-flex align-center">
    <v-icon
      v-if="!hideIcon"
      size="20"
      color="red"
    >
      mdi-alert
    </v-icon>
    <span class="warning-message ml-2">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'Warning',
  props: {
    message: {
      type: String,
      default: ''
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  }
}
</script>
