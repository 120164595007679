<script>
export default {
  data: () => ({
    expandedItems: [],
  }),
  methods: {
    expandRow (item, slot) {
      if (item.groupedItems?.length) slot.expand(!slot.isExpanded)
    },
    getGroupedEntries(items) {
      const expandableItems = items.map((item) => {
        if (item.groupedEntries?.length === 1) {
          const { groupedEntries, ...rest } = item
          return { ...rest, ...groupedEntries[0] }
        }
        return item
      }) // BE may return groupedEntries as an array with a single element, so we need to extract it to display in a single row without expanding
      return expandableItems
    },
  }
}
</script>
