<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      :key="tableName"
      ref="table"
      :table-name="tableName"
      @openDetails="openTableDetails"
      @contextMenu="openContextMenu"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          :key="currentTab"
          ref="tableTop"
          :table-name="tableName"
          table-mode-filter
          :table-mode-filter-props="{ class: 'mr-4' }"
          search-bar
          date-range-picker
          show-filters-button
          table-filters
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'tasks',
    currentTab: null,
    configured: false,
  }),
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    tabs() {
      return [
        { value: 'mineTasks', text: 'Moje zadania', filters: [{ name: 'assignedUserId', filterBy: this.user?.id, hide: true }, { name: 'taskTypes', filterBy: [], hide: false }] },
        { value: 'tasks', text: 'Ogólne', filters: [{ name: 'assignedUserId', filterBy: '', hide: false }, { name: 'taskTypes', filterBy: [], hide: false }] },
        { value: 'coursesConfirmations', text: 'Potwierdzenia kursów', filters: [{ name: 'assignedUserId', filterBy: '', hide: false }, { name: 'taskTypes', filterBy: ['Potwierdzenie kursu'], hide: true }] },
        { value: 'inactiveClients', text: 'Nieaktywni klienci', filters: [{ name: 'assignedUserId', filterBy: '', hide: false }, { name: 'taskTypes', filterBy: ['Brak aktywności klienta'], hide: true }] },
        { value: 'incorrectMessages', text: 'Niepoprawna wiadomość SMS', filters: [{ name: 'assignedUserId', filterBy: '', hide: false }, { name: 'taskTypes', filterBy: ['Niepoprawna wiadomość SMS'], hide: true }] }
      ]
    }
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters'
    }),
    goTo (tab) {
      this.setTableFilters({ filters: tab.filters, tableName: this.tableName, disableFetch: true })
      this.currentTab = tab.value
    },
  }
}
</script>
