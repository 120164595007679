<script>
import get from 'lodash/get'
import { getTableDetails, getTitleFormatted, getBlockerValue } from '../utils'
import { filterInactiveActions } from '../utils/quickActions'
import ActionButtons from '../components/Elements/ActionButtons.vue'
import DetailsToolbar from '../components/Single/Sections/DetailsToolbar'
import DetailsSection from '../components/Single/Sections/DetailsSection.vue'
import DetailsSectionList from '../components/Single/Sections/DetailsSectionList'

export default {
  components: {
    ActionButtons,
    DetailsToolbar,
    DetailsSection,
    DetailsSectionList,
  },
  data: () => ({
    details: {},
    configured: false,
  }),
  computed: {
    getDetailsActions () {
      const baseActions = getTableDetails(this.tableName).actions
      return this.actions || filterInactiveActions(baseActions, this.entity, this.tableName)
    },
    getDetailsTitle () {
      const title = getTableDetails(this.tableName).title
      return getTitleFormatted(title, this.entity)
    },
    isBlocked () {
      return getBlockerValue(this.entity, this.tableName)
    },
  },
  methods: {
    nestedSection (path) {
      return get(this.entity, path, [])
    },
    detailsColumnSize (col) {
      const column = {
        left: '8',
        right: '4',
        bottom: '12'
      }
      return column[col]
    },
    setDetails () {
      const details = {
        left: [],
        right: [],
        bottom: []
      }
      getTableDetails(this.tableName).sections.forEach((section) => {
        details[section.type].push(section)
      })
      this.details = details
      this.configured = true
    },
    getSectionFields(detail) {
      if (!detail?.fields) return detail
      return {
        ...detail,
        fields: detail?.fields.filter((field) => {
          return !field?.header
        })
      }
    },
  }
}
</script>
