import courses from './courses'

export default {
  ...courses,
  table: {
    ...courses.table,
    maximumDateRange: 366,
    specialFlag: {
      endpoint: 'courses/pages'
    },
    filters: [
      ...courses.table.filters,
      { name: 'withoutCards', text: '', options: '', filterBy: true, propPath: '', hide: true, initial: true, defalut: true },
      { name: 'forTails', text: '', options: '', filterBy: true, propPath: '', hide: true, initial: true, defalut: true },
    ]
  }
}
