<template>
  <div class="reports-view">
    <Header
      ref="header"
      :title="$route.meta.title"
      :buttons="[]"
    >
      <v-btn
        v-if="tableName !== 'mainReport'"
        class="px-4"
        color="primary"
        outlined
        :loading="isLoading"
        v-shortkey="exportShortkeys"
        @shortkey.native="exportReport('csv')"
        @click="exportReport('csv')"
      >
        {{ getLabelWithShortkey('Eksportuj raport', exportShortkeys.key) }}
      </v-btn>
      <v-btn
        class="px-4 ml-6"
        color="primary"
        :loading="isLoading"
        outlined
        v-shortkey="printShortkeys"
        @shortkey.native="exportReport('pdf')"
        @click="exportReport('pdf')"
      >
        {{ getLabelWithShortkey('Drukuj raport', printShortkeys.key) }}
      </v-btn>
    </Header>
    <template v-if="configured">
      <Tabs
        ref="tabs"
        :tabs="tabs"
        persistent
        @goTo="goTo"
      />

      <div
        class="filters"
        ref="tableTop"
      >
        <div class="filters__inputs d-flex justify-end">
          <DateRangePicker
            :table-name="tableName"
            disable-fetch
          />
          <ShowFiltersButton :table-name="tableName" />
        </div>

        <TableFilters
          :table-name="tableName"
          :current-tab="tableName"
          disable-fetch
          @fetch="fetchReport"
        />
      </div>

      <div class="px-4">
        <component :is="reportComponent" />
      </div>
    </template>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import DateRangePicker from '../../components/Filters/DateRangePicker'
import TableFilters from '../../components/Table/TableFilters'
import ShowFiltersButton from '../../components/Filters/ShowFiltersButton'
import MainReport from '../../components/Reports/MainReport'
import IncomeReport from '../../components/Reports/IncomeReport'
import { mapState, mapActions, mapGetters } from 'vuex'
import { getLabelWithShortkey, printShortkeys, exportShortkeys } from '../../const/shortKeys'

export default {
  components: {
    Tabs,
    Header,
    DateRangePicker,
    TableFilters,
    ShowFiltersButton,
    MainReport,
    IncomeReport,
  },
  data: () => ({
    tableName: 'mainReport',
    configured: false,
    tabs: [
      { value: 'mainReport', text: 'Ogólne', component: 'MainReport', tabName: 'main' },
      { value: 'incomeReport', text: 'Przychód dzienny', component: 'IncomeReport', tabName: 'income' },
    ],
    printShortkeys,
    exportShortkeys
  }),
  computed: {
    ...mapState({
      isLoading: state => state.core.isLoading,
      department: state => state.core.department
    }),
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
    reportsDate () {
      return this.$store.state[this.tableName].dateRange
    },
    activeTab () {
      return this.tabs.find(tab => tab.value === this.tableName)
    },
    reportComponent () {
      return this.activeTab?.component
    },
    departmentsFilter () {
      return this.getFilterValue(this.tableName, 'departmentIds') || []
    },
  },
  watch: {
    reportsDate () {
      this.fetchReport()
    },
    departmentsFilter (ids) {
      if (this.tableName === 'incomeReport') {
        const departmentIds = ids.map(department => department.value)
        this.getAddresses({ params: { filters: { departmentIds }, departmentId: this.department.id, } })
      }
    },
  },
  async mounted () {
    this.tabs.forEach((tab) => {
      this.setTableConfig({ tableName: tab.value, disableFetch: true })
    })
    await this.$nextTick()
    this.fetchReport()
      .then(() => {
        this.configured = true
      })
    this.getAddresses({ params: { filters: { departmentIds: [this.department.id] }, } })
  },
  methods: {
    ...mapActions({
      fetchReport: function(dispatch) {
        return dispatch(`${this.tableName}/fetchReport`)
      },
      generateReport: function(dispatch, payload) {
        return dispatch(`${this.tableName}/generateReport`, payload)
      },
      setTableFilters: 'tables/setTableFilters',
      setTableConfig: 'tables/setTableConfig',
      getAddresses: 'addresses/getItems',
    }),
    getLabelWithShortkey,
    goTo (tab) {
      this.tableName = tab.value
    },
    exportReport (exportType) {
      this.generateReport({ exportType, tabName: this.activeTab.tabName })
    }
  }
}
</script>
