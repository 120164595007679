<template>
  <div>
    <ReportTable
      class="mb-10 mt-4"
      :items="income.data"
      :headers="headers"
      title="Przychód"
      table-name="incomeReport"
      report="income"
    />
  </div>
</template>

<script>
import ReportTable from './Tables/ReportTable'
import { mapState } from 'vuex'

export default {
  components: {
    ReportTable
  },
  computed: {
    ...mapState({
      income: state => state.incomeReport.income,
      headers: state => state.tables.incomeReport.parameters
    })
  }
}
</script>
