import api from '../api/v1'
import Vue from 'vue'
import debounce from 'lodash/debounce'
import DateTime from 'luxon/src/datetime'

class Route {
  constructor (route = {}) {
    this.id = route.id
    this.stops = route.stops?.map((stop) => ({ ...stop, timeSlot: [stop.fromTime, stop.toTime] })) || []
    this.courseIds = this.stops.map((stop) => (stop.course.id))
    this.driverId = route.driver ? route.driver.id : null
    this.dueDate = route.dueDate || null
    this.routeStatus = route.routeStatus
    this.distances = []
    this.durations = []
    this.directions = {
      routes: []
    }
  }
}

export default {
  namespaced: true,
  state: {
    entity: { ...new Route() },
    entityUnchanged: { ...new Route() },
    isProcessing: false
  },
  mutations: {
    SET_ROUTE_PLANING (state, route) {
      state.entity = { ...route }
      state.entityUnchanged = { ...route }
    },
    ADD_NEXT_STOP_TO_ROUTE_PLANING (state, stop) {
      state.entity = {
        ...state.entity,
        courseIds: [...state.entity.courseIds, stop.course.id],
        stops: [...state.entity.stops, stop]
      }
    },
    REMOVE_STOP_FROM_ROUTE_PLANING (state, id) {
      state.entity = {
        ...state.entity,
        courseIds: state.entity.courseIds.filter((courseId) => courseId !== id),
        stops: state.entity.stops.filter((stop) => stop.course.id !== id)
      }
    },
    SET_DRIVER_IN_ROUTE_PLANING (state, driverId) {
      state.entity.driverId = driverId || undefined
    },
    SET_DATE_IN_ROUTE_PLANING (state, dueDate) {
      state.entity.dueDate = dueDate || null
    },
    CHANGE_STOPS_IN_ROUTE_PLANING (state, stops) {
      state.entity.stops = stops
      state.entity.courseIds = stops.map((stop) => (stop.course.id))
    },
    SET_ROUTE_DIRECTIONS (state, meta) {
      state.entity.distances = meta ? meta.distances : []
      state.entity.durations = meta ? meta.durations : []
      state.entity.directions = meta ? meta.directions : { routes: [] }
    },
    // TODO this is not used yet but probably should be called after cancelSingleCourse or sendRouteToDriver
    SET_PROCESSING (state, status) {
      state.isProcessing = status
    }
  },
  actions: {
    startRoutePlaning ({ rootState, commit, dispatch }, course) {
      const today = DateTime.now().startOf('day')
      let dueDate = DateTime.fromISO(rootState.coursesMap?.dateRange?.[0]).startOf('day')
      if (+dueDate > +today) {
        dueDate = dueDate.set({ hours: 5, minutes: 30 })
      } else {
        dueDate = DateTime.now()
      }
      const route = {
        ...new Route({
          dueDate: dueDate.toISO(),
          stops: [{
            course: course,
            id: null,
            sequence: 0
          }]
        })
      }
      commit('SET_ROUTE_PLANING', route)
      dispatch('setRouteDirections')
      dispatch('layout/setSidebar', { size: 1 }, { root: true })
    },
    getSingleRoute: debounce(({ commit, dispatch }, id) => {
      return api.getRoute(id)
        .then((res) => {
          const route = new Route(res.data)
          commit('SET_ROUTE_PLANING', route)
          dispatch('setRouteDirections')
        })
    }, 1000, { leading: true, trailing: true }),
    addNextStopToRoutePlaning ({ state, commit, dispatch }, course) {
      const id = state.entity.stops.length
      const stop = {
        course: course,
        id,
        sequence: 0
      }
      commit('ADD_NEXT_STOP_TO_ROUTE_PLANING', stop)
      dispatch('setRouteDirections')
    },
    resetRoutePlanning ({ commit, dispatch }) {
      dispatch('layout/closeSidebar', null, { root: true })
      commit('SET_ROUTE_PLANING', new Route())
      dispatch('setRouteDirections')
    },
    removeStopFromRoutePlaning ({ state, commit, dispatch }, id) {
      const { courseIds } = state.entity
      if (courseIds.length === 1 && courseIds[0] === id) {
        dispatch('resetRoutePlanning')
      } else {
        commit('REMOVE_STOP_FROM_ROUTE_PLANING', id)
        dispatch('setRouteDirections')
      }
    },
    setDriverInRoutePlaning ({ commit }, driverId) {
      commit('SET_DRIVER_IN_ROUTE_PLANING', driverId)
    },
    setDateInRoutePlaning ({ commit }, date) {
      commit('SET_DATE_IN_ROUTE_PLANING', date)
    },
    changeStopsInRoutePlaning ({ commit, dispatch }, stops) {
      commit('CHANGE_STOPS_IN_ROUTE_PLANING', stops)
      dispatch('setRouteDirections')
    },
    submitPlannedRoute ({ state, dispatch, rootState }, { tableName, send, receiverData }) {
      const { driverId, dueDate, courseIds } = state.entity
      const params = {
        departmentId: rootState.core.department.id,
        driverId,
        dueDate,
        courseIds,
        send,
        receiverData: receiverData || null
      }
      const parameters = {
        tableName: 'routes',
        params,
        id: state.entity.id,
        fetchedData: { tableName }
      }
      const method = state.entity.id ? 'editItem' : 'addNewItem'
      dispatch(`routes/${method}`, parameters, { root: true }).then(() => {
        dispatch('resetRoutePlanning')
      })
    },
    sendRouteToDriver ({ state, dispatch }, params) {
      const { id, driverId } = state.entity
      params = params || {
        id,
        params: { driverId }
      }
      api.sendRoute(params)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Wysłano trasę'],
            type: 'success'
          }, { root: true })
          dispatch('resetRoutePlanning')
        })
    },
    cancelSingleCourse ({ dispatch, rootGetters }, id) {
      api.cancelCourse(id)
        .then(() => new Promise((resolve) => {
          const params = rootGetters['tables/getTableParameters']('coursesMap')
          dispatch(`${params.tableName}/getItems`, params, { root: true }).then(() => { resolve() })
        })
        )
        .finally(() => {
          dispatch('layout/closeSidebar', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Cofnięto kurs'],
            type: 'success'
          }, { root: true })
        })
    },
    displayEditModeMessage ({ dispatch }) {
      dispatch('snackbar/showSnackbar', {
        message: ['Naciśnij anuluj, aby przerwać planowanie trasy'],
        type: 'error'
      }, { root: true })
    },
    setRouteDirections: debounce(({ state, commit, rootState }) => {
      const waypoints = state.entity.stops.map((item) => {
        return {
          location: {
            // TODO normalize stops
            lat: parseFloat(item.course?.order?.address?.location?.lat || item.course.locationLat),
            lng: parseFloat(item.course?.order?.address?.location?.lng || item.course.locationLng)
          },
          stopover: false
        }
      })
      const origin = rootState.core.department.location
      Vue.$gmapApiPromiseLazy().then(() => {
        // eslint-disable-next-line no-undef
        new google.maps.DirectionsService().route({
          origin,
          destination: origin,
          travelMode: 'DRIVING',
          waypoints
        }, (response, status) => {
          if (status === 'OK') {
            if (waypoints.length > 0) {
              const myRoute = response.routes[0].legs[0]
              const stepIndex = []
              let path = 0
              const distances = [0]
              const durations = [0]
              myRoute.via_waypoint.forEach((step) => {
                stepIndex.push(step.step_index)
              })
              myRoute.steps.forEach((step, index) => {
                const groupedSteps = stepIndex.filter((step) => step === index)
                groupedSteps.map(() => {
                  path += 1
                  distances[path] = 0
                  durations[path] = 0
                })
                distances[path] += step.distance.value
                durations[path] += step.duration.value
              })
              commit('SET_ROUTE_DIRECTIONS', { distances, durations, directions: response })
            } else {
              commit('SET_ROUTE_DIRECTIONS', null)
            }
          }
        })
      })
    }, 1000, { leading: true, trailing: true })
  }
}
