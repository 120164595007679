const mapOptions = {
  zoom: 12,
  center: { lat: 51.110, lng: 17.036 },
  scrollWheel: true,
  zoomControl: true,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: true,
  clickableIcons: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }]
    }
  ]
}

const mapDirectionsParameters = {
  polylineOptions: {
    fillOpacity: 1,
    icons: [
      {
        icon: {
          path: 'M-1,0a1,1 0 1,0 2,0a1,1 0 1,0 -2,0',
          strokeColor: '#2cb56b',
          fillColor: 'white',
          fillOpacity: 1,
          scale: 4,
          strokeWeight: 4
        },
        offset: '0%'
      }
    ],
    strokeColor: '#5695DF',
    strokeWeight: 4
  },
  preserveViewport: true,
  suppressMarkers: true,
  suppressBicyclingLayer: true
}

const courseDetails = [
  { name: 'client', text: 'Klient', value: 'course.orderClientName', type: 'inline', col: 12 },
  { name: 'order', text: 'Nr zlecenia', value: 'course.orderRefNumber', type: 'inline', col: 6 },
  { name: 'containerWithType', text: 'Kontener', value: 'course.containerWithType', type: 'inline', col: 6 },
  { name: 'dueDate', text: 'Data realizacji', value: 'course.dueDate', type: 'inline', col: 6 },
  { name: 'timeSlot', text: 'Przedział godzinowy', value: 'course.timeSlot', type: 'inline', col: 6 },
  { name: 'bdoStatus', text: 'Status BDO', value: 'course.bdoStatus', type: 'inline', col: 6 },
  { name: 'paymentStatus', text: 'Status płatności', value: 'course.paymentStatus', type: 'inline', col: 6 },
  { name: 'courseNotes', text: 'Notatka jednorazowa', value: 'course.userNotes', type: 'inline', col: 12 }
]

export {
  mapOptions,
  mapDirectionsParameters,
  courseDetails
}
