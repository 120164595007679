<template functional>
  <div>
    <div
      class="d-inline-block select-status"
      :class="`select-status--${$options.statusColor(props)}`"
    >
      {{ props.status }}
    </div>
  </div>
</template>
<script>
import { statusColorName } from '../../../utils/recordStatuses'

export default {
  props: {
    status: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    }
  },
  statusColor (props) {
    return props.color || statusColorName[props.status]
  }

}
</script>
