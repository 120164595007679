import api from '../api/v1'

export default {
  namespaced: true,

  state: {
    entity: {},
    tableName: null,
  },

  mutations: {
    SET_SINGLE_VIEW_ENTITY (state, item) {
      state.entity = item
    },
    SET_TABLE_NAME (state, tableName) {
      state.tableName = tableName
    }
  },

  actions: {
    getSingleViewEntity ({ commit }, { id, tableName }) {
      return api.getItem({ tableName, id })
        .then((resp) => {
          commit('SET_SINGLE_VIEW_ENTITY', resp.data)
          commit('SET_TABLE_NAME', tableName)
        })
    },
    setSingleViewEntity ({ commit }, { item, tableName }) {
      commit('SET_SINGLE_VIEW_ENTITY', item)
      commit('SET_TABLE_NAME', tableName)
    },
    clearSingleViewEntity ({ commit }) {
      commit('SET_SINGLE_VIEW_ENTITY', {})
      commit('SET_TABLE_NAME', null)
    }
  }
}
