<template>
  <div>
    <div class="form-title title pl-4 py-1">
      <b>{{ $route.params.title }}</b>
    </div>
    <div class="form-view">
      <component
        v-if="loadComponent()"
        :is="loadComponent()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.setFullViewMode()
  },
  methods: {
    ...mapActions({
      setFullViewMode: 'core/setFullViewMode'
    }),
    loadComponent () {
      const { form } = this.$route.params
      if (form && !this.$options.components[form]) {
        this.$options.components[form] = () =>
          import(`@/components/Forms/${form}.vue`)
      }
      return form
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  background-color:#95a2b710;
  height: 40px;
}

.form-view {
  height: calc(100vh - 40px);
  & > div {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
}
</style>
