import { authorizeAxios, deauthorizeAxios } from '@/api/axios'
import axiosApi from '@/api/v1/index'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('EL-Auth-Token') || '',
    refreshToken: localStorage.getItem('EL-Refresh-Token') || '',
    user: JSON.parse(localStorage.getItem('EL-Auth-User')) || null,
  },
  mutations: {
    SET_USER_DATA(state, { id, login, profile }) {
      const userData = { id, login, ...profile }
      state.user = userData
      localStorage.setItem('EL-Auth-User', JSON.stringify(userData))
    },

    SET_TOKEN(state, { token, refreshToken }) {
      state.token = token
      state.refreshToken = refreshToken
      localStorage.setItem('EL-Auth-Token', token)
      localStorage.setItem('EL-Refresh-Token', refreshToken)
    },

    UPDATE_USER_DATA(state, payload) {
      state.user = { ...state.user, ...payload }
    },

    CLEAR_SESSION_DATA(state) {
      state.token = ''
      state.refreshToken = ''
      state.user = {}
      localStorage.removeItem('EL-Auth-Token')
      localStorage.removeItem('EL-Refresh-Token')
      localStorage.removeItem('EL-Auth-User')
    },
  },
  actions: {
    login({ state, commit, dispatch }, userCredentials) {
      dispatch('core/setLoading', true, { root: true })

      axiosApi
        .login(userCredentials)
        .then(resp => {
          commit('SET_TOKEN', resp.data)
          commit('SET_USER_DATA', resp.data.user)
          authorizeAxios(resp.data)
          dispatch('core/initApp', null, { root: true }).then(() => {
            const logoutRoute = localStorage.getItem('EL-Logout-Route')
            if (logoutRoute) {
              router.push({ name: logoutRoute })
              localStorage.removeItem('EL-Logout-Route')
            } else {
              router.push({ name: 'courses' })
            }
          })
        })
        .catch(() => {
          commit('CLEAR_SESSION_DATA')
        })
        .finally(() => {
          dispatch('core/setLoading', false, { root: true })
        })
    },

    logout({ commit, dispatch }, { noApi } = {}) {
      const performLogout = () => {
        commit('CLEAR_SESSION_DATA')
        commit('tables/SET_INITIAL_STATE', false, { root: true })
        commit('core/SET_APP_LOADED_STATE', false, { root: true })
        dispatch('driversOccupancy/setOccupancyVisibility', null, { root: true })
        this.$cable.connection.disconnect('')
        deauthorizeAxios()
        router.push({ name: 'login' })
        window.location.reload(true)
      }

      if (noApi) {
        performLogout()
        return
      }

      axiosApi.logout().then(() => performLogout())
    },
    updateUserData ({ commit }, payload) {
      commit('UPDATE_USER_DATA', payload)
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    getFullName(state) {
      const { firstName, lastName } = state.user || {}
      return `${firstName} ${lastName}`
    },
    getInitials(state) {
      const { firstName, lastName } = state.user || {}
      return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`
    },
    getUserPermissions(state) {
      return state.user?.permissions || []
    }
  },
}
