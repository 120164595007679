import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"contact-details"},[_c('div',{staticClass:"detail-section__header contact-details__header"},[_vm._v(" Lokalizacje "),_c('div',{staticClass:"d-flex"},[_c(VTextField,{staticClass:"contact-details__input",attrs:{"placeholder":"Szukaj adresu","outlined":"","clearable":"","hide-details":"","dense":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icon',{staticClass:"mr-2 mt-1",attrs:{"name":"search","size":"small"}})]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(_vm.actions.length)?_c('ActionButtons',{attrs:{"actions":_vm.actions,"item":_vm.client}}):_vm._e()],1)]),_c(VDivider),_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(_vm.navigateTableShortkeys),expression:"navigateTableShortkeys"}],on:{"shortkey":_vm.navigateTable}},[_c(VDataTable,{staticClass:"addresses-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.addressesFound,"items-per-page":-1,"value":_vm.selectedAddress},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('TableRow',{attrs:{"table-name":"addresses","item":item,"header":_vm.headers,"actions":_vm.filteredRowActions,"is-selected":item.id === _vm.selectedId},nativeOn:{"click":function($event){return _vm.selectAddress(item, index)},"contextmenu":function($event){$event.preventDefault();return _vm.handleAddressContextMenu($event, item)}}})]}}])})],1)],1)]),(_vm.isCompanyClient)?_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"contact-details"},[_c('div',{staticClass:"detail-section__header contact-details__header"},[_vm._v(" Produkty lokalizacji "),(_vm.selectedAddress.length)?_c('ActionButtons',{attrs:{"actions":[Object.assign({}, _vm.allActions.addNewProduct, {shortkey :{key: ['f7']}})],"item":_vm.selectedAddress[0]}}):_vm._e()],1),_c(VDivider),_c(VDataTable,{staticClass:"products-table",attrs:{"hide-default-footer":"","headers":_vm.productHeaders,"items":_vm.products,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TableRow',{attrs:{"table-name":"products","item":item,"header":_vm.productHeaders,"actions":_vm.productActions(item)},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.handleProductContextMenu($event, item)}}})]}}],null,false,4158978210)})],1)]):_vm._e(),(_vm.isCompanyClient)?_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"contact-details"},[_c('div',{staticClass:"detail-section__header contact-details__header"},[_c('span',{staticClass:"my-2"},[_vm._v("Historia produktów")])]),_c(VDivider),_c(VDataTable,{staticClass:"products-history-table",attrs:{"hide-default-footer":"","headers":_vm.productHistoryHeders,"items":_vm.productsHistory,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TableRow',{attrs:{"table-name":"products","item":item,"header":_vm.productHistoryHeders}})]}}],null,false,1388491213)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }