export default {
  menu: {
    contentClass: 'moved',
    outlined: true,
    closeOnContentClick: false,
    transition: 'scale-transition',
    offsetY: true,
    maxWidth: '290px',
    minWidth: '290px'
  },
  textField: {
    class: 'pt-0',
    readonly: true,
    outlined: true
  },
  datePicker: {
    locale: 'pl',
    noTitle: true,
    scrollable: true,
    color: 'primary',
    firstDayOfWeek: '1'
  }
}
