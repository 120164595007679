<template>
  <v-btn
    v-shortkey="action.shortkey"
    v-bind="getActionButtonProps.props"
    v-on="getActionButtonProps.events"
  >
    {{ getLabelWithShortkey(action.label, action.shortkey['key']) }}
  </v-btn>
</template>

<script>
import { getLabelWithShortkey } from '../../const/shortKeys'
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },
    tableName: {
      type: String,
      required: true
    },
  },
  computed: {
    multiselectSubject() {
      return this.$store.state[this.tableName].multiselectSubject || ''
    },
    isMultiselectActive() {
      return this.$store.state[this.tableName].isMultiselectActive
    },
    getActionButtonProps() {
      return {
        props: {
          class: ['px-4 ml-6', { 'button-active': this.isMultiselectActive && this.multiselectSubject === this.action.subject }],
          color: 'primary',
          outlined: true,
        },
        events: {
          shortkey: () => this.toggleMultiselect(),
          click: () => this.toggleMultiselect(),
        }
      }
    }
  },
  methods: {
    getLabelWithShortkey,
    setMultiselectSubject(status) {
      this.$store.dispatch(`${this.tableName}/setMultiselectSubject`, status)
    },
    toggleMultiselectStatus(status) {
      this.$store.dispatch(`${this.tableName}/toggleMultiselectStatus`, status)
    },
    async toggleMultiselect () {
      const { subject } = this.action
      const prevSubject = this.multiselectSubject
      if (subject !== this.multiselectSubject) {
        this.setMultiselectSubject(subject)
        await this.$nextTick()
      }
      if (!this.isMultiselectActive || subject === prevSubject) {
        this.toggleMultiselectStatus(!this.isMultiselectActive)
      }
    },
  }
}
</script>
