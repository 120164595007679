import actions from '../../../actions'

export default {
  header: [
    { ...actions.addNewTrailer, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [
      { name: 'departmentId', text: 'Oddział', options: 'departments', filterBy: undefined, propPath: '' },
      { name: 'active', text: 'Tylko aktywne', options: '', filterBy: '', switch: true, falseValue: '', propPath: 'active' },
    ],
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'licensePlate', text: 'Nr rejestracyjny', value: 'licensePlate', show: true },
      { name: 'maxWeight', text: 'DMC', value: 'maxWeight', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'additionalInfo', text: 'Dodatkowe informacje', value: 'additionalInfo', show: true },
    ],
    actions: [
      { ...actions.deleteTrailer, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteTrailer }
    ]
  },
  details: {
    title: '{{licensePlate}}',
    actions: [
      { ...actions.deleteTrailer, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'trailers',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'licensePlate', text: 'Nr rejestracyjny', value: 'licensePlate', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'maxWeight', text: 'DMC', value: 'maxWeight', type: 'textField', col: 3, editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 1001 }], unit: ' [T]' },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'additionalInfo', text: 'Dodatkowe informacje', value: 'additionalInfo', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'departmentId', text: 'Oddział', value: 'department.name', type: 'selectFromItems', options: 'departments', col: 3, editable: true },
          { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName', type: 'textField', col: 3 },
        ]
      }
    ]
  }
}
