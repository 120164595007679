<template>
  <v-data-table
    hide-default-footer
    :headers="header"
    :items="courses"
    class="details-table"
    :items-per-page="-1"
  >
    <template #item="{ item }">
      <TableRow
        ref="tableRow"
        table-name="scheduleCourses"
        :item="item"
        :header="header"
      />
    </template>
  </v-data-table>
</template>

<script>
import TableRow from '../../Table/TableRow'
import { mapState, mapActions } from 'vuex'
import config from '../../../const/views/courses/courses'

export default {
  components: {
    TableRow
  },
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      courses: state => state.courses.items
    }),
    header () {
      return config.table.parameters.filter(parameter => !['actions', 'blocked', 'moveToRelatedCourse'].includes(parameter.name))
    }
  },
  mounted () {
    if (this.data.id) {
      this.getCourses({
        params: {
          departmentId: this.department.id,
          orderScheduleId: this.data.id,
          order: {
            field: 'ref_number',
            direction: 'asc'
          }
        }
      })
    }
  },
  methods: {
    ...mapActions({
      getCourses: 'courses/getItems'
    })
  }
}
</script>
