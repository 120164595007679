<template>
  <div>
    <div
      v-if="showFilters"
      class="filters__options"
    >
      <template v-for="(filter, index) in localFilters">
        <div
          v-if="filter.options"
          :key="filter.name"
          class="input--select map-select px-3 pt-4"
        >
          <v-autocomplete
            outlined
            hide-details
            :items="filter.options"
            item-text="name"
            :label="filter.text"
            placeholder="Wybierz"
            append-icon="mdi-chevron-down"
            return-object
            :multiple="filter.multiple"
            clearable
            v-model="filter.filterBy"
            @change="changeFilters(filter.tableName, index)"
            dense
            attach
            :search-input.sync="searchQuery[index]"
          >
            <template #selection="{ index: idx, item }">
              <span
                v-if="filter.filterBy.length < 2 || !filter.multiple"
                class="input--long mr-1 ml-1"
              >
                {{ item.name }}
              </span>
              <span
                v-else-if="idx === 0"
                class="mr-1 ml-1"
              >
                {{ filtersDeclension(filter.filterBy.length) }}
              </span>
            </template>
          </v-autocomplete>
        </div>
      </template>
      <div
        v-if="showDisplayMode"
        class="input--select map-select px-3 pt-4"
      >
        <v-autocomplete
          :value="displayMode"
          outlined
          hide-details
          :items="displayModeOptions"
          label="Wyświetl"
          item-text="name"
          item-value="value"
          placeholder="Wybierz"
          append-icon="mdi-chevron-down"
          dense
          attach
          @change="$emit('changeDisplayMode', $event)"
        />
      </div>
    </div>
    <div
      v-if="filters && filters.length"
      class="filters__selected-row"
    >
      <div class="filters__selected">
        <span
          v-for="(multipleFilter, filterIndex) in localFilters"
          :key="multipleFilter.name"
          :class="{'mr-4': isFilterActive(multipleFilter.filterBy)}"
        >
          <v-chip
            v-for="(filter, filterByIndex) in multipleFilter.filterBy"
            :key="filter.name"
            :value="filter.value"
            class="mx-1 my-2"
            :class="{disabled: !filtersEnabled}"
            close
            close-icon="mdi-close"
            color="primary"
            outlined
            @update:active="changeFilters(multipleFilter.tableName)"
            @click:close="removeFilter(filterIndex, filterByIndex)"
          >
            {{ filter.name || filter }}
          </v-chip>
        </span>
      </div>
      <v-spacer />
      <v-switch
        v-if="isAnyFilterSelected"
        v-model="filtersEnabled"
        hide-details
        class="mt-0"
      />
    </div>
  </div>
</template>

<script>
import { isFilterActive, declensionName } from '../../utils'
import { mapState, mapActions } from 'vuex'

const displayModeOptions = [
  {
    name: 'Wszyscy',
    value: 'all'
  },
  {
    name: 'Kierowcy',
    value: 'drivers'
  },
  {
    name: 'Kursy',
    value: 'courses'
  }
]

export default {
  props: {
    mapName: {
      type: String,
      required: true
    },
    filters: {
      type: Array,
      deafult: () => []
    },
    displayMode: {
      type: String,
      required: false
    },
    showDisplayMode: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    searchQuery: [],
    displayModeOptions
  }),
  computed: {
    ...mapState({
      containerTypes: state => state.containerTypes.items,
      showFilters (state) {
        return state[this.mapName].showFilters
      }
    }),
    tableNames () {
      return [...new Set(this.filters.map(filter => filter.tableName))]
    },
    localFilters () {
      return [...this.filters].filter(filter => !filter.topBar)
    },
    filtersEnabled: {
      get () {
        // checking only map filtersEnabled because all tableNames are being set together
        return this.$store.getters['tables/getTableConfig'](this.mapName)?.filtersEnabled
      },
      set (status) {
        this.tableNames.forEach(tableName => this.setTableFiltersStatus({ status, tableName }))
      }
    },
    isAnyFilterSelected () {
      return this.filters?.some(filter => isFilterActive(filter.filterBy))
    }
  },
  watch: {
    isAnyFilterSelected (newValue, oldValue) {
      if (oldValue !== newValue) this.$emit('toggle')
    }
  },
  mounted () {
    this.checkContainerTypesExistence()
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      setTableFiltersStatus: 'tables/setTableFiltersStatus'
    }),
    isFilterActive (filterBy) {
      return isFilterActive(filterBy)
    },
    changeFilters (tableName, index) {
      if (index || index === 0) this.searchQuery[index] = ''
      const filters = this.localFilters.filter(filter => filter.tableName === tableName)
      this.setTableFilters({ filters, tableName })
      if (!this.isAnyFilterSelected) this.setTableFiltersStatus({ status: true, tableName: this.mapName })
    },
    removeFilter (filterIndex, filterByIndex) {
      this.localFilters[filterIndex].filterBy.splice(filterByIndex, 1)
    },
    filtersDeclension (counter) {
      return declensionName(counter, 'element', 'elementy', 'elementów')
    },
    checkContainerTypesExistence () {
      const containerTypesFilterIndex = this.localFilters.findIndex(filter => filter.name === 'containerTypeIds')

      this.localFilters[containerTypesFilterIndex].filterBy = this.localFilters[containerTypesFilterIndex].filterBy
        .filter(filter => {
          const containerTypesIds = this.containerTypes.map(containerType => containerType.id)
          return containerTypesIds.includes(filter.value)
        })

      this.changeFilters(this.mapName)
    }
  }
}
</script>

<style lang="scss" scoped>
.map-select {
  width: 25%;
  &::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
