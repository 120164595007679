export default {
  table: {
    isWebSocketHandled: true,
    maximumDateRange: 31,
    filtersEnabled: false,
    noDateRange: true,
    specialFlag: {
      endpoint: 'crm/users',
    },
    sorting: false,
    parameters: [
      { name: 'id', text: 'Id', value: 'id', show: true, sortable: true },
      { name: 'fullName', text: 'Imię i nazwisko', value: 'fullName', show: true, sortable: false },
    ],
    daterangeCriteria: ['createdAt'],
  },
}
