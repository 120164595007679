import api from '@/api/v1'
import { Course } from '../models'

export default {
  namespaced: true,
  state: {
    isProcessing: false
  },
  mutations: {
    SET_PROCESSING (state, status) {
      state.isProcessing = status
    }
  },
  actions: {
    startCourse ({ commit, dispatch }, { item }) {
      const { id } = item
      api.startCourse(id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Rozpoczęto kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    finishCourse ({ commit, dispatch }, params) {
      api.finishCourse(params)
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Zakończono kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    sendCourse ({ commit, dispatch }, params) {
      commit('SET_PROCESSING', true)
      api.sendCourse(params)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Wysłano kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    cancelCourse ({ commit, dispatch }, id) {
      commit('SET_PROCESSING', true)
      api.cancelCourse(id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Cofnięto kurs'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    renewCourse({ commit, dispatch }, { item }) {
      commit('SET_PROCESSING', true)
      api.renewCourse(item.id).then(() => {
        dispatch('snackbar/showSnackbar', {
          message: ['Cofnięto zakończony kurs'],
          type: 'success'
        }, { root: true })
      }).finally(() => {
        commit('SET_PROCESSING', false)
      })
    },
    assignOrder ({ commit }, { id, params }) {
      commit('SET_PROCESSING', true)
      api.assignOrder({ id, params })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    unassignOrder ({ commit, dispatch }, data) {
      const item = new Course(data.item)
      const { id } = item?.swapCourse || item

      function unassignOrder () {
        commit('SET_PROCESSING', true)
        api.unassignOrder(id)
          .then(() => {
            dispatch('layout/closeDialog', null, { root: true })
          })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      }

      dispatch('layout/setDialog', {
        data: {
          component: 'ConfirmationDialog',
          text: 'Odepnij zlecenie',
          description: 'Czy na pewno chcesz odpiąć zlecenie?',
          callback: unassignOrder
        },
      }, { root: true })
    },
    generateKwu ({ commit, dispatch }, { id, contactName }) {
      commit('SET_PROCESSING', true)
      return api.generateCourseKwu(id, { contactName })
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Wygenerowano KWU']
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    unassignKpoCard ({ dispatch, rootState, commit }, { item }) {
      let course = null
      if (Object.prototype.hasOwnProperty.call(item, 'address')) {
        course = item
      } else {
        course = new Course(item)
      }
      const { bdoCard, bdoCardId } = course.swapCourse || course
      const cardId = bdoCard?.id || bdoCardId
      // in single course root course does not have bdoCard object but bdoCardId property

      function unassignCard () {
        commit('SET_PROCESSING', true)
        api.unassignOrderFromBdoCard(cardId)
          .then(() => {
            if (course.id === rootState.order?.entity?.swapCourse?.id) {
              rootState.order.entity.swapCourse.bdoFile = null
            } // remove bdoFile from anyContainer swap
            commit('SET_PROCESSING', false)
            dispatch('layout/closeDialog', null, { root: true })
          })
      }
      dispatch('layout/setDialog', {
        data: {
          component: 'ConfirmationDialog',
          text: 'Odepnij kartę',
          description: 'Czy na pewno chcesz odpiąć kartę?',
          tableName: 'course',
          callback: unassignCard
        },
      }, { root: true })
    },
    removeFromTails ({ commit }, { id, reason }) {
      commit('SET_PROCESSING', true)
      api.updateTailCourse({ id, noBdoAllowed: true, noBdoReason: reason || null })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    moveToTails ({ commit }, { item }) {
      commit('SET_PROCESSING', true)
      api.updateTailCourse({ id: item.id, noBdoAllowed: false, noBdoReason: null })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    }

  }
}
