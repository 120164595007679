<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      :key="tableName"
      ref="table"
      :table-name="tableName"
      @contextMenu="openContextMenu"
      @openDetails="openTableDetails"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          date-range-picker
          :show-filters-button="isTransformed"
          :table-filters="isTransformed"
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import { mapActions } from 'vuex'

const tabs = Object.freeze([
  { value: 'orderTemplates', text: 'Nowe zamówienia', filter: { name: 'transformed', filterBy: false, hide: true } },
  { value: 'transformedTemplates', text: 'Przyjęte zamówienia', filter: { name: 'transformed', filterBy: true, hide: true } }
])
export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar
  },
  channels: {
    OrderTemplateIndexChannel: { received (data) { this.captureChanges(data) } }
  },
  mixins: [
    defaultTableEventsMixin,
    webSocketMixin,
  ],
  data: () => ({
    tableName: 'orderTemplates',
    tabs,
    isTransformed: false,
    configured: false
  }),
  mounted () {
    this.subscribeSocket('OrderTemplateIndexChannel')
  },
  beforeDestroy () {
    this.unsubscribeSocket('OrderTemplateIndexChannel')
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      setTableFiltersStatus: 'tables/setTableFiltersStatus',
      setSingleOrderTemplate: 'orderTemplate/setSingleOrderTemplate',
    }),
    goTo (tab) {
      this.isTransformed = tab.value === 'transformedTemplates'
      this.setTableFiltersStatus({ tableName: this.tableName, status: this.isTransformed, noApi: true })
      this.setTableFilters({ filters: [tab.filter], tableName: this.tableName })
    },
    openTableDetails (item) {
      this.setSingleOrderTemplate(item)
      this.$router.push({ name: 'singleOrderTemplate', params: { id: item.id } })
    },
  }
}
</script>
