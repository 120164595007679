const getFullName = name => `${name.firstName} ${name.lastName}`

const getBdoNumber = course => course.swapBdoCardNumber || course.bdoCardNumber || '-'
const getBdoStatus = course => course.swapBdoCardStatus || course.bdoCardStatus || '-'

const getCourseTimeSlot = course => {
  const { fromTime, toTime, timeNotes } = course
  const notes = timeNotes ? `(${timeNotes})` : ''
  return fromTime && toTime ? (fromTime + '-' + toTime + ' ' + notes) : '-'
}

const getCoursePhoneNumber = course => course.orderAddressPhoneNumber || course.phoneNumber || '-'

export default {
  getFullName,
  getBdoNumber,
  getBdoStatus,
  getCourseTimeSlot,
  getCoursePhoneNumber
}
