<template>
  <div class="d-flex justify-space-between align-center ml-10 w-100">
    <div class="d-flex">
      <div
        v-for="stat in statistics"
        :key="stat.text"
        class="mr-6 d-flex"
      >
        <span class="table-statistics__label">{{ stat.text }}</span>
        <span class="table-statistics__value">{{ stat.value }}</span>
      </div>
    </div>

    <v-btn
      v-if="driversOccupancy"
      text
      small
      color="primary"
      @click="setOccupancyVisibility(true)"
    >
      Pokaż dostępność kierowców
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    tableName: {
      type: String,
      default: 'courses'
    },
    driversOccupancy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statistics () {
      return this.$store.getters[`${this.tableName}/getStatistics`]
    },
  },
  methods: {
    ...mapActions({
      setOccupancyVisibility: 'driversOccupancy/setOccupancyVisibility'
    })
  }
}
</script>
