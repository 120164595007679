<template>
  <div>
    <div class="app-version">
      {{ version }}
    </div>

    <div
      class="d-flex  mt-2"
      :class="[$isLogisticsApp ? 'justify-space-around' : 'justify-start']"
    >
      <v-btn
        @click="clearAll()"
        text
      >
        <v-img
          v-if="mini"
          contain
          width="100%"
          height="30"
          max-width="30"
          src="@/assets/images/logo2.png"
        />
        <v-img
          v-else
          contain
          width="100%"
          max-width="90"
          src="@/assets/images/logo.png"
        />
      </v-btn>
      <v-btn
        icon
        v-if="!mini && $isLogisticsApp"
        :disabled="$route.name === 'hints'"
        @click="$router.push({ name: 'hints' })"
      >
        <v-icon color="#67758E">
          mdi-information-variant
        </v-icon>
      </v-btn>
    </div>
    <v-btn
      class="menu-toggler"
      text
      @click="$emit('toggle')"
    >
      <v-icon color="primary">
        mdi-chevron-{{ mini ? 'right' : 'left' }}
      </v-icon>
    </v-btn>

    <v-list-item class="px-2">
      <v-list-item-content class="pt-0">
        <v-list-item-title
          class="menu-name mb-2 mx-2 text--white"
          :class="{ 'menu-initials': mini }"
        >
          {{ mini ? getInitials : getFullName }}
        </v-list-item-title>
        <v-select
          background-color="#2E3849"
          :items="departmentsList"
          :value="department ? department.id : null"
          hide-details
          item-text="name"
          item-value="id"
          solo
          dense
          class="white--text"
          :append-icon="!mini && $isLogisticsApp ? 'mdi-chevron-down' : ''"
          :disabled="!$isLogisticsApp"
          @input="findNewDepartment($event)"
        >
          <template #selection="{ item }">
            {{ mini ? item.name.substring(0, 3) : item.name }}
          </template>
        </v-select>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    ...mapState({
      departmentsList: state => state.departments?.items,
      department: state => state.core.department,
    }),
    getFullName() {
      const module = this.$isLogisticsApp ? 'auth' : 'authClient'
      return this.$store.getters[`${module}/getFullName`]
    },
    getInitials() {
      const module = this.$isLogisticsApp ? 'auth' : 'authClient'
      return this.$store.getters[`${module}/getInitials`]
    },
  },
  methods: {
    clearAll() {
      // TODO Only for development
      localStorage.clear()
      window.location.reload(true)
    },
    findNewDepartment(id) {
      const department = this.departmentsList.find(d => d.id === id)
      this.setDepartment(department)
    },
    ...mapActions({
      setDepartment: 'core/setDepartment',
    }),
  },
}
</script>
