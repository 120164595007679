<template>
  <div class="d-flex">
    <v-btn
      icon
      max-width="20px"
      max-height="24px"
      :disabled="minBoundaryReached"
      @click.stop="changeDate(-1)"
    >
      <v-icon class="inverted">
        mdi-play
      </v-icon>
    </v-btn>
    <v-btn
      icon
      max-width="20px"
      max-height="24px"
      :disabled="maxBoundaryReached"
      @click.stop="changeDate(1)"
    >
      <v-icon>
        mdi-play
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import DateTime from 'luxon/src/datetime'
const dateValidator = (value) => /^(\d{4})-(\d{2})-(\d{2})$/gm.test(value)

export default {
  name: 'SwitchDayButtons',
  props: {
    value: {
      type: String,
      required: true,
    },
    min: {
      type: String,
      required: false,
      validator: dateValidator
    },
    max: {
      type: String,
      required: false,
      validator: dateValidator
    },
  },
  computed: {
    minBoundaryReached() {
      if (!this.min) return
      const prevDay = this.getNewDate(-1)
      return prevDay < this.min
    },
    maxBoundaryReached() {
      if (!this.max) return
      const nextDay = this.getNewDate(1)
      return nextDay > this.max
    }
  },
  methods: {
    changeDate(day) {
      const newDate = this.getNewDate(day)
      this.$emit('input', newDate)
    },
    getNewDate(day) {
      return DateTime.fromISO(this.value)
        .plus({ day })
        .toISODate()
    }
  }
}
</script>

<style lang="scss" scoped>
.inverted {
  transform: rotateY(180deg)
}
</style>
