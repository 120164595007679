<template>
  <SingleView v-bind="$attrs" />
</template>

<script>
import { mapActions } from 'vuex'
import SingleView from './SingleView.vue'

export default {
  components: {
    SingleView
  },
  methods: {
    ...mapActions({
      setBdoCardTemplatesOptions: 'core/setBdoCardTemplatesOptions'
    })
  },
  created() {
    this.setBdoCardTemplatesOptions()
  }
}
</script>
