import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showFilters)?_c('div',{staticClass:"filters__options"},[_vm._l((_vm.localFilters),function(filter,index){return [(filter.options)?_c('div',{key:filter.name,staticClass:"input--select map-select px-3 pt-4"},[_c(VAutocomplete,{attrs:{"outlined":"","hide-details":"","items":filter.options,"item-text":"name","label":filter.text,"placeholder":"Wybierz","append-icon":"mdi-chevron-down","return-object":"","multiple":filter.multiple,"clearable":"","dense":"","attach":"","search-input":_vm.searchQuery[index]},on:{"change":function($event){return _vm.changeFilters(filter.tableName, index)},"update:searchInput":function($event){return _vm.$set(_vm.searchQuery, index, $event)},"update:search-input":function($event){return _vm.$set(_vm.searchQuery, index, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var idx = ref.index;
var item = ref.item;
return [(filter.filterBy.length < 2 || !filter.multiple)?_c('span',{staticClass:"input--long mr-1 ml-1"},[_vm._v(" "+_vm._s(item.name)+" ")]):(idx === 0)?_c('span',{staticClass:"mr-1 ml-1"},[_vm._v(" "+_vm._s(_vm.filtersDeclension(filter.filterBy.length))+" ")]):_vm._e()]}}],null,true),model:{value:(filter.filterBy),callback:function ($$v) {_vm.$set(filter, "filterBy", $$v)},expression:"filter.filterBy"}})],1):_vm._e()]}),(_vm.showDisplayMode)?_c('div',{staticClass:"input--select map-select px-3 pt-4"},[_c(VAutocomplete,{attrs:{"value":_vm.displayMode,"outlined":"","hide-details":"","items":_vm.displayModeOptions,"label":"Wyświetl","item-text":"name","item-value":"value","placeholder":"Wybierz","append-icon":"mdi-chevron-down","dense":"","attach":""},on:{"change":function($event){return _vm.$emit('changeDisplayMode', $event)}}})],1):_vm._e()],2):_vm._e(),(_vm.filters && _vm.filters.length)?_c('div',{staticClass:"filters__selected-row"},[_c('div',{staticClass:"filters__selected"},_vm._l((_vm.localFilters),function(multipleFilter,filterIndex){return _c('span',{key:multipleFilter.name,class:{'mr-4': _vm.isFilterActive(multipleFilter.filterBy)}},_vm._l((multipleFilter.filterBy),function(filter,filterByIndex){return _c(VChip,{key:filter.name,staticClass:"mx-1 my-2",class:{disabled: !_vm.filtersEnabled},attrs:{"value":filter.value,"close":"","close-icon":"mdi-close","color":"primary","outlined":""},on:{"update:active":function($event){return _vm.changeFilters(multipleFilter.tableName)},"click:close":function($event){return _vm.removeFilter(filterIndex, filterByIndex)}}},[_vm._v(" "+_vm._s(filter.name || filter)+" ")])}),1)}),0),_c(VSpacer),(_vm.isAnyFilterSelected)?_c(VSwitch,{staticClass:"mt-0",attrs:{"hide-details":""},model:{value:(_vm.filtersEnabled),callback:function ($$v) {_vm.filtersEnabled=$$v},expression:"filtersEnabled"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }