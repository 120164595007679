<template>
  <div v-resize="calculateHeight">
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <v-btn
        outlined
        color="primary"
        class="ml-4"
        @click="exportData(false)"
      >
        Eksportuj do csv
      </v-btn>
      <v-btn
        outlined
        color="primary"
        class="ml-4"
        @click="exportData(true)"
      >
        Eksportuj do xlsx
      </v-btn>
    </Header>
    <FiltersBar
      ref="tableTop"
      :table-name="tableName"
      date-range-picker
      show-filters-button
      table-filters
    />
    <div
      id="pivot"
      :style="`height: ${height}px`"
    />
    <link
      rel="stylesheet"
      href="pivot-1.5.6.min.css"
    >
  </div>
</template>

<script>
/* global dhx */
import Header from '../../components/Layout/Header'
import FiltersBar from '../../components/Filters/FiltersBar'
import { mapState, mapActions } from 'vuex'
import pivotLocale from '../../const/pivotLocale'

const formatPriceCell = (text, row, col) => {
  if (col.method === 'count') return text
  return text ? Number(text).toFixed(2) + ' zł' : '-'
}

export default {
  components: {
    Header,
    FiltersBar,
  },
  data: () => ({
    tableName: 'customReport',
    height: 800,
    configured: false,
    pivotEntity: {}
  }),
  created() {
    const pivot = document.createElement('script')
    pivot.setAttribute('src', 'pivot-1.5.6.min.js')
    document.head.appendChild(pivot)
  },
  mounted() {
    this.setTableConfig({ tableName: this.tableName })
      .then(() => {
        this.calculateHeight()
        dhx.i18n.setLocale('pivot', pivotLocale)
        this.getTablesSettings('customReport').then(() => {
          const fields = this.savedFieldsConfig?.config || {
            rows: ['clientName'],
            columns: ['settlementType'],
            values: [{ id: 'netValue', method: 'sum' }],
          }
          this.pivotEntity = new dhx.Pivot('pivot', {
            data: this.customReportData,
            fields,
            fieldList: [
              { id: 'containerContainerTypeName', label: 'Typ kontenera' },
              { id: 'addressName', label: 'adres' },
              { id: 'clientName', label: 'klient' },
              { id: 'clientClientType', label: 'Typ klienta' },
              { id: 'clientBdoType', label: 'Typ BDO' },
              { id: 'debrisTypeDisplayName', label: 'Typ Odpadu' },
              { id: 'debrisTypeCode', label: 'Kod Odpadu' },
              { id: 'parsedOrigin', label: 'Pochodzenie' },
              { id: 'departmentName', label: 'Oddział' },
              { id: 'settlementType', label: 'Typ rozliczenia' },
              { id: 'paymentType', label: 'Typ płatności' },
              {
                id: 'netValue',
                label: 'Wartość netto',
                cellTemplate: formatPriceCell
              },
              {
                id: 'totalGrossValue',
                label: 'Wartość brutto',
                cellTemplate: formatPriceCell
              },
              {
                id: 'debrisNetValue',
                label: 'Wartość netto odpadów',
                cellTemplate: formatPriceCell
              },
              {
                id: 'debrisGrossValue',
                label: 'Wartość brutto odpadów',
                cellTemplate: formatPriceCell
              },
            ],
            layout: {
              columnsWidth: 'auto',
              rowsHeadersWidth: 'auto',
              footer: true,
            }
          })

          this.configured = true
        })
      })
  },
  beforeDestroy() {
    if (this.pivot) this.pivot.destructor()
  },
  watch: {
    customReportData(data) {
      if (this.configured) this.pivotEntity.setData(data)
    },
    pivotFieldsConfig: {
      deep: true,
      handler(config) {
        if (!config || !this.configured) return
        const settings = {
          ...this.savedFieldsConfig,
          config
        }
        this.$store.commit('tables/SET_TABLE_SETTINGS', { viewType: 'customReport', settings })
        this.updateTableSettings({ tableName: 'customReport' })
      }
    }
  },
  computed: {
    ...mapState({
      customReportData: state => state.customReport.items,
      savedFieldsConfig: state => state.tables.savedTableSettings?.customReport,
    }),
    pivotFieldsConfig() {
      return this.pivotEntity.config?.fields
    },
  },
  methods: {
    ...mapActions({
      setTableConfig: 'tables/setTableConfig',
      updateTableSettings: 'tables/updateTableSettings',
      getTablesSettings: 'tables/getTablesSettings',
    }),
    calculateHeight() {
      const { header, tableTop } = this.$refs
      const offset = (header?.$el.clientHeight || 0) + (tableTop?.$el.clientHeight || 0)
      this.height = window.innerHeight - offset
    },
    exportData(isXlsx) {
      const name = `custom report - ${new Date().toISOString()}`
      if (isXlsx) {
        this.pivotEntity.export.xlsx({
          name,
          url: '//export.dhtmlx.com/excel'
        })
      } else {
        this.pivotEntity.export.csv({
          name, // pivot data will be exported to a CSV file named "pivot_data"
          flat: true, // pivot data will be presented as a flat structure
          rowDelimiter: '\r\n', // the tab delimiter will be used to separate rows
          columnDelimiter: ';' // the semicolon delimiter will be used to separate col
        })
      }
    }
  }
}
</script>

<style>
.dhx_grid-content {
  z-index: 4;
}
</style>
