import { bdoTabsSharedFilters, bdoTabsSharedParameters } from './sharedParams'
import cloneDeep from 'lodash/cloneDeep'
import actions from '../../actions'

export default {
  header: [
    { ...actions.refreshInternalBdoCards }
  ],
  table: {
    maximumDateRange: 1,
    isWebSocketHandled: true,
    specialFlag: {
      endpoint: 'bdoCards',
    },
    sorting: {
      sortBy: 'order.refNumber',
      sortDesc: false
    },
    filters: [
      { name: 'orderTypes', text: 'Typ zlecenia', filterBy: ['Przekazanie', 'Międzyoddziałowa'], propPath: 'orderType', options: 'internalBdoCardTypes', default: ['Przekazanie', 'Międzyoddziałowa'], multiple: true, hide: true },
      ...cloneDeep(bdoTabsSharedFilters),
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false },
    ],
    parameters: [
      { name: 'loading', text: '', value: 'loading', show: true },
      ...cloneDeep(bdoTabsSharedParameters),
      { name: 'senderName', text: 'Przekazujący', value: 'senderName', show: true },
      { name: 'receiverName', text: 'Przyjmujący', value: 'receiverName', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [],
    menu: []
  },
  details: {}
}
