import actions from '../../actions'
const isNotIndividualCustomer = client => client.clientType !== 'Osoba fizyczna'

export default {
  header: [{ ...actions.addNewClient, create: true }],
  table: {
    title: '{name}',
    specialFlag: {
      departmentId: undefined,
    },
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'id',
      sortDesc: true,
    },
    modes: [
      { value: { clientType: 'Firma', withoutPatron: '' }, initial: true, text: 'Klienci firmowi' },
      { value: { clientType: 'Osoba fizyczna', withoutPatron: '' }, text: 'Klienci prywatni' },
      { value: { clientType: '' }, text: 'Wszyscy klienci' },
    ],
    filters: [
      { name: 'clientType', filterBy: 'Firma', propPath: 'clientType' },
      { name: 'withoutPatron', filterBy: '', propPath: 'withoutPatron', hide: true },
    ],
    parameters: [
      { name: 'id', text: 'Nr klienta', value: 'id', show: true, sortable: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true, sortable: true },
      { name: 'name', text: 'Klient', value: 'name', clientLabel: true, show: true, sortable: true },
      { name: 'invoiceNip', text: 'Nr NIP', value: 'invoiceNip', show: true, sortable: true },
      { name: 'bdoType', text: 'Logika BDO', value: 'bdoType', show: true, sortable: true },
      { name: 'bdoNumber', text: 'Nr BDO', value: 'bdoNumber', show: true, sortable: true },
      { name: 'invoiceCity', text: 'Miejscowość', value: 'invoiceCity', show: true, sortable: true },
      { name: 'invoicePhoneNumber', text: 'Telefon', value: 'invoicePhoneNumber', show: true, sortable: true },
      { name: 'invoiceAddress', text: 'Adres', value: 'invoiceAddress', show: true, sortable: true },
      { name: 'email', text: 'Email', value: 'email', show: true, sortable: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true, sortable: true },
      { name: 'blocked', text: 'Blokada', value: 'blocked', show: true, sortable: true },
      { name: 'signatureRequired', text: 'Wymagany podpis', value: 'signatureRequired', show: true, sortable: true },
      { name: 'patron', text: 'Opiekun', value: 'patronFullName', show: true, sortable: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false },
    ],
    actions: [{ ...actions.deleteClient, icon: 'usun' }],
    menu: [
      { ...actions.blockClient },
      { ...actions.unblockClient },
      { ...actions.deleteClient },
      { ...actions.editClient, idPath: 'id' },
    ],
  },
  details: {
    title: '{{name}}',
    actions: [],
    sections: [
      {
        name: 'general',
        text: 'Podstawowe dane klienta',
        type: 'bottom',
        endpoint: 'clients',
        fields: [
          { name: 'name', text: 'Klient', value: 'name', type: 'textField', col: 12, editable: true, rules: ['required'], },
          { name: 'id', text: 'Nr klienta', value: 'id', type: 'textField', col: 4 },
          { name: 'phoneNumber', text: 'Numer telefonu', value: 'phoneNumber', type: 'textField', col: 4, editable: true, rules: ['required', 'phoneNumber'], },
          { name: 'email', text: 'Email', value: 'email', type: 'textField', col: 4, editable: true, rules: ['required', 'email'], },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'bdoNumber', type: 'textField', col: 4, editable: true, rules: ['required'], },
          { name: 'bdoType', text: 'Logika BDO', value: 'bdoType', type: 'bdoLogic', col: 4, editable: true, rules: ['required'], },
          { name: 'controlName', text: 'Dane przekazującego', value: 'controlName', type: 'bdoLogic', col: 4, editable: true, rules: ['required'], },
          { name: 'blockExplanation', text: 'Powód blokady', value: 'blockExplanation', type: 'textField', col: 4 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 4 },
          { name: 'notes', text: 'Notatka do klienta', value: 'notes', type: 'textArea', col: 4, editable: true },
          { name: 'active', text: 'Status klienta', value: 'active', type: 'boolean', col: 4, editable: true, rules: ['required'], },
          { name: 'signatureRequired', text: 'Wymagany podpis', value: 'signatureRequired', type: 'boolean', col: 4, editable: true, },
          { name: 'patronId', text: 'Opiekun', value: 'patronFullName', to: 'defaultSingleView', idPath: 'patronId', routeParams: { tableName: 'users' }, editable: true, type: 'selectFromItems', options: 'users', itemText: 'fullName', clearable: true, col: 4 },
          { name: 'middlemanIds', text: 'Pośrednik', value: 'middlemen', editable: true, type: 'selectMiddleman', col: 4, valueGetter: (middlemen) => Array.isArray(middlemen) ? middlemen?.map(middleman => middleman.name)?.join(', ') || '-' : '-' },
          { name: 'isMiddleman', text: 'Czy pośrednik', value: 'isMiddleman', editable: true, type: 'boolean', refreshTables: ['middlemen'], col: 4 },
        ],
      },
      {
        name: 'billing',
        text: 'Informacje o umowie',
        type: 'bottom',
        endpoint: 'clients',
        fields: [
          { name: 'invoiceBuyerName', text: 'Nabywca', value: 'invoiceBuyerName', type: 'textField', col: 12, editable: true, rules: ['required'], },
          { name: 'invoiceNip', text: 'Nr NIP', value: 'invoiceNip', col: 4 },
          { name: 'settlementType', text: 'Typ dokumentu', value: 'settlementType', type: 'settlementType', col: 4, editable: true, rules: ['required'], },
          { name: 'invoicePhoneNumber', text: 'Telefon', value: 'invoicePhoneNumber', type: 'textField', col: 4, editable: true, rules: ['required', 'phoneNumber'], },
          { name: 'invoiceAddress', text: 'Adres', value: 'invoiceAddress', type: 'textField', col: 4, editable: true, rules: ['required'], },
          { name: 'invoiceCity', text: 'Miejscowość', value: 'invoiceCity', type: 'textField', col: 4, editable: true, rules: ['required'], },
          { name: 'invoicePostalCode', text: 'Kod pocztowy', value: 'invoicePostalCode', type: 'textField', col: 4, editable: true, rules: ['required', 'postCode'], },
          { name: 'purchaseLimit', text: 'Limit kupiecki', value: 'purchaseLimit', type: 'textField', col: 4, editable: true, rules: ['required', 'money', { name: 'lowerThan', arg: 10000001 }], unit: ' [zł]', },
          { name: 'purchaseLimitRemaining', text: 'Stan limitu', value: 'purchaseLimitRemaining', type: 'textField', col: 4, },
          { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', type: 'paymentType', col: 4, editable: true, rules: ['required'], options: 'paymentTypes' },
          { name: 'paymentDueDays', text: 'Termin przelewu', value: 'paymentDueDays', type: 'textField', col: 4, editable: true, rules: ['required'], },
          { name: 'contractNumber', text: 'Numer umowy', value: 'contractNumber', type: 'textField', col: 4, editable: true, },
          { name: 'contractDate', text: 'Data podpisania umowy', value: 'contractDate', type: 'date', noMinDate: true, col: 4, editable: true, },
        ],
      },
    ],
  },
}

export const clientContacts = {
  headers: [
    { name: 'fullName', text: 'Imię i nazwisko', value: 'fullName' },
    { name: 'role', text: 'Rola', value: 'role' },
    { name: 'email', text: 'Email', value: 'email' },
    { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber' },
    { name: 'arePricesVisible', text: 'Widocznosć cen', value: 'arePricesVisible' },
    { name: 'isInvoiceReceivable', text: 'Otrzymywanie dokumentów', value: 'isInvoiceReceivable' },
    { name: 'passwordExists', text: 'Dostęp do aplikacji', value: 'passwordExists' },
  ],
  actions: [actions.addNewContact],
  rowActions: [
    { ...actions.deleteContact, icon: 'usun' },
    { ...actions.editContact, icon: 'edytuj' },
  ],
}

export const clientAddresses = {
  headers: [
    { name: 'sequence', text: 'Lp.', value: 'sequence' },
    { name: 'department', text: 'Oddział', value: 'department.name' },
    { name: 'commune', text: 'Gmina', value: 'commune.name' },
    { name: 'addressName', text: 'Nazwa', value: 'name' },
    { name: 'addressFormattedAddress', text: 'Adres lokalizacji', value: 'formattedAddress', length: 200 },
    { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber' },
    { name: 'email', text: 'Email', value: 'email' },
    { name: 'invoiceNotes', text: 'Notatka do faktury', value: 'invoiceNotes' },
    { name: 'discount', text: 'Wysokość rabatu', value: 'discount' },
    { name: 'discountJustification', text: 'Uzasadnienie rabatu', value: 'discountJustification' },
    { name: 'info', text: 'Uwagi', value: 'remarks' },
    { name: 'actions', text: '', value: 'actions' },
  ],
  actions: [actions.addNewClientAddress],
  rowActions: [
    { ...actions.addNewProduct, icon: 'add_product', condition: [isNotIndividualCustomer] },
    { ...actions.editClientAddress, icon: 'edytuj' },
    { ...actions.deleteClientAddress, icon: 'usun' },
  ],
  contextMenuActions: [
    { ...actions.addNewProduct, excluded: 'Osoba fizyczna' },
    { ...actions.editClientAddress },
    { ...actions.deleteClientAddress },
  ],
}

export const clientDocuments = {
  actions: [actions.addNewDocument],
  rowActions: [
    { ...actions.deleteDocument, icon: 'usun' },
    { ...actions.downloadDocument, icon: 'pobierz' },
  ],
  headers: [
    { name: 'filename', text: 'Nazwa', value: 'name' },
    { name: 'createdAt', text: 'Data', value: 'createdAt' },
    { name: 'fileType', text: 'Typ dokumentu', value: 'fileType' },
    { name: 'actions', text: '', value: 'actions' },
  ],
}

export const clientBlockages = {
  actions: [],
  rowActions: [],
  headers: [
    { name: 'blockedAt', text: 'Data zablokowania', value: 'blockedAt' },
    { name: 'blockedBy', text: 'Zablokowany przez', value: 'blockingUser.fullName' },
    { name: 'explanation', text: 'Powód blokady', value: 'explanation' },
    { name: 'unblockedAt', text: 'Data odblokowania', value: 'unblockedAt' },
    { name: 'unblockedBy', text: 'Odblokowany przez', value: 'unblockingUser.fullName' },
  ],
}

export const clientChanges = {
  actions: [],
  rowActions: [],
  headers: [
    { name: 'createdAt', text: 'Data zmiany', value: 'createdAt' },
    { name: 'changeType', text: 'Typ zmiany', value: 'changeType' },
    { name: 'text', text: 'Komentarz', value: 'text' },
    { name: 'userFullName', text: 'Uzytkownik', value: 'user.fullName' },
    { name: 'userRole', text: 'Role', value: 'user.roles', concatValues: 'name' },
  ],
}

export const endClients = {
  actions: [],
  rowActions: [],
  headers: [
    { name: 'client', text: 'Klient', value: 'name', sortable: false },
    { name: 'clientType', text: 'Typ klienta', value: 'clientType', sortable: false },
    { name: 'settlementType', text: 'Typ rozliczenia', value: 'settlementType', sortable: false },
    { name: 'bdoType', text: 'Logika BDO', value: 'bdoType', sortable: false },
    { name: 'actions', text: '', value: 'actions', sortable: false }
  ],
  expandedRowHeaders: []
}
