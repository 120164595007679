import { VHover } from 'vuetify/lib/components/VHover';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(_vm.navigateMenuShortkeys),expression:"navigateMenuShortkeys"}],on:{"shortkey":_vm.changeView}},[_c(VList,{staticClass:"menu-pages",attrs:{"nav":""}},[_vm._l((_vm.filteredMenuItems),function(menuItem){return [_c(VHover,{key:menuItem.text,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(menuItem.children)?_c(VListGroup,{staticClass:"v-list-item--dense",class:{ active: menuItem.active },attrs:{"color":"#2cb56b","append-icon":"mdi-chevron-down"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c('Icon',{attrs:{"name":hover || menuItem.active ? menuItem.icon : menuItem.icon + '_inactive'}})],1),_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(_vm.getNavText(menuItem))}})]},proxy:true}],null,true),model:{value:(menuItem.active),callback:function ($$v) {_vm.$set(menuItem, "active", $$v)},expression:"menuItem.active"}},[_vm._l((menuItem.children),function(child){return [_c(VListItem,{key:child.text,class:{ active: child.active },attrs:{"link":"","dense":"","ripple":false},on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.openPage(child)},function($event){if(!$event.altKey){ return null; }return _vm.newWindow(child.link)}]}},[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(_vm.getNavText(child))}})],1)]})],2):_c(VListItem,{class:{ active: menuItem.active },attrs:{"link":"","ripple":false,"dense":""},on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.openPage(menuItem)},function($event){if(!$event.altKey){ return null; }return _vm.newWindow(menuItem.link)}]}},[_c(VListItemIcon,[_c('img',{attrs:{"src":_vm.getNavIcon(menuItem)}})]),_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(_vm.getNavText(menuItem))}})],1)]}}],null,true)})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }