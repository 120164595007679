<template>
  <div>
    <v-btn
      v-if="isAssignable"
      icon
      x-small
      class="mr-1"
      color="primary"
      @click="assignCard"
    >
      <v-icon small>
        mdi-clipboard-arrow-left
      </v-icon>
    </v-btn>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <span
          :style="{ color: KPONumberColor }"
          v-on="on"
        >{{
          value !== '-' ? `${value.slice(0, 10)}...` : value
        }}</span>
      </template>
      <span>{{ value }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import quickActionsMixin from '../../../mixins/quickActionsMixin'
import actions from '../../../const/actions'

export default {
  props: {
    value: {
      type: String,
      default: '-'
    },
    item: {
      type: Object,
      required: true
    },
    isSwap: {
      type: Boolean,
      required: true
    }
  },
  mixins: [quickActionsMixin], // triggerAction
  computed: {
    isAssignable() {
      return this.item.withAssignableKpoCards && (this.item.courseType !== 'Podstawienie' || this.item.swapCourseId)
    },
    KPODate() {
      const dateKey = this.isSwap ? 'swapBdoCardPlannedTransportTime' : 'bdoCardPlannedTransportTime'
      return this.item[dateKey] ? new Date(this.item[dateKey]) : null
    },
    isKPODateToday() {
      return this.KPODate?.toDateString() === new Date().toDateString()
    },
    isKPODatePlusMinusOneDay() {
      const today = new Date()
      const tomorrow = new Date(today.setDate(today.getDate() + 1))
      const yesterday = new Date(today.setDate(today.getDate() - 2))
      return (
        this.KPODate?.toDateString() === tomorrow.toDateString() ||
        this.KPODate?.toDateString() === yesterday.toDateString()
      )
    },
    KPONumberColor() {
      if (this.item.courseStatus === 'Wykonany' || this.value === '-' || !this.KPODate) {
        return ''
      } else if (this.isKPODateToday) {
        return '#32b332' // green
      } else if (this.isKPODatePlusMinusOneDay) {
        return '#ffc300' // yellow
      } else {
        return 'red'
      }
    }
  },
  methods: {
    assignCard() {
      this.triggerAction({ action: actions.assignKpoCardToOrder, item: this.item })
    }
  }
}
</script>
