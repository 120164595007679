<template>
  <v-snackbar
    v-model="isSnackbarShown"
    color="rgb(56, 68, 87)"
    timeout="15000"
  >
    <div class="d-flex align-center justify-space-between">
      {{ activeTemplate.message }}
      <v-btn
        plain
        text
        small
        color="primary"
        class="ml-2"
        @click="openTemplatesView"
      >
        Przejdź
      </v-btn>

      <v-btn
        text
        small
        color="error"
        @click="isSnackbarShown = false"
      >
        Zamknij
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import humps from 'humps'

export default {
  data: () => ({
    isSnackbarShown: false,
    activeTemplate: {
      message: '',
      id: null,
      startDate: null,
      alertType: ''
    }
  }),
  channels: {
    OrderTemplateAlertChannel: {
      received (data) {
        this.activeTemplate = humps.camelizeKeys(data)
        this.isSnackbarShown = true
      }
    }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
    })
  },
  mounted () {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
    this.$cable.subscribe({ channel: 'OrderTemplateAlertChannel' })
  },
  methods: {
    ...mapActions({
      setTableDate: 'orderTemplates/setTableDate',
    }),
    openTemplatesView () {
      this.setTableDate({ date: [new Date().toISOString().substring(0, 10)] })
      this.$nextTick(() => {
        const routeName = this.activeTemplate.alertType === 'shop' ? 'orderTemplates' : 'clientAppOrders'
        this.$router.push({ name: routeName, query: { activeItemId: this.activeTemplate.id } })
        this.isSnackbarShown = false
      })
    }
  }
}
</script>
