import Base from './base'
import api from '../api/v1'
import get from 'lodash/get'

const bdoCards = tableName => {
  const cardMatchesFiltersCriteria = (item, filters) => {
    if (['revisionIndex', 'bdoCardTemplates'].includes(tableName) && !item.order) return false
    return filters.every(filter => {
      const { filterBy, propPath } = filter
      const filterValue = filterBy.value ?? filterBy

      return Array.isArray(filterValue)
        ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
        : get(item, propPath) === filterValue
    })
  }

  const base = new Base(tableName, cardMatchesFiltersCriteria)

  const actions = {
    refreshBdoCards: ({ state, commit, rootGetters }) => {
      const { filters } = rootGetters['tables/getTableParameters'](tableName).params
      state.items = state.items.map(item => ({ ...item, loading: 'loading' }))
      commit('SET_PROCESSING', true)
      const params = { ...filters }
      return api.refreshBdoCards({ filters: { ...params }, revised: tableName === 'revisionIndex' }).finally(() => {
        commit('SET_PROCESSING', false)
      })
    },
    fetchNewBdoCards: ({ commit, rootGetters }) => {
      const { daterange } = rootGetters['tables/getTableParameters'](tableName).params?.filters || {}
      const [dateFrom, dateTo] = daterange
      const params = { dateFrom, dateTo: dateTo || dateFrom }
      commit('SET_PROCESSING', true)
      return api.fetchNewBdoCards(params).finally(() => {
        commit('SET_PROCESSING', false)
      })
    },
    updateBdoCard({ commit }, card) {
      commit('UPDATE_BDO_CARD', card)
    },
    massConfirmTransport: ({ state, commit }) => {
      commit('SET_PROCESSING', true)
      return api.massConfirmTransport(state.selectedItems).finally(() => {
        commit('SET_PROCESSING', false)
      })
    },
    addBdoCardTemplate: ({ state, commit }, params) => {
      commit('SET_PROCESSING', true)
      return api.addBdoCardTemplate(params).finally(() => {
        commit('SET_PROCESSING', false)
      })
    },
    createBdoCardFromTemplate: ({ state, commit }, params) => {
      commit('SET_PROCESSING', true)
      return api.createBdoCardFromTemplate(params).finally(() => {
        commit('SET_PROCESSING', false)
      }
      )
    },
    getCounters ({ commit, rootGetters }) {
      const params = rootGetters['tables/getTableParameters'](tableName).params
      api.getBdoCardsCounters(params)
        .then((resp) => {
          commit('SET_COUNTERS', resp.data)
        })
    },
  }

  const mutations = {
    UPDATE_ITEMS_WITH_OFFSET(state, records) {
      const updatedItemIds = records.map(record => record.id)
      state.items = [...state.items].map(item => {
        return updatedItemIds.includes(item.id)
          ? { ...records.find(record => record.id === item.id), loading: item.loading === 'loading' ? 'loaded' : '' }
          : item
          // set checkmark for recently loaded items after refreshBdoCards
      })
    },
    UPDATE_BDO_CARD(state, card) {
      const index = state.items.findIndex(item => item.id === card.id)
      state.items.splice(index, 1, card)
    }
  }

  base.actions = {
    ...base.actions,
    ...actions
  }
  base.mutations = {
    ...base.mutations,
    ...mutations
  }

  return base
}

export default bdoCards
