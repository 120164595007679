import api from '../api/v1'

export default {
  namespaced: true,
  state: {
    isLoadedApp: false,
    isLoading: false,
    department: JSON.parse(localStorage.getItem('EL-Department')) || {},
    filters: {},
  },
  mutations: {
    APP_IS_LOADED(state) {
      state.isLoadedApp = true
    },
    LOADING(state, loading) {
      state.isLoading = loading
    },
    SET_DEPARTMENT(state, department) {
      department.location = {
        lat: parseFloat(department.lat),
        lng: parseFloat(department.lng),
      }
      state.department = department
      localStorage.setItem('EL-Department', JSON.stringify(department))
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
  },
  actions: {
    initApp({ state, dispatch, commit, rootState }) {
      return new Promise(resolve => {
        dispatch('departments/getItems', { params: {} }, { root: true }).then(() => {
          const department = state.department || rootState.departments.items[0]
          commit('SET_DEPARTMENT', department)
          const filters = dispatch('getFilters')
          const containerTypes = dispatch('containerTypes/getItems', null, { root: true })
          const debrisTypes = dispatch('debrisTypes/getItems', { params: { aggregate: false } }, { root: true })

          return Promise.all([containerTypes, filters, debrisTypes]).finally(() => {
            commit('APP_IS_LOADED')
            commit('LOADING', false)
            resolve()
          })
        })
      })
    },
    setLoading({ commit }, loading) {
      commit('LOADING', loading)
    },
    setDepartment({ commit }, department) {
      // todo this needs to be invoke after clientApp login
      commit('SET_DEPARTMENT', department)
    },
    getFilters({ commit }) {
      return api.getFilters().then(resp => {
        commit('SET_FILTERS', resp.data)
      })
    },
  },
}
