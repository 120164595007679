import actions from '../../actions'
import { bdoTabsSharedFilters, bdoTabsSharedParameters } from './sharedParams'
import cloneDeep from 'lodash/cloneDeep'

export default {
  header: [
    { ...actions.refreshBdoCards },
    { ...actions.fetchNewBdoCards }
  ],
  table: {
    maximumDateRange: 92,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'orderRefNumber',
      sortDesc: false
    },
    filters: [
      {
        name: 'cardStatuses',
        text: 'Status karty',
        options: 'bdoCardStatuses',
        filterBy: '',
        propPath: 'cardStatus',
        multiple: true
      },
      {
        name: 'departmentId',
        text: 'Oddział',
        options: 'departments',
        filterBy: '',
      },
      ...cloneDeep(bdoTabsSharedFilters)
    ],
    parameters: [
      { name: 'select', text: '', show: true, value: 'select', sortable: false },
      { name: 'loading', text: '', value: 'loading', show: true, sortable: false },
      { name: 'departmentName', text: 'Oddział', value: 'departmentName', show: true, sortable: false },
      ...cloneDeep(bdoTabsSharedParameters),
      { name: 'senderName', text: 'Przekazujący', value: 'senderName', show: true },
      { name: 'receiverName', text: 'Przyjmujący', value: 'receiverName', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [],
    menu: [
      { ...actions.addBdoCardTemplate },
    ],
    daterangeCriteria: ['realTransportTime']
  },
  details: {
    title: 'Karta nr {{cardNumber}}',
    actions: [
      { ...actions.updateTransportTime },
      { ...actions.confirmTransport },
      { ...actions.confirmBdoCard },
      { ...actions.generateKpoConfirmation },
      { ...actions.reviseKpoCard },
      { ...actions.rejectBdoCard },
      { ...actions.addBdoCardTemplate },
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje o karcie',
        type: 'bottom',
        tableName: 'bdoCards',
        fields: [
          { name: 'cardNumber', text: 'Nr karty', value: 'cardNumber', col: 3 },
          { name: 'cardStatus', text: 'Status karty', value: 'cardStatus', col: 3 },
          { name: 'vehicleRegNumber', text: 'Nr rejestracyjny', value: 'vehicleRegNumber', col: 3 },
          { name: 'senderName', text: 'Przekazujący', value: 'senderName', col: 3 },
          { name: 'senderBusinessPlace', text: 'MPD przekazującego', value: 'senderBusinessPlace.name', col: 3 },
          { name: 'senderBussinessPlaceAddress', text: 'Adres MPD przekazującego', value: 'senderBusinessPlace.address', col: 3 },
          { name: 'placeOfWasteProduction', text: 'Miejsce wytwarzania odpadów', value: ['commune.voivodeship', 'commune.communeType', 'commune.name'], col: 3 },
          { name: 'wasteGeneratingAdditionalInfo', text: 'Dodatkowe informacje o miejscu wytwarzania odpadów', value: 'wasteGeneratingAdditionalInfo', col: 3 },
          { name: 'receiverName', text: 'Przyjmujący', value: 'receiverName', col: 3 },
          { name: 'receiverBusinessPlace', text: 'MPD przyjmującego', value: 'receiverBusinessPlace.name', col: 3 },
          { name: 'receiverBusinessPlace', text: 'MPD transportującego', value: 'carrierBusinessPlace.name', col: 3 },
          { name: 'plannedTransportTime', text: 'Planowany czas transportu', value: 'plannedTransportTime', col: 3 },
          { name: 'realTransportTime', text: 'Rzeczywisty czas transportu', value: 'realTransportTime', col: 3 },
          { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'reportedDebrisType.code', col: 3 },
          { name: 'debrisTypeName', text: 'Typ odpadu', value: 'reportedDebrisType.displayName', col: 3 },
          { name: 'wasteCodeExtendedDescription', text: 'Kod ex informacje', value: 'wasteCodeExtendedDescription', col: 3 },
          { name: 'reportedWeight', text: 'Masa odpadów', value: 'reportedWeight', col: 3 },
          { name: 'additionalInfo', text: 'Dodatkowe informacje', value: 'additionalInfo', col: 3 },
          { name: 'clientNip', text: 'NIP klienta', value: 'clientNip', col: 3 },
          { name: 'remarks', text: 'Uwagi', value: 'remarks', col: 3 }
        ]
      },
      {
        name: 'reviseInfo',
        text: 'Informacje o korekcie',
        type: 'bottom',
        tableName: 'bdoCards',
        fields: [
          { name: 'revisedDebrisTypeCode', text: 'Kod odpadu (przed korektą)', value: 'revisedDebrisType.code', col: 3 },
          { name: 'revisedDebrisTypeName', text: 'Typ odpadu (przed korektą)', value: 'revisedDebrisType.displayName', col: 9 },
          { name: 'revisedWeight', text: 'Masa odpadów (przed korektą)', value: 'revisedWeight', col: 12 },
          { name: 'revisedBy', text: 'Poprawione przez', value: 'revisedBy', col: 3 },
          { name: 'revisedAt', text: 'Czas korekty', value: 'revisedAt', col: 3 }
        ]
      },
      {
        name: 'orderInfo',
        text: 'Informacje o zleceniu',
        type: 'bottom',
        tableName: 'bdoCards',
        endpoint: 'orders',
        idPath: 'order.id',
        fields: [
          { name: 'refNumber', text: 'Nr zlecenia', value: 'order.refNumber', to: 'singleOrder', idPath: 'order.id', col: 3 },
          { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', col: 3 },
          { name: 'clientName', text: 'Klient', value: 'client.name', to: 'singleClient', idPath: 'order.client.id', col: 3 },
          { name: 'reportedWeight', text: 'Raportowana waga [T]', value: 'order.reportedWeight', col: 3, type: 'textField', editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 1001 }] },
          { name: 'debrisTypeId', text: 'Kod odpadu', value: 'order.debrisType.code', type: 'selectDebrisType', col: 3, editable: true, rules: ['required'] },
          { name: 'debrisTypeName', text: 'Typ odpadu', value: 'order.debrisType.displayName', col: 3 },
          { name: 'reportedDebrisTypeId', text: 'Raportowany kod odpadu', value: 'order.reportedDebrisType.code', type: 'selectDebrisType', editable: true, rules: ['required'], col: 3 },
          { name: 'reportedDebrisTypeName', text: 'Raportowany typ odpadu', value: 'order.reportedDebrisType.displayName', col: 3 }
        ]
      },
      {
        name: 'courseInfo',
        text: 'Informacje o kursie',
        type: 'bottom',
        tableName: 'bdoCards',
        endpoint: 'courses',
        idPath: 'course.id',
        fields: [
          { name: 'address', text: 'Adres', value: 'course.address.formattedAddress', col: 3 },
          { name: 'courseType', text: 'Typ kursu', value: 'course.courseType', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'course.dueDate', col: 3 },
          { name: 'phoneNumber', text: 'Numer', value: 'course.phoneNumber', col: 3 }
        ]
      },
      { name: 'bdoPdf', type: 'bottom', component: 'DetailsSectionKPOPdf' }
    ]
  }
}
