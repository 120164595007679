import actions from '../../../actions'

export default {
  table: {
    noApi: true,
    specialFlag: {
      departmentId: undefined,
      aggregate: false
    },
    sorting: {
      sortBy: 'maximumDistance',
      sortDesc: false
    },
    filters: [
      { name: 'departmentId', filterBy: '', hide: true, required: true }
    ],
    parameters: [
      { name: 'maximumDistance', text: 'Odległość od miasta', value: 'maximumDistance', show: true },
      { name: 'price', text: 'Cena', value: 'price', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.editTransportPrice, icon: 'edytuj' },
      { ...actions.deleteTransportPrice, icon: 'usun' }
    ],
    menu: [
      { ...actions.editTransportPrice },
      { ...actions.deleteTransportPrice }
    ]
  }
}
