<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <template v-if="isConfigSet">
      <FiltersBar
        ref="tableTop"
        :table-name="tableName"
        date-range-picker
        show-filters-button
        table-filters
        search-bar
      />
      <v-data-table
        ref="containersHistoryTable"
        class="containers-history-table"
        :headers="tableParams"
        :items="containersHistory"
        :items-per-page="-1"
        :search="containersHistorySearchQuery"
        fixed-header
        hide-default-footer
        :expanded.sync="expandedContainers"
        show-expand
        item-key="containerRefNumber"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              class="expanded-table"
              :headers="expandedTableParams"
              :items="item.entries"
              :items-per-page="-1"
              fixed-header
              hide-default-footer
            >
              <template #[`item.createdAt`]="{item: expandableItem}">
                {{ dateStringFormat(expandableItem.createdAt) }}
              </template>
            </v-data-table>
          </td>
        </template>
        <template #[`header.data-table-expand`]>
          <th>
            <v-switch
              :input-value="!!expandedContainers.length"
              @change="toggleAllContainersHistory"
              hide-details
              dense
              class="ma-0"
              title="Przełącz wszystkie"
            />
          </th>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import FiltersBar from '../../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import { getTableOptions, dateStringFormat } from '../../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Header,
    FiltersBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'containersHistory',
    isConfigSet: false,
    expandedContainers: [],
  }),
  computed: {
    ...mapState({
      containersHistory: state => state.containersHistory.items,
    }),
    tableParams () {
      return getTableOptions(this.tableName).parameters
    },
    expandedTableParams () {
      return getTableOptions(this.tableName).expandedTableParameters
    },
    isAnyFilterSelected () {
      return this.$store.getters['tables/isAnyFilterSelected'](this.tableName)
    },
    isFiltersBarShown () {
      return this.$store.state[this.tableName]?.showFilters
    },
    containersHistorySearchQuery () {
      return this.$store.getters['tables/getTableSearchQuery'](this.tableName)
    },
  },
  watch: {
    isAnyFilterSelected () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeTable() })
    },
    isFiltersBarShown () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeTable() })
    },
  },
  created () {
    this.setTableConfig({ tableName: this.tableName })
      .then(() => {
        this.isConfigSet = true
        this.toggleAllContainersHistory(true)
        this.resizeTable()
      })
  },
  methods: {
    ...mapActions({
      setTableConfig: 'tables/setTableConfig',
    }),
    dateStringFormat,
    resizeTable () {
      this.$nextTick(() => {
        const headerHeight = 64
        const tableTopHeight = this.$refs.tableTop.$el.clientHeight
        this.$refs.containersHistoryTable.$el.querySelector('.v-data-table__wrapper')
          .style.maxHeight = `calc(100vh - ${headerHeight + tableTopHeight}px)`
      })
    },
    toggleAllContainersHistory (expand) {
      this.expandedContainers = expand ? [...this.containersHistory] : []
    }
  }
}
</script>

<style lang="scss" scoped>
.containers-history-table.v-data-table::v-deep {
  .v-data-table__wrapper {
    max-height: calc(100vh - 136px); // 136 is a sum of filters and topbar heights
    tr th span, tr td {
      color: #333 !important;
      font-size:16px !important;
      font-weight: 600
    }
  }
  tbody tr {
    cursor: pointer;
  }
}
</style>
