<template>
  <div class="details-courses my-2">
    <template v-if="list.length">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="details__row--multiple"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="field in detail.fields"
              :key="field.name"
              cols="12"
              sm="12"
              :md="field.col"
              class="py-1"
            >
              <div v-if="checkCondition(field, item)">
                <DialogInput
                  v-if="field.name != 'select'"
                  :field="field"
                  :multiple="item"
                  :detail="detail"
                  :warning="checkWarning(field, item)"
                />
                <v-checkbox
                  v-else
                  :value="selectedItems.includes(item.id)"
                  hide-details
                  default="false"
                  @change="(event)=>selectItem(!!event,item)"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-col v-if="warnings.length">
        <Warning
          v-for="warning in warnings"
          :key="warning"
          :message="warning"
          class="ml-2 my-2"
        />
      </v-col>
    </template>
    <div
      v-else
      class="px-6 details-attachments__name"
    >
      Brak elementów do wyświetlenia
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DialogInput from '../../Forms/Inputs/DialogInput'
import detailsSectionWarningMixin from '../../../mixins/detailsSectionWarningMixin'

export default {
  components: {
    DialogInput
  },
  mixins: [detailsSectionWarningMixin], // warnings
  props: {
    detail: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
  },
  beforeDestroy() {
    this.unselectAllItems()
  },
  computed: {
    selectedItems() {
      return this.$store.state[this.detail.name]?.selectedItems || []
    }
  },
  methods: {
    ...mapActions({
      selectItem (dispatch, selected, item) {
        const action = selected ? 'selectItem' : 'unselectItem'
        return dispatch(`${this.detail.name}/${action}`, item.id)
      },
      unselectAllItems(dispatch) {
        if (!this.$store.hasModule(this.detail.name)) return // ensure module exists
        return dispatch(`${this.detail.name}/unselectAllItems`)
      }
    }),
    checkCondition(field, item) {
      return !field.condition || field.condition(item)
    },
    checkWarning(field, item) {
      if (Object.hasOwn(field, 'warning')) {
        return field.warning?.condition(item)
      } else {
        return false
      }
    }

  }
}
</script>

<style scoped>
 .v-input--checkbox input[type="checkbox"]:hover ~ .v-input__control {
    background-color: transparent !important;
  }
</style>
