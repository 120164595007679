import axios from 'axios'
import humps from 'humps'
import qs from 'qs'
import store from '../store'
import isLogisticsApp from '../const/isLogisticsApp'

export const axiosApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v1/`,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem('EL-Auth-Token')}`,
      Refresh: `${localStorage.getItem('EL-Refresh-Token')}`
    }
  },
  paramsSerializer: params => qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'brackets' }),
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => (data instanceof Blob ? data : humps.camelizeKeys(data))
  ],
  transformRequest: [
    data => (data instanceof FormData ? data : humps.decamelizeKeys(data)),
    ...axios.defaults.transformRequest
  ]
})

async function renewToken(config) {
  const res = await axios({
    ...config,
    responseType: 'json',
    method: 'post',
    url: 'sessions/refresh_token',
    params: {},
    headers: {
      Authorization: `Bearer ${localStorage.getItem('EL-Auth-Token')}`,
      Refresh: `${localStorage.getItem('EL-Refresh-Token')}`,
    },
  })

  const module = isLogisticsApp ? 'auth' : 'authClient'
  store.commit(`${module}/SET_TOKEN`, res.data)
  authorizeAxios(res.data)
  localStorage.setItem('EL-Auth-Token', res.data.token)
  localStorage.setItem('EL-Refresh-Token', res.data.refreshToken)
  config.headers.Authorization = 'Bearer ' + res.data.token
  config.headers.Refresh = res.data.refreshToken || ''
  return res.data
}

let refreshingFunc

export function axiosErrorInterceptor() {
  axiosApi.interceptors.response.use(undefined, async error => {
    const module = isLogisticsApp ? 'auth' : 'authClient'

    const logout = ({ noApi } = { noApi: false }) => {
      store.dispatch(`${module}/logout`, { noApi })
      store.dispatch('snackbar/showSnackbar', {
        message: ['Zaloguj się ponownie'],
        type: 'error'
      })
    }

    const { data } = error.response
    const responseData = data instanceof Blob ? await data?.text() : data || {}
    let parsedData
    try {
      parsedData = JSON.parse(responseData)
    } catch (e) {
      parsedData = responseData
    }

    switch (error.response?.status) {
      case 401: {
        if (!store.state[module].token) {
          return Promise.reject(error)
        }
        let errorData = error.response.data
        if (errorData instanceof Blob) {
          errorData = JSON.parse(await error.response.data.text())
        }
        if (errorData.errors === 'Token wygasł.') {
          const originalConfig = error.config

          try {
            if (!refreshingFunc) refreshingFunc = renewToken(originalConfig)
            const { token, refreshToken } = await refreshingFunc

            // Retry the original request with the new token
            return axiosApi
              .request({
                ...originalConfig,
                headers: { ...originalConfig.headers, Authorization: `Bearer ${token}`, Refresh: refreshToken }
              })
              .catch(retryError => {
                return Promise.reject(retryError) // Propagate the retry error
              })
          } catch (err) {
            return logout({ noApi: true }) // if refresh token is expired
          } finally {
            refreshingFunc = undefined
          }
        } else if (errorData.errors === 'Token nieprawidłowy.') {
          return logout({ noApi: true }) // if any token is invalid
        }

        logout()
        break
      }

      case 404:
        store.dispatch('snackbar/showSnackbar', {
          message: parsedData.errors || ['Nie znaleziono'],
          type: 'error'
        })
        break
      case 409:
        store.dispatch('snackbar/showSnackbar', {
          message: parsedData.errors,
          type: 'error'
        })
        break
      case 422:
        store.dispatch('snackbar/showSnackbar', {
          message: parsedData.errors || parsedData.warnings,
          type: 'error'
        })
        break
      default:
        store.dispatch('snackbar/showSnackbar', {
          message: ['Coś poszło nie tak, spróbuj ponownie.'],
          type: 'error'
        })
    }
    return Promise.reject(error)
  })
}

export function authorizeAxios ({ token, refreshToken }) {
  if (token) axiosApi.defaults.headers.common.Authorization = `Bearer ${token}`
  if (refreshToken) axiosApi.defaults.headers.common.Refresh = refreshToken
}

export function deauthorizeAxios() {
  delete axiosApi.defaults.headers.common.Authorization
  delete axiosApi.defaults.headers.common.Refresh
}
