
import actions from '../actions'

export default {
  table: {
    noDateRange: true,
    sorting: {
      sortBy: 'displayName',
      sortDesc: false
    },
    parameters: [
      { name: 'displayName', text: 'Nazwa', value: 'displayName', show: true, maxLength: 200 },
      { name: 'settingValue', text: 'Wartość', value: 'value', show: true, maxLength: 200 },
    ],
    menu: [
      { ...actions.editSettings },
    ],
  },
}
