import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"logout-section-input",attrs:{"value":_vm.formattedDate,"background-color":"#2E3849","readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(!_vm.mini)?_c(VBtn,{staticClass:"ml-2 mr-n2",attrs:{"disabled":_vm.isTodayDate,"icon":"","x-small":""},on:{"click":_vm.refreshDate}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-calendar-remove ")])],1):_vm._e()]},proxy:true},{key:"append",fn:function(){return [(!_vm.mini)?_c('SwitchDayButtons',{staticClass:"mr-1 mt-1",attrs:{"value":_vm.globalDate},on:{"input":_vm.setGlobalDate}}):_vm._e()]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},'v-menu',_vm.attributes.menu,false),[_c(VDatePicker,_vm._b({attrs:{"value":_vm.globalDate},on:{"input":function($event){[_vm.setGlobalDate($event), _vm.showDatePicker = false]}}},'v-date-picker',_vm.attributes.datePicker,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }