<template>
  <div>
    <Header
      ref="header"
      v-if="tableName"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <template v-if="tableName !== 'orderSchedules'">
        <MultipleItemsActions
          v-for="action in multipleActionButtons"
          :key="action.subject"
          :action="action"
          table-name="orders"
        />
      </template>
    </Header>
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured"
          ref="tabs"
          :tabs="tabs"
          persistent
          @goTo="goTo"
        />
        <Table
          v-if="tableName"
          :key="currentTab"
          :current-tab="currentTab"
          ref="table"
          :table-name="tableName"
          :set-row-class="highlightRow"
          @selectRow="selectTableRow"
          @openDetails="openTableDetails"
          @closeDetails="closeTableDetails"
          @contextMenu="openContextMenu"
          @configured="configured = true"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :table-name="tableName"
              search-bar
              :week-days-picker="tableName === 'orderSchedules'"
              :date-range-picker="tableName !== 'orderSchedules'"
              :date-range-picker-props="{hidePresets: sidebar.size > 0}"
              show-filters-button
              table-filters
            />
          </template>
          <template #stats>
            <div class="ml-10 mr-12 d-flex">
              <span class="table-statistics__label">Łączna raportowana waga</span>
              <span class="table-statistics__value">{{ roundToFourthDecimalPlace(counters.reportedWeight || 0) }} T</span>
            </div>
          </template>
        </Table>
      </div>
      <TableConfirmationBar
        v-if="selectedItems.length"
        :confirm-actions="confirmationBarActions"
        :table-name="tableName"
        ref="confirmation"
      />
    </div>
    <Sidebar v-if="chosenItem">
      <component
        :key="chosenItem.id"
        :is="componentTableDetails"
        :table-name="tableName"
        :data="chosenItem"
        :actions="availableActions"
        @closeDetails="closeTableDetails"
      />
    </Sidebar>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import SingleViewOrder from '../../components/Single/SingleViewOrder'
import SingleView from '../../components/Single/SingleView'
import FiltersBar from '../../components/Filters/FiltersBar'
import Sidebar from '../../components/Layout/Sidebar/Sidebar'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import MultipleItemsActions from '../../components/Elements/MultipleItemsActions'
import actions from '../../const/actions'
import { mapState, mapGetters, mapActions } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import { getLabelWithShortkey, multiselectShortkeys, exportShortkeys, paymentShortkeys } from '../../const/shortKeys'

const tabs = Object.freeze([
  { value: 'openOrders', tableName: 'orders', text: 'Zlecenia aktywne', filters: [{ name: 'done', filterBy: false, hide: true }, { name: 'withInvoiceStatus', filterBy: '', hide: true }, { name: 'clientBdoTypes', hide: false, disabled: false }, { name: 'bdoCardStatuses', hide: true, disabled: true }] },
  { value: 'ordersCompleted', tableName: 'orders', text: 'Zakończone zlecenia', filters: [{ name: 'done', filterBy: true, hide: true }, { name: 'withInvoiceStatus', filterBy: '', hide: true }, { name: 'clientBdoTypes', hide: false, disabled: false }, { name: 'bdoCardStatuses', hide: false, disabled: false }] },
  { value: 'allOrders', tableName: 'orders', text: 'Wszystkie zlecenia', filters: [{ name: 'done', filterBy: '', hide: true }, { name: 'withInvoiceStatus', filterBy: '', hide: true }, { name: 'clientBdoTypes', hide: false, disabled: false }, { name: 'bdoCardStatuses', hide: true, disabled: true }] },
  { value: 'orderSchedules', tableName: 'orderSchedules', text: 'Harmonogramy', filters: [{ name: 'clientBdoTypes', hide: true, disabled: true }, { name: 'kpoStatuses', hide: true, disabled: true }] },
  { value: 'ordersWithInvoiceStatus', tableName: 'orders', text: 'Wymaga korekty faktury', filters: [{ name: 'withInvoiceStatus', filterBy: true, hide: true }, { name: 'clientBdoTypes', hide: true, disabled: true }, { name: 'bdoCardStatuses', hide: true, disabled: true }] }
])

export default {
  components: {
    Header,
    Tabs,
    Table,
    SingleView,
    SingleViewOrder,
    FiltersBar,
    TableConfirmationBar,
    MultipleItemsActions,
    Sidebar
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'orders',
    currentTab: 0,
    tabs,
    actions,
    configured: false,
  }),
  channels: {
    OrderIndexChannel: { received (data) { this.captureChanges(data) } },
    OrderScheduleIndexChannel: { received (data) { this.captureChanges(data) } },
  },
  computed: {
    ...mapState({
      order: state => state.order.entity,
      department: state => state.core.department,
      orderSchedule: state => state.orderSchedule.entity,
      selectedItems: state => state.orders.selectedItems,
      counters: state => state.orders.counters
    }),
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
    multiselectSubject() {
      return this.$store.state[this.tableName].multiselectSubject
    },
    clientsFilter () {
      return this.getFilterValue(this.tableName, 'clientIds')
    },
    chosenItem () {
      if (this.order.id && this.tableName !== 'orderSchedules') return this.order
      if (this.orderSchedule.id && this.tableName === 'orderSchedules') return this.orderSchedule
      return {}
    },
    componentTableDetails () {
      const component = {
        orders: 'SingleViewOrder',
        orderSchedules: 'SingleView',
        ordersCompleted: 'SingleViewOrder',
        ordersWithInvoiceStatus: 'SingleViewOrder'
      }
      return component[this.tableName]
    },
    multipleActionButtons() {
      return [
        { label: 'Link do płatności', subject: 'generateLink', shortkey: paymentShortkeys, confirmActions: [actions.generatePaymentLink] },
        { label: 'Faktura zbiorcza', subject: 'generateInvoice', shortkey: multiselectShortkeys, confirmActions: [actions.generateInvoice] },
        { label: 'Exportuj do csv', subject: 'export', shortkey: exportShortkeys, confirmActions: [actions.exportOrdersToCsv] },
      ]
    },
    confirmationBarActions () {
      return this.multipleActionButtons.find(action => action.subject === this.multiselectSubject)?.confirmActions || []
    },
  },
  watch: {
    selectedItems (newValue, oldValue) {
      if (newValue.length !== oldValue.length && (newValue.length === 0 || oldValue.length === 0)) {
        this.$refs.table.resizeTable()
      }
    },
    clientsFilter (clientIds) {
      if (this.tableName === 'orders') {
        const isAddressFilterEnabled = clientIds?.length === 1
        const isAddressFilterActive = !!this.getFilterValue(this.tableName, 'addressIds')?.length
        if (isAddressFilterEnabled) {
          const clientId = clientIds[0].value
          this.getAddresses({ params: { filters: { clientId, departmentIds: [this.department.id] } } })
        }
        this.setTableFilters({
          filters: [{ name: 'addressIds', filterBy: '', disabled: !isAddressFilterEnabled, }],
          tableName: this.tableName,
          disableFetch: !isAddressFilterActive
        })
      }
    },
  },
  mounted () {
    this.subscribeSocket('OrderIndexChannel')
    this.unsubscribeAction = this.$store.subscribeAction({
      after: action => {
        if (['orders/getItems'].includes(action.type)) {
          this.getCounters()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribeSocket('OrderIndexChannel')
    this.unsubscribeSocket('OrderScheduleIndexChannel')
    this.unsubscribeAction()
  },
  methods: {
    ...mapActions({
      toggleMultiselectStatus: 'orders/toggleMultiselectStatus',
      setSingleOrder: 'order/setSingleOrder',
      getAddresses: 'addresses/getItems',
      getSingleOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      getSingleSchedule: 'orderSchedule/getSingleSchedule',
      clearSingleSchedule: 'orderSchedule/clearSingleSchedule',
      setTableFilters: 'tables/setTableFilters',
      closeSidebar: 'layout/closeSidebar',
      getCounters: function(dispatch) {
        return dispatch(`${this.tableName}/getCounters`)
      },
    }),
    getLabelWithShortkey,
    goTo (tab) {
      const { value, tableName, filters } = tab
      const prevTableName = this.tableName
      this.currentTab = value
      this.tableName = tableName

      if (filters?.length) {
        this.setTableFilters({ filters, tableName })
      }
      if (tableName !== prevTableName) {
        if (tableName === 'orderSchedules') {
          this.subscribeSocket('OrderScheduleIndexChannel')
          this.unsubscribeSocket('OrderIndexChannel')
        } else {
          this.subscribeSocket('OrderIndexChannel')
          this.unsubscribeSocket('OrderScheduleIndexChannel')
        }
      }
    },
    selectTableRow (data) {
      if (this.tableName !== 'orderSchedules') this.setSingleOrder(data)
    },
    openTableDetails (data) {
      if (this.tableName === 'orderSchedules') {
        this.$router.push({ name: 'singleOrderSchedule', params: { id: data.id } })
      } else {
        this.$router.push({ name: 'singleOrder', params: { id: data.id, originTable: 'orders' }, query: { nonSwapView: true } }) // for all orders don't display swap view
      }
    },
    closeTableDetails () {
      if (this.tableName === 'orderSchedules') {
        this.clearSingleSchedule()
      }
      this.closeSidebar()
    },
    highlightRow(item) {
      if (this.currentTab === 'orderSchedules') return ''
      else if (['Zapłacone', 'Zapłacone online [Link]', 'Zapłacone online [Sklep]', 'Stare'].includes(item.paymentStatus)) return 'paid-order'
    },
    roundToFourthDecimalPlace (num) {
      return Math.round(num * 10000) / 10000
    },
  }
}
</script>

<style lang="scss" scoped>
.table-view-wrapper::v-deep {
  .paid-order  {
    background-color: #fff20030;
  }
}
</style>
