<template>
  <hsc-window-style-white>
    <hsc-window
      :title="modalTitle"
      position-hint="-50 / -50"
      :close-button="true"
      :is-open.sync="showModal"
      class="floating-popup moving-popup"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <v-card
        min-width="400px"
        max-width="500px"
        color="white"
        light
      >
        <v-card-text>
          <v-row
            v-if="lastCall"
            class="mb-3"
          >
            <v-col cols="6">
              <span class="label">Odbiorca</span>
              <div>{{ lastCall.user || "Brak" }}</div>
            </v-col>
            <v-col cols="6">
              <span class="label">Data</span>
              <div>{{ formatDate(lastCall.createdAt) || "Brak" }}</div>
            </v-col>
            <v-col cols="12">
              <span class="label">Notatka</span>
              <div class="notes-wrapper">
                {{ lastCall.notes || "Brak" }}
              </div>
            </v-col>
          </v-row>
          <template v-if="clientsRelatedToPhoneNumber.length">
            <div
              v-shortkey="selectActiveClientShortkeys"
              @shortkey="manageActiveClient"
              class="client-list"
            >
              <v-btn
                v-for="(client, index) in clientsRelatedToPhoneNumber"
                :ref="`button${index}`"
                :key="client.id"
                class="client-button mb-4"
                :class="{'not-selected' : index !== activeIndex}"
                color="primary"
                max-width="100%"
                dark
                block
                @click="openClientPanel(client)"
              >
                {{ client.name }}
              </v-btn>
            </div>
          </template>

          <template v-else>
            <v-radio-group
              v-model="isProspectMode"
              class="pt-0 mt-0"
              row
              @change="$refs.form.resetValidation()"
            >
              <v-radio
                label="Prospekt"
                :value="true"
              />
              <v-radio
                label="Istniejacy klient"
                :value="false"
              />
            </v-radio-group>

            <v-form
              ref="form"
              class="custom-form"
              @submit.prevent="performSubmitAction"
            >
              <template v-if="isProspectMode">
                <v-textarea
                  v-model="prospectNotes"
                  class="my-2"
                  placeholder="Wpisz notatkę do rozmowy"
                  label="Notatka"
                  outlined
                  validate-on-blur
                  :rules="[rules.required]"
                  :rows="3"
                  hide-details
                  auto-grow
                  light
                />

                <v-btn
                  color="primary"
                  class="base-hover d-block ml-auto"
                  type="submit"
                  :loading="prospectProcessing"
                >
                  Stwórz prospekt
                </v-btn>
              </template>
              <template v-else>
                <v-autocomplete
                  outlined
                  :items="clientsFromQuery"
                  :rules="[rules.required]"
                  label="Szukaj klienta"
                  v-model="chosenClient"
                  item-text="name"
                  return-object
                  hide-details
                  :loading="clientProcessing"
                  placeholder="Szukaj klienta"
                  validate-on-blur
                  :search-input.sync="clientQuery"
                  :filter="() => clientsFromQuery"
                  @keydown="searchForClientsWithQuery"
                  dense
                />

                <v-input
                  :value="chosenClient.id"
                  :rules="[rules.required]"
                  hide-details
                  class="validated-label mb-6"
                >
                  <div class="mr-1">
                    Wybrany klient:
                  </div>
                  <span class="font-weight-medium">{{ chosenClient.name || '-' }}</span>
                </v-input>

                <v-text-field
                  v-model="contactName"
                  placeholder="Wpisz imię i nazwisko kontaktu"
                  label="Imię i nazwisko kontaktu"
                  class="mb-4"
                  outlined
                  hide-details
                />

                <v-textarea
                  v-model="clientNotes"
                  class="my-2"
                  placeholder="Wpisz notatkę do rozmowy"
                  label="Notatka"
                  outlined
                  :rules="[rules.required]"
                  :rows="3"
                  hide-details
                  auto-grow
                  light
                />

                <v-btn
                  color="primary"
                  class="base-hover d-block ml-auto"
                  type="submit"
                  :disabled="!chosenClient.id"
                  :loading="clientProcessing"
                >
                  Dodaj do klienta
                </v-btn>
              </template>
            </v-form>
          </template>
        </v-card-text>
      </v-card>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { openNewWindow } from '../../utils'
import rules from '../../utils/validators'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapState, mapActions } from 'vuex'
import { selectActiveClientShortkeys, shortkeysNavigateBoundaries } from '../../const/shortKeys'
import humps from 'humps'

export default {
  data: () => ({
    clientsRelatedToPhoneNumber: [],
    callEntryId: null,
    activeIndex: 0,
    prospectNotes: null,
    clientNotes: null,
    callStart: null,
    isProspectMode: true,
    clientQuery: '',
    clientsFromQuery: [],
    chosenClient: { id: null },
    contactName: '',
    selectActiveClientShortkeys,
    rules,
    dialogZIndex: 10,
    lastCall: null,
  }),
  channels: {
    CRMCallNotifyChannel: { received (data) { this.findClientsRaletedToPhoneNumber(data) } }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      prospectProcessing: state => state.prospects.isProcessing,
      clientProcessing: state => state.client.isProcessing,
      incomingPhoneNumber: state => state.clientSearchDialog.incomingPhoneNumber,
    }),
    showModal: {
      get () {
        return this.$store.state.clientSearchDialog.isActiveModal
      },
      set (showModal) {
        this.setModalActivity(showModal)
        if (!showModal) {
          this.clientsRelatedToPhoneNumber = []
          this.callEntryId = null
          this.prospectNotes = null
          this.clientNotes = null
          this.clientQuery = ''
          this.clientsFromQuery = []
          this.chosenClient = { id: null }
          this.contactName = ''
          this.$refs.form.resetValidation()
        } else {
          this.updateDialogIndex()
        }
      }
    },
    modalTitle () {
      return this.clientsRelatedToPhoneNumber.length
        ? `Klienci powiązani z numerem ${this.incomingPhoneNumber}`
        : `Prospekt klienta ${this.incomingPhoneNumber}`
    }
  },
  mounted () {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
    this.$cable.subscribe({ channel: 'CRMCallNotifyChannel' })
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      createProspect: 'prospects/editItem',
      getClientsByQuery: 'client/getClientsByQuery',
      addNewContact: 'clients/addNewItem',
      setIncomingPhoneNumber: 'clientSearchDialog/setIncomingPhoneNumber',
      setModalActivity: 'clientSearchDialog/setModalActivity',
      updateDialogIndex: function (dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      },
    }),
    searchForClientsWithQuery: debounce(function () {
      if (this.clientQuery && this.clientQuery.length > 2) {
        this.getClientsByQuery(this.clientQuery)
          .then((clients) => {
            this.clientsFromQuery = clients
          })
      }
    }, 500),
    findClientsRaletedToPhoneNumber (data) {
      this.callStart = new Date().toISOString()
      const { phoneNumber, clients, callEntryId, lastCall } = humps.camelizeKeys(data)
      this.setIncomingPhoneNumber(phoneNumber)
      this.callEntryId = callEntryId
      this.clientsRelatedToPhoneNumber = clients
      this.showModal = true
      this.activeIndex = 0
      this.lastCall = lastCall
    },
    openClientPanel (client) {
      const path = `/clients/${client.id}`
      const { contact_id: contactId } = client
      const query = {
        phoneNumber: this.incomingPhoneNumber,
        callStart: this.callStart,
        contactId,
        callEntryId: this.callEntryId,
        immediateInit: true,
      }
      openNewWindow.call(this, { path, query })
    },
    manageActiveClient (event) {
      if (event.srcKey === 'enter') {
        this.openClientPanel(this.clientsRelatedToPhoneNumber[this.activeIndex])
      } else {
        const { activeIndex, clientsRelatedToPhoneNumber } = this
        this.activeIndex = shortkeysNavigateBoundaries(
          event.srcKey,
          activeIndex,
          clientsRelatedToPhoneNumber,
          ['up'],
          ['down']
        )
      }
    },
    performSubmitAction() {
      if (this.isProspectMode) {
        this.addProspect()
      } else {
        this.addToClient()
      }
    },
    addProspect () {
      const params = {
        notes: this.prospectNotes,
        direction: 'Wychodzące',
        callStart: new Date().toISOString(),
        phoneNumber: this.incomingPhoneNumber,
      }
      this.createProspect({ params, id: this.callEntryId, tableName: 'callEntries' })
        .then(() => {
          this.showModal = false
        })
    },
    addToClient () {
      const names = this.contactName.split(' ')
      const params = {
        clientId: this.chosenClient.id,
        phoneNumber: this.incomingPhoneNumber,
        role: 'Inny',
        lastName: names.pop() || null,
        firstName: names.join(' ') || null,
      }
      this.addNewContact({ params, endpoint: 'contacts' })
        .then((data) => {
          const params = {
            clientId: this.chosenClient.id,
            contactId: data.id,
            notes: this.clientNotes,
          }
          this.createProspect({ params, id: this.callEntryId, tableName: 'callEntries' }).then(() => {
            this.openClientPanel(cloneDeep({ ...this.chosenClient, contactId: data.id }))
            this.showModal = false
          })
        })
    },
    formatDate (date) {
      if (!date) return null
      return new Date(date).toLocaleString()
    },
  }
}
</script>

<style lang="scss" scoped>
.client-button::v-deep .v-btn__content {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block;
  text-align: center;
}

.not-selected {
  opacity: 0.7;
}

.client-list {
  max-height: 400px;
  overflow-y: scroll;
}

.label {
  font-weight: 800;
  font-size: 12px;
  display: block;
  color: $color-text-dark-grey;
}

.notes-wrapper {
  max-height: 100px;
  overflow-y: auto;
}

</style>
