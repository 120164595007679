import Base from './base'
import api from '../api/v1'

const state = {
  settings: []
}

const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings
  },
  CHANGE_SETTING_VALUE(state, param) {
    const index = state.settings.findIndex(setting => setting.var === param.var)
    state.settings[index].value = param.value
  }
}

const actions = {
  getClientAppOrdersSettings: ({ commit }) => {
    commit('SET_PROCESSING', true)
    return api.getClientAppOrdersSettings()
      .then((resp) => {
        commit('SET_SETTINGS', resp.data)
      })
      .finally(() => {
        commit('SET_PROCESSING', false)
      })
  },
  changeSettingValue: ({ commit }, records) => {
    records.forEach(record => {
      commit('CHANGE_SETTING_VALUE', record.data)
    })
  },
}

const module = new Base('clientAppOrders')

module.state = {
  ...module.state,
  ...state
}
module.mutations = {
  ...module.mutations,
  ...mutations
}
module.actions = {
  ...module.actions,
  ...actions
}

export default module
