<template>
  <v-menu
    v-if="drivers.length"
    bottom
    offset-y
    elevation="0"
    min-width="200"
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="relative"
      >
        {{ driversNames }}
        <div
          v-if="otherDriversCount"
          class="alerts__drivers--number"
        >
          +{{ otherDriversCount }}
        </div>
      </div>
    </template>
    <v-card elevation="0">
      <v-card-text class="pt-3 pb-0 px-3">
        <div
          :key="driver.id"
          v-for="driver in drivers"
          class="alerts__drivers--more d-flex pb-2"
        >
          <Icon
            :name="'alert_'+ iconName(driver.deliveryStatus)"
            size="small"
            class="mr-2 mt-1"
          />
          <div>
            {{ driver.name }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>

export default {
  props: {
    drivers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    driversNames () {
      const drivers = []
      this.drivers.map((item, index) => {
        if (index < 2) drivers.push(item.name)
      })
      return drivers.join(', ')
    },
    otherDriversCount () {
      const { length } = this.drivers
      return length > 2 ? length - 2 : null
    }
  },
  methods: {
    iconName (status) {
      const availableStatuses = {
        Wysłany: 'sent',
        Dostarczony: 'delivered',
        Odczytany: 'opened'
      }
      return availableStatuses[status] || 'sent'
    }
  }
}
</script>
