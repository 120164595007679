<template>
  <div class="d-flex align-center">
    {{ formattedValue }}
    <Icon
      v-if="done"
      name="checkmark"
      color="#E0F7EC"
      size="small"
      class="ml-1"
    />
  </div>
</template>
<script>

import { dateStringFormat } from '../../../utils'

export default {
  props: {
    done: {
      type: Boolean,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedValue () {
      const hasTime = this.text.toLowerCase().includes('czas')
      return dateStringFormat(this.date, hasTime)
    }
  }
}
</script>
