<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <MultipleItemsActions
        v-for="action in multipleActionButtons"
        :key="action.subject"
        :action="action"
        table-name="invoices"
      />
    </Header>
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured"
          ref="tabs"
          :tabs="tabs"
          persistent
          @goTo="goTo"
        />
        <Table
          ref="table"
          :table-name="tableName"
          @selectRow="selectTableRow"
          @openDetails="openSingleInvoiceView"
          @contextMenu="openContextMenu"
          @configured="configured = true"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :key="currentTab"
              :table-name="tableName"
              search-bar
              date-range-picker
              show-filters-button
              table-filters
            />
          </template>
          <template #stats>
            <TableStatisticsBar
              ref="statistics"
              :table-name="tableName"
            />
          </template>
        </Table>
      </div>
      <TableConfirmationBar
        v-if="selectedItems.length"
        :confirm-actions="confirmationBarActions"
        :table-name="tableName"
        ref="confirmation"
      />
    </div>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import Tabs from '../../components/Layout/Tabs'
import FiltersBar from '../../components/Filters/FiltersBar'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import TableStatisticsBar from '../../components/Table/TableStatisticsBar'
import MultipleItemsActions from '../../components/Elements/MultipleItemsActions'
import actions from '../../const/actions'
import { mapActions, mapState } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import {
  getLabelWithShortkey,
  multiselectShortkeys,
  paymentShortkeys,
  exportShortkeys
} from '../../const/shortKeys'

const tabs = Object.freeze([
  {
    value: 'invoices',
    text: 'Faktura',
    filters: [
      { name: 'clientIds', hide: false },
      { name: 'corrections', filterBy: '', hide: true },
      { name: 'settlementTypes', filterBy: ['Faktura', 'Faktura imienna'], hide: false },
    ]
  },
  {
    value: 'receipt',
    text: 'Paragon',
    filters: [
      { name: 'clientIds', filterBy: [], hide: true },
      { name: 'corrections', filterBy: '', hide: true },
      { name: 'settlementTypes', filterBy: ['Paragon'], hide: true },
    ]
  },
  {
    value: 'correction',
    text: 'Faktury korygujące',
    filters: [
      { name: 'clientIds', hide: false },
      { name: 'corrections', filterBy: true, hide: true },
      { name: 'settlementTypes', filterBy: [], hide: false },
    ]
  },
])

export default {
  components: {
    Header,
    Table,
    Tabs,
    FiltersBar,
    TableConfirmationBar,
    MultipleItemsActions,
    TableStatisticsBar
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'invoices',
    currentTab: 'openInvoices',
    actions,
    tabs,
    multiselectShortkeys,
    exportShortkeys,
    configured: false
  }),
  channels: {
    InvoiceIndexChannel: {
      received(data) {
        this.captureChanges(data)
        this.getInvoicesCounters()
      }
    }
  },
  computed: {
    ...mapState({
      invoice: state => state.invoice.entity,
      selectedItems: state => state.invoices.selectedItems,
      isMultiselectActive: state => state.invoices.isMultiselectActive
    }),
    multiselectSubject() {
      return this.$store.state[this.tableName].multiselectSubject
    },
    multipleActionButtons() {
      return [
        { label: 'Prześlij dokumenty', subject: 'send', shortkey: paymentShortkeys, confirmActions: [{ ...actions.sendInvoices, text: 'Prześlij dokumenty' }] },
        { label: 'Pobierz dokumenty', subject: 'download', shortkey: multiselectShortkeys, confirmActions: [actions.downloadMultipleInvoices] },
        { label: 'Eksportuj do csv', subject: 'export', shortkey: exportShortkeys, confirmActions: [actions.exportInvoicesToCsv] },
      ]
    },
    confirmationBarActions () {
      return this.multipleActionButtons.find(action => action.subject === this.multiselectSubject)?.confirmActions || []
    },
  },
  watch: {
    selectedItems(newValue, oldValue) {
      if (
        newValue.length !== oldValue.length &&
        (newValue.length === 0 || oldValue.length === 0)
      ) {
        this.$refs.table.resizeTable()
      }
    }
  },
  mounted() {
    this.unsubscribeAction = this.$store.subscribeAction({
      after: action => {
        if (action.type === 'invoices/getItems') this.getInvoicesCounters()
      }
    })
    this.subscribeSocket('InvoiceIndexChannel')
  },
  beforeDestroy() {
    this.unsubscribeSocket('InvoiceIndexChannel')
    this.unsubscribeAction()
  },
  methods: {
    ...mapActions({
      setSingleInvoice: 'invoice/setSingleInvoice',
      getInvoicesCounters: 'invoices/getCounters',
      setTableFilters: 'tables/setTableFilters'
    }),
    getLabelWithShortkey,
    selectTableRow(data) {
      this.setSingleInvoice(data)
    },
    openSingleInvoiceView({ id }) {
      this.$router.push({ name: 'singleInvoice', params: { id } })
    },
    goTo(tab) {
      this.currentTab = tab.value
      this.setTableFilters({ filters: tab.filters, tableName: this.tableName })
    }
  }
}
</script>
