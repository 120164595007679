import Base from './base'
import api from '../api/v1'
import { downloadFile } from '../utils'
import { formatValue } from '../utils/typesEnum'
import { decamelize } from 'humps'

const invoices = (tableName) => {
  const base = new Base(tableName)

  const state = {
    counters: {},
  }

  const actions = {
    downloadMultipleInvoices ({ state, dispatch }) {
      api.archiveInvoices({ invoiceIds: state.selectedItems })
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/zip' }))
          const filename = 'Faktury ' + new Date().toISOString().substring(0, 10)
          downloadFile(url, filename)
        })
        .finally(() => {
          dispatch('toggleMultiselectStatus', false)
        })
    },
    exportInvoicesSpreadsheet ({ state, commit, rootState, rootGetters }) {
      commit('SET_PROCESSING', true)
      const invoiceIds = state.selectedItems

      const invoiceHeaders = rootState.tables[tableName].parameters
        .filter(header => header.show && !['select', 'actions'].includes(header.name))
        .map(header => decamelize(header.name))

      const { order, filters, departmentId } = rootGetters['tables/getTableParameters'](tableName).params

      api.exportInvoicesSpreadsheet({
        departmentId,
        invoiceHeaders,
        invoiceIds,
        order,
        filters
      }).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'text/csv' }))
        downloadFile(url, `Faktury ${state.dateRange}`)
        commit('UNSELECT_ALL_ITEMS')
      })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    getCounters ({ commit, rootGetters }) {
      const params = rootGetters['tables/getTableParameters'](tableName).params
      api.getInvoicesCounters(params)
        .then((resp) => {
          commit('SET_COUNTERS', resp.data)
        })
    },
  }

  const getters = {
    getStatistics: (state) => {
      const labels = {
        leftToPayValue: 'Pozostało do zapłaty',
        grossValue: 'Wartość brutto',
        netValue: 'Wartość netto',
        paidValue: 'Zapłacono'
      }
      const results = {}
      for (const [key, value] of Object.entries(state.counters)) {
        results[key] = {
          text: `${labels[key]}:`,
          value: formatValue(value, 'price')
        }
      }
      return results
    }
  }

  base.state = {
    ...base.state,
    ...state
  }
  base.actions = {
    ...base.actions,
    ...actions
  }
  base.getters = {
    ...base.getters,
    ...getters
  }

  return base
}

export default invoices
