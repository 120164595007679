<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :table-name="tableName"
      disable-pagination
      @contextMenu="openContextMenu"
    />
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Table from '../../components/Table/Table'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'

export default {
  components: {
    Header,
    Table,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'settings',
  })
}
</script>
