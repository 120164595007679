<template>
  <div class="contact-details">
    <div class="detail-section__header contact-details__header">
      Klienci końcowi

      <ActionButtons
        v-if="actions.length"
        :actions="actions"
        :item="client"
      />
    </div>

    <v-divider />

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="middlemanClients"
      :loading="isProcessing"
      loading-text="Pobieram elementy..."
      class="details-table mb-4"
      @dblclick:row="navigateToClient"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          x-small
          color="primary"
          title="Odepnij klienta"
          @click="removeEndClient(item.id)"
        >
          <v-icon small>
            mdi-pin-off-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ActionButtons from '../../Elements/ActionButtons'
import { endClients } from '../../../const/views/administration/clients'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    ActionButtons
  },
  data: () => ({
    ...endClients // { headers, rowActions, actions }
  }),
  computed: {
    ...mapState({
      isProcessing: state => state.client.entity.isProcessing,
      client: state => state.client.entity,
      middlemanClients: state => state.client.middlemanClients
    })
  },
  mounted () {
    this.getMiddlemanClients(this.client.id)
  },
  beforeDestroy () {
    this.clearMiddlemanClients()
  },
  methods: {
    ...mapActions({
      getMiddlemanClients: 'client/getMiddlemanClients',
      clearMiddlemanClients: 'client/clearMiddlemanClients',
      setDialog: 'layout/setDialog',
      closeDialog: 'layout/closeDialog',
      editClient: 'clients/editItem',
    }),
    removeEndClient (id) {
      const deleteEndClient = () => {
        this.editClient({ tableName: 'clients', params: { middlemanIds: null }, id })
          .then(() => {
            this.getMiddlemanClients(this.client.id)
            this.closeDialog()
          })
      }
      this.setDialog({
        data: {
          component: 'ConfirmationDialog',
          text: 'Odepnij klienta',
          description: 'Czy na pewno chcesz odpiąć klienta?',
          callback: deleteEndClient
        },
      })
    },
    navigateToClient(_, { item }) {
      this.$router.push({ name: 'singleClient', params: { id: item.id } })
    }
  }
}
</script>
