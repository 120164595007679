import api from '../api/v1'

export default {
  namespaced: true,
  state: {
    isProcessing: false,
    entity: {}
  },
  mutations: {
    SET_PROCESSING (state, status) {
      state.isProcessing = status
    },
    SET_SINGLE_CLIENT_APP_ORDER (state, order) {
      state.entity = order
    }
  },
  actions: {
    setSingleClientAppOrder ({ commit }, order) {
      commit('SET_SINGLE_CLIENT_APP_ORDER', order)
    },
    getSingleClientAppOrder ({ commit }, id) {
      commit('SET_PROCESSING', true)
      api.getItem({ id, tableName: 'clientAppOrders' })
        .then((res) => {
          commit('SET_SINGLE_CLIENT_APP_ORDER', res.data)
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    clearSingleClientAppOrder ({ commit }) {
      commit('SET_SINGLE_CLIENT_APP_ORDER', {})
    }
  }
}
