import auth from './auth'
import authClient from './authClient'
import Base from './base'
import bdoCard from './bdoCard'
import bdoCards from './bdoCards'
import callEntry from './callEntry'
import callEntries from './callEntries'
import client from './client'
import clientAppOrder from './clientAppOrder'
import clientAppOrders from './clientAppOrders'
import clientSearchDialog from './clientSearchDialog'
import containers from './containers'
import clientCore from './clientCore'
import course from './course'
import courses from './courses'
import debrisTypes from './debrisTypes'
import driversLocation from './driversLocation'
import driversOccupancy from './driversOccupancy'
import hints from './hints'
import incident from './incident'
import invoice from './invoice'
import invoices from './invoices'
import layout from './layout'
import logisticsCore from './logisticsCore'
import map from './maps'
import order from './order'
import orders from './orders'
import orderSchedule from './orderSchedule'
import orderSchedules from './orderSchedules'
import orderTemplate from './orderTemplate'
import price from './price'
import prospect from './prospect'
import reports from './reports'
import route from './route'
import routes from './routes'
import singleView from './singleView'
import snackbar from './snackbar'
import tables from './tables'
import tabs from './tabs'
import Vue from 'vue'
import Vuex from 'vuex'
import isLogisticsApp from '../const/isLogisticsApp'

Vue.use(Vuex)

const logisticsModules = {
  auth,
  addresses: { ...new Base('addresses') },
  aggregatePrices: { ...new Base('aggregatePrices') },
  aggregateTypes: debrisTypes('aggregateTypes'),
  alerts: { ...new Base('alerts') },
  assigningBdoCards: { ...bdoCards('assigningBdoCards') },
  bdoCard,
  bdoCards: { ...bdoCards('bdoCards') },
  businessPlaces: { ...new Base('businessPlaces') },
  callEntries: callEntries,
  callEntry,
  client,
  clientAppOrder,
  clientAppOrders,
  clientBDO: { ...new Base('clientBDO') },
  clientInvoices: { ...invoices('clientInvoices') },
  clientOrders: { ...orders('clientOrders') },
  clients: { ...new Base('clients') },
  clientsReports: { ...new Base('clientsReports') },
  clientSearchDialog,
  codDeposits: { ...new Base('codDeposits') },
  communes: { ...new Base('communes') },
  containers,
  containersHistory: { ...new Base('containersHistory') },
  containersMap: { ...map('containersMap') },
  core: logisticsCore,
  course,
  courses: { ...courses('courses') },
  coursesMap: { ...map('coursesMap') },
  coursesWithoutCards: { ...courses('coursesWithoutCards') },
  debrisPrices: { ...new Base('debrisPrices') },
  drivers: { ...new Base('drivers') },
  driversLocation,
  driversOccupancy,
  files: { ...new Base('files') },
  hints,
  incident,
  // incidents: { ...new Base('incidents') },
  invoice,
  invoiceItemTemplates: { ...new Base('invoiceItemTemplates') },
  invoices: { ...invoices('invoices') },
  internalBdoCards: { ...bdoCards('internalBdoCards') },
  bdoCardTemplates: { ...bdoCards('bdoCardTemplates') },
  logCRMActiveUsers: { ...new Base('logCRMActiveUsers') },
  middlemen: { ...new Base('middlemen') },
  order,
  orders: { ...orders('orders') },
  orderSchedule,
  orderSchedules,
  ordersCompleted: { ...orders('ordersCompleted') },
  ordersWithInvoiceStatus: { ...orders('ordersWithInvoiceStatus') },
  orderTemplate,
  orderTemplates: { ...new Base('orderTemplates') },
  price,
  prospect,
  prospects: { ...new Base('prospects') },
  receiverBusinessPlaces: { ...new Base('businessPlaces') },
  mainReport: reports('mainReport'),
  incomeReport: reports('incomeReport'),
  revenueReport: reports('revenueReport'),
  revisionIndex: { ...bdoCards('revisionIndex') },
  route,
  routes: { ...routes('routes') },
  routesCompleted: { ...routes('routesCompleted') },
  roles: { ...new Base('roles') },
  settings: { ...new Base('settings') },
  senderBusinessPlaces: { ...new Base('businessPlaces') },
  singleView,
  tasks: { ...new Base('tasks') },
  terminals: { ...new Base('terminals') },
  transportPrices: { ...new Base('transportPrices') },
  trailers: { ...new Base('trailers') },
  users: { ...new Base('users') },
  vehicles: { ...new Base('vehicles') },
  customReport: { ...new Base('customReport') },
}

const clientModules = {
  authClient,
  core: clientCore,
  clientOrders: { ...new Base('clientOrders') }, // todo most likely this would have to be dedicated module
}

const sharedModules = {
  containerTypes: { ...new Base('containerTypes') },
  debrisTypes: debrisTypes('debrisTypes'),
  departments: { ...new Base('departments') },
  layout,
  snackbar,
  tables,
  tabs,
}

export default new Vuex.Store({
  modules: {
    ...(isLogisticsApp ? logisticsModules : clientModules),
    ...sharedModules,
  },
})
