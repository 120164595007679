import api from '../api/v1'
import { Schedule } from './../models'
import debounce from 'lodash/debounce'

export default {
  namespaced: true,
  state: {
    isProcessing: false,
    entity: new Schedule()
  },
  mutations: {
    SET_SCHEDULE_PROCESSING (state, status) {
      state.isProcessing = status
    },
    SET_SINGLE_SCHEDULE (state, course) {
      state.entity = course
    }
  },
  actions: {
    setScheduleProcessing ({ commit }, status) {
      commit('SET_SCHEDULE_PROCESSING', status)
    },
    setSingleSchedule: debounce(({ commit }, schedule) => {
      commit('SET_SINGLE_SCHEDULE', new Schedule(schedule))
    }, 1000, { leading: true, trailing: true }),
    getSingleSchedule ({ commit }, id) {
      commit('SET_SCHEDULE_PROCESSING', true)
      api.getOrderSchedule(id)
        .then((res) => {
          commit('SET_SINGLE_SCHEDULE', new Schedule(res.data))
        })
        .finally(() => {
          commit('SET_SCHEDULE_PROCESSING', false)
        })
    },
    clearSingleSchedule ({ commit }) {
      commit('SET_SINGLE_SCHEDULE', new Schedule())
    },
    setScheduleBlock ({ commit, dispatch }, params) {
      commit('SET_SCHEDULE_PROCESSING', true)
      api.editScheduleStatus(params)
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Zablokowano harmonogram'],
            type: 'success'
          }, { root: true })
          dispatch('layout/closeDialog', null, { root: true })
        })
        .finally(() => {
          commit('SET_SCHEDULE_PROCESSING', false)
        })
    },
    setScheduleUnblock ({ commit, dispatch }, params) {
      commit('SET_SCHEDULE_PROCESSING', true)
      api.editScheduleStatus(params)
        .then(() => {
          dispatch('snackbar/showSnackbar', {
            message: ['Odblokowano harmonogram'],
            type: 'success'
          }, { root: true })
          dispatch('layout/closeDialog', null, { root: true })
        })
        .finally(() => {
          commit('SET_SCHEDULE_PROCESSING', false)
        })
    }
  }
}
