export default {
  table: {
    maximumDateRange: 1,
    specialFlag: {
      endpoint: 'containers/map'
    },
    filters: [
      {
        tableName: 'containersMap',
        name: 'idleDays',
        text: 'Czas stania',
        options: 'containerIdleTimes',
        filterBy: '',
        propPath: 'daysStanding',
        topBar: true
      },
      {
        tableName: 'containersMap',
        name: 'containerTypeIds',
        text: 'Wielkość kontenera',
        filterBy: [],
        propPath: 'containerType.id',
        options: 'containerTypes',
        multiple: true
      }
    ]
  }
}
