export default {
  header: [],
  table: {
    maximumDateRange: 1096,
    sorting: {
      sortBy: 'name',
      sortDesc: false,
      tableName: 'general_client_data'
    },
    specialFlag: {
      endpoint: 'reports/client_analysis'
    },
    filters: [
      { name: 'patronIds', text: 'Opiekun', options: 'users', filterBy: '', propPath: 'patron.id', multiple: true },
      { name: 'departmentIds', text: 'Oddział', options: 'departments', filterBy: '', propPath: 'department.id', multiple: true },
    ],
    parameters: [
      { name: 'name', text: 'Klient', value: 'name' },
      { name: 'ordersCount', text: 'Liczba zleceń', value: 'ordersCount' },
      { name: 'totalGrossValue', text: 'Kwota', value: 'totalGrossValue' },
      { name: 'trend', text: 'Trend', value: 'trend', width: 180, sortable: false },
    ],
    actions: [],
    menu: []
  },
  details: {
    title: '',
    actions: [],
    sections: []
  }
}
