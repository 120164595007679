import actions from '../../../actions'

export default {
  table: {
    noApi: true,
    specialFlag: {
      departmentId: undefined,
      aggregate: true
    },
    sorting: {
      sortBy: 'debrisType.code',
      sortDesc: false
    },
    filters: [
      { name: 'departmentId', filterBy: '', hide: true, required: true }
    ],
    parameters: [
      { name: 'aggregateType', text: 'Typ kruszywa', value: 'debrisType.displayName', show: true, length: 50 },
      { name: 'price', text: 'Cena za tonę', value: 'price', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.editAggregatePrice, icon: 'edytuj' },
      { ...actions.deleteAggregatePrice, icon: 'usun' }
    ],
    menu: [
      { ...actions.editTransportPrice },
      { ...actions.deleteTransportPrice }
    ]
  }
}
