<template>
  <v-menu
    v-model="showDatePicker"
    v-bind="attributes.menu"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        class="logout-section-input"
        background-color="#2E3849"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
      >
        <template #prepend-inner>
          <v-btn
            v-if="!mini"
            class="ml-2 mr-n2"
            :disabled="isTodayDate"
            icon
            x-small
            @click="refreshDate"
          >
            <v-icon small>
              mdi-calendar-remove
            </v-icon>
          </v-btn>
        </template>
        <template #append>
          <SwitchDayButtons
            v-if="!mini"
            :value="globalDate"
            class="mr-1 mt-1"
            @input="setGlobalDate"
          />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="globalDate"
      v-bind="attributes.datePicker"
      @input="[setGlobalDate($event), showDatePicker = false]"
    />
  </v-menu>
</template>

<script>
import attributes from '../../../const/datePickerAttrributes'
import { dateStringFormat } from '../../../utils'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    attributes,
    showDatePicker: false,
  }),
  computed: {
    ...mapGetters({
      globalDate: 'core/getGlobalDate'
    }),
    formattedDate () {
      return dateStringFormat(this.globalDate)
    },
    isTodayDate() {
      return this.globalDate === new Date().toISOString().substring(0, 10)
    }
  },
  methods: {
    ...mapActions({
      setGlobalDate: 'core/setGlobalDate',
    }),
    refreshDate() {
      this.setGlobalDate(new Date().toISOString().substring(0, 10))
    }
  }
}
</script>
