import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticClass:"header",attrs:{"app":"","color":"white"}},[_c(VToolbarTitle,[_vm._v("Wskazówki")]),_c(VSpacer),_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.createMode = true}}},[_vm._v(" Dodaj wskazówkę ")])],1),_c('div',{staticClass:"px-6 py-4 hints-content"},[_c(VTextField,{directives:[{name:"shortkey",rawName:"v-shortkey",value:(_vm.searchShortkeys),expression:"searchShortkeys"}],staticClass:"search-bar",attrs:{"placeholder":"Szukaj wskazówek","disabled":_vm.editMode || _vm.createMode,"outlined":""},nativeOn:{"shortkey":function($event){return _vm.focusSearchField.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icon',{staticClass:"mr-2",attrs:{"name":"search","size":"small"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VExpansionPanels,[(_vm.createMode)?_c('SingleHint',{attrs:{"hint":{}},on:{"close":function($event){_vm.createMode = false}}}):_vm._e(),(_vm.filteredHints.length)?_vm._l((_vm.filteredHints),function(hint){return _c('SingleHint',{key:'hint' + hint.id,attrs:{"hint":hint},on:{"editMode":function($event){_vm.editMode = $event}}})}):(!_vm.createMode)?_c(VCard,{staticClass:"px-6 py-4 w-100"},[_vm._v(" Brak wskazówek ")]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }