<template>
  <div>
    <v-checkbox
      v-model="activeValue"
      dense
      hide-details
      class="d-inline-block my-0 py-0"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    endpoint () {
      const { specialFlag } = this.$store.getters['tables/getTableConfig'](this.tableName)
      return specialFlag?.endpoint || this.tableName
    },
    activeValue: {
      get () {
        return this.value
      },
      set () {
        this.changeStatus()
      }
    }
  },
  methods: {
    ...mapActions({
      editItem (dispatch, payload) {
        return dispatch(`${this.tableName}/editItem`, payload)
      },
      getItems (dispatch) {
        return dispatch(`${this.tableName}/getItems`)
      }
    }),
    changeStatus () {
      this.editItem({
        tableName: this.endpoint,
        params: { active: !this.item.active },
        id: this.item.id
      }).then(() => {
        if (this.tableName !== 'clients') this.getItems()
      })
    }
  }
}
</script>
