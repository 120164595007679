<template>
  <div
    class="map"
    v-resize="resizeMap"
  >
    <v-app-bar
      app
      flat
      :height="mapTopHeight"
      color="#f7f9fc"
    >
      <div
        class="filters px-0"
        ref="tableTop"
      >
        <div class="filters__inputs">
          <SearchBar
            :table-name="tableName"
            :config="containersMap"
          />
          <StandingTime
            class="mr-4"
            :table-name="tableName"
            :all-filters="readJSON(filters)"
            @changeFilters="changeFilters"
          />
          <ShowFiltersButton :table-name="tableName" />
        </div>
        <MapFilters
          :key="areResourcesBeingLoaded"
          :map-name="tableName"
          :show-display-mode="false"
          :filters="readJSON(allFilters)"
        />
        <MapSwitch :view-name="tableName" />
      </div>
    </v-app-bar>
    <GmapMap
      ref="mapRef"
      :center="department.location"
      style="width:100%"
      :style="mapSize"
      :options="mapOptions"
      :map-type-id="mapType"
    >
      <MapContent
        ref="mapContentRef"
        :base="department.location"
        :department="department"
        :containers="containers"
        :map-ref="$refs.mapRef"
        :sidebar="false"
      />
    </GmapMap>

    <div
      class="table-statistics"
      ref="statisticsBar"
    >
      <div class="table-statistics__value mr-2">
        {{ countContainers[0] }}
      </div>
      <div class="table-statistics__label">
        {{ countContainers[1] }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import filtersMixin from '../../mixins/filtersMixin'
import SearchBar from '../../components/Filters/SearchBar'
import StandingTime from '../../components/Filters/StandingTime'
import ShowFiltersButton from '../../components/Filters/ShowFiltersButton'
import MapFilters from '../../components/Map/MapFilters'
import MapContent from '../../components/Map/MapContent'
import MapSwitch from '../../components/Map/MapSwitch'
import { readJSON, declensionName } from '../../utils'
import webSocketMixin from '../../mixins/webSocketMixin'
import mapMixin from '../../mixins/mapMixin'

export default {
  components: {
    SearchBar,
    StandingTime,
    ShowFiltersButton,
    MapFilters,
    MapContent,
    MapSwitch
  },
  mixins: [filtersMixin, webSocketMixin, mapMixin],
  data: () => ({
    tableName: 'containersMap',
  }),
  channels: {
    ContainersMapChannel: { received (data) { this.captureChanges(data, 'containersMap', true) } }
  },
  computed: {
    ...mapState({
      containersMap: state => state.tables.containersMap,
      showFilters: state => state.containersMap.showFilters,
      departmentId: state => state.core.department.id,
      mapContainers: state => state.containersMap.items,
      mapType: state => state.core.mapType,
    }),
    containers () {
      return this.spreadOverlappingMarkers(readJSON(this.mapContainers), 'containersMap')
    },
    allFilters () {
      return this.containersMap.filters // for filtersMixin
    },
    mapSize () {
      return {
        height: this.mapHeight + 'px',
        width: '100%'
      }
    },
    countContainers () {
      const itemsCount = this.containers.length
      return declensionName(itemsCount, 'kontener', 'kontenery', 'kontenerów').split(' ')
    },
  },
  watch: {
    mapContainers: {
      deep: true,
      handler (value) {
        if (value.every((val) => !val.fromWS)) this.fitBounds(this.containers)
      }
    }
  },
  mounted () {
    this.subscribeSocket('ContainersMapChannel')
  },
  beforeDestroy () {
    this.unsubscribeSocket('ContainersMapChannel')
  },
  // using router hooks because don't want this to be called with every department change
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getContainerTypes(true))
  },
  beforeRouteLeave (to, from, next) {
    this.getContainerTypes()
    next()
  },
  methods: {
    ...mapActions({
      getContainerTypes: function (dispatch, onRoute) {
        return dispatch('containerTypes/getItems', {
          params: {
            departmentId: this.departmentId, onRoute
          }
        })
      }
    })
  }
}
</script>
