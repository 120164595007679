import Base from './base'
import api from '../api/v1'

const base = new Base('containers')

const actions = {
  getCounters ({ commit, rootGetters }) {
    const params = rootGetters['tables/getTableParameters']('containers').params
    api.getContainersCounters(params)
      .then((resp) => {
        commit('SET_COUNTERS', resp.data)
      })
  },
}

base.actions = {
  ...base.actions,
  ...actions
}

export default base
