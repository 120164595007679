import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100",attrs:{"title":_vm.printedValue}},[_c('div',{staticClass:"details-value",class:['details-value--' + _vm.field.type, _vm.field.extraClass]},[(_vm.field.text)?_c('div',{staticClass:"input-label px-2"},[_vm._v(" "+_vm._s(_vm.field.text)+" "),_c(VBtn,{attrs:{"icon":"","x-small":"","color":"primary","title":"Skopiuj wartość","disabled":_vm.printedValue === '-'},on:{"click":function($event){$event.stopPropagation();return _vm.copyValue.apply(null, arguments)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v(" mdi-content-copy ")])],1),(_vm.field.to && _vm.value !== '-')?_c(VBtn,{attrs:{"icon":"","x-small":"","color":"primary","title":("Przejdź do " + _vm.shortenValue),"disabled":_vm.printedValue === '-' || !_vm.linkId,"to":_vm.link}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-arrow-right-bold-box ")])],1):_vm._e(),(_vm.field.label)?_c('span',{staticClass:"label ml-1"},[_vm._v(_vm._s(_vm.field.label))]):_vm._e()],1):_vm._e(),_c(VTooltip,{attrs:{"top":"","nudge-bottom":"10","disabled":!_vm.warning,"open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"fit-content"}},'div',attrs,false),on),[_c(VBtn,{staticClass:"details-value__btn",attrs:{"disabled":_vm.disabled,"text":"","title":""},on:{"click":_vm.update}},[_c('span',{staticClass:"input-value",class:[_vm.statusClass]},[(_vm.field.icon)?_c('Icon',{staticClass:"mr-2",attrs:{"name":_vm.field.icon}}):_vm._e(),_c('span',{class:{'error--text': _vm.warning}},[_vm._v(_vm._s(_vm.printedValue)+" ")])],1)])],1)]}}])},[(_vm.field.warning && _vm.field.warning.text)?_c('span',[_vm._v(_vm._s(_vm.field.warning.text))]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }