import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-details"},[_c('div',{staticClass:"detail-section__header contact-details__header"},[_vm._v(" Klienci końcowi "),(_vm.actions.length)?_c('ActionButtons',{attrs:{"actions":_vm.actions,"item":_vm.client}}):_vm._e()],1),_c(VDivider),_c(VDataTable,{staticClass:"details-table mb-4",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.middlemanClients,"loading":_vm.isProcessing,"loading-text":"Pobieram elementy..."},on:{"dblclick:row":_vm.navigateToClient},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","x-small":"","color":"primary","title":"Odepnij klienta"},on:{"click":function($event){return _vm.removeEndClient(item.id)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-pin-off-outline ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }