import { Table } from '../models'

export const logisticsTables = {
  addresses: new Table(),
  aggregatePrices: new Table(),
  aggregateTypes: new Table(),
  alerts: new Table(),
  assigningBdoCards: new Table(),
  bdoCards: new Table(),
  bdoCardTemplates: new Table(),
  businessPlaces: new Table(),
  callEntries: {
    ...new Table(),
    filters: [{ name: 'clientId', filterBy: '' }]
  },
  clientAppOrders: new Table(),
  clientBDO: new Table(),
  clientInvoices: new Table(),
  clientOrders: new Table(),
  clients: new Table(),
  clientsReports: new Table(),
  codDeposits: new Table(),
  communes: new Table(),
  containers: new Table(),
  containersMap: new Table(),
  courses: new Table(),
  coursesMap: new Table(),
  coursesWithoutCards: new Table(),
  containersHistory: new Table(),
  debrisPrices: new Table(),
  drivers: new Table(),
  driversLocation: new Table(),
  invoiceItemTemplates: new Table(),
  roles: new Table(),
  settings: new Table(),
  invoices: new Table(),
  internalBdoCards: new Table(),
  logCRMActiveUsers: new Table(),
  middlemen: {
    // middlemen is faked clients list with filter isMiddleman: true, for selects and filters
    ...new Table(),
    specialFlag: { endpoint: 'clients' },
    sorting: { sortBy: 'name', sortDesc: true },
    parameters: [{ name: 'name', value: 'name' }],
    filters: [
      { name: 'isMiddleman', filterBy: true, initial: true, required: true, default: true },
      { name: 'withoutPatron', filterBy: true, initial: true, required: true, default: true }
    ]
  },
  orders: new Table(),
  ordersCompleted: new Table(),
  ordersWithInvoiceStatus: new Table(),
  orderSchedules: new Table(),
  orderTemplates: new Table(),
  prospects: new Table(),
  mainReport: new Table(),
  revenueReport: new Table(),
  incomeReport: new Table(),
  revisionIndex: new Table(),
  routes: new Table(),
  routesCompleted: new Table(),
  tasks: new Table(),
  terminals: new Table(),
  trailers: new Table(),
  transportPrices: new Table(),
  users: new Table(),
  customReport: new Table(),
  vehicles: new Table()
}

export const clientTables = {
  clientOrders: new Table()
}

export const sharedTables = {
  containerTypes: new Table(),
  debrisTypes: new Table(),
  departments: new Table()
}
