<template>
  <div>
    <template v-if="value !== emptyValue">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            size="15"
            color="error"
            class="alert-icon mr-1"
            v-bind="attrs"
            v-on="on"
          >
            mdi-alert
          </v-icon>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
    </template>
    <span v-else>
      <v-icon
        size="15"
        color="success"
        class="alert-icon mr-1"
        v-bind="attrs"
        v-on="on"
      >
        mdi-check-circle
      </v-icon>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableColumnPixelStatus',
  props: {
    value: {
      type: String,
      required: true
    },
    emptyValue: {
      type: String,
      default: '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-icon {
  cursor: pointer;
}
</style>
