<template>
  <div
    class="table-confirmation-bar"
    :class="{'moved' : sidebarSize === 1}"
  >
    <v-btn
      class="px-4"
      color="primary"
      outlined
      v-shortkey="{esc: ['esc']}"
      @shortkey.native="toggleMultiselectStatus(false)"
      @click="toggleMultiselectStatus(false)"
    >
      Anuluj
    </v-btn>
    <v-btn
      v-for="action in confirmActions"
      :key="action.name"
      class="px-4 ml-6 base-hover"
      color="primary"
      :disabled="action.disabled || isDialogAlreadyOpen(action.target)"
      :loading="isProcessing"
      v-shortkey="action.shortkey"
      @shortkey.native="dispatchAction(action)"
      @click="dispatchAction(action)"
    >
      {{ action.text }}
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getExecutiveMultiselectTableName } from '../../utils'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    confirmActions: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapState({
      isProcessing (state) {
        return state[this.tableName].isProcessing
      },
      sidebarSize: state => state.layout.sidebar.size,
      dialogActive: state => state.layout.dialog.active,
    }),
    executiveTableName () {
      return getExecutiveMultiselectTableName(this.tableName)
    }
  },
  methods: {
    ...mapActions({
      toggleMultiselectStatus: function (dispatch, show) {
        return dispatch(`${this.executiveTableName}/toggleMultiselectStatus`, show)
      },
      setDialog: 'layout/setDialog'
    }),
    isDialogAlreadyOpen (target) {
      return this.dialogActive && target === 'dialog'
    },
    dispatchAction (action) {
      if (action.target === 'dialog') {
        this.setDialog({ data: action })
      } else if (action.target === 'store') {
        this.$store.dispatch(action.action)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-confirmation-bar.moved {
  padding-right: 440px;
}
</style>
