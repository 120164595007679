import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('module-wrapper',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.value ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")])],1)]},proxy:true}])},[_c(VExpandTransition,[(!_vm.value)?_c(VDataTable,{ref:"reportTable",staticClass:"details-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.data,"items-per-page":150,"show-expand":"","item-key":"chargedByName","expanded":_vm.expandedItems},on:{"update:expanded":function($event){_vm.expandedItems=$event},"click:row":function (item, slot) { return _vm.expandRow(item, slot); }},scopedSlots:_vm._u([{key:"header.data-table-expand",fn:function(){return [_c('th',[_c(VSwitch,{staticClass:"ma-0",attrs:{"input-value":!!_vm.expandedItems.length,"hide-details":"","dense":"","title":"Przełącz wszystkie"},on:{"change":_vm.toggleAllCallEntries}})],1)]},proxy:true},{key:"item.data-table-expand",fn:function(){return [_c('td',{attrs:{"colspan":"3"}},[_c(VBtn,{attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v("mdi-chevron-down")])],1)],1)]},proxy:true},{key:"item.sumRequiredAmount",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.parseValue(item, header))+" ")]}},{key:"item.sumDepositedAmount",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.parseValue(item, header))+" ")]}},{key:"expanded-item",fn:function(ref){
var expandedHeaders = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":expandedHeaders.length}},[_c(VDataTable,{staticClass:"expanded-table",attrs:{"headers":_vm.expandedRowHeaders,"items":item.deposits,"items-per-page":-1,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var deposit = ref.item;
return [_c('tr',_vm._l((_vm.expandedRowHeaders),function(header){return _c('td',{key:header.value,staticClass:"text-start",style:(("max-width: " + (header.width) + "px, width: " + (header.width) + "px"))},[_vm._v(" "+_vm._s(_vm.parseValue(deposit, header))+" ")])}),0)]}}],null,true)})],1)]}},{key:"footer",fn:function(){return [_c('ReportTableFooter',{attrs:{"items":_vm.data,"headers":_vm.headers}})]},proxy:true}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }