<template>
  <v-btn
    outlined
    color="primary"
    width="120px"
    v-shortkey="toggleFiltersShortkeys"
    @shortkey.native="toggleFiltersVisibility()"
    @click="toggleFiltersVisibility()"
  >
    {{ buttonLabel }}
  </v-btn>
</template>

<script>
import { toggleFiltersShortkeys, getLabelWithShortkey } from '../../const/shortKeys'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    toggleFiltersShortkeys,
  }),
  computed: {
    ...mapState({
      showFilters (state) {
        return state[this.tableName].showFilters
      },
      sidebarSize: state => state.layout.sidebar.size
    }),
    buttonLabel () {
      const label = this.showFilters ? 'Ukryj filtry' : 'Pokaż filtry'
      return getLabelWithShortkey(label, toggleFiltersShortkeys.key)
    }
  },
  methods: {
    ...mapActions({
      toggleFiltersVisibility: function (dispatch) {
        if (!this.sidebarSize) {
          return dispatch(`${this.tableName}/toggleFiltersVisibility`)
        }
      }
    }),
  }
}
</script>
