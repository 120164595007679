<template>
  <div class="contact-details">
    <Table
      ref="table"
      :table-name="tableName"
      :has-grouped-entries="true"
      :overwritten-items="callEntries"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          date-range-picker
        />
        <v-divider />
      </template>
    </Table>
    <Pagination :table-name="tableName" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Pagination from '../../Layout/Pagination'
import groupedEntriesMixin from '../../../mixins/groupedEntriesMixin.vue'
import Table from '../../Table/Table'
import FiltersBar from '../../../components/Filters/FiltersBar'

export default {
  data: () => ({
    tableName: 'callEntries',
  }),
  components: {
    Pagination,
    Table,
    FiltersBar,
  },
  mixins: [groupedEntriesMixin], // expandedItems, expandRow, getGroupedEntries
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      callEntries(state) {
        return this.getGroupedEntries(state.callEntries.items)
      },
      isProcessing: state => state.callEntries.isProcessing,
      itemsPerPage: state => state.tables.callEntries.pagination?.itemsPerPage,
    }),
  },
  created () {
    this.setTablePagination({ pagination: { itemsPerPage: 20 }, tableName: 'callEntries', disableFetch: true })
    const filters = [{ name: 'clientId', filterBy: this.clientId }]
    this.setTableDate({ date: [new Date().toISOString().substring(0, 10)] })
    this.setTableFilters({ filters, tableName: 'callEntries' })

    this.unsubscribeAction = this.$store.subscribeAction((action) => {
      if (action.type === 'callEntry/createCallEntry') this.getCallEntries()
    })
  },
  beforeDestroy() {
    this.unsubscribeAction()
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      getCallEntries: 'callEntries/getItems',
      setTableDate: 'callEntries/setTableDate',
      setTablePagination: 'tables/setTablePagination',
    }),
  }
}
</script>
