<template>
  <div class="route mb-5">
    <div class="route__description mb-6 px-2">
      Trasa kierowcy
    </div>
    <div class="px-2">
      <div class="route__title">
        <div class="route__icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="8"
              fill="#2CB56B"
            />
            <circle
              cx="12"
              cy="12"
              r="4"
              fill="white"
            />
          </svg>
        </div>
        Baza
      </div>
      <div class="time-line route__address">
        {{ department.address }}
      </div>
      <div class="time-line route__meta grey--text pt-4 pb-2">
        {{ pathInfo[0] }}
      </div>
    </div>
    <draggable
      v-model="points"
      :disabled="!isDraggable"
      class="list-group"
      ghost-class="ghost"
    >
      <div
        v-for="(item, index) in points"
        :key="item.id"
        class="d-block px-2 relative"
        :class="isDraggable ? 'draggable' : ''"
      >
        <div>
          <div class="route__title">
            <div
              v-if="item.course.courseType==='Zabranie'"
              class="route__icon"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 17.25C11.25 13.9363 13.9363 11.25 17.25 11.25C20.5637 11.25 23.25 13.9363 23.25 17.25C23.25 20.5637 20.5637 23.25 17.25 23.25C13.9363 23.25 11.25 20.5637 11.25 17.25"
                  fill="#2CB56B"
                />
                <path
                  d="M11.25 17.25C11.25 13.9363 13.9363 11.25 17.25 11.25C20.5637 11.25 23.25 13.9363 23.25 17.25C23.25 20.5637 20.5637 23.25 17.25 23.25C13.9363 23.25 11.25 20.5637 11.25 17.25"
                  stroke="#2CB56B"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.25 17.25L14.25 17.25"
                  stroke="white"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.25 17.25L16.5 19.5"
                  stroke="white"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.25 17.25L16.5 15"
                  stroke="white"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.25 18.75H2.25C1.42157 18.75 0.75 18.0784 0.75 17.25V6.75C0.75 5.92157 1.42157 5.25 2.25 5.25H17.25C18.0784 5.25 18.75 5.92157 18.75 6.75V8.25"
                  stroke="#2CB56B"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.2659 5.647L15.4449 1.418C15.1673 1.00159 14.7003 0.751038 14.1999 0.75H5.29989C4.79837 0.750036 4.33006 1.00071 4.05189 1.418L1.23389 5.647"
                  stroke="#2CB56B"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.75 0.75V5.25"
                  stroke="#2CB56B"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <Icon
              v-else
              :name="getIcon(item.course.courseType)"
              class="route__icon"
            />
            {{ item.course.courseType }}
          </div>
          <div class="time-line route__address">
            {{ item.course.orderAddressFormattedAddress }}
          </div>
          <div class="time-line pl-1">
            <v-btn
              v-if="isCancellableCourse(item)"
              text
              @click="cancelSingleCourse(item.course.id)"
            >
              Cofnij kurs
            </v-btn>
            <v-btn
              v-if="item.course.courseStatus !== 'Wykonany' && !isCancellableCourse(item)"
              text
              @click="removeStopFromRoutePlaning(item.course.id)"
            >
              Usuń kurs z trasy
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="toggleDetails(item.id)"
            >
              {{ !detailsVisible(item.id) ? 'Pokaż' : ' Ukryj' }} szczegóły
            </v-btn>
          </div>
          <div
            v-if="detailsVisible(item.id)"
            class="route__details pa-2"
          >
            <v-row align="center">
              <v-col
                v-for="field in courseDetails"
                :key="field.name"
                cols="12"
                sm="12"
                :md="field.col"
                class="py-1"
              >
                <div class="input--select">
                  <v-text-field
                    :label="field.text"
                    :title="value(field, item)"
                    readonly
                    :value="value(field, item)"
                    :placeholder="field.placeholder || '-'"
                    hide-details
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="time-line route__meta grey--text pb-2">
            {{ pathInfo[index + 1] }}
          </div>
        </div>
        <Icon
          v-if="isDraggable"
          name="drag"
          class="icon--drag mt-2 absolute-t-r"
        />
      </div>
    </draggable>
    <div class="px-2">
      <div class="route__title">
        <Icon
          name="base_green"
          class="route__icon"
        />
        Baza
      </div>
      <div class="route__address pl-9">
        {{ department.address }}
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Duration from 'luxon/src/duration'
import get from 'lodash/get'
import { mapActions, mapState } from 'vuex'
import { courseDetails } from '../../../helpers/mapOptions'
import { isCancellableCourseStatus } from '../../../utils/recordStatuses'
import { dateValues, formatValue } from '../../../utils/typesEnum'

export default {
  components: {
    draggable
  },
  props: {
    isNotEditableRoute: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    courseDetails,
    details: []
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      route: state => state.route.entity
    }),
    points: {
      get () {
        return this.route.stops
      },
      set (val) {
        this.changeStopsInRoutePlaning(val)
      }
    },
    pathInfo () {
      const textInfo = []
      this.route.distances.forEach((item, index) => {
        const distances = (item / 1000).toFixed(1)
        const durations = Duration.fromObject({ seconds: this.route.durations[index] }).toFormat('mm')
        textInfo.push(distances + ' km • ' + durations + ' min')
      })
      return textInfo
    },
    isDraggable () {
      return this.points.length > 1 && !this.isNotEditableRoute
    }
  },
  methods: {
    ...mapActions({
      cancelSingleCourse: 'route/cancelSingleCourse',
      changeStopsInRoutePlaning: 'route/changeStopsInRoutePlaning',
      removeStopFromRoutePlaning: 'route/removeStopFromRoutePlaning'

    }),
    isCancellableCourse (item) {
      return isCancellableCourseStatus(item.course.courseStatus)
    },
    value (field, data) {
      const value = get(data, field.value, '-')
      return dateValues.includes(field.name) ? formatValue(value, 'date') : value
    },
    getIcon (type) {
      const icons = {
        Zabranie: 'take_away_green',
        Wymiana: 'exchange_green',
        Podstawienie: 'substitution_green'
      }
      return icons[type] || 'substitution_green'
    },
    toggleDetails (id) {
      const index = this.details.indexOf(id)
      if (index > -1) {
        this.details.splice(index, 1)
      } else {
        this.details.push(id)
      }
    },
    detailsVisible (id) {
      return this.details.includes(id)
    },
    removeCourse (course) {
      this.points = this.points.filter(item => item.course.id !== course.course.id)
    }
  }
}
</script>
