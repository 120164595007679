<template>
  <div class="details details--full mt-80px">
    <DetailsToolbar
      :title="getDetailsTitle"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="bdoCard"
      />
    </DetailsToolbar>
    <div class="px-4">
      <v-row>
        <v-col cols="12">
          <div
            v-for="detail in details"
            :key="detail.name"
            class="detail-section__wrapper mb-4"
          >
            <div
              class="detail-section__header"
              v-if="detail.text"
            >
              {{ detail.text }}
            </div>
            <div :class="{'detail-section__content': detail.text}">
              <component
                v-if="isCardFetched"
                :is="detail.component || 'DetailsSection'"
                :detail="detail"
                :data="bdoCard"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getTableDetails, getTitleFormatted } from '../../utils'
import { filterInactiveActions } from '../../utils/quickActions'
import ActionButtons from '../Elements/ActionButtons'
import DetailsToolbar from './Sections/DetailsToolbar'
import DetailsSection from './Sections/DetailsSection'
import DetailsSectionKPOPdf from './Sections/DetailsSectionKPOPdf'
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    ActionButtons,
    DetailsToolbar,
    DetailsSection,
    DetailsSectionKPOPdf
  },
  mixins: [
    webSocketMixin,
    navigateBackMixin // prevRoute
  ],
  provide () {
    return {
      notWebSocketHandled: false
    }
  },
  data: () => ({
    details: {},
    tableName: 'bdoCards',
    isCardFetched: false
  }),
  channels: {
    BdoCardShowChannel: { received (data) { this.captureSingleChange(data) } },
  },
  computed: {
    ...mapState({
      bdoCard: state => state.bdoCard.entity,
    }),
    bdoCardsDate () {
      return this.$store.state[this.tableName].dateRange
    },
    getDetailsActions () {
      const { actions } = getTableDetails(this.tableName)
      return filterInactiveActions(actions, this.bdoCard, this.tableName)
    },
    getDetailsTitle () {
      const title = getTableDetails(this.tableName).title
      return getTitleFormatted(title, this.bdoCard)
    },
  },
  mounted () {
    const bdoCardId = this.$route.params.id
    this.details = getTableDetails(this.tableName).sections
    this.getBdoCard(bdoCardId).then(() => { this.isCardFetched = true })
    this.subscribeSocket('BdoCardShowChannel', { bdoCardId, dueDate: this.bdoCardsDate[0] })
  },
  beforeDestroy () {
    this.unsubscribeSocket('BdoCardShowChannel')
    this.clearSingleBdoCard()
  },
  methods: {
    ...mapActions({
      getBdoCard: 'bdoCard/getSingleBdoCard',
      clearSingleBdoCard: 'bdoCard/clearSingleBdoCard',
    }),
  }
}
</script>
