<template>
  <div
    id="setPassword"
    class="set-password"
  >
    <v-container fill-height>
      <v-row class="fill-height">
        <v-col class="login__col login__col--left hidden-md-and-down">
          <img
            class="login__logo"
            src="@/assets/images/logo.png"
          >
          <v-spacer />
          <img
            class="login__img"
            src="@/assets/images/login-pic.svg"
          >
        </v-col>
        <v-col class="login__col login__col--right">
          <v-form
            ref="loginForm"
            class="custom-form"
            v-model="isFormValid"
            @submit.prevent="resetPassword(form)"
          >
            <v-row>
              <v-col cols="12">
                <div class="text-h5 font-weight-bold mb-3">
                  Wybierz swoje hasło
                </div>
                <div class="text-subtitle-2 text--disabled mb-6">
                  Wymagana jest zmiana hasła przy pierwszym logowaniu.
                </div>
              </v-col>
              <v-col
                cols="12"
                class="mb-2"
              >
                <v-text-field
                  outlined
                  label="Nowe hasło"
                  type="password"
                  v-model.trim="form.password"
                  :rules="[rules.required]"
                  placeholder="Wpisz nowe hasło"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Powtórz hasło"
                  type="password"
                  v-model.trim="form.repeatPassword"
                  :rules="[rules.required]"
                  placeholder="Powtórz nowe hasło"
                />
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  class="form-action"
                >
                  Zmień hasło
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import rules from '@/utils/validators'

export default {
  data() {
    return {
      rules,
      isFormValid: true,
      form: {
        password: null,
        repeatPassword: null,
      },
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'authClient/resetPassword',
    }),
  },
}
</script>
