<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      isDialogOpen: state => state.layout.dialog.active
    })
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog'
    }),
    triggerAction ({ action, item, index }) {
      if (action.target === 'dialog') {
        this.setDialog({ data: action, size: action.size, index, item })
      } else if (action.target === 'store') {
        // todo it'll be good to make loader for these
        this.$store.dispatch(action.action, { item })
      } else if (action.target === 'function') {
        action.callback(item)
      }
    },
    isDialogAlreadyOpen (target) {
      return this.isDialogOpen && target === 'dialog'
    }
  }
}
</script>
