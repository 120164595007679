<template>
  <v-app-bar
    v-if="sidebar.size < 2"
    app
    flat
    color="white"
    class="header"
  >
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <v-spacer />

    <slot />

    <ActionButtons
      v-if="actions.length"
      :actions="actions"
    />
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import { getHeaderButtons } from '../../utils/quickActions'
import ActionButtons from '../Elements/ActionButtons.vue'

export default {
  components: {
    ActionButtons
  },
  props: {
    title: {
      type: String,
      require: true
    },
    tableName: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar
    }),
    actions () {
      return this.buttons ? this.buttons : getHeaderButtons(this.tableName)
    }
  }
}
</script>
