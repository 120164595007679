<template>
  <div
    v-shortkey="toggleButtonsShortkeys"
    @shortkey="changeTab"
  >
    <v-btn-toggle
      v-model="currentMode"
      class="type-switch"
      active-class="type-switch--active"
      mandatory
      @change="filterElements"
    >
      <v-btn
        v-for="mode in modes"
        :key="mode.text"
        :value="mode.value"
        min-height="40px"
        class="base-hover"
        small
        depressed
        hide-details
      >
        {{ mode.text }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { toggleButtonsShortkeys, shortkeysNavigateBoundaries } from '../../const/shortKeys'
import { getTableOptions } from '../../utils'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    omitFetch: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentMode: null,
    modes: [],
    toggleButtonsShortkeys
  }),
  computed: {
    ...mapState({
      savedTab (state) {
        return state.tabs.tabs[this.tableName]
      }
    })
  },
  mounted () {
    const { modes } = getTableOptions(this.tableName)

    this.currentMode = (this.persistent && this.savedTab) ? this.savedTab : modes.find(mode => mode.initial)?.value

    this.modes = modes
    if (!this.omitFetch) this.filterElements()
  },
  beforeDestroy () {
    if (this.persistent) this.saveTab({ view: this.tableName, tabValue: this.currentMode })
  },
  methods: {
    ...mapActions({
      setTableTypeFilter: 'tables/setTableFilters',
      saveTab: 'tabs/saveTab',
    }),
    filterElements () {
      const filters = Object.entries(this.currentMode)
        .map(([name, filterBy]) => ({ name, filterBy }))

      this.setTableTypeFilter({ filters, tableName: this.tableName })
    },
    changeTab (event) {
      const currentIndex = this.modes.findIndex(type => type.value === this.currentMode)
      const index = shortkeysNavigateBoundaries(
        event.srcKey,
        currentIndex,
        this.modes,
        ['ctrll', 'cmdl'],
        ['ctrlr', 'cmdr'],
      )
      this.currentMode = this.modes[index].value
      this.filterElements()
    }
  }
}
</script>

<style lang="scss" scoped>
.type-switch {
  .v-btn {
    background-color: white !important;
  }
  &--active.v-btn {
    background-color: $primary !important;
    color: white !important;
    border: 0;
  }
}
</style>
