<template>
  <div class="contact-details">
    <div class="detail-section__header contact-details__header">
      Historia blokad klienta
    </div>

    <v-divider />

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="blockages"
      class="details-table"
      :items-per-page="-1"
    >
      <template #item="{ item }">
        <TableRow
          table-name="clients"
          class="details-table-row"
          :item="item"
          :header="headers"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { clientBlockages } from '../../../const/views/administration/clients'
import TableRow from '../../Table/TableRow'

export default {
  components: {
    TableRow,
  },
  props: {
    blockages: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    ...clientBlockages, // { headers }
  }),
}
</script>

<style lang="scss" scoped>
.details-table-row::v-deep .table__column--row {
  height: 100%;
  padding: 8px 0;
  text-overflow: clip;
  white-space: normal;
  line-height:18px;
}
</style>
