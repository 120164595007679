<template>
  <tr
    class="table__row relative"
    :class="{
      'table__row--selected': isSelected,
      'table__row--ws': item.fromWS,
      'table__row--expanded': isExpandedTableItem,
      'table__row--disabled': disabled
    }"
    @mouseenter="renderRowActions(true)"
    @mouseleave="renderRowActions(false)"
  >
    <td
      v-for="(column, index) in header"
      :key="column.name + index"
      class="table__column"
      :class="[
        {
          'table__column--checkbox-show':
            column.name === 'select' && isMultiselectActive
        },
        column.cellClass
      ]"
      :style="[header.length === index + 1 ? { paddingRight: '10px' } : {}]"
    >
      <template v-if="column.name === 'actions'">
        <div class="relative h-100 px-6">
          <TableColumnActions
            v-if="showActions"
            :table-name="tableName"
            :item="item"
            :static-actions="actions"
            :offset="actionsOffset"
          />
        </div>
      </template>
      <template v-else-if="column.name === 'expand'">
        <td>
          <v-btn
            v-if="item.groupedEntries && item.groupedEntries.length"
            icon
            small
            @click.stop="$emit('expandRow')"
            :style="[
              expandedItems && expandedItems.includes(item)
                ? { transform: 'rotate(180deg)' }
                : {},
            ]"
          >
            <v-icon color="#2CB56B">
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </td>
      </template>
      <div v-else>
        <div
          v-for="row in rowsInCell(column)"
          :key="row.id"
          class="table__column--row"
        >
          <TableColumnDebrisType
            v-if="column.name === 'debrisType' && tableName === 'orders' || (tableName === 'courses' && (column.name === 'reportedDebrisType' || column.name === 'debrisType'))"
            :value="columnValue(column.value, row)"
            :table-name="tableName"
            :row-data="row"
          />
          <TableColumnInvoiceStatus
            v-else-if="column.name === 'hasOrderInvoiceStatus'"
            :value="columnValue(column.value, row)"
          />
          <TableColumnContainerType
            v-else-if="column.name === 'containerType' && tableName === 'courses'"
            :value="columnValue(column.value, row)"
            :row-data="row"
          />
          <TableColumnPixelStatusVue
            v-else-if="column.name === 'pixelStatus' && tableName === 'orderTemplates'"
            :value="columnValue(column.value, row)"
            :empty-value="emptyValue"
          />
          <TableColumnSelectItem
            v-else-if="isMultiselectActive && column.name === 'select'"
            :disabled="isSelectionDisabled(row)"
            :item-id="row.id"
            :table-name="tableName"
          />

          <template v-else-if="isDefaultColumn(column.name)">
            <div
              :title="formatColumnValue(column, row, column.length)"
              :class="{
                'error--text font-weight-bold':
                  column.clientLabel && isBlocked()
              }"
            >
              {{ limitedColumnValue(column, row, column.length) }}
            </div>
          </template>

          <TableColumnActive
            v-else-if="column.name === 'active'"
            :value="get(row, column.value)"
            :table-name="tableName"
            :item="item"
          />

          <TableColumnMovement
            v-else-if="column.name === 'moveToRelatedCourse'"
            :course="item"
          />
          <span
            v-else-if="column.name === 'loading'"
            class="d-flex align-center h-100"
          >
            <v-progress-circular
              v-if="get(row, 'loading') === 'loading'"
              indeterminate
              color="primary"
            />
            <img
              v-else-if="get(row, 'loading') === 'loaded'"
              src="@/assets/icons/checkmark.svg"
              class="checkmark"
              alt="tak"
            >
          </span>

          <div
            v-else-if="isCheckmarkColumn(column.name)"
            class="d-flex align-center h-100"
          >
            <span v-if="checkmarkColumnValue(column.value, row) === null">{{ emptyValue }}</span>
            <img
              v-else-if="checkmarkColumnValue(column.value, row) !== emptyValue"
              src="@/assets/icons/checkmark.svg"
              class="checkmark"
              alt="tak"
            >
            <img
              v-else
              src="@/assets/icons/block-mark.svg"
              class="checkmark"
              alt="nie"
            >
          </div>

          <template v-else-if="column.name === 'volume'">
            <span>{{ columnValue(column.value, row) / 1000 }} m<sup>3</sup></span>
          </template>
          <template v-else-if="column.name === 'documentStatus'">
            <span>{{ columnValue(column.value, row) === emptyValue ? 'Wystawiono' :'Oczekuje' }} </span>
          </template>

          <TableColumnKpoNumber
            v-else-if="column.name === 'kpoNumber'"
            :value="limitedColumnValue(column, row)"
            :item="item"
            :is-swap="isSwap()"
          />

          <template v-else-if="column.name === 'blocked'">
            <img
              v-if="columnValue(column.value, row) !== emptyValue"
              src="@/assets/icons/block-mark.svg"
              class="checkmark"
              alt="blocked"
            >
          </template>

          <template v-else-if="column.name === 'departmentId'">
            {{ getDepartmentName(column.value) }}
          </template>

          <span v-else-if="column.name === 'senderBusinessPlace'">
            {{ getSenderMpd(column.value) }}
          </span>

          <TableColumnCourseType
            v-else-if="column.name === 'courseType'"
            :course-type="columnValue(column.value, row)"
            :is-swap="isSwap()"
          />
          <TableColumnStatus
            v-else-if="column.name === 'courseStatus'"
            :status="columnValue(column.value, row)"
          />
          <TableColumnStatus
            v-else-if="column.name === 'cardStatus'"
            :status="columnValue(column.value, row)"
            :color="item.attentionStatus"
          />
          <TableColumnStatus
            v-else-if="column.name === 'paymentStatus'"
            :status="columnValue(column.value, row)"
            :color="columnValue(column.value, row).includes('Zapłacone') || columnValue(column.value, row) === 'Stare' ? 'green' : 'red'"
          />
          <TableColumnStatus
            v-else-if="column.name === 'origin'"
            :status="formatColumnValue(column, row)"
            :color="getOriginColor(column.value)"
          />
          <TableColumnDriverAlerts
            v-else-if="column.name === 'driverAlerts'"
            :drivers="columnValue(column.value, row)"
          />
          <TableColumnDate
            v-else-if="isDateColumn(column.name)"
            :done="courseIsDone(column.value)"
            :date="columnValue(column.value, row)"
            :text="column.text"
          />
          <TableColumnInfo
            v-else-if="infoColumns.includes(column.name) && get(row, column.value) !== undefined"
            :text="get(row, column.value)"
          />

          <div
            v-else-if="column.name === 'filename'"
            class="d-flex align-center"
          >
            <Icon
              name="attachment"
              size="medium"
              class="mr-2"
            />
            {{ limitedColumnValue(column, row) }}
          </div>
          <div v-else-if="column.name === 'settingValue'">
            <TableColumnSetting :value="get(row, column.value)" />
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import TableColumnActions from './Columns/TableColumnActions'
import TableColumnDate from './Columns/TableColumnDate'
import TableColumnStatus from './Columns/TableColumnStatus'
import TableColumnCourseType from './Columns/TableColumnCourseType'
import TableColumnDriverAlerts from './Columns/TableColumnDriverAlerts'
import TableColumnSelectItem from './Columns/TableColumnSelectItem'
import TableColumnActive from './Columns/TableColumnActive'
import TableColumnMovement from './Columns/TableColumnMovement'
import TableColumnInfo from './Columns/TableColumnInfo'
import TableColumnDebrisType from './Columns/TableColumnDebrisType'
import TableColumnKpoNumber from './Columns/TableColumnKpoNumber'
import TableColumnContainerType from './Columns/TableColumnContainerType'
import TableColumnSetting from './Columns/TableColumnSetting'
import TableColumnPixelStatusVue from './Columns/TableColumnPixelStatus.vue'
import TableColumnInvoiceStatus from './Columns/TableColumnInvoiceStatus'
import { getBlockerValue } from '../../utils'
import paramGetters from '../../utils/paramGetters'
import { formatValue } from '../../utils/typesEnum'
import selectionConditions from '../../utils/selectionConditions'
import get from 'lodash/get'
import { SwapCourse } from '../../models'

// TODO the best would be if headers types were specified in table config files so we don't have to iterate
const dateColumns = [
  'courseDeliveryDate',
  'courseDepartureDate',
  'courseDeliveryAt',
  'createdAt',
  'dueDate',
  'leaveDate',
  'pickupDate',
  'exportDate',
  'issueDate',
  'saleDate',
  'paymentDate',
  'depositedAt',
  'plannedTransportTime',
  'endDate',
  'startDate',
  'realTransportTime',
  'lastNoteDate',
  'notesCallStart',
  'finishedAt',
]
const checkmarkColumns = [
  'bLicense',
  'cLicense',
  'ceLicense',
  'otherLicense',
  'active',
  'visibleForDriver',
  'reserved',
  'wasteCodeExtended',
  'obsolete',
  'pinned',
  'personalInvoice',
  'callProspect',
  'callReceived',
  'hasInvoice',
  'callReceived'
]
const infoColumns = [
  'info',
  'discountJustification',
  'lastNoteText',
  'notes',
  'wasteCodeExtendedDescription',
  'containerNotes',
  'comment',
]

const specialColumns = [
  ...dateColumns,
  ...checkmarkColumns,
  ...infoColumns,
  'expand',
  'select',
  'actions',
  'courseStatus',
  'courseType',
  'volume',
  'driverAlerts',
  'blocked',
  'active',
  'filename',
  'cardStatus',
  'loading',
  'origin',
  'documentStatus',
  'departmentId',
  'paymentStatus',
  'kpoNumber',
  'moveToRelatedCourse',
  'settingValue'
]

export default {
  components: {
    TableColumnActions,
    TableColumnDate,
    TableColumnStatus,
    TableColumnCourseType,
    TableColumnSelectItem,
    TableColumnDriverAlerts,
    TableColumnActive,
    TableColumnMovement,
    TableColumnInfo,
    TableColumnKpoNumber,
    TableColumnDebrisType,
    TableColumnContainerType,
    TableColumnSetting,
    TableColumnPixelStatusVue,
    TableColumnInvoiceStatus
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false
    },
    isMultiselectActive: {
      type: Boolean,
      default: false
    },
    header: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: false
    },
    actionsOffset: {
      type: Object,
      required: false
    },
    isExpandedTableItem: {
      type: Boolean,
      required: false,
      default: false
    },
    expandedItems: {
      type: Array,
      required: false,
      default: () => []
    },
    emptyValue: {
      type: String,
      required: false,
      default: '-'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dateColumns,
      checkmarkColumns,
      infoColumns,
      showActions: false
    }
  },
  methods: {
    get,
    renderRowActions(showActions = false) {
      this.showActions = showActions
    },
    getDepartmentName(path) {
      const departmentId = this.columnValue(path)
      return this.$store.state.departments.items.find(dep => dep.id === departmentId)?.name || '-'
    },
    getColumnValue(column, item) {
      if (column.concatValues) {
        return get(item, column.value)?.map(el => el[column.concatValues]).join(', ') || this.emptyValue
      } else {
        return get(item, column.value)
      }
    },
    formatColumnValue(column, item = this.item) {
      const value = this.getColumnValue(column, item)
      return formatValue(value, column.name, this.emptyValue)
    },
    isSelectionDisabled(item) {
      const { multiselectSubject } = this.$store.state[this.tableName]
      const selectionCondition = selectionConditions[multiselectSubject]
      return selectionCondition ? !selectionCondition(item) : false
    },
    limitedColumnValue(column, item = this.item, maxLength = 32) {
      const { name, paramGetter } = column

      const valueMaxLength = column.maxLength || maxLength

      const rawValue = this.getColumnValue(column, item)

      let formattedValue = formatValue(rawValue, name, this.emptyValue)
      if (paramGetter) {
        const getValue = get(paramGetters, paramGetter, null) // find getter by label
        if (getValue) formattedValue = getValue(rawValue || item)
      }
      if (formattedValue.length > valueMaxLength) {
        return formattedValue.substring(0, valueMaxLength).trim() + '...'
      }
      return formattedValue
    },
    columnValue(path, item = this.item) {
      const value = get(item, path)
      return value || this.emptyValue
    },
    checkmarkColumnValue(path, item = this.item) {
      const value = get(item, path, null)
      if (value === null) return null // null means that value is not set so we don't want to show checkmark representation
      return value || this.emptyValue // 'emptyValue' means that value is set to false -> display 'x' checkmark, otherwise display '✓' checkmark
    },
    rowsInCell(column) {
      let values = [this.item]
      if (column.multipleValue) {
        values = this.item[column.multipleValue]
      } else if (column.swapValue && this.item.swapCourseId) {
        values.push(new SwapCourse(this.item))
      } else if (column.name === 'documentStatus' && this.item.swapCourseId && this.tableName === 'courses') {
        values.push({ withoutDocuments: this.item.swapWithoutDocuments })
        // this adds extra row in swap documents status column, for BE reasons it cant be in swapCourse
      }
      return values
    },
    courseIsDone(key) {
      const statusKey = key.replace('DueDate', 'Status')
      return this.item[statusKey] === 'Wykonany'
    },
    isDefaultColumn(name) {
      return !specialColumns.includes(name)
    },
    isCheckmarkColumn(name) {
      return this.checkmarkColumns.includes(name)
    },
    isDateColumn(name) {
      return this.dateColumns.includes(name)
    },
    isSwap() {
      return !!this.item.swapCourseId
    },
    isBlocked() {
      return getBlockerValue(this.item, this.tableName)
    },
    getOriginColor(color) {
      const value = this.columnValue(color)
      const colors = {
        client_app: 'purple',
        desktop: 'green',
        template: 'blue'
      }
      return colors[value] || 'red'
    },
    getSenderMpd(value) {
      const { name, address } = value
      return `${name}
      ${address}`
    }
  }
}
</script>

<style lang="scss" scoped>
.checkmark {
  width: 24px;
}
</style>
