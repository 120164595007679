<template>
  <div
    v-if="availableActions.length"
    class="table__actions"
    :style="`right: ${fullOffset}px`"
  >
    <div
      v-for="action in availableActions"
      :key="action.name"
    >
      <div
        v-for="(row, index) in rowsInActions(action)"
        :key="row.id"
        class="table__column-row"
      >
        <v-btn
          @click.stop="triggerAction({action, item: row, index})"
          :disabled="isDialogAlreadyOpen(action.target)"
          :title="action.text"
          class="mx-1"
          icon
          small
        >
          <Icon
            :name="action.icon"
            :color="action.color"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuickActions, filterInactiveActions } from '../../../utils/quickActions'
import quickActionsMixin from '../../../mixins/quickActionsMixin'

export default {
  mixins: [quickActionsMixin], // triggerAction, isDialogAlreadyOpen
  props: {
    item: {
      type: Object,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    staticActions: {
      type: Array,
      default: () => []
    },
    offset: {
      type: Object,
      required: false
    }
  },
  computed: {
    availableActions () {
      if (this.staticActions.length) return this.staticActions
      const actions = getQuickActions(this.tableName)
      return filterInactiveActions(actions, this.item, this.tableName) || []
    },
    fullOffset () {
      const { tableVisibleArea, scrolled, tableWidth } = this.offset || {}
      return this.offset ? tableWidth - (tableVisibleArea + scrolled) : 0
    }
  },
  methods: {
    rowsInActions (action) {
      return action.multipleValue ? this.item[action.multipleValue] : [this.item]
    }
  }
}
</script>

<style lang="scss" scoped>
.table__actions {
  padding-left: 20px;
  padding-right: 15px;
  position: absolute;
  display:flex;
  align-items:center;
  height:100%;
  top:0;
  background: linear-gradient(90deg, transparent 0%,  #e7f2ff 70%, #e7f2ff 100%);
  &::v-deep .v-btn:before {
    display: none;
  }
}
</style>
