<template>
  <div class="contact-details">
    <div
      class="detail-section__header contact-details__header"
      ref="header"
    >
      Osoby kontaktowe

      <ActionButtons
        v-if="actions.length"
        :actions="actions"
        :item="client"
      />
    </div>

    <v-divider />

    <v-data-table
      class="contacts-table"
      :style="tableStyles"
      :headers="headers"
      :items="contacts"
      :items-per-page="-1"
      fixed-header
      hide-default-footer
      :expanded.sync="expandedContacts"
      show-expand
      item-key="id"
      @click:row="(item, slot) => expandRow(item, slot)"
    >
      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding:0"
        >
          <v-data-table
            class="expanded-table br-0"
            :headers="[{value:'formattedAddress', text: 'Obsługiwane adresy', sortable: false}]"
            :items="item.addresses"
            :items-per-page="-1"
            fixed-header
            hide-default-footer
          />
        </td>
      </template>
      <template #item="{ item, expand, isExpanded }">
        <tr @contextmenu.prevent="handleContextMenu($event, item)">
          <td>
            <v-btn
              v-if="item.addresses && item.addresses.length"
              icon
              small
              @click="expand(!isExpanded)"
              :style="[
                expandedContacts.includes(item)
                  ? { transform: 'rotate(180deg)' }
                  : {},
              ]"
            >
              <v-icon color="#2CB56B">
                mdi-chevron-up
              </v-icon>
            </v-btn>
          </td>
          <td
            v-for="header in headers"
            :key="header.name"
            :style="[isExpanded ? { textShadow: '0 0 0.01px black' } : {}]"
          >
            {{ getRowItem(item, header.name) }}
          </td>
        </tr>
      </template>
      <template #[`header.data-table-expand`]>
        <th>
          <v-switch
            :input-value="!!expandedContacts.length"
            @change="toggleAllContacts"
            hide-details
            dense
            class="ma-0"
            title="Przełącz wszystkie"
          />
        </th>
      </template>
      <template #[`item.passwordExists`]="{ item }">
        <td class="d-flex align-center">
          <template v-if="item.passwordExists">
            <img
              width="24"
              src="@/assets/icons/checkmark.svg"
              class="mr-4"
              alt="tak"
            >
            <v-btn
              outlined
              x-small
              color="blue"
              @click="modifyCredentials(item, true)"
            >
              Zresetuj hasło
            </v-btn>
          </template>
          <template v-else>
            <img
              width="24"
              src="@/assets/icons/block-mark.svg"
              class="mr-4"
              alt="nie"
            >
            <v-btn
              outlined
              x-small
              color="green"
              @click="modifyCredentials(item)"
            >
              Nadaj dostęp
            </v-btn>
          </template>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ActionButtons from '../../Elements/ActionButtons'
import { clientContacts } from '../../../const/views/administration/clients'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    ActionButtons
  },
  props: {
    client: {
      type: Object,
      required: false
    },
    topSectionHeight: {
      type: Number,
      default: 500
    }
  },
  data: () => ({
    ...clientContacts, // { headers, rowActions, actions }
    expandedContacts: [],
    query: ''
  }),
  computed: {
    ...mapState({
      contacts: state => state.client.entity.contacts.map(contact => {
        const { firstName, lastName, pricesVisible, invoiceReceivable } = contact
        const parseBoolean = isTrue => isTrue ? 'Tak' : 'Nie'
        return {
          ...contact,
          arePricesVisible: parseBoolean(pricesVisible),
          isInvoiceReceivable: parseBoolean(invoiceReceivable),
          fullName: (firstName || lastName) && `${firstName || ''} ${lastName || ''}`
        }
      })
    }),
    tableStyles () {
      const topOffset = this.topSectionHeight + this.$refs.header?.clientHeight
      return {
        maxHeight: `calc(100vh - ${topOffset}px)`,
        overflow: 'auto'
      }
    }
  },
  methods: {
    ...mapActions({
      setContextMenu: 'layout/setContextMenu',
      setDialog: 'layout/setDialog',
    }),
    handleContextMenu (e, item) {
      this.setContextMenu({
        actions: this.rowActions,
        item,
        position: { x: e.clientX, y: e.clientY }
      })
    },
    expandRow (item, slot) {
      if (item.addresses?.length) slot.expand(!slot.isExpanded)
    },
    toggleAllContacts (expand) {
      this.expandedContacts = expand ? this.contacts.filter(contact => contact.addresses?.length) : []
    },
    modifyCredentials (item, update) {
      const data = {
        text: update ? 'Zresetuj hasło' : 'Nadaj dostęp',
        component: 'ManageClientAppAccess',
      }
      this.setDialog({ data, item })
    },
    getRowItem(item, header) {
      if (item[header]) return item[header]
      if (item[header] === false) return 'Nie'
      else return ''
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .expanded-table > .v-data-table__wrapper > table > tbody > tr > .text-start {
  padding-left: 93px !important;
}

td{
  text-align: left !important;
}
</style>
