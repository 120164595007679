import actions from '../actions'

export default {
  header: [],
  table: {
    noApi: false,
    sorting: {
      sortBy: 'createdAt',
      sortDesc: true
    },
    parameters: [
      { name: 'createdAt', text: 'Czas wysłania', value: 'createdAt', show: true },
      { name: 'text', text: 'Treść komunikatu', value: 'text', length: 10000, show: true },
      { name: 'driverAlerts', text: 'Kierowca', value: 'drivers', sortable: false, show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteAlert, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteAlert }
    ]
  }
}
