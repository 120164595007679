import actions from '../../../actions'

export default {
  header: [
    { ...actions.fetchDebrisTypes, create: true }
  ],
  table: {
    noApi: true,
    specialFlag: {
      aggregate: false,
      departmentId: undefined
    },
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'code', text: 'Kod odpadu', value: 'code', show: true },
      { name: 'name', text: 'Typ odpadu', value: 'name', show: false },
      { name: 'displayName', text: 'Własna nazwa', value: 'displayName', show: true },
      { name: 'visibleForDriver', text: 'Widoczne dla kierowcy', value: 'visibleForDriver', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteDebrisType, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteDebrisType },
    ]
  },
  details: {
    title: 'Kod odpadu {{code}}',
    actions: [
      { ...actions.deleteDebrisType, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'debrisTypes',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'inline', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'inline', col: 3 },
          { name: 'code', text: 'Kod odpadu', value: 'code', type: 'textField', col: 3, rules: ['required'] },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'visibleForDriver', text: 'Widoczne dla kierowcy', value: 'visibleForDriver', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'name', text: 'Typ odpadu', value: 'name', type: 'textField', col: 12, rules: ['required'] },
          { name: 'displayName', text: 'Własna nazwa', value: 'displayName', type: 'textField', col: 12, editable: true, rules: ['required'], refreshTables: ['debrisTypes'] },
          { name: 'clientDisplayName', text: 'Nazwa widoczna przez klienta', value: 'clientDisplayName', type: 'textField', col: 12, editable: true, rules: ['required'] },
        ]
      },
      {
        name: 'debrisTypesDepartments',
        text: 'Typy KWU',
        type: 'left',
        component: 'DetailsDebrisTypesDepartments',
        tableName: 'debrisTypes',
      },
    ]
  }
}
