import actions from '../../actions'

export default {
  header: [
    { ...actions.addNewContainerType, create: true }
  ],
  table: {
    noApi: true,
    specialFlag: {
      departmentId: null
    },
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'name', text: 'Typ kontenera', value: 'name', show: true },
      { name: 'volume', text: 'Rozmiar kontenera', value: 'volume', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteContainerType, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteContainerType }
    ]
  },
  details: {
    title: 'Typ kontenera {{name}}',
    actions: [
      { ...actions.deleteContainerType, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'containerTypes',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'name', text: 'Typ kontenera', value: 'name', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'volume', text: 'Rozmiar kontenera', value: 'volume', type: 'textField', col: 3, editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 101 }], unit: ' [m<sup>3</sup>]' },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] }
        ]
      }
    ]
  }
}
