import actions from '../../actions'

export default {
  header: [
    { ...actions.addNewOrder, text: 'Nowe zlecenie', create: true }
  ],
  table: {
    maximumDateRange: 1,
    specialFlag: {
      done: true,
      endpoint: 'routes'
    },
    sorting: {
      sortBy: 'driver.fullName',
      sortDesc: false
    },
    filters: [
      { name: 'done', text: '', filterBy: true, hide: true, checkIfDone: true },
      { name: 'courseStatus', text: 'Status kursu', options: 'courseStatuses', filterBy: '', propPath: 'courseStatus' },
      { name: 'courseType', text: 'Typ kursu', options: 'courseTypes', filterBy: '', propPath: 'courseType' },
      { name: 'containerTypeId', text: 'Typ kontenera', options: 'containerTypes', filterBy: '', propPath: 'order.containerTypeId' },
      { name: 'driverId', text: 'Kierowca', options: 'drivers', filterBy: '', propPath: 'driver.id', root: true },
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'order.client.id' },
      { name: 'paymentType', text: 'Typ płatności', options: 'paymentTypes', filterBy: '', propPath: 'order.payment.paymentType' },
      { name: 'paymentStatus', text: 'Status płatności', options: 'paymentStatuses', filterBy: '', propPath: 'order.payment.paymentStatus' }
    ],
    parameters: [
      { name: 'refNumber', text: 'Nr zlecenia', value: 'course.order.refNumber', show: true, multipleValue: 'stops', sortable: false },
      { name: 'courseStatus', text: 'Status kursu', value: 'course.courseStatus', show: true, multipleValue: 'stops', sortable: false },
      { name: 'courseType', text: 'Typ kursu', value: 'course.courseType', show: true, multipleValue: 'stops', sortable: false },
      { name: 'containerId', text: 'Kontener', value: 'course.order.containerWithType', show: true, multipleValue: 'stops', sortable: false },
      { name: 'bdoNumber', text: 'Nr KPO', value: 'course.order.bdoNumber', show: true, multipleValue: 'stops', sortable: false },
      { name: 'bdoStatus', text: 'Status KPO', value: 'course.order.bdoStatus', show: true, multipleValue: 'stops', sortable: false },
      { name: 'courseId', text: 'Nr kursu', value: 'course.driverDailySequence', show: true, multipleValue: 'stops', sortable: false },
      { name: 'driver', text: 'Kierowca', value: 'driver.fullName', show: true },
      { name: 'address', text: 'Adres zlecenia', value: 'course.order.address.formattedAddress', show: true, multipleValue: 'stops', sortable: false },
      { name: 'clientName', text: 'Klient', value: 'course.order.client.name', clientLabel: true, show: true, multipleValue: 'stops', sortable: false },
      { name: 'paymentType', text: 'Typ płatności', value: 'course.order.payment.paymentType', show: true, multipleValue: 'stops', sortable: false },
      { name: 'paymentStatus', text: 'Status płatności', value: 'course.order.payment.paymentStatus', show: true, multipleValue: 'stops', sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, multipleValue: 'stops', sortable: false }
    ],
    actions: [],
    menu: []
  }
}
