import Base from './base'
import get from 'lodash/get'

const driversMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath } = filter
    const filterValue = filterBy.value ?? filterBy

    return typeof filterValue === 'object' && Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      // filter can be either a String or object with name and value
      : get(item, propPath) === filterValue
  })
}

export default new Base('driversLocation', driversMatchesFiltersCriteria)
