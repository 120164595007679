<template>
  <div class="details-courses my-2">
    <v-container fluid>
      <v-row>
        <v-col
          v-for="field in filteredFields"
          :key="field.name"
          cols="12"
          sm="12"
          :md="field.col"
          class="py-1"
        >
          <DialogInput
            :field="field"
            :data="entity"
            :detail="detail"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'
import { get } from 'lodash'

export default {
  components: {
    DialogInput
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    entity() {
      return get(this.data, this.detail.dataPath, {
        courseType: null,
        id: null
      })
    },
    filteredFields() {
      return this.detail.fields
        .filter((field) => !field.condition || field.condition(this.data))
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input.v-text-field.hide-margin::v-deep {
  .v-input__slot {
    margin-bottom:0 !important;
    min-height: 36px;
    height: 36px;
  }
  .v-input__append-inner {
    opacity: 1 !important;
  }
}
</style>
