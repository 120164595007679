<template>
  <div class="d-flex">
    <v-btn
      icon
      max-width="20px"
      class="mr-1 base-hover"
      :disabled="!previousCourseInfo.id"
      @click="goToRelatedCourse(previousCourseInfo)"
      @dblclick.stop
    >
      <v-icon
        color="primary"
        class="inverted"
      >
        mdi-play
      </v-icon>
    </v-btn>
    <v-btn
      icon
      max-width="20px"
      class="ml-1 base-hover"
      :disabled="!nextCourseInfo.id"
      @click="goToRelatedCourse(nextCourseInfo)"
      @dblclick.stop
    >
      <v-icon color="red">
        mdi-play
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    course: {
      type: Object,
      required: true
    },
  },
  computed: {
    nextCourseInfo () {
      return {
        id: this.course.nextCourseId,
        dueDate: this.course.nextCourseDueDate
      }
    },
    previousCourseInfo () {
      return {
        id: this.course.previousCourseId,
        dueDate: this.course.previousCourseDueDate
      }
    }
  },
  methods: {
    ...mapActions({
      setTableDate: 'courses/setTableDate', // so far used only in courses
      setTableFiltersStatus: 'tables/setTableFiltersStatus'
    }),
    goToRelatedCourse ({ dueDate, id }) {
      this.setTableDate({ date: [dueDate] })
      this.setTableFiltersStatus({ status: false, tableName: 'courses' })
        .then(() => {
          this.$router.push({ query: { activeItemId: id } })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.inverted {
  transform: rotateY(180deg)
}
</style>
