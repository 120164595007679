<template>
  <hsc-window-style-white>
    <hsc-window
      title="Notatka do połączenia"
      position-hint="-60 / -30"
      :close-button="true"
      :is-open.sync="showModal"
      class="floating-popup"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <v-card
        min-width="400px"
        color="white"
      >
        <v-form
          id="addCallEntryForm"
          ref="addCallEntryForm"
          class="custom-form"
          v-model="isFormValid"
          @submit.prevent="addCallEntry"
        >
          <v-card-text class="custom-form pt-6">
            <v-text-field
              v-if="!contactId"
              v-model="contactName"
              :rules="[rules.required]"
              placeholder="Wpisz imię i nazwisko kontaktu"
              label="Imię i nazwisko kontaktu"
              class="mb-6"
              outlined
              hide-details
            />
            <v-textarea
              v-model="notes"
              :rules="[rules.required]"
              outlined
              :rows="3"
              auto-grow
              label="Notatki"
              hide-details
              placeholder="Wpisz notatki do rozmowy"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              class="base-hover ml-auto"
              form="addCallEntryForm"
              :disabled="!isFormValid"
              type="submit"
            >
              Stwórz wpis
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import rules from '../../utils/validators'

export default {
  data: () => ({
    rules,
    isFormValid: true,
    showModal: false,
    notes: null,
    contactName: null,
    dialogZIndex: 10
  }),
  mounted () {
    this.showModal = !!this.$route.query.callStart
  },
  watch: {
    showModal (isShown) {
      if (isShown) this.updateDialogIndex()
    }
  },
  computed: {
    ...mapState({
      contactId: state => state.callEntry.callEntry.contactId,
      phoneNumber: state => state.callEntry.callEntry.phoneNumber,
      clientId: state => state.callEntry.callEntry.clientId
    })
  },
  methods: {
    ...mapActions({
      createCallEntry: 'callEntry/createCallEntry',
      addNewContact: 'clients/addNewItem',
      updateDialogIndex: function (dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      },
    }),
    addCallEntry() {
      const createCallEntry = (contactId) => {
        this.createCallEntry({ notes: this.notes, contactId }).then(() => {
          this.showModal = false
        })
      }

      if (this.contactId) {
        createCallEntry()
      } else {
        const names = this.contactName.split(' ')

        const params = {
          clientId: this.clientId,
          phoneNumber: this.phoneNumber,
          role: 'Inny',
          lastName: names.pop() || '',
          firstName: names.join(' ') || ''
        }

        this.addNewContact({ params, endpoint: 'contacts' })
          .then((resp) => {
            const { id: contactId } = resp
            createCallEntry(contactId)
          })
      }
    }
  }
}
</script>
