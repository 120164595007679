import actions from '../../../actions'

export default {
  header: [
    { ...actions.addNewDriver, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [
      { name: 'departmentId', text: 'Oddział', options: 'departments', filterBy: '' },
    ],
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'firstName', text: 'Imię', value: 'firstName', show: true },
      { name: 'lastName', text: 'Nazwisko', value: 'lastName', show: true },
      { name: 'pesel', text: 'Nr PESEL', value: 'pesel', show: true },
      { name: 'licenseNumber', text: 'Nr prawa jazdy', value: 'licenseNumber', show: true },
      { name: 'bLicense', text: 'Kat. B', value: 'bLicense', show: true },
      { name: 'cLicense', text: 'Kat. C', value: 'cLicense', show: true },
      { name: 'ceLicense', text: 'Kat. CE', value: 'ceLicense', show: true },
      { name: 'otherLicense', text: 'Kat. inna', value: 'otherLicense', show: true },
      { name: 'terminal', text: 'Terminal', value: 'terminal.name', show: true },
      { name: 'licensePlate', text: 'Nr rejestracyjny', value: 'vehicle.licensePlate', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteDriver, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteDriver }
    ]
  },
  details: {
    title: '{{firstName}} {{lastName}}',
    actions: [
      { ...actions.changePassword },
      { ...actions.deleteDriver, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'drivers',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'firstName', text: 'Imię', value: 'firstName', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'lastName', text: 'Nazwisko', value: 'lastName', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'bLicense', text: 'Kat. B', value: 'bLicense', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'cLicense', text: 'Kat. C', value: 'cLicense', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'ceLicense', text: 'Kat. CE', value: 'ceLicense', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'otherLicense', text: 'Kat. inna', value: 'otherLicense', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'pesel', text: 'Nr PESEL', value: 'pesel', type: 'textField', col: 3, editable: true, rules: ['peselNumber'] },
          { name: 'licenseNumber', text: 'Nr prawa jazdy', value: 'licenseNumber', type: 'textField', col: 3, editable: true },
          { name: 'terminalId', text: 'Terminal', value: 'terminal.name', type: 'selectFromItems', options: 'terminals', col: 3, editable: true, clearable: true, refreshTables: ['drivers', 'terminals'] },
          { name: 'vehicleId', text: 'Pojazd', value: 'vehicle.parsedData', type: 'selectFromItems', options: 'vehicles', itemText: 'parsedData', col: 3, editable: true, clearable: true, refreshTables: ['drivers', 'vehicles'] },
          { name: 'email', text: 'Adres email', value: 'email', type: 'textField', col: 3, editable: true, rules: ['required', 'email'] },
          { name: 'login', text: 'Login', value: 'login', type: 'textField', col: 3, editable: true, rules: ['required', 'noSpaces'] },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'trailerId', text: 'Przyczepa', value: 'trailer.licensePlate', type: 'selectFromItems', options: 'trailers', itemText: 'licensePlate', col: 3, editable: true, clearable: true, refreshTables: ['drivers', 'trailers'] },
        ]
      }
    ]
  }
}
