import Base from './base'
import get from 'lodash/get'

const scheduleMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, name } = filter
    const filterValue = filterBy.value ?? filterBy
    if (name === 'day') {
      const scheduleDays = get(item, propPath)?.split(', ')
      const days = {
        monday: 'pon',
        tuesday: 'wt',
        wednesday: 'śr',
        thursday: 'czw',
        friday: 'pt',
        saturday: 'sb',
      }
      return scheduleDays?.includes(days[filterBy])
    }
    return get(item, propPath) === filterValue
  })
}

export default new Base('orderSchedules', scheduleMatchesFiltersCriteria)
