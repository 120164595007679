import { bdoTabsSharedFilters, bdoTabsSharedParameters } from './sharedParams'
import cloneDeep from 'lodash/cloneDeep'
import actions from '../../actions'

export default {
  header: [
    { ...actions.refreshRevisionCards },
  ],
  table: {
    noDateRange: true,
    isWebSocketHandled: true,
    sorting: {
      sortBy: 'order.refNumber',
      sortDesc: false
    },
    specialFlag: {
      endpoint: 'bdoCards',
      revised: true,
    },
    filters: [
      { name: 'departmentId', text: 'Oddział', options: 'departments', filterBy: '' },
      { name: 'cardStatuses', text: 'Status karty', filterBy: ['Odrzucona', 'Potwierdzenie wygenerowane'], propPath: 'cardStatus', options: 'revisionBdoCardStatuses', multiple: true, default: ['Odrzucona', 'Potwierdzenie wygenerowane'], hide: true },
      ...cloneDeep(bdoTabsSharedFilters),
    ],
    parameters: [
      { name: 'loading', text: '', value: 'loading', show: true },
      { name: 'departmentName', text: 'Oddział', value: 'departmentName', show: true, sortable: false },
      ...cloneDeep(bdoTabsSharedParameters),
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false },
    ],
    actions: [],
    menu: []
  },
  details: {}
}
