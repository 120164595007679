import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[(_vm.editMode)?[_c(VCard,[_c(VCardTitle,[_c(VTextField,{staticClass:"topic-input",attrs:{"outlined":"","placeholder":"Wpisz temat","hide-details":""},model:{value:(_vm.newValues.topic),callback:function ($$v) {_vm.$set(_vm.newValues, "topic", $$v)},expression:"newValues.topic"}}),_c(VBtn,{staticClass:"base-hover ml-4",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.closeEditMode}},[_vm._v(" Anuluj ")]),_c(VBtn,{staticClass:"base-hover ml-4",attrs:{"color":"primary","disabled":!_vm.newValues.topic || !_vm.newValues.text},on:{"click":_vm.saveHint}},[_vm._v(" Zapisz ")])],1),_c(VCardText,[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.newValues.text),callback:function ($$v) {_vm.$set(_vm.newValues, "text", $$v)},expression:"newValues.text"}})],1)],1)]:[_c(VExpansionPanelHeader,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('b',[_vm._v(_vm._s(_vm.hint.topic))]),(open)?_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"base-hover mr-3",attrs:{"color":"primary","small":"","icon":"","title":"Edytuj"},on:{"click":function($event){_vm.editMode=true}}},[_c('Icon',{attrs:{"name":"edytuj","size":"small"}})],1),_c(VBtn,{staticClass:"base-hover mr-3",attrs:{"color":"error","small":"","icon":"","title":"Usuń"},on:{"click":_vm.openConfirmationModal}},[_c('Icon',{attrs:{"name":"usun","size":"small"}})],1)],1):_vm._e()])]}}])}),_c(VExpansionPanelContent,{staticClass:"px-6 py-4",domProps:{"innerHTML":_vm._s(_vm.safeHintText)}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }