<template>
  <v-container
    fluid
    style="padding: 0;"
  >
    <div class="d-flex justify-space-between">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          :md="3"
          style="padding: 0;"
          class="d-flex"
        >
          <span
            v-if="detail.text"
            style="flex-shrink: 0"
            class="mx-4 align-self-center"
          >{{ detail.text }}</span>
        </v-col>
        <v-col
          v-for="field in filteredFields"
          :key="field.name"
          cols="12"
          sm="12"
          :md="field.col"
          class="px-2 py-0"
        >
          <DialogInput
            :field="field"
            :data="entity"
            :root-data="data"
            :detail="detail"
          />
        </v-col>
        <v-col>
          <div v-if="headerActions.length">
            <ActionButtons
              :item="entity"
              :actions="headerActions"
              small
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import get from 'lodash/get'
import DialogInput from '../../Forms/Inputs/DialogInput'
import ActionButtons from '../../Elements/ActionButtons.vue'

export default {
  components: {
    DialogInput,
    ActionButtons,
  },
  name: 'SectionHeader',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    entity() {
      return this.detail?.dataPath ? get(this.data, this.detail.dataPath) : this.data
    },
    headerActions() {
      return this.detail?.headerActions?.map(action => ({ ...action, shortKey: null })) || []
    },
    filteredFields() {
      return this.detail.fields?.filter((field) => !field.condition || field.condition(this.entity))
    },
  }
}
</script>
