import Base from './base'
import api from '../api/v1'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { downloadFile } from '../utils'
import { decamelize } from 'humps'

const courseMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, name } = filter
    const filterValue = filterBy.value ?? filterBy
    if (filterValue === 'Wymiana') return !!item.swapCourse // courseType in courses
    if (name === 'withoutCards') {
      const { bdoNumber } = item.swapCourse || item
      return !bdoNumber === !!filterValue
    }
    if (name === 'forTails') {
      // this filter eliminates courses with courseType Transport and Podstawienie (which are not part of a swap)
      return item.courseType !== 'Transport' && (item.courseType !== 'Podstawienie' || item.swapCourse)
    }

    return Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      : get(item, propPath) === filterValue
  })
}

const courses = (tableName) => {
  const base = new Base(tableName, courseMatchesFiltersCriteria)

  const mutations = {
    SELECT_ALL_ITEMS (state, courses) {
      state.selectedItems = courses.map(course => course.id)
    },
  }
  const actions = {
    editCourseDueDate ({ commit, dispatch }, { id, dueDate }) {
      commit('SET_PROCESSING', true)
      const params = { dueDate }
      api.updateItem({ tableName, id, params })
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Zmieniono datę realizacji kursu']
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    editMultipleCoursesDueDate ({ state, commit, dispatch }, { dueDate }) {
      commit('SET_PROCESSING', true)
      const params = { dueDate, courseIds: state.selectedItems }
      api.postponeCourses(params)
        .then(() => {
          commit('REMOVE_ITEMS', state.selectedItems)
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Zmieniono daty realizacji kursów']
          }, { root: true })
        })
        .finally(() => {
          dispatch('toggleMultiselectStatus', false)
          commit('SET_PROCESSING', false)
        })
    },
    editAllCoursesDueDate ({ state, commit, dispatch }, { dueDate }) {
      const notPlannedCourses = state.items
        .filter(course => course.courseStatus === 'Zaplanuj')

      commit('SELECT_ALL_ITEMS', notPlannedCourses)
      dispatch('editMultipleCoursesDueDate', { dueDate })
      commit('UNSELECT_ALL_ITEMS')
    },
    exportAllCoursesSpreadsheet ({ state, commit, dispatch }) {
      commit('SELECT_ALL_ITEMS', state.items)
      dispatch('exportCoursesSpreadsheet')
      commit('UNSELECT_ALL_ITEMS')
    },
    exportCoursesSpreadsheet ({ state, commit, rootState, rootGetters }) {
      commit('SET_PROCESSING', true)
      const courseIds = state.selectedItems

      const { order, filters, departmentId } = rootGetters['tables/getTableParameters'](tableName).params

      const courseHeaders = rootState.tables[tableName].parameters
        .filter(header => header.show && !['moveToRelatedCourse', 'select', 'actions'].includes(header.name))
        .map(header => decamelize(header.name))
      // todo headers keys must be unified between FE and BE

      api.exportCoursesSpreadsheet({
        departmentId,
        order,
        courseHeaders,
        courseIds,
        filters
      }).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'text/csv' }))
        downloadFile(url, `Kursy ${state.dateRange}`)
        commit('UNSELECT_ALL_ITEMS')
      })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    getCounters ({ commit, rootGetters }) {
      const params = rootGetters['tables/getTableParameters'](tableName).params
      api.getCoursesCounters(params)
        .then((resp) => {
          commit('SET_COUNTERS', resp.data)
        })
    },
    resendTextConfirmation: debounce(function({ commit, dispatch }, { item }) {
      commit('SET_PROCESSING', true)
      api.resendTextConfirmation(item.id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Wysłano potwierdzenie']
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    }, 5000, { leading: true }),
    generateDigitalSignature: debounce(function({ commit, dispatch }, { item }) {
      commit('SET_PROCESSING', true)
      api.generateDigitalSignature(item.id)
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Wygenerowano podpis']
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    }, 5000, { leading: true }),

  }

  base.state = {
    ...base.state,
    ...(tableName === 'coursesWithoutCards' && {
      dateRange: []
    })
  }
  base.mutations = {
    ...base.mutations,
    ...mutations
  }
  base.actions = {
    ...base.actions,
    ...actions
  }

  return base
}

export default courses
