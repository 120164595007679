<template>
  <div class="details-attachments">
    <div v-if="list.length">
      <div
        v-for="(item, index) in list"
        :key="'report' + index"
        class="details-attachments__row"
      >
        <div class="d-flex">
          <div class="details-attachments__icon">
            <Icon name="attachment" />
          </div>
          <div class="details-attachments__content">
            <div class="details-attachments__name">
              {{ item.file.filename }}
            </div>
            <div class="details-attachments__meta">
              {{ formatValue(item.file.size, 'fileSize') }} / {{ dateStringFormat(item.createdAt, true) }}
            </div>
            <div class="details-attachments__meta">
              {{ item.note }}
            </div>
          </div>
        </div>
        <div class="d-flex">
          <v-btn
            class="mx-1"
            icon
            small
            @click="downloadFile(item.file.url, item.file.filename, '_blank')"
          >
            <Icon name="pobierz" />
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-else
      class="py-3 px-6 details-attachments__name"
    >
      Brak raportów
    </div>
  </div>
</template>

<script>
import { downloadFile, dateStringFormat } from '../../../utils'
import { formatValue } from '../../../utils/typesEnum'

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadFile,
    formatValue,
    dateStringFormat,
  }
}
</script>

<style lang="scss" scoped>
.details-attachments .details-attachments__row:last-child {
 border-bottom: none;
}
</style>
