<template>
  <v-data-table
    hide-default-footer
    :items="orders"
    :headers="invoiceCoursesHeaders"
    class="details-table"
    :items-per-page="-1"
  >
    <template #item="{item}">
      <tr>
        <td
          v-for="header in invoiceCoursesHeaders"
          :key="header.value"
          :style="`min-width: ${header.minWidth}px; text-align:left;`"
          align="left"
        >
          <template v-if="header.url && item[header.value]">
            <a
              :href="item[header.value]"
              target="blank"
            >
              <img
                class="pa-1"
                :src="item[header.value]"
                alt="sign"
                height="48"
              >
            </a>
          </template>
          <template v-else-if="header.date && item[header.value]">
            {{ dateStringFormat(item[header.value]) }}
          </template>
          <template v-else>
            {{ item[header.value] || '-' }}
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { dateStringFormat } from '../../../utils'

export default {
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    orders() {
      if (this.data.orders) return this.data.orders
      const invoiceItemsOrders = this.data.invoiceItems?.map(item => item.orders)?.flat() || []
      return Array.from(new Set(invoiceItemsOrders.map(obj => obj?.id))).map(id => {
        return invoiceItemsOrders.find(obj => obj?.id === id)
      }) // to ensure unique orders
    },
    invoiceCoursesHeaders() {
      const headers = [
        { text: 'Nr Zlecenia', value: 'refNumber' },
        { text: 'Nr KPO', value: 'pickupCourseBdoNumber' },
        { text: 'Nr kontenera', value: 'containerRefNumber' },
        { text: 'Wielkość kontenera', value: 'containerTypeVolume' },
        { text: 'Data podstawienia', value: 'leaveCourseDueDate', date: true },
        { text: 'Kontener podstawił', value: 'leaveCourseDriverFullName' },
        { text: 'Data zabrania', value: 'pickupCourseDueDate', date: true },
        { text: 'Kontener zabrał', value: 'pickupCourseDriverFullName' },
        { text: 'Lokalizacja', value: 'formattedAddress', minWidth: 200 },
        { text: 'Potwierdzający zabranie', value: 'signatureName' },
        { text: 'Potwierdzenie', value: 'signatureUrl', url: true }
      ]
      const debrisInfoHeaders = [
        { text: 'Kod odpadu', value: 'debrisTypeCode' },
        { text: 'Ilość odpadów', value: 'reportedWeight' }
      ]
      if (this.data?.withDebrisInfo) {
        headers?.splice(4, 0, ...debrisInfoHeaders)
      }
      return headers
    }
  },
  methods: {
    dateStringFormat
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__wrapper {
  max-height: 500px;
  overflow-y: auto;
}
</style>
