<template>
  <v-card
    class="d-flex flex-column"
    width="300"
    elevation="0"
  >
    <v-card-text class="pb-0">
      <v-row>
        <v-col class="py-0">
          <div
            v-for="detail in containerDetails"
            :key="detail.text"
            class="detail-line"
          >
            <span>{{ detail.text }}: </span>
            <b>{{ detail.value || '-' }}</b>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer />
    <v-card-actions class="d-flex justify-space-between">
      <v-btn
        v-if="true"
        text
        color="primary"
        :disabled="container.orderId && !container.orderContainerPickable"
        @click="commitAction('pickup')"
      >
        Zabierz kontener
      </v-btn>
      <v-btn
        v-if="true"
        text
        color="primary"
        :disabled="container.orderId && !container.orderContainerPickable"
        @click="commitAction('swap')"
      >
        Wymień kontener
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { dateStringFormat } from '../../../utils'
import actions from '../../../const/actions'

export default {
  props: {
    container: {
      type: Object,
      required: true
    }
  },
  computed: {
    containerDetails() {
      const {
        refNumber,
        leftAt,
        orderRefNumber,
        clientName,
        addressFormattedAddress,
        debrisTypeDisplayName,
        orderCoordinatesType
      } = this.container

      return [
        { text: 'Numer', value: refNumber },
        { text: 'Typ', value: debrisTypeDisplayName },
        { text: 'Adres', value: addressFormattedAddress },
        { text: 'Nr zlecenia', value: orderRefNumber },
        { text: 'Podstawiono', value: this.dateStringFormat(leftAt) },
        { text: 'Klient', value: clientName },
        { text: 'Współrzędne', value: orderCoordinatesType }
      ]
    }
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog'
    }),
    dateStringFormat,
    commitAction(action) {
      class ContainerOrder {
        constructor(container) {
          this.address = {
            formattedAddress: container.addressFormattedAddress,
            id: container.addressId,
            location: {
              lat: container.addressLocationLat,
              lng: container.addressLocationLng
            },
            phoneNumber: container.addressPhoneNumber
          }
          this.client = {
            blocked: container.clientBlocked,
            id: container.clientId,
            name: container.clientName,
            patronId: container.clientPatronId,
            phoneNumber: container.clientPhoneNumber
          }
          this.containerPickable = container.orderContainerPickable
          this.coordinatesType = container.orderCoordinatesType
          this.debrisType = {
            aggregate: container.debrisTypeAggregate,
            clientDisplayName: container.debrisTypeClientDisplayName,
            code: container.debrisTypeCode,
            displayName: container.debrisTypeDisplayName,
            id: container.debrisTypeId,
            name: container.debrisTypeName
          }
          this.id = container.orderId
          this.origin = container.orderOrigin
          this.refNumber = container.orderRefNumber
        }
      }

      const isSwap = action === 'swap'
      const containerAction = isSwap ? actions.swapContainer : actions.pickupContainer
      const data = {
        ...containerAction,
        idPath: 'data.order.id',
        order: new ContainerOrder(this.container)
      }
      this.setDialog({ data, size: isSwap && 'big' })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-line {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 0;
  width: 100%;
  b {
    text-align: right;
  }
}
.mw-50 {
  max-width: 50%;
}
</style>
