import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":!_vm.text}},'v-btn',attrs,false),on),[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.infoIcon)}})],1)]}}])},[_vm._v(" "+_vm._s(_vm.text)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }