import actions from '../../actions'

export default {
  header: [],
  table: {
    maximumDateRange: 1,
    noDateRange: true,
    isWebSocketHandled: true,
    specialFlag: {
      endpoint: 'bdoCardTemplates',
    },
    sorting: {
      sortBy: 'name',
      sortDesc: false
    },
    filters: [
      { name: 'senderCity', text: 'MPD przekazującego', options: 'senderCities', filterBy: '' },
      { name: 'carrierBusinessPlaceId', text: 'MPD transportującego', options: 'carrierBusinessPlaces', filterBy: '' },
      { name: 'receiverBusinessPlaceId', text: 'MPD przejmującego', options: 'receiverBusinessPlaces', filterBy: '' },
      { name: 'debrisTypeId', text: 'Typ odpadu', options: 'bdoCardTemplateDebrisTypes', filterBy: '' },
    ],
    parameters: [
      { name: 'loading', text: '', value: 'loading', show: true },
      { name: 'name', text: 'Nazwa', value: 'name', show: true },
      { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'debrisType.code', show: true },
      { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName', show: true },
      { name: 'wasteCodeExtended', text: 'Kod ex', value: 'wasteCodeExtended', show: true, sortable: false },
      { name: 'wasteCodeExtendedDescription', text: 'Ex info', value: 'wasteCodeExtendedDescription', show: true },
      { name: 'reportedWeight', text: 'Masa odpadów [T]', value: 'reportedWeight', show: true },
      { name: 'vehicleRegNumber', text: 'Nr rejestracyjny', value: 'vehicleRegNumber', show: true },
      { name: 'senderName', text: 'Przekazujący', value: 'senderBusinessPlace.name', show: true, sortable: false },
      { name: 'receiverName', text: 'Przyjmujący', value: 'receiverBusinessPlace.name', show: true, sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [],
    menu: [
      { ...actions.createBdoCardFromTemplate },
      { ...actions.deleteBdoCardTemplate },
    ]
  },
  details: {
    title: '{{name}}',
    actions: [
      { ...actions.createBdoCardFromTemplate },
      { ...actions.deleteBdoCardTemplate, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'bdoCardTemplates',
        fields: [
          { name: 'name', text: 'Nazwa', value: 'name', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'reportedWeight', text: 'Masa odpadów w tonach', value: 'reportedWeight', type: 'textField', col: 3, editable: true, rules: ['required', 'number', { name: 'greaterThan', arg: 0 }] },
          { name: 'wasteCodeExtended', text: 'Kod ex ', value: 'wasteCodeExtended', type: 'boolean', valueGetter: value => value ? 'Tak' : 'Nie', col: 3, editable: true, rules: ['required'] },
          { name: 'receiverBusinessPlaceId', text: 'MPD przejmującego', value: 'receiverBusinessPlace.name', type: 'selectFromFilters', options: 'receiverBusinessPlaces', itemText: 'name', itemValue: 'id', col: 3, editable: true, rules: ['required'] },
          { name: 'carrierBusinessPlaceId', text: 'MPD transportującego', value: 'carrierBusinessPlace.name', type: 'selectFromFilters', options: 'carrierBusinessPlaces', itemText: 'name', itemValue: 'id', col: 3, editable: true, rules: ['required'] },
          { name: 'senderCity', text: 'MPD przekazującego', value: 'senderBusinessPlace.value', type: 'selectFromFilters', options: 'senderCities', col: 3, itemText: 'value', itemValue: 'key', editable: true, rules: ['required'] },
          { name: 'debrisTypeId', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'selectFromFilters', options: 'bdoCardTemplateDebrisTypes', itemText: 'name', itemValue: 'id', col: 3, editable: true, rules: ['required'] },
          { name: 'vehicleRegNumber', text: 'Numer rejestracyjny pojazdu', value: 'vehicleRegNumber', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'wasteCodeExtendedDescription', text: 'Ex info', value: 'wasteCodeExtendedDescription', type: 'textField', col: 3, editable: true, rules: ['required'] },
        ]
      }
    ]
  }
}
