<template>
  <v-text-field
    class="search-bar"
    ref="searchBar"
    v-model="search"
    :placeholder="getSearchShortkeysPlaceholder()"
    single-line
    outlined
    hide-details
    clearable
    @input="handleSearch"
    @click:clear="handleSearch"
    v-shortkey="searchShortkeys"
    @shortkey.native="focusSearchField"
  >
    <template #prepend-inner>
      <Icon
        name="search"
        size="small"
        class="mr-2"
      />
    </template>
  </v-text-field>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions } from 'vuex'
import { searchShortkeys, getSearchShortkeysPlaceholder } from '../../const/shortKeys'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    search: '',
    searchShortkeys
  }),
  computed: {
    config () {
      return this.$store.getters['tables/getTableConfig'](this.tableName)
    }
  },
  mounted () {
    this.search = this.config.search
  },
  methods: {
    ...mapActions({
      setTableSearch: 'tables/setTableSearch'
    }),
    getSearchShortkeysPlaceholder,
    handleSearch (event) {
      if (event?.key === 'Enter' || event?.key === 'ArrowDown') {
        event.target.blur()
      }
      this.setSearch()
    },
    setSearch: debounce(function () {
      const { setTableSearch, search, tableName } = this
      setTableSearch({ search, tableName })
    }, 700),
    focusSearchField () {
      this.$refs.searchBar.focus()
    },
  }
}
</script>

<style lang="scss" scoped>
.search-bar::v-deep .v-input__append-inner {
  margin-top: 9px !important;
}

.search-bar ::v-deep .v-input__control > .v-input__slot {
  margin-bottom: 0 !important;
}
</style>
