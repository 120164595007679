<template>
  <div class="details-attachments">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="details-attachments__row"
    >
      <div class="d-flex">
        <div class="details-attachments__icon">
          <Icon name="attachment" />
        </div>
        <div class="details-attachments__content">
          <div
            class="details-attachments__name"
            :class="{ 'details-attachments__warning': item.critical }"
          >
            {{ item.filename }}
          </div>
          <div class="details-attachments__meta">
            {{ formatValue(item.size, 'fileSize') }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          class="mx-1"
          icon
          small
          @click="callConfirmDeleteDialog(item)"
        >
          <Icon name="usun" />
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="downloadFile(item.url, item.filename, '_blank')"
        >
          <Icon name="pobierz" />
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="callConfirmSendDialog(item)"
        >
          <v-icon color="#252D3C">
            mdi-email-arrow-right-outline
          </v-icon>
        </v-btn>
        <v-checkbox
          class="pa-0 ma-0 ml-4"
          :value="selectedItems.includes(item.id)"
          hide-details
          default="false"
          @change="(event)=>selectItem(!!event,item)"
        />
      </div>
    </div>
    <ActionButtons
      small
      :actions="actions"
      :item="data"
      class="py-3"
    />
  </div>
</template>

<script>
import ActionButtons from '../../Elements/ActionButtons'
import actions from '../../../const/actions'
import { downloadFile } from '../../../utils'
import { formatValue } from '../../../utils/typesEnum'
import { mapActions } from 'vuex'
import get from 'lodash/get'

export default {
  components: {
    ActionButtons
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMultiple() {
      return !!this.detail.multiple
    },
    actions () {
      return this.isMultiple ? [
        { ...actions.addOrderFile, id: this.data.id },
        { ...actions.generateOrderKwu },
        { ...actions.sendFilesToContacts }
      ] : [
        { ...actions.generateInvoiceKwu },
        { ...actions.sendFilesToContacts }
      ]
    },
    items() {
      if (this.isMultiple) {
        return this.list
      }
      const singleValue = get(this.data, this.detail.value, null)
      const singleArray = singleValue ? [singleValue] : []
      return singleArray
    },
    selectedItems() {
      return this.$store.state[this.detail.name]?.selectedItems || []
    }
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog',
      selectItem (dispatch, selected, item) {
        const action = selected ? 'selectItem' : 'unselectItem'
        return dispatch(`${this.detail.name}/${action}`, item.id)
      },
    }),
    callConfirmSendDialog (file) {
      this.setDialog({
        data: {
          component: 'SendFileToContacts',
          text: 'Wyślij plik',
        },
        item: this.data,
        file,
      })
    },
    downloadFile (url, filename, target) {
      return downloadFile(url, filename, target)
    },
    formatValue,
    callConfirmDeleteDialog (item) {
      const data = { component: 'ConfirmDeleteDialog', text: 'Usuń plik', deleteFileType: this.isMultiple ? 'attachment' : 'kwuFile', tableName: this.isMultiple ? 'orders' : 'invoices' }
      if (!this.isMultiple) data.invoiceId = this.data.id
      this.setDialog({
        data,
        item
      })
    }
  }
}
</script>
