<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <div
        v-for="setting in parsedSettings"
        :key="setting.var"
        class="ml-6 d-flex align-center"
      >
        <v-switch
          :input-value="setting.value"
          hide-details
          @change="setSettingState($event, setting.var)"
        />
        <div class="input-label w-140">
          {{ setting.label }}
        </div>
      </div>
    </Header>
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      :key="tableName"
      ref="table"
      :table-name="tableName"
      @contextMenu="openContextMenu"
      @openDetails="openTableDetails"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          date-range-picker
          show-filters-button
          table-filters
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import api from '../../api/v1'
import { mapState, mapActions } from 'vuex'

const tabs = Object.freeze([
  { value: 'orderTemplates', text: 'Nowe zamówienia', filter: { name: 'transformed', filterBy: false, hide: true } },
  { value: 'transformedTemplates', text: 'Przyjęte zamówienia', filter: { name: 'transformed', filterBy: true, hide: true } }
])
export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar
  },
  channels: {
    ClientAppOrderIndexChannel: { received (data) { this.captureChanges(data) } },
    SettingClientAppOrderChannel: { received (data) { this.changeSettingValue(data.records) } }
  },
  mixins: [
    defaultTableEventsMixin,
    webSocketMixin,
  ],
  data: () => ({
    tableName: 'clientAppOrders',
    tabs,
    configured: false
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      settings: state => state.clientAppOrders.settings,
    }),
    parsedSettings() {
      const labels = {
        create_client_app_order_leave: 'Przyjmowanie dzisiejszych podstawień',
        create_client_app_order_pickup: 'Przyjmowanie  dzisiejszych zabrań',
        create_client_app_order_swap: 'Przyjmowanie dzisiejszych wymian',
      }
      return this.settings.map(setting => ({
        ...setting,
        label: labels[setting.var]
      }))
    }
  },
  mounted () {
    this.subscribeSocket('ClientAppOrderIndexChannel', { departmentId: this.department.id })
    this.subscribeSocket('SettingClientAppOrderChannel')
    this.getClientAppOrdersSettings()
  },
  beforeDestroy () {
    this.unsubscribeSocket('ClientAppOrderIndexChannel')
    this.unsubscribeSocket('SettingClientAppOrderChannel')
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      setTableFiltersStatus: 'tables/setTableFiltersStatus',
      getClientAppOrdersSettings: 'clientAppOrders/getClientAppOrdersSettings',
      changeSettingValue: 'clientAppOrders/changeSettingValue',
      setSingleClientAppOrder: 'clientAppOrder/setSingleClientAppOrder',
    }),
    goTo (tab) {
      this.setTableFilters({ filters: [tab.filter], tableName: this.tableName })
    },
    setSettingState(value, key) {
      api.setClientAppOrdersSettings({ [`${key}`]: value })
    },
    openTableDetails (item) {
      this.setSingleClientAppOrder(item)
      this.$router.push({ name: 'singleClientAppOrder', params: { id: item.id } })
    },
  }
}
</script>

<style lang="scss" scoped>
.w-140 {
  max-width: 140px;
}
</style>
