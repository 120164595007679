
<template>
  <module-wrapper :title="title">
    <template #head>
      <v-btn
        icon
        small
        @click="$emit('input', !value)"
      >
        <v-icon>
          {{ value ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
        </v-icon>
      </v-btn>
    </template>
    <v-expand-transition>
      <v-data-table
        v-if="!value"
        ref="reportTable"
        hide-default-footer
        :headers="headers"
        :items="data"
        class="details-table"
        :items-per-page="150"
        show-expand
        item-key="chargedByName"
        :expanded.sync="expandedItems"
        @click:row="(item, slot) => expandRow(item, slot)"
      >
        <template #[`header.data-table-expand`]>
          <th>
            <v-switch
              :input-value="!!expandedItems.length"
              @change="toggleAllCallEntries"
              hide-details
              dense
              class="ma-0"
              title="Przełącz wszystkie"
            />
          </th>
        </template>
        <template #[`item.data-table-expand`]>
          <td colspan="3">
            <v-btn
              icon
              small
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
        <template #[`item.sumRequiredAmount`]="{item, header}">
          {{ parseValue(item, header) }}
        </template>
        <template #[`item.sumDepositedAmount`]="{item, header}">
          {{ parseValue(item, header) }}
        </template>
        <template #expanded-item="{ headers: expandedHeaders, item }">
          <td :colspan="expandedHeaders.length">
            <v-data-table
              class="expanded-table"
              :headers="expandedRowHeaders"
              :items="item.deposits"
              :items-per-page="-1"
              fixed-header
              hide-default-footer
            >
              <template #item="{item: deposit}">
                <tr>
                  <td
                    v-for="header in expandedRowHeaders"
                    :key="header.value"
                    class="text-start"
                    :style="`max-width: ${header.width}px, width: ${header.width}px`"
                  >
                    {{ parseValue(deposit, header) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
        <template #footer>
          <ReportTableFooter
            :items="data"
            :headers="headers"
          />
        </template>
      </v-data-table>
    </v-expand-transition>
  </module-wrapper>
</template>

<script>
import ModuleWrapper from './../ReportModuleWrapper.vue'
import ReportTableFooter from './ReportTableFooter.vue'
import { formatValue } from '../../../utils/typesEnum'
import get from 'lodash/get'

export default {
  components: {
    ModuleWrapper,
    ReportTableFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    value: { // is table hidden
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      expandedItems: [],
      headers: [
        { text: 'Pobrano przez', value: 'chargedByName' },
        { text: 'Do pobrania', value: 'sumRequiredAmount', type: 'price', summarize: true },
        { text: 'Pobrano', value: 'sumDepositedAmount', type: 'price', summarize: true },
      ],

    }
  },
  computed: {
    expandedRowHeaders() {
      return [
        { text: 'Data', value: 'depositedAt', type: 'date', width: 200 },
        { text: 'Typ kursu', value: 'courseType', width: 150 },
        { text: 'Klient', value: 'clientName', width: 150 },
        { text: 'Zlecenie', value: 'orderRefNumber', width: 150 },
        { text: 'Adres', value: 'address', width: 240 },
        { text: 'Do pobrania', value: 'requiredAmount', type: 'price', width: 100 },
        { text: 'Pobrano', value: 'depositedAmount', type: 'price', width: 100 },
      ]
    },
    tableWrapper() {
      return !this.value ? this.$refs.reportTable?.$el?.querySelector(':scope > .v-data-table__wrapper') : undefined
    }
  },
  watch: {
    maxHeight() {
      this.resizeTable()
    },
    value(hidden) {
      if (!hidden) this.resizeTable()
    }
  },
  methods: {
    expandRow (item, slot) {
      if (item.deposits?.length) slot.expand(!slot.isExpanded)
    },
    toggleAllCallEntries (expand) {
      this.expandedItems = expand ? [...this.data] : []
    },
    parseValue(item, header) {
      return formatValue(get(item, header.value), header.type)
    },
    resizeTable() {
      this.$nextTick(() => {
        this.tableWrapper.style.maxHeight = this.maxHeight + 'px'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__wrapper {
  overflow-y: auto;
}
</style>
