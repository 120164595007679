import Base from './base'

const mutations = {
  SET_ITEMS (state, { items }) {
    state.items = items.map(type => ({ ...type, codeParsed: type.code?.replace(/\s/g, '') }))
  },
}

const debrisTypes = (tableName) => {
  const module = new Base(tableName)
  module.mutations = {
    ...module.mutations,
    ...mutations
  }
  return module
}

export default debrisTypes
