import actions from '../../actions'

export default {
  header: [
    { ...actions.addNewPickup },
    { ...actions.addNewSwap },
    { ...actions.addNewOrder, text: 'Nowe zlecenie', create: true }
  ],
  table: {
    maximumDateRange: 1,
    sorting: {
      sortBy: 'driver.fullName',
      sortDesc: false
    },
    filters: [
      { name: 'done', text: '', filterBy: false, hide: true, checkIfDone: true },
      { name: 'courseStatus', text: 'Status kursu', options: 'courseStatuses', filterBy: '', propPath: 'courseStatus' },
      { name: 'courseType', text: 'Typ kursu', options: 'courseTypes', filterBy: '', propPath: 'courseType' },
      { name: 'containerTypeId', text: 'Typ kontenera', options: 'containerTypes', filterBy: '', propPath: 'order.containerTypeId' },
      { name: 'driverId', text: 'Kierowca', options: 'drivers', filterBy: '', propPath: 'driver.id', root: true },
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'order.client.id' },
      { name: 'paymentType', text: 'Typ płatności', options: 'paymentTypes', filterBy: '', propPath: 'order.payment.paymentType' },
      { name: 'paymentStatus', text: 'Status płatności', options: 'paymentStatuses', filterBy: '', propPath: 'order.payment.paymentStatus' }
    ],
    parameters: [
      { name: 'refNumber', text: 'Nr zlecenia', value: 'course.order.refNumber', multipleValue: 'stops', show: true, sortable: false },
      { name: 'courseStatus', text: 'Status kursu', value: 'course.courseStatus', multipleValue: 'stops', show: true, sortable: false },
      { name: 'courseType', text: 'Typ kursu', value: 'course.courseType', multipleValue: 'stops', show: true, sortable: false },
      { name: 'containerId', text: 'Kontener', value: 'course.order.containerWithType', multipleValue: 'stops', show: true, sortable: false },
      { name: 'bdoNumber', text: 'Nr KPO', value: 'course.order.bdoNumber', multipleValue: 'stops', show: true, sortable: false },
      { name: 'bdoStatus', text: 'Status KPO', value: 'course.order.bdoStatus', multipleValue: 'stops', show: true, sortable: false },
      { name: 'courseId', text: 'Nr kursu', value: 'course.driverDailySequence', multipleValue: 'stops', show: true, sortable: false },
      { name: 'driver', text: 'Kierowca', value: 'driver.fullName', show: true },
      { name: 'address', text: 'Adres zlecenia', value: 'course.order.address.formattedAddress', multipleValue: 'stops', show: true, sortable: false },
      { name: 'client', text: 'Klient', value: 'course.order.client.name', multipleValue: 'stops', show: true, sortable: false },
      { name: 'paymentType', text: 'Typ płatności', value: 'course.order.payment.paymentType', multipleValue: 'stops', show: true, sortable: false },
      { name: 'paymentStatus', text: 'Status płatności', value: 'course.order.payment.paymentStatus', multipleValue: 'stops', show: true, sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.cancelCourse, icon: 'cofnij_wyslanie_kursu', multipleValue: 'stops' },
      { ...actions.sendRouteToDriver, icon: 'przypisz_kierowce' }
    ],
    menu: [
      { ...actions.sendRouteToDriver },
      { ...actions.messageDriver }
    ]
  }
}
