import actions from '../../actions'

export default {
  header: [
    { ...actions.addNewUser, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    parameters: [
      { name: 'id', text: 'Nr użytkownika', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'firstName', text: 'Imię', value: 'firstName', show: true },
      { name: 'lastName', text: 'Nazwisko', value: 'lastName', show: true },
      { name: 'roles', text: 'Role', value: 'roles', concatValues: 'name', show: true },
      { name: 'login', text: 'Login', value: 'login', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteUser, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteUser }
    ]
  },
  details: {
    title: '{{firstName}} {{lastName}}',
    actions: [
      { ...actions.changePassword },
      { ...actions.deleteUser, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'users',
        fields: [
          { name: 'id', text: 'Nr użytkownika', value: 'id', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'firstName', text: 'Imię', value: 'firstName', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'lastName', text: 'Nazwisko', value: 'lastName', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'login', text: 'Login', value: 'login', type: 'textField', col: 3, editable: true, rules: ['required', 'noSpaces'] },
          { name: 'active', text: 'Status użytkownika', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] },
          { name: 'rolesAndPermissions', text: 'Role i dostepy', value: 'roles', type: 'selectUserPermissions', options: 'userRoles', col: 12, editable: true, inputVisibleItems: 3, rules: ['required'], multiple: true, valueGetter: value => value.map(v => v.name).join(', '), inputAttrs: { itemText: 'name', itemValue: 'id' } },
        ]
      }
    ]
  }
}
