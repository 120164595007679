<template>
  <div>
    <div class="detail-section__header py-4 d-flex justify-space-between align-center">
      <span>Karta KPO/KPOK</span>
      <div>
        <v-btn
          v-bind="buttonProps"
          :loading="isProcessing"
          @click="fetchBdoPdfs(data.id)"
        >
          {{ mainPdfUrl || printPdfUrl ? 'Odśwież karty' : 'Pobierz karty' }}
        </v-btn>

        <v-btn
          v-if="data.order || data.course"
          :disabled="!mainPdfUrl"
          v-bind="buttonProps"
          @click="unassign"
        >
          Odepnij kartę
        </v-btn>
        <v-btn
          v-else
          :disabled="!mainPdfUrl"
          v-bind="buttonProps"
          @click="assignToCourse"
        >
          Przypisz do kursu
        </v-btn>
      </div>
    </div>
    <div class="detail-section__content details-pdf">
      <h3 class="pl-4 py-2">
        Widok skrócony
      </h3>
      <embed
        v-if="mainPdfUrl"
        :src="mainPdfUrl"
        type="application/pdf"
        width="100%"
        height="800px"
      >
      <div
        v-else
        class="details-pdf--empty"
      >
        <h3>
          Brak karty KPO/KPOK do wyświetlenia
        </h3>
      </div>
    </div>
    <div class="detail-section__content details-pdf">
      <h3 class="pl-4 py-2">
        Widok rozszerzony
      </h3>
      <embed
        v-if="printPdfUrl"
        :src="printPdfUrl"
        type="application/pdf"
        width="100%"
        height="800px"
      >
      <div
        v-else
        class="details-pdf--empty"
      >
        <h3>
          Brak karty KPO/KPOK do wyświetlenia
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mainPdfUrl: null,
      printPdfUrl: null,
    }
  },
  channels: {
    BdoCardShowChannel: {
      received (message) {
        const { data } = message.records[0]
        this.printPdfUrl = data.bdo_file_print?.url
        this.mainPdfUrl = data.bdo_file?.url
      }
    },
  },
  mounted () {
    this.mainPdfUrl = this.data.bdoFile?.url || null
    this.printPdfUrl = this.data.bdoFilePrint?.url || null
  },
  computed: {
    ...mapState({
      isProcessing: state => state.bdoCard.isProcessing
    }),
    isDefaultPdfView() {
      return ['Zrealizowane przejęcie', 'Potwierdzenie transportu'].includes(this.data.cardStatus)
    },
    buttonProps () {
      return {
        class: 'ml-4',
        outlined: true,
        small: true,
        color: 'primary'
      }
    }
  },
  methods: {
    ...mapActions({
      setDialog: 'layout/setDialog',
      fetchBdoPdfs: 'bdoCard/fetchBdoPdfs',
    }),
    assignToCourse () {
      const data = {
        text: 'Przypisz do kursu',
        component: 'AssignCourseToBdoCard'
      }
      this.setDialog({ data })
    },
    unassign () {
      const data = {
        text: 'Odepnij kartę',
        component: 'UnassignOrderFromBdoCard'
      }
      this.setDialog({ data })
    },
  }
}
</script>
