
export default {
  namespaced: true,
  state: {
    departmentId: null
  },
  mutations: {
    SET_ACTIVE_DEPARTMENT (state, id) {
      state.departmentId = id || null
    }
  },
  actions: {
    setActiveDepartment ({ commit }, id) {
      commit('SET_ACTIVE_DEPARTMENT', id)
    }
  }
}
