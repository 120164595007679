<template>
  <div class="details-history">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="details-history__row mx-3 py-3"
    >
      <div class="details-history__content">
        {{ item.text }}
      </div>
      <div class="details-history__meta mt-2">
        {{ dateStringFormat(item.reportedAt) }} / {{ item.reportingUser.fullName }}
      </div>
      <div
        class="details-history__meta"
        v-if="item.reportingUser"
      >
        Osoba zgłaszająca: {{ item.contactName }}
      </div>
    </div>
    <ActionButtons
      class="ml-n3 my-4"
      v-if="actions.length"
      :actions="actions"
      :item="data"
      small
    />
  </div>
</template>

<script>
import { dateStringFormat } from '../../../utils'
import ActionButtons from '../../Elements/ActionButtons'
import actions from '../../../const/actions'
export default {
  components: {
    ActionButtons
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      actions: [actions.addNewIncidentMessage]
    }
  },
  methods: {
    dateStringFormat
  }
}
</script>
