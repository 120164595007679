<template>
  <div>
    <v-app-bar
      app
      color="white"
      class="header"
    >
      <v-toolbar-title>Wskazówki</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary"
        class="base-hover"
        outlined
        @click="createMode = true"
      >
        Dodaj wskazówkę
      </v-btn>
    </v-app-bar>

    <div class="px-6 py-4 hints-content">
      <v-text-field
        class="search-bar"
        v-model="search"
        placeholder="Szukaj wskazówek"
        :disabled="editMode || createMode"
        outlined
        v-shortkey="searchShortkeys"
        @shortkey.native="focusSearchField"
      >
        <template #prepend-inner>
          <Icon
            name="search"
            size="small"
            class="mr-2"
          />
        </template>
      </v-text-field>
      <v-expansion-panels>
        <SingleHint
          v-if="createMode"
          :hint="{}"
          @close="createMode = false"
        />
        <template v-if="filteredHints.length">
          <SingleHint
            v-for="hint in filteredHints"
            :key="'hint' + hint.id"
            :hint="hint"
            @editMode="editMode = $event"
          />
        </template>
        <v-card
          v-else-if="!createMode"
          class="px-6 py-4 w-100"
        >
          Brak wskazówek
        </v-card>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import SingleHint from '../../components/Elements/SingleHint'
import { searchShortkeys } from '../../const/shortKeys'
import { mapState, mapActions } from 'vuex'

export default {
  components: { SingleHint },
  data: () => ({
    createMode: false,
    editMode: false,
    searchShortkeys,
    search: ''
  }),
  computed: {
    ...mapState({
      hints: state => state.hints.hints
    }),
    filteredHints() {
      return this.hints.filter(hint => {
        const query = this.search?.toLowerCase()
        const textWithoutTags = hint.text?.replace(/( |<([^>]+)>)/gi, '')
        return !!hint.topic?.toLowerCase()?.includes(query) || !!textWithoutTags?.toLowerCase()?.includes(query)
      })
    }
  },
  created() {
    this.getHints()
  },
  methods: {
    ...mapActions({
      getHints: 'hints/getHints'
    })
  }
}
</script>

<style lang="scss" scoped>
.hints-content {
  max-height: calc(100vh - 64px);
  overflow: auto;
}
</style>
