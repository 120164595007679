<template>
  <div class="date-picker d-flex align-center">
    <v-btn-toggle
      v-model="selectedDay"
      @change="changeDate"
      class="date-picker__week-days mx-2"
      active-class="date-picker__day--active"
    >
      <v-btn
        v-for="day in days"
        :key="day.label"
        :value="day.value"
        :disabled="day.value === selectedDay"
        small
        depressed
        hide-details
        class="date-picker__day"
        :class="day.current ? 'date-picker__day--current': ''"
      >
        {{ day.label }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import DateTime from 'luxon/src/datetime'
import { mapActions } from 'vuex'

export default {
  props: {
    tableName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    selectedDay: '',
    days: [
      {
        label: 'Pon',
        filterBy: 'monday',
        value: 1
      },
      {
        label: 'Wt',
        filterBy: 'tuesday',
        value: 2
      },
      {
        label: 'Śr',
        filterBy: 'wednesday',
        value: 3
      },
      {
        label: 'Czw',
        filterBy: 'thursday',
        value: 4
      },
      {
        label: 'Pt',
        filterBy: 'friday',
        value: 5
      },
      {
        label: 'Sob',
        filterBy: 'saturday',
        value: 6
      }
    ]
  }),
  computed: {
    tableDateRange () {
      return this.$store.state[this.tableName].dateRange
    },
  },
  created () {
    // current day of week
    const today = DateTime.local()
    if (this.tableDateRange.length > 1 || DateTime.fromISO(this.tableDateRange[0]).weekNumber !== today.weekNumber) {
      this.selectedDay = today.toISODate()
    } else {
      this.selectedDay = this.tableDateRange[0]
    }
    this.days = this.days.map((item, index) => {
      const selectedDay = DateTime.fromISO(this.selectedDay)
      const diff = index + 1 - selectedDay.weekday
      return {
        ...item,
        value: selectedDay.plus({ day: diff }).toISODate(),
        current: index + 1 - today.weekday === 0
      }
    })
    this.changeDate()
  },
  methods: {
    ...mapActions({
      setTableDate: function (dispatch, payload) {
        return dispatch(`${this.tableName}/setTableDate`, payload)
      },
      setTableFilters: 'tables/setTableFilters'
    }),
    changeDate () {
      const filterBy = this.days.filter((day) => this.selectedDay === day.value)[0].filterBy
      const filters = [{ name: 'day', filterBy }]
      this.setTableDate({ date: [this.selectedDay] })
      this.setTableFilters({ filters, tableName: this.tableName })
    }
  }
}
</script>
