<template>
  <v-card>
    <v-card-title class="px-6 py-4 w-100 d-flex justify-space-between">
      <div class="d-flex align-center">
        <b>{{ title }}</b>
        <div
          v-if="Object.keys(summary).length"
          class="d-flex align-center text-subtitle-1 ml-4"
        >
          <div
            v-for="data in summary"
            :key="data.key"
            class="px-2"
          >
            <span>{{ data.label }}: </span>
            <b>{{ data.value }}</b>
          </div>
        </div>
      </div>
      <div>
        <slot name="head" />
        <v-btn
          icon
          small
          @click="$emit('toggle-fullscreen')"
        >
          <v-icon>
            {{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-divider />
    <div
      class="chart-wrapper"
      :class="[padding]"
      :style="{
        height: isFullscreen ? '800px' : '',
        minHeight: isFullscreen ? 'calc(100vh - 400px)' : ''
      }"
    >
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: ''
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrapper::v-deep > div {
  height: 100%;
}
</style>
