<template>
  <div class="report-table__footer">
    <div>
      {{ positionDeclension }}
    </div>
    <div class="d-flex">
      <div
        v-for="(summary, index) in summaryColumns"
        :key="summary.value"
        :ref="'reportTableSum'+ (index+1)"
        class="report-table__sum"
      >
        {{ summarizeMoneyValues(summary) }}
      </div>
    </div>
  </div>
</template>

<script>
import { declensionName } from '../../../utils'
import { formatValue, formatLargeNumber } from '../../../utils/typesEnum'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    positionDeclension () {
      return declensionName(this.items.length, 'pozycja', 'pozycje', 'pozycji')
    },
    summaryColumns () {
      return this.headers
        .filter(header => header.summarize)
        .map(header => ({
          value: header.value,
          label: header.summaryLabel || header.text
        }))
    },
  },
  watch: {
    items () {
      this.$nextTick(() => {
        this.setMoneyCellsWidth()
      })
    }
  },
  mounted () {
    this.setMoneyCellsWidth()
  },
  methods: {
    summarizeMoneyValues ({ value, label }) {
      const sum = this.items.reduce((acc, item) => acc + item[value], 0)
      const formattedValue = formatValue(sum, 'price')
      return formatLargeNumber(formattedValue) + ' ' + label?.toLowerCase()
    },
    setMoneyCellsWidth () {
      if (this.items.length) {
        for (let i = this.summaryColumns.length; i > 0; i--) {
          const sumCellWidth = this.$refs['reportTableSum' + i][0].clientWidth
          const order = this.summaryColumns.length - i + 1
          const cells = this.$parent.$el.querySelectorAll(`tr td:nth-last-of-type(${order}), tr th:nth-last-of-type(${order})`)
          cells.forEach(function (cell) { cell.style.width = sumCellWidth + 'px' })
        }
      }
    },
  }
}
</script>
