export default function (tableName, method) {
  const messages = {
    default: {
      create: 'Utworzono nową pozycję',
      update: 'Zmieniono pozycję',
      delete: 'Usunięto pozycję'
    },
    clients: {
      create: 'Utworzono nowego klienta',
      update: 'Zmieniono dane klienta',
      delete: 'Usunięto klienta'
    },
    addresses: {
      create: 'Dodano nową lokalizację',
      update: 'Zmieniono lokalizację',
      delete: 'Usunięto lokalizację'
    },
    containers: {
      create: 'Dodano nowy kontener',
      update: 'Zmieniono kontener',
      delete: 'Usunięto kontener'
    },
    departments: {
      create: 'Utworzono nowy oddział',
      update: 'Zaktualizowano oddział',
      delete: 'Usunięto oddział'
    },
    drivers: {
      create: 'Dodano nowego kierowcę',
      update: 'Zmieniono dane kierowcy',
      delete: 'Usunięto kierowcę'
    },
    incidents: {
      create: 'Utworzono nowy incydent',
      update: 'Zmieniono incydent',
      delete: 'Usunięto incydent'
    },
    invoices: {
      create: 'Wygenerowano dokument',
      update: 'Zmieniono dokument',
      delete: 'Usunięto dokument'
    },
    order: {
      create: 'Utworzono nowe zlecenie',
      update: 'Zmieniono zlecenie',
      delete: 'Usunięto zlecenie'
    },
    courses: {
      create: 'Utworzono nowe zabranie',
      update: 'Zaktualizowano kurs',
      delete: 'Usunięto kurs'
    },
    codDeposits: {
      create: 'Utworzono nowe rozliczenie',
      update: 'Zaktualizowano rozliczenie',
      delete: 'Usunięto rozliczenie'
    },
    tasks: {
      create: 'Utworzono nowe zadanie',
      update: 'Zmieniono zadanie',
      delete: 'Usunięto zadanie'
    },
    terminals: {
      create: 'Dodano nowy terminal',
      update: 'Zaktualizowno terminal',
      delete: 'Usunięto terminal'
    },
    users: {
      create: 'Utworzono nowego użytkownika',
      update: 'Zmieniono dane użytkownika',
      delete: 'Usunięto użytkownika'
    },
    vehicles: {
      create: 'Dodano nowy pojazd',
      update: 'Zmieniono dane pojazdu',
      delete: 'Usunięto pojazd'
    },
    debrisPrices: {
      create: 'Utworzono nową cenę odpadu',
      update: 'Zmieniono cenę odpadu',
      delete: 'Usunięto cenę odpadu'
    },
    aggregatePrices: {
      create: 'Utworzono nową cenę kruszywa',
      update: 'Zmieniono cenę kruszywa',
      delete: 'Usunięto cenę kruszywa'
    },
    transportPrices: {
      create: 'Utworzono nową cenę transportu',
      update: 'Zmieniono cenę transportu',
      delete: 'Usunięto cenę transportu'
    },
    routes: {
      create: 'Utworzono nową trasę',
      update: 'Zmieniono trasę',
      delete: 'Usunięto trasę'
    },
    'orders/createSwap': {
      create: 'Utworzono nowy kurs wymiana',
      update: 'Zaktualizowano kurs wymiana',
      delete: 'Usunięto kurs wymiana'
    },
    'courses/map': {
      create: 'Utworzono nową trasę',
      update: 'Zmieniono trasę',
      delete: 'Usunięto trasę'
    }
  }
  return messages[tableName] ? [messages[tableName][method]] : [messages.default[method]]
}
