import Base from './base'
import get from 'lodash/get'
import { getCoursesStatistics } from '../utils'

const routeMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, root, checkIfDone } = filter
    const filterValue = filterBy.value ?? filterBy

    if (checkIfDone) {
      return item.stops.every(stop => stop.course.courseStatus === 'Wykonany') === filterValue
    }

    if (root) { return get(item, propPath, filterValue) === filterValue }
    return item.stops.some(stop => get(stop.course, propPath) === filterValue)
  })
}

const getters = {
  getStatistics: (state) => {
    const countCourses = (stats, routes) => {
      for (const route of routes) {
        for (const stop of route.stops) {
          stats[stop.course?.courseType] && stats[stop.course.courseType].value++
        }
      }
      return stats
    }
    return getCoursesStatistics(state.items, countCourses)
  }
}

const routes = (tableName) => {
  const module = new Base(tableName, routeMatchesFiltersCriteria)
  module.getters = {
    ...module.getters,
    ...getters
  }
  return module
}

export default routes
