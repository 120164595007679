<template>
  <div
    class="details details-route details-preview"
    v-shortkey="shortKeys"
    @shortkey="displayEditModeMessage"
  >
    <DetailsToolbar
      :title="titleToolbar"
      height="80px"
      is-preview
      close-sidebar
      @closeDetails="handleCloseSidebar"
    />
    <div class="px-3 pb-2">
      <v-alert
        v-if="route.routeStatus"
        dense
        text
        :icon="false"
        :type="routeStatusColor"
        class="mx-2"
      >
        {{ route.routeStatus }}
      </v-alert>
      <div class="custom-form">
        <v-row
          class="mt-6"
          align="center"
        >
          <v-col>
            <v-autocomplete
              v-model="receiverData"
              :items="departmentsList"
              :rules="[rules.required]"
              outlined
              label="Oddział"
              item-text="name"
              item-value="name"
              placeholder="Wybierz oddział"
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="driverId"
              outlined
              :items="parsedDrivers"
              label="Kierowca"
              placeholder="Wybierz kierowcę"
              append-icon="mdi-chevron-down"
              :readonly="isNotEditableRoute"
              dense
            >
              <template #item="{item}">
                <div class="w-100 d-flex justify-space-between">
                  {{ item.text }}
                  <span
                    v-if="item.disabled"
                    class="error--text"
                  >({{ item.disabled }})</span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <DateWithTimePicker
              v-if="dueDate"
              :date-with-time="dueDate"
              label="Data realizacji"
              default-time="05:30"
              @changeTime="setDateInRoutePlaning($event)"
              :readonly="isNotEditableRoute"
            />
          </v-col>
        </v-row>
      </div>
      <DetailsSectionStopsRoute
        :is-not-editable-route="isNotEditableRoute"
      />
    </div>
    <v-footer
      absolute
      tile
      color="white"
      width="100%"
      elevation="0"
      class="px-6"
      height="80px"
    >
      <v-spacer />
      <v-btn
        outlined
        color="primary"
        class="px-4"
        @click="resetRoutePlanning"
      >
        Anuluj
      </v-btn>
      <v-btn
        v-if="route.id"
        :disabled="!isEditMode"
        color="primary"
        class="ml-4 px-2 base-hover"
        @click="submitPlannedRoute({tableName, send: false, receiverData})"
      >
        Edytuj trasę
      </v-btn>
      <template v-if="!route.routeStatus && !route.id">
        <v-btn
          dark
          color="primary"
          class="ml-4 px-2"
          @click="submitPlannedRoute({tableName, send: false, receiverData})"
        >
          Stwórz trasę
        </v-btn>
        <v-btn
          color="primary"
          class="ml-4 px-2 base-hover"
          :disabled="!driverId || !receiverData"
          @click="submitPlannedRoute({tableName, send: true, receiverData})"
        >
          Stwórz i wyślij trasę
        </v-btn>
      </template>
      <v-btn
        v-if="route.id"
        :disabled="!receiverData || !route.driverId || ['W trakcie realizacji','Zrealizowana'].includes(route.routeStatus)"
        color="primary"
        class="ml-4 px-2 base-hover"
        @click="sendRouteToDriver({id: route.id, params: { driverId: route.driverId, receiverData } })"
      >
        Wyślij trasę
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DetailsSectionStopsRoute from './Sections/DetailsSectionStopsRoute'
import DateWithTimePicker from '../Forms/Inputs/DateWithTime'
import { navigateTableShortkeys } from '../../const/shortKeys'
import driversLacksMixin from '../../mixins/driversLacksList.vue'
import DetailsToolbar from './Sections/DetailsToolbar'
import rules from '../../utils/validators'

export default {
  components: {
    DetailsSectionStopsRoute,
    DateWithTimePicker,
    DetailsToolbar
  },
  mixins: [driversLacksMixin], // parsedDrivers
  data: () => ({
    shortKeys: {},
    rules,
    receiverData: null,
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      route: state => state.route.entity,
      entityUnchanged: state => state.route.entityUnchanged,
      departmentsList: state => state.departments.items,
    }),
    isEditMode () {
      const { route, entityUnchanged } = this
      return (
        JSON.stringify(route.courseIds) !== JSON.stringify(entityUnchanged.courseIds) ||
        route.driverId !== entityUnchanged.driverId ||
        route.dueDate !== entityUnchanged.dueDate
      )
    },
    tableName () {
      return this.isMapPage ? 'coursesMap' : 'routes'
    },
    isMapPage () {
      return this.$route.name === 'coursesMap'
    },
    isNotEditableRoute () {
      const { routeStatus } = this.route
      return routeStatus === 'Zrealizowana' || routeStatus === 'W trakcie realizacji'
    },
    isRouteToBeSend () {
      const { id, routeStatus, driverId } = this.route
      return !this.isEditMode && id && driverId && routeStatus === 'Do zrealizowania'
    },
    titleToolbar () {
      return this.route.id ? 'Szczegóły trasy' : 'Zaplanuj trasę'
    },
    routeStatusColor () {
      const type = {
        Zrealizowana: 'success',
        'W trakcie realizacji': 'info',
        'Do zrealizowania': 'error'
      }
      return type[this.route.routeStatus]
    },
    driverId: {
      get () {
        return this.route.driverId || null
      },
      set (id) {
        this.setDriverInRoutePlaning(id)
      }
    },
    dueDate: {
      get () {
        return this.route.dueDate || null
      },
      set (dueDate) {
        this.setDateInRoutePlaning(dueDate)
      }
    }
  },
  watch: {
    route: {
      deep: true,
      handler () {
        this.toggleEditMode()
      }
    }
  },
  beforeDestroy () {
    this.resetRoutePlanning()
  },
  methods: {
    ...mapActions({
      setSidebar: 'layout/setSidebar',
      submitPlannedRoute: 'route/submitPlannedRoute',
      sendRouteToDriver: 'route/sendRouteToDriver',
      resetRoutePlanning: 'route/resetRoutePlanning',
      displayEditModeMessage: 'route/displayEditModeMessage',
      setDriverInRoutePlaning: 'route/setDriverInRoutePlaning',
      setDateInRoutePlaning: 'route/setDateInRoutePlaning',
    }),
    handleCloseSidebar () {
      this.isEditMode ? this.displayEditModeMessage() : this.resetRoutePlanning()
    },
    toggleEditMode () {
      if (this.isMapPage) {
        this.setSidebar({ isEditMode: this.isEditMode })
      } else {
        this.setSidebar({ showOverlay: this.isEditMode })
      }
      this.shortKeys = this.isEditMode ? navigateTableShortkeys : {}
    }
  }
}
</script>
