<template>
  <module-wrapper :title="title">
    <v-data-table
      ref="reportTable"
      class="report-table"
      :items="formattedItems"
      :headers="headers"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
      @update:options="updateSortParams"
      @dblclick:row="goToDetails"
    >
      <template #footer>
        <ReportTableFooter
          :items="items"
          :headers="headers"
        />
      </template>
    </v-data-table>
  </module-wrapper>
</template>

<script>
import ModuleWrapper from '../ReportModuleWrapper'
import ReportTableFooter from './ReportTableFooter.vue'
import { mapArray } from '../../../utils/typesEnum'
import { mapActions } from 'vuex'

export default {
  components: {
    ModuleWrapper,
    ReportTableFooter,
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    report: {
      type: String,
      default: ''
    },
    tableName: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedItems () {
      return mapArray(this.items, { formatLargeNumbers: true })
    },
  },
  methods: {
    ...mapActions({
      storeTableOrder: function(dispatch, payload) {
        dispatch(`${this.tableName}/storeTableOrder`, payload)
      }
    }),
    updateSortParams (options) {
      const order = {
        field: options.sortBy[0] || 'sequence',
        direction: options.sortDesc[0] ? 'desc' : 'asc'
      }
      this.storeTableOrder({ report: this.report, order })
    },
    goToDetails (_, { item }) {
      if (item.orderId) this.$router.push({ name: 'singleOrder', params: { id: item.orderId }, query: { nonSwapView: true } })
    }
  }
}
</script>
