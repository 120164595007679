export default {
  namespaced: true,

  state: {
    tabs: {}
  },

  mutations: {
    SAVE_TAB (state, { view, tabValue }) {
      state.tabs[view] = tabValue
    },
  },

  actions: {
    saveTab ({ commit }, { view, tabValue }) {
      commit('SAVE_TAB', { view, tabValue })
    },
  }
}
