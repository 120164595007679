<template>
  <chart-wrapper
    :title="`${mode} kierowców`"
    padding="pa-6"
    class="driver-routes"
    :is-fullscreen="isFullscreen"
    @toggle-fullscreen="$emit('toggle-fullscreen')"
  >
    <template #head>
      <v-btn-toggle
        v-model="mode"
        tile
        color="primary"
        small
        group
      >
        <v-btn
          value="Trasy"
          small
        >
          Trasy
        </v-btn>

        <v-btn
          value="Pętle"
          small
        >
          Pętle
        </v-btn>
      </v-btn-toggle>
    </template>
    <EmptyData v-if="emptyData" />
    <VarticalBarChart
      v-else
      :key="isFullscreen"
      :chart-data="chartData"
      :options="chartOptions"
    />
  </chart-wrapper>
</template>

<script>
import VarticalBarChart from './Canvas/VerticalBarChart'
import EmptyData from './EmptyData'
import { formatValue } from '../../../utils/typesEnum'
import ChartWrapper from '../ReportModuleWrapper'

const chartOptions = {
  maintainAspectRatio: false,
  legend: { display: false },
  scales: {
    yAxes: [{ ticks: { min: 0 } }],
    xAxes: [{ gridLines: { display: false } }]
  }
}

export default {
  components: {
    VarticalBarChart,
    EmptyData,
    ChartWrapper
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isFullscreen: {
      type: Boolean,
      defaul: false
    }
  },
  data () {
    return {
      chartOptions,
      mode: 'Trasy'
    }
  },
  computed: {
    statsData() {
      return this.mode === 'Pętle' ? this.data.dateLoopsCount?.data : this.data.driverRoutesCount?.data
    },
    emptyData () {
      return !this.statsData.map(state => state.value).reduce((acc, val) => acc + val, 0)
    },
    chartData () {
      return {
        labels: this.statsData.map(state => formatValue(state.key, 'date')),
        datasets: [
          {
            label: this.mode,
            data: this.statsData.map(state => state.value),
            backgroundColor: this.mode === 'Pętle' ? '#A8B8F2' : '#2CB56B',
            maxBarThickness: 50
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.driver-routes::v-deep .v-btn-toggle .v-btn {
  border-radius: 6px !important;
}
</style>
