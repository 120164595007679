import api from '../api/v1'
import humps from 'humps'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    isOccupancyDialogOpen: false,
    drivers: []
  },
  mutations: {
    SET_OCCUPANCY_VISIBILITY (state, isOpen) {
      state.isOccupancyDialogOpen = isOpen
    },
    SET_DRIVERS_LIST (state, drivers) {
      state.drivers = drivers
    },
    REMOVE_DRIVER (state, driverId) {
      state.drivers = state.drivers
        .filter(driver => driverId === driver.id)
    },
    ADD_DRIVER (state, driver) {
      state.drivers.push(driver)
    },
    UPDATE_DRIVER (state, driverData) {
      const updatingDriverIndex = state.drivers
        .findIndex(driver => driver.id === driverData.id)
      Vue.set(state.drivers, updatingDriverIndex, driverData)
    }
  },
  actions: {
    setOccupancyVisibility ({ state, rootState, commit, dispatch }, isShown) {
      if (isShown && !state.drivers.length) {
        dispatch('getDriversOccupancy')
        const params = {
          channel: 'DriversSummaryChannel',
          departmentId: rootState.core.department.id,
        }
        this.$cable.subscribe(humps.decamelizeKeys(params))
      }
      commit('SET_OCCUPANCY_VISIBILITY', isShown)
    },
    getDriversOccupancy ({ rootState, commit },) {
      const departmentId = rootState.core.department.id
      api.getDriversOccupancy({ departmentId })
        .then((resp) => {
          commit('SET_DRIVERS_LIST', resp.data)
        })
    },
    updateDriversOccupancy ({ commit }, data) {
      const { records, actionType } = humps.camelizeKeys(data)
      records.forEach(record => {
        if (actionType === 'update') {
          commit('UPDATE_DRIVER', record.data)
        } else if (actionType === 'create') {
          commit('CREATE_DRIVER', record.data)
        } else {
          commit('REMOVE_DRIVER', record.id)
        }
      })
    }
  }
}
