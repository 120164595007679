import { months } from '../utils/typesEnum'

export default {
  availableFields: 'dostępne pola',
  values: 'dane',
  columns: 'kolumny',
  rows: 'rzędy',
  moveFieldsHere: 'Przenieś pola tutaj',
  hideSettings: 'Ukryj ustawienia',
  showSettings: 'Pokaż ustawienia',
  apply: 'Zastosuj',
  day: 'Dzień',
  week: 'Tydzień',
  month: 'Miesiąc',
  quarter: 'Kwartał',
  year: 'Rok',
  min: 'Min',
  max: 'Max',
  sum: 'Suma',
  count: 'Ile',
  equal: 'Równe',
  notEqual: 'Nie równe',
  contains: 'Zawiera',
  notContains: 'Nie zawiera',
  typeHere: 'Wpisz',
  selectAll: 'Wybierz wszystkie',
  unselectAll: 'Wyczyść wybór',
  cancel: 'Anuluj',
  ok: 'Ok',
  total: '',
  date: {
    month_full: months,
    month_short: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    day_full: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    day_short: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
    quarter: ['Q1', 'Q2', 'Q3', 'Q4'],
    week: 'T'
  },
  types: {
    operations: [
      { id: 'sum', label: 'Suma' },
      { id: 'count', label: 'Ile' },
      { id: 'min', label: 'Min' },
      { id: 'max', label: 'Max' }
    ],
    dates: [
      { id: 'dateByDay', label: 'Dzień' },
      { id: 'dateByWeek', label: 'Tydzień' },
      { id: 'dateByMonth', label: 'Miesiąc' },
      { id: 'dateByQuarter', label: 'Kwartał' },
      { id: 'dateByYear', label: 'Rok' }
    ]
  }
}
