import actions from '../../../actions'
import store from '../../../../store'
import api from '../../../../api/v1'

const importBusinessPlaces = () => {
  return api.importBusinessPlaces()
    .then((resp) => {
      store.commit('businessPlaces/SET_ITEMS', { items: resp.data }, { root: true })
      store.dispatch('snackbar/showSnackbar', { message: ['Zaimportowano MPD'] }, { root: true })
    })
}

export default {
  header: [
    { ...actions.importBusinessPlaces, callback: importBusinessPlaces }
  ],
  table: {
    noApi: true,
    specialFlag: {
      external: false
    },
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    parameters: [
      { name: 'name', text: 'Miejsce prowadzenia działalności', value: 'name', show: true },
      { name: 'identificationNumber', text: 'Numer identyfikacyjny', value: 'identificationNumber', show: true },
    ],
    actions: [],
    menu: []
  },
  details: {}
}
