<template>
  <div
    class="switch-wrapper"
    v-show="!sidebar.size"
  >
    <div class="map-switch">
      <v-btn
        class="map-button"
        :class="{ 'map-button__active': isActiveView('coursesMap') }"
        icon
        @click="changeView('coursesMap')"
      >
        <Icon :name="isActiveView('coursesMap') ? 'map_courses_active' : 'map_courses'" />
      </v-btn>
      <v-divider
        vertical
        class="mx-2"
      />
      <v-btn
        class="map-button"
        :class="{ 'map-button__active': isActiveView('containersMap') }"
        icon
        @click="changeView('containersMap')"
      >
        <Icon :name="isActiveView('containersMap') ? 'map_containers_active' : 'map_containers'" />
      </v-btn>
    </div>
    <v-divider />
    <div class="map-switch">
      <v-btn
        class="map-button"
        :class="{ 'map-button__active': isActiveMapType('terrain') }"
        icon
        @click="setMapType('terrain')"
      >
        <v-icon
          :color="isActiveMapType('terrain') ? '#2cb56b' : ''"
          size="x-large"
        >
          mdi-terrain
        </v-icon>
      </v-btn>
      <v-divider
        vertical
        class="mx-2"
      />
      <v-btn
        class="map-button"
        :class="{ 'map-button__active': isActiveMapType('satellite') }"
        icon
        @click="setMapType('satellite')"
      >
        <v-icon
          :color="isActiveMapType('satellite') ? '#2cb56b' : ''"
          size="x-large"
        >
          mdi-satellite
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    viewName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar,
      mapType: state => state.core.mapType
    })
  },
  methods: {
    ...mapActions({
      setMapMode: 'core/setMapMode',
      setMapType: 'core/setMapType'
    }),
    changeView(view) {
      if (this.isActiveView(view)) return
      const navs = {
        containersMap: 'coursesMap',
        coursesMap: 'containersMap'
      }
      this.$router.push({ name: navs[this.viewName] })
      this.setMapMode(navs[this.viewName])
    },
    isActiveView(view) {
      return view === this.viewName
    },
    isActiveMapType(type) {
      return type === this.mapType
    }
  }
}
</script>

<style lang="scss" scoped>
.switch-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 12px;
  top: calc(100% + 12px);
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}

.map-switch {
  background-color: white;
  display: flex;
  padding: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
}
.map-button {
  height: 54px !important;
  width: 54px !important;
  padding: 16px;
  border: none !important;
  &__active {
    background-color: #f1f5f9 !important;
    border: 1px solid #d7e0eb !important;
  }
}
</style>
