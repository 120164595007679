<template>
  <div class="details details--full mt-80px">
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="orderSchedule"
      />
    </DetailsToolbar>
    <div class="px-4">
      <SectionsWrapper
        :data="orderSchedule"
        :details="details"
      >
        <template #default="{ detail, slotData }">
          <component
            :is="detail.component || 'DetailsSection'"
            :detail="getSectionFields(detail)"
            :data="slotData"
            :list="nestedSection(detail.multiple)"
          />
        </template>
      </SectionsWrapper>
    </div>
  </div>
</template>

<script>
import SectionsWrapper from './Sections/SectionsWrapper'
import DetailsSectionCoursesTable from './Sections/DetailsSectionCoursesTable'
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import singleViewMixin from '../../mixins/singleViewMixin'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    SectionsWrapper,
    DetailsSectionCoursesTable,
  },
  mixins: [
    webSocketMixin,
    navigateBackMixin, // prevRoute,
    singleViewMixin, // ActionButtons, DetailsToolbar, DetailsSection, DetailsSectionList, getDetailsActions,
    // getDetailsTitle, isBlocked, details, configured, setDetails, getSectionFields, nestedSection
  ],
  provide () {
    return {
      notWebSocketHandled: true
    }
  },
  data: () => ({
    tableName: 'orderSchedules'
  }),
  channels: {
    OrderScheduleShowChannel: { received (data) { this.captureSingleChange(data) } }
  },
  computed: {
    ...mapState({
      orderSchedule: state => state.orderSchedule.entity, // webSocketMixin purpose
      entity: state => state.orderSchedule.entity // singleViewMixin purpose
    }),
  },
  created () {
    const { id } = this.$route.params
    this.getSingleSchedule(id)
      .then(() => {
        this.setDetails()
      })
    this.subscribeSocket('OrderScheduleShowChannel', { orderScheduleId: id })
  },
  beforeDestroy () {
    this.unsubscribeSocket('OrderScheduleShowChannel')
    this.clearSingleIncident()
  },
  methods: {
    ...mapActions({
      clearSingleIncident: 'orderSchedule/clearSingleIncident',
      getSingleSchedule: 'orderSchedule/getSingleSchedule'
    }),
  }
}
</script>
