<template>
  <chart-wrapper
    title="Liczba kursów"
    padding="pa-6"
    :is-fullscreen="isFullscreen"
    @toggle-fullscreen="$emit('toggle-fullscreen')"
  >
    <EmptyData v-if="emptyData" />
    <DoughnutChart
      v-else
      :key="isFullscreen"
      :chart-data="chartData"
      :options="chartOptions"
    />
  </chart-wrapper>
</template>

<script>
import DoughnutChart from './Canvas/DoughnutChart'
import EmptyData from './EmptyData'
import ChartWrapper from '../ReportModuleWrapper'

const chartOptions = {
  maintainAspectRatio: false,
  legend: { position: 'right' }
}

export default {
  components: {
    DoughnutChart,
    EmptyData,
    ChartWrapper
  },
  props: {
    courseTypes: {
      type: Array,
      required: true
    },
    isFullscreen: {
      type: Boolean,
      defaul: false
    }
  },
  data () {
    return {
      chartOptions
    }
  },
  computed: {
    emptyData () {
      return !this.courseTypes.map(type => type.value).reduce((acc, val) => acc + val, 0)
    },
    chartColors () {
      const dataTypes = this.courseTypes.map(type => type.key)
      const datasets = {
        Podstawienie: '#BADA55',
        Zabranie: '#FFCD56',
        Wymiana: '#FF6384',
        Transport: '#36A2EB'
      }
      return dataTypes.map((type) => datasets[type])
    },
    chartData () {
      return {
        labels: this.courseTypes.map(type => type.key),
        datasets: [
          {
            type: 'doughnut',
            data: this.courseTypes.map(type => type.value),
            backgroundColor: this.chartColors
          }
        ]
      }
    }
  }
}
</script>
