import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapCluster',{attrs:{"clickable":"","animation":2,"image-path":"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m","grid-size":60,"max-zoom":14,"zoom-on-click":true}},[_c('GmapMarker',{attrs:{"position":{ lat: +_vm.base.lat, lng: +_vm.base.lng },"icon":{ url: require('@/assets/icons/map-pins/base.svg') }},on:{"click":function($event){return _vm.toggleWindow($event, _vm.department, 'department')}}}),_vm._l((_vm.controlledContainers),function(container,index){return _c('GmapMarker',{key:index + 'container',attrs:{"position":{
        lat: +container.spreadLocation.lat,
        lng: +container.spreadLocation.lng
      },"icon":_vm.getContainerMarkerIconConfig(container),"label":_vm.getContainerMarkerLabelConfig(container)},on:{"click":function($event){return _vm.toggleWindow($event, container, 'container')}}})})],2),_vm._l((_vm.controlledCourses),function(course,index){return [(_vm.standing(course))?_c('GmapMarker',{key:index + 'course',attrs:{"position":{ lat: +course.spreadLocation.lat, lng: +course.spreadLocation.lng },"icon":{ url: require(("@/assets/icons/map-pins/simple/" + (_vm.getCourseIconName(course)) + ".svg")) },"label":{
        text: course.containerIdleDays && course.containerIdleDays.toString(),
        color: '#F86262',
        fontWeight: 'bold',
        fontSize: '11px',
        className: 'idle-days-counter'
      }},on:{"click":function($event){return _vm.toggleWindow($event, course, 'course')}}}):_c('GmapMarker',{key:index + 'course',attrs:{"position":{ lat: +course.spreadLocation.lat, lng: +course.spreadLocation.lng },"icon":{ url: require(("@/assets/icons/map-pins/simple/" + (_vm.getCourseIconName(course)) + ".svg")) }},on:{"click":function($event){return _vm.toggleWindow($event, course, 'course')}}})]}),_vm._l((_vm.controlledDrivers),function(driver,index){return _c('GmapMarker',{key:index + 'driver',attrs:{"position":{ lat: +driver.lastPosLat, lng: +driver.lastPosLng },"label":{ text: driver.firstName + ' ' + driver.lastName, className: 'mt-16 font-weight-black' },"icon":{ url: require(("@/assets/icons/map-pins/driver_" + (driver.available ? 'available' : 'unavailable') + ".svg")) }},on:{"click":function($event){return _vm.toggleWindow($event, driver, 'driver')}}})}),(_vm.windowData && _vm.showWindow)?_c('MarkerWrapper',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.windowType !== 'driver' ? _vm.toggleWindow : null),expression:"windowType !== 'driver' ? toggleWindow : null"}],key:_vm.windowPosition.x + _vm.windowPosition.y,ref:"markerWindowRef",attrs:{"marker":_vm.windowDataPosition,"z-index":1000002,"offset-top":_vm.windowOffsetTop,"offset-left":_vm.windowOffsetLeft}},[_c('div',{attrs:{"id":"marker-window"}},[(_vm.windowType === 'course')?_c('CourseWindow',{attrs:{"item":_vm.windowData},on:{"hideWindow":_vm.toggleWindow}}):(_vm.windowType === 'container')?_c('ContainerWindow',{attrs:{"container":_vm.windowData},on:{"hideWindow":_vm.toggleWindow}}):(_vm.windowType === 'driver')?_c('DriverWindow',{attrs:{"driver":_vm.windowData},on:{"hideWindow":_vm.toggleWindow}}):_c('BaseWindow',{attrs:{"department":_vm.windowData}}),_c('div',{staticClass:"marker-window__arrow",class:_vm.windowClass})],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }