<template>
  <div class="details details--full mt-80px">
    <DetailsToolbar
      :title="getDetailsTitle"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="invoice"
      />
    </DetailsToolbar>
    <div class="px-4">
      <SectionsWrapper
        :details="details"
        :data="invoice"
      >
        <template #default="{ detail, slotData }">
          <component
            :is="detail.component || 'DetailsSection'"
            :detail="getSectionFields(detail)"
            :data="slotData"
            :list="nestedSection(detail.multiple)"
          />
        </template>
      </SectionsWrapper>
    </div>
  </div>
</template>

<script>
import SectionsWrapper from './Sections/SectionsWrapper'
import DetailsSectionInvoiceItems from './Sections/DetailsSectionInvoiceItems'
import DetailsSectionInvoiceCoursesTable from './Sections/DetailsSectionInvoiceCoursesTable'
import DetailsSectionOrders from './Sections/DetailsSectionOrders'
import DetailsSectionFiles from './Sections/DetailsSectionFiles'
import DetailsSectionOperationHistory from './Sections/DetailsSectionOperationHistory.vue'
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import singleViewMixin from '../../mixins/singleViewMixin'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    SectionsWrapper,
    DetailsSectionInvoiceItems,
    DetailsSectionInvoiceCoursesTable,
    DetailsSectionFiles,
    DetailsSectionOrders,
    DetailsSectionOperationHistory,
  },
  mixins: [
    webSocketMixin,
    navigateBackMixin, // prevRoute,
    singleViewMixin, // ActionButtons, DetailsToolbar, DetailsSection, DetailsSectionList, getDetailsActions,
    // getDetailsTitle, isBlocked, details, configured, setDetails, getSectionFields, nestedSection, activeChannels
  ],
  provide () {
    return {
      notWebSocketHandled: false
    }
  },
  data: () => ({
    tableName: 'invoices',
    isInvoiceFetched: false
  }),
  channels: {
    InvoiceShowChannel: { received (data) { this.captureSingleChange(data) } },
  },
  computed: {
    ...mapState({
      invoice: state => state.invoice.entity, // webSocketMixin purpose
      entity: state => state.invoice.entity, // singleViewMixin purpose
    }),
  },
  mounted () {
    const invoiceId = this.$route.params.id
    this.initInvoice(invoiceId)
  },
  beforeDestroy () {
    this.unsubscribeSocket('InvoiceShowChannel')
    this.clearSingleInvoice()
  },
  beforeRouteUpdate (to, from, next) {
    if (this.activeChannels.includes('InvoiceShowChannel')) this.unsubscribeSocket('InvoiceShowChannel')
    this.initInvoice(to.params.id)
    next()
  },
  methods: {
    ...mapActions({
      getSingleInvoice: 'invoice/getSingleInvoice',
      clearSingleInvoice: 'invoice/clearSingleInvoice',
    }),
    initInvoice (invoiceId) {
      this.setDetails()
      this.getSingleInvoice(invoiceId).then(() => { this.isInvoiceFetched = true })
      this.subscribeSocket('InvoiceShowChannel', { invoiceId })
    },
  }
}
</script>
