<template>
  <div class="details details--full mt-80px">
    <DetailsToolbar
      :title="getDetailsTitle"
      :blocked="isBlocked"
      :table-name="tableName"
      :prev-route="prevRoute"
    >
      <v-spacer />
      <ActionButtons
        :actions="getDetailsActions"
        :item="orderTemplate"
      />
    </DetailsToolbar>
    <div class="px-4">
      <SectionsWrapper
        :details="details"
        :data="orderTemplate"
      >
        <template #default="{ detail, slotData }">
          <component
            :is="detail.component || 'DetailsSection'"
            :detail="getSectionFields(detail)"
            :data="slotData"
            :list="nestedSection(detail.multiple)"
          />
        </template>
      </SectionsWrapper>
    </div>
  </div>
</template>

<script>
import SectionsWrapper from './Sections/SectionsWrapper'
import webSocketMixin from '../../mixins/webSocketMixin'
import navigateBackMixin from '../../mixins/navigateBackMixin'
import singleViewMixin from '../../mixins/singleViewMixin'
import { mapState, mapActions } from 'vuex'

export default {
  mixins: [
    webSocketMixin,
    navigateBackMixin, // prevRoute,
    singleViewMixin, // ActionButtons, DetailsToolbar, DetailsSection, DetailsSectionList, getDetailsActions,
    // getDetailsTitle, isBlocked, details, configured, nestedSection, detailsColumnSize, setDetails, getSectionFields, nestedSection
  ],
  components: {
    SectionsWrapper,
  },
  data: () => ({
    tableName: 'orderTemplates'
  }),
  provide () {
    return {
      notWebSocketHandled: false
    }
  },
  channels: {
    OrderTemplateShowChannel: { received (data) { this.captureSingleChange(data) } }
  },
  computed: {
    ...mapState({
      orderTemplate: state => state.orderTemplate.entity, // webSocketMixin purpose
      entity: state => state.orderTemplate.entity // singleViewMixin purpose
    }),
  },
  mounted () {
    const { id } = this.$route.params
    this.getSingleOrderTemplate(id)
      .then(() => {
        this.setDetails()
      })
    this.subscribeSocket('OrderTemplateShowChannel', { orderTemplateId: id })
  },
  beforeDestroy () {
    this.unsubscribeSocket('OrderTemplateShowChannel')
    this.clearSingleOrderTemplate()
  },
  methods: {
    ...mapActions({
      clearSingleOrderTemplate: 'orderTemplate/clearSingleOrderTemplate',
      getSingleOrderTemplate: 'orderTemplate/getSingleOrderTemplate'
    }),
  }
}
</script>
