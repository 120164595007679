<template>
  <div class="pa-2">
    <template v-if="$isLogisticsApp">
      <GlobalDatePicker :mini="mini" />
      <PhoneNumberSearch />
    </template>

    <v-btn
      class="logout-button"
      :class="{ 'logout-button--mini': mini }"
      text
      :small="mini"
      v-shortkey="logoutShortkeys"
      @shortkey.native="logout"
      @click="logout"
    >
      <Icon
        name="logout"
        :class="{ 'mr-6': !mini }"
      />
      <span v-if="!mini">Wyloguj się</span>
    </v-btn>
  </div>
</template>

<script>
import PhoneNumberSearch from './PhoneNumberSearch.vue'
import GlobalDatePicker from './GlobalDatePicker.vue'
import { logoutShortkeys } from '../../../const/shortKeys'
import { mapActions } from 'vuex'

export default {
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PhoneNumberSearch,
    GlobalDatePicker,
  },
  data: () => ({
    logoutShortkeys,
  }),
  methods: {
    ...mapActions({
      logout: function(dispatch) {
        const module = this.$isLogisticsApp ? 'auth' : 'authClient'
        return dispatch(`${module}/logout`)
      },
    }),
  },
}
</script>
