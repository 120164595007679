<script>
import { getActions, filterInactiveActions, getContextMenu } from '../utils/quickActions'
import { getTableDetails } from '../utils'
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar,
    }),
    availableActions () {
      const actions = getTableDetails(this.tableName).actions
      if (!this.chosenItem) return actions
      const blockerName = (this.sidebar.size === 2 && ['courses', 'coursesWithoutCards'].includes(this.tableName)) ? 'orders' : this.tableName
      return filterInactiveActions(actions, this.chosenItem, blockerName)
    }
  },
  methods: {
    ...mapActions({
      setContextMenu: 'layout/setContextMenu',
      setSingleViewEntity: 'singleView/setSingleViewEntity',
    }),
    openTableDetails (item, singleViewName = 'defaultSingleView') {
      this.setSingleViewEntity({ item, tableName: this.tableName }) // todo remove once singleGets will be ready (from BE)
      this.$router.push({ name: singleViewName, params: { tableName: this.tableName, id: item.id } })
    },
    openContextMenu ({ item, position }) {
      let actions = getContextMenu(this.tableName)
      actions = getActions(actions, item, this.tableName, this.currentTab)
      this.setContextMenu({ actions, item, position })
    }
  }
}
</script>
