<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Table
      ref="table"
      :table-name="tableName"
      @selectRow="setSingleClient"
      @openDetails="openSingleClientView"
      @contextMenu="openContextMenu"
    >
      <template #tableTop>
        <div
          class="filters"
          ref="tableTop"
        >
          <div class="filters__inputs">
            <SearchBar :table-name="tableName" />
            <TableModeFilter
              class="ml-4"
              :table-name="tableName"
              :omit-fetch="omitFetch"
              persistent
            />
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Table from '../../../components/Table/Table'
import SearchBar from '../../../components/Filters/SearchBar'
import TableModeFilter from '../../../components/Filters/TableModeFilter'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../../mixins/webSocketMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    Header,
    Table,
    SearchBar,
    TableModeFilter,
  },
  props: {
    omitFetch: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    defaultTableEventsMixin,
    webSocketMixin
  ],
  data: () => ({
    tableName: 'clients',
  }),
  channels: {
    ClientIndexChannel: { received (data) { this.captureChanges(data) } },
  },
  mounted () {
    this.subscribeSocket('ClientIndexChannel')
  },
  beforeDestroy () {
    this.unsubscribeSocket('ClientIndexChannel')
  },
  methods: {
    ...mapActions({
      setSingleClient: 'client/setSingleClient',
    }),
    openSingleClientView (params) {
      this.$router.push({ name: 'singleClient', params: { id: params.id } })
    },
  }
}
</script>
