import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.drivers.length)?_c(VMenu,{attrs:{"bottom":"","offset-y":"","elevation":"0","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"relative"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.driversNames)+" "),(_vm.otherDriversCount)?_c('div',{staticClass:"alerts__drivers--number"},[_vm._v(" +"+_vm._s(_vm.otherDriversCount)+" ")]):_vm._e()])]}}],null,false,4211060247)},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,{staticClass:"pt-3 pb-0 px-3"},_vm._l((_vm.drivers),function(driver){return _c('div',{key:driver.id,staticClass:"alerts__drivers--more d-flex pb-2"},[_c('Icon',{staticClass:"mr-2 mt-1",attrs:{"name":'alert_'+ _vm.iconName(driver.deliveryStatus),"size":"small"}}),_c('div',[_vm._v(" "+_vm._s(driver.name)+" ")])],1)}),0)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }