import api from '../api/v1'
import debounce from 'lodash/debounce'
import { Order } from './../models'

export default {
  namespaced: true,
  state: {
    entity: new Order(),
    swapOrder: new Order(),
    isProcessing: false
  },
  mutations: {
    SET_SINGLE_ORDER (state, order) {
      state.entity = order
    },
    SET_SWAP_ORDER (state, order) {
      state.swapOrder = order
    },
    SET_PROCESSING (state, processing) {
      state.isProcessing = processing
    }
  },
  actions: {
    setSingleOrder ({ commit }, order) {
      commit('SET_SINGLE_ORDER', order)
    },
    setSingleOrderFromWS ({ commit, state }, order) {
      const displaySwapScreen = state.entity.displaySwapScreen // display swap screen should be preserved, becouse WS doesn't know if user is on swap view
      commit('SET_SINGLE_ORDER', { ...order, displaySwapScreen })
    },
    setSwapOrder ({ commit }, order) {
      commit('SET_SWAP_ORDER', order)
    },
    clearSwapOrder ({ commit }) {
      commit('SET_SWAP_ORDER', new Order())
    },
    clearOrder({ commit }) {
      commit('SET_SINGLE_ORDER', new Order())
    },
    getSingleOrder: debounce(({ commit }, { id, nonSwapView = false }) => {
      return api.getOrder(id, nonSwapView)
        .then((res) => {
          const order = new Order(res.data)
          commit('SET_SINGLE_ORDER', order)
        })
    }, 1000, { leading: true, trailing: false }),
    getSwapOrder: debounce(({ commit }, id) => {
      return api.getOrder(id)
        .then((res) => {
          const order = new Order(res.data)
          commit('SET_SWAP_ORDER', order)
        })
    }, 1000, { leading: true, trailing: false }),
    clearSingleOrder ({ commit }) {
      commit('SET_SINGLE_ORDER', new Order())
      commit('SET_SWAP_ORDER', new Order())
    },
    uploadOrderFile ({ commit, rootState }, formData) {
      commit('SET_PROCESSING', true)
      const { id } = rootState.layout.dialog.data
      return api.uploadOrderFiles(id, formData)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    uploadBDOFile ({ commit, rootState }, formData) {
      commit('SET_PROCESSING', true)
      const { id } = rootState.layout.dialog.data
      return api.uploadBDOFile(id, formData)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    deleteOrderFile ({ commit }, id) {
      commit('SET_PROCESSING', true)
      api.deleteOrderFiles(id)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    deleteBDOFile ({ commit }, { fileId, orderId }) {
      commit('SET_PROCESSING', true)
      api.deleteBDOFile(orderId, fileId)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    sendFiles({ commit, dispatch }, { ids, contactEmails }) {
      commit('SET_PROCESSING', true)
      return api.sendFiles({ ids, contactEmails })
        .then((resp) => {
          const message = [resp.data.success]
          dispatch('snackbar/showSnackbar', { message }, { root: true })
          dispatch('layout/closeDialog', null, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    generateKwu ({ commit, dispatch }, { id, contactName }) {
      commit('SET_PROCESSING', true)
      return api.generateOrderKwu(id, { contactName })
        .then((resp) => {
          const response = resp.data?.success || resp.data?.errors
          const messageType = resp.data?.errors ? 'error' : 'success'
          dispatch('snackbar/showSnackbar', {
            message: messageType === 'error' ? response : ['Wygenerowano KWU'],
            type: messageType
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    assignContainer ({ commit, dispatch }, { id, params }) {
      commit('SET_PROCESSING', true)
      api.updateItem({ id, params, tableName: 'orders' })
        .then(() => {
          dispatch('layout/closeDialog', null, { root: true })
          dispatch('snackbar/showSnackbar', {
            message: ['Przypisano kontener'],
            type: 'success'
          }, { root: true })
        })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    deleteOrderInvoiceStatus({ commit }, { item }) {
      const { id } = item
      commit('SET_PROCESSING', true)
      return api.deleteInvoiceStatus(id)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    }
  }
}
