
export default {
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    parameters: [
      { name: 'id', text: 'Id', value: 'id', show: true },
      { name: 'name', text: 'Nazwa', value: 'name', show: true },
    ],
  },
  details: {
    title: '{{name}}',
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'roles',
        fields: [
          { name: 'name', text: 'Nazwa', value: 'name', type: 'textField', col: 3, rules: ['required'] },
          { name: 'permissionIds', text: 'Dostęp do widoków i ustawień', value: 'defaultPermissions', type: 'selectRolePermissions', col: 9, editable: true, valueGetter: (permissions) => permissions.length ? permissions.map(permission => permission.displayName).join(', ') : '-' },
        ]
      }
    ]
  }
}
