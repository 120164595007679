import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import Login from '../views/Login'
import Alerts from '../views/Logistics/Alerts'
import BDOCards from '../views/Logistics/BDOCards'
import ClientAppOrders from '../views/Logistics/ClientAppOrders'
import Clients from '../views/Logistics/Administration/Clients'
import CodDeposits from '../views/Logistics/CodDeposits'
import Containers from '../views/Logistics/Containers/Containers'
import ContainersHistory from '../views/Logistics/Containers/ContainersHistory'
import ContainersMap from '../views/Logistics/ContainersMap'
import ContainerTypes from '../views/Logistics/Containers/ContainerTypes'
import Courses from '../views/Logistics/Courses'
import CoursesMap from '../views/Logistics/CoursesMap'
import FormView from '../views/Logistics/FormView'
import Hints from '../views/Logistics/Hints'
import CallEntries from '../views/Logistics/CallEntries'
import Invoices from '../views/Logistics/Invoices'
import InvoiceItemTemplates from '../views/Logistics/Administration/InvoiceItemTemplates'
import Orders from '../views/Logistics/Orders'
import OrderTemplates from '../views/Logistics/OrderTemplates'
import Pricing from '../views/Logistics/Administration/Pricing'
import RevenueReport from '../views/Logistics/RevenueReport'
import Reports from '../views/Logistics/Reports'
import ClientsReports from '../views/Logistics/ClientsReports'
import SingleBdoCard from '../components/Single/SingleBdoCard'
import SingleBdoCardTemplate from '../components/Single/SingleBdoCardTemplate'
import SingleClient from '../components/Single/SingleClientView'
import SingleInvoice from '../components/Single/SingleInvoice'
import SingleOrder from '../components/Single/SingleViewOrder'
import SingleOrderTemplate from '../components/Single/SingleOrderTemplate'
import SingleClientAppOrder from '../components/Single/SingleClientAppOrder'
import SingleOrderSchedule from '../components/Single/SingleOrderSchedule'
import SingleView from '../components/Single/SingleView'
import Tasks from '../views/Logistics/Tasks'
import Users from '../views/Logistics/Administration/Users'
import Warehouse from '../views/Logistics/Administration/Warehouse'
import Pivot from '../views/Logistics/Pivot'
import Roles from '../views/Logistics/Administration/Roles.vue'
import Settings from '../views/Logistics/Settings.vue'

Vue.use(Router)

const router = new Router({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { public: true }
    },
    {
      path: '/alerts',
      name: 'alerts',
      component: Alerts,
      meta: { title: 'Komunikaty', requiredPermissions: ['view-alerts'] }
    },
    {
      path: '/callEntries',
      name: 'callEntries',
      component: CallEntries,
      meta: { title: 'Centrala', requiredPermissions: ['view-call-entries'] }
    },
    {
      path: '/',
      name: 'courses',
      component: Courses,
      meta: { title: 'Kursy', requiredPermissions: ['view-courses'] }
    },
    {
      path: '/courses-map',
      name: 'coursesMap',
      component: CoursesMap,
      meta: { title: 'Mapa kursów', requiredPermissions: ['view-map'] }
    },
    {
      path: '/containers-map',
      name: 'containersMap',
      component: ContainersMap,
      meta: { title: 'Mapa kontenerów', requiredPermissions: ['view-map'] }
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients,
      props: true,
      meta: { title: 'Lista klientów', requiredPermissions: ['view-clients'] }
    },
    {
      path: '/bdo',
      name: 'bdoCards',
      component: BDOCards,
      meta: { title: 'Karty BDO', requiredPermissions: ['view-bdo-cards'] }
    },
    {
      path: '/bdo/:id',
      name: 'singleBdo',
      component: SingleBdoCard,
      meta: { requiredPermissions: ['view-bdo-cards'] }
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: Tasks,
      meta: { title: 'Zadania', requiredPermissions: ['view-tasks'] }
    },
    {
      path: '/orders',
      name: 'orders',
      component: Orders,
      meta: { title: 'Zlecenia', requiredPermissions: ['view-orders'] }
    },
    {
      path: '/orderSchedules/:id',
      name: 'singleOrderSchedule',
      component: SingleOrderSchedule,
      meta: {
        parentName: 'orders',
        requiredPermissions: ['view-orders']
      }
    },
    {
      path: '/order-templates',
      name: 'orderTemplates',
      component: OrderTemplates,
      meta: { title: 'Zamówienia ze sklepu', requiredPermissions: ['view-order-templates'] }
    },
    {
      path: '/client-app-orders',
      name: 'clientAppOrders',
      component: ClientAppOrders,
      meta: { title: 'Zamówienia z aplikacji klienckiej', requiredPermissions: ['view-client-app-order'] }
    },
    {
      path: '/orders/:id',
      name: 'singleOrder',
      component: SingleOrder,
      meta: {
        title: 'Panel klienta',
        parentName: 'orders',
        requiredPermissions: ['view-orders', 'view-courses']
      }
    },
    {
      path: '/invoices/:id',
      name: 'singleInvoice',
      component: SingleInvoice,
      meta: {
        parentName: 'invoices',
        requiredPermissions: ['view-invoices']
      }
    },
    {
      path: '/clientAppOrders/:id',
      name: 'singleClientAppOrder',
      component: SingleClientAppOrder,
      meta: {
        parentName: 'clientAppOrders',
        requiredPermissions: ['view-client-app-order']
      }
    },
    {
      path: '/orderTemplates/:id',
      name: 'singleOrderTemplate',
      component: SingleOrderTemplate,
      meta: {
        parentName: 'orderTemplates',
        requiredPermissions: ['view-order-templates']
      }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: Invoices,
      meta: { title: 'Faktury', requiredPermissions: ['view-invoices'] }
    },
    {
      path: '/invoice-item-templates',
      name: 'invoiceItemTemplates',
      component: InvoiceItemTemplates,
      meta: { title: 'Usługi', requiredPermissions: ['view-invoice-item-templates'] }
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles,
      meta: { title: 'Role', requiredPermissions: ['view-roles'] }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { title: 'Ustawienia', requiredPermissions: ['view-settings'] }
    },
    {
      path: '/cod-deposits',
      name: 'codDeposits',
      component: CodDeposits,
      meta: { title: 'Rozliczenia', requiredPermissions: ['view-cod-deposits'] }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      meta: { title: 'Raporty zleceń', requiredPermissions: ['view-reports-orders'] }
    },
    {
      path: '/revenue-report',
      name: 'revenueReport',
      component: RevenueReport,
      meta: { title: 'Utarg dzienny', requiredPermissions: ['view-revenue'] }
    },
    {
      path: '/clients-reports',
      name: 'clientsReports',
      component: ClientsReports,
      meta: { title: 'Raporty klientów', requiredPermissions: ['view-reports-client-analysis'] }
    },
    {
      path: '/custom-report',
      name: 'customReport',
      component: Pivot,
      meta: { title: 'Własny raport', requiredPermissions: ['view-reports-order-details'] }
    },
    {
      path: '/clients/:id',
      name: 'singleClient',
      component: SingleClient,
      meta: {
        title: 'Panel klienta',
        parentName: 'clients',
        requiredPermissions: ['view-clients']
      }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: { title: 'Lista użytkowników', requiredPermissions: ['view-users'] }
    },
    {
      path: '/warehouse',
      name: 'warehouse',
      component: Warehouse,
      meta: { title: 'Magazyn', requiredPermissions: ['view-warehouse'] }
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
      meta: { title: 'Cennik', requiredPermissions: ['view-prices'] }
    },
    {
      path: '/containers-history',
      name: 'containersHistory',
      component: ContainersHistory,
      meta: { title: 'Historia kontenerów', requiredPermissions: ['view-containers-entries'] }
    },
    {
      path: '/containers',
      name: 'containers',
      component: Containers,
      meta: { title: 'Kontenery', requiredPermissions: ['view-containers'] }
    },
    {
      path: '/container-types',
      name: 'containerTypes',
      component: ContainerTypes,
      meta: { title: 'Typy kontenerów', requiredPermissions: ['view-containers-types'] }
    },
    {
      path: '/hints',
      name: 'hints',
      component: Hints
    },
    {
      path: '/form/:form/:title',
      name: 'form',
      component: FormView
    },
    {
      path: '/bdo/:tableName/:id',
      name: 'singleBdoTemplate',
      component: SingleBdoCardTemplate
    },
    {
      path: '/:tableName/:id',
      name: 'defaultSingleView',
      component: SingleView
    }
  ]
})

router.beforeEach((to, from, next) => {
  // filter permsissions relative to view access
  const userViewPermissions = store.getters['auth/getUserPermissions'].filter(permission => permission.name.includes('view-')).map(permission => permission.name)
  // check if user has permission to view route
  const isPermitted = (requiredPermissions) => requiredPermissions?.some((permission) => (permission && userViewPermissions.includes(permission))) || false

  const getAnyPermittedView = () => router.options.routes.find(route => isPermitted(route.meta.requiredPermissions))

  const checkPermissionAndGoNext = (toRoute, { forceRoute } = { forceRoute: false }) => {
    const requiredPermissions = toRoute.meta.requiredPermissions

    if (!isPermitted(requiredPermissions) || !requiredPermissions) {
      if (from.name !== 'login' && to.name !== 'login') {
        store.dispatch('snackbar/showSnackbar', {
          message: [`Nie masz uprawnień do widoku ${to.meta.title || ''}`],
          type: 'error'
        })
      }

      if (!from.name || from.name === 'login') {
        const permittedView = getAnyPermittedView()
        if (permittedView) next({ name: permittedView.name })
      } else {
        next({ name: from.name })
      }
    } else {
      if (forceRoute) {
        next({ name: toRoute.name })
      } else {
        next()
      }
    }
  }

  store.dispatch('layout/closeDialog')
  if (store.getters['auth/isLoggedIn']) {
    if (to.name === 'login') {
      const toRoute = from || router.options.routes.find(route => route.name === 'courses')
      checkPermissionAndGoNext(toRoute, { forceRoute: true })
    } else if (to.meta.requiredPermissions && to.meta.requiredPermissions.length) {
      checkPermissionAndGoNext(to)
    } else {
      next()
    }
  } else if (to.meta.public) {
    next()
  } else {
    const destination = to.meta?.parentName || to.name
    localStorage.setItem('EL-Logout-Route', destination)
    next({ name: 'login' })
  }
})

export default router
