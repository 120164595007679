<template>
  <chart-wrapper
    :title="`${mode} kierowców`"
    :summary="summaryData[mode] || {}"
    padding="pa-6"
    class="driver-courses"
    :is-fullscreen="isFullscreen"
    @toggle-fullscreen="$emit('toggle-fullscreen')"
  >
    <template #head>
      <v-btn-toggle
        v-model="mode"
        tile
        color="primary"
        small
        group
        mandatory
      >
        <v-btn
          value="Kursy"
          small
        >
          Trasy
        </v-btn>

        <v-btn
          value="Pętle"
          small
        >
          Pętle
        </v-btn>
      </v-btn-toggle>
    </template>
    <EmptyData v-if="emptyData" />
    <HorizontalBarChart
      v-else
      :key="isFullscreen"
      :chart-data="chartData"
      :options="chartOptions"
    />
  </chart-wrapper>
</template>

<script>
import HorizontalBarChart from './Canvas/HorizontalBarChart'
import EmptyData from './EmptyData'
import ChartWrapper from '../ReportModuleWrapper'

const chartOptions = {
  maintainAspectRatio: false,
  legend: { position: 'bottom' },
  indexAxis: 'y',
  scales: {
    xAxes: [{
      ticks: { min: 0 },
      stacked: true
    }],
    yAxes: [{
      gridLines: { display: false },
      stacked: true
    }]
  }
}

const coursesDataset = [
  { courseType: 'Podstawienie', backgroundColor: '#BADA55' },
  { courseType: 'Zabranie', backgroundColor: '#FFCD56' },
  { courseType: 'Wymiana', backgroundColor: '#FF6384' },
  { courseType: 'Transport', backgroundColor: '#36A2EB' }
]
const loopDataset = [
  { courseType: 'Pętla', backgroundColor: '#A8B8F2' },
]

export default {
  components: {
    HorizontalBarChart,
    EmptyData,
    ChartWrapper
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isFullscreen: {
      type: Boolean,
      defaul: false
    }
  },
  data () {
    return {
      chartOptions,
      mode: 'Kursy'
    }
  },
  computed: {
    statsData() {
      const filterCondition = (val) => {
        return this.mode === 'Pętle' ? val === 'Pętla' : val !== 'Pętla'
      }
      return this.data.driverCourseTypesCount?.data.map((type) => ({ driver: type.driver, data: type.data.filter((d) => filterCondition(d.key)) }))
    },
    dataset() {
      return this.mode === 'Pętle' ? loopDataset : coursesDataset
    },
    emptyData () {
      return !this.statsData
        .map(course => course.data?.map(type => type.value))
        .flat()
        .reduce((acc, val) => acc + val, 0)
    },
    chartData () {
      const countValues = dataset => dataset.reduce((acc, data) => acc + data.value, 0)
      return {
        labels: this.statsData.map(course => course.driver + ' - ' + countValues(course.data)),
        datasets: this.dataset.map(set => this.getDataset(set))
      }
    },
    summaryData() {
      return {
        Pętle: this.data.totalDriverCourseTypesCount.data.filter((d) => d.key !== 'totalLoopCount'),
      }
    }
  },
  methods: {
    getDataset ({ courseType, backgroundColor }) {
      const set = this.statsData.map(course => {
        return course.data.find(type => type.key === courseType) || { key: courseType, value: 0 }
      })
      return {
        label: courseType,
        data: set ? set.map(s => s.value) : [],
        backgroundColor,
        maxBarThickness: 20
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.driver-courses::v-deep .v-btn-toggle .v-btn {
  border-radius: 6px !important;
}
</style>
