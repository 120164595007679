export default {
  table: {
    specialFlag: {
      endpoint: 'drivers/current_positions'
    },
    filters: [
      {
        name: 'available',
        text: 'Kierowcy',
        filterBy: [{ value: false, name: 'W trakcie trasy' }, { value: true, name: 'Dostępni' }],
        required: true,
        default: [false, true],
        propPath: 'available',
        options: 'driverStatuses',
        multiple: true
      }
    ]
  }
}
