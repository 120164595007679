import isLogisticsApp from './isLogisticsApp'

const clientMenuItems = [
  {
    text: 'Zamówienia',
    link: '/',
    icon: 'zlecenia'
  }
]

const logisticsMenuItems = [
  {
    text: 'Komunikaty',
    viewPermission: 'view-alerts',
    link: '/alerts',
    icon: 'komunikaty'
  },
  {
    text: 'Centrala',
    viewPermission: 'view-call-entries',
    link: '/callEntries',
    icon: 'incydenty',
  },
  {
    text: 'Kursy',
    viewPermission: 'view-courses',
    link: '/',
    icon: 'kursy'
  },
  {
    text: 'Klienci',
    viewPermission: 'view-clients',
    link: '/clients',
    icon: 'klienci',
    nested: [{ name: 'singleClient' }]
  },
  {
    text: 'Mapa',
    viewPermission: 'view-map',
    name: 'coursesMap',
    link: '/courses-map',
    icon: 'mapa'
  },
  {
    text: 'Mapa',
    name: 'containersMap',
    viewPermission: 'view-map',
    link: '/containers-map',
    icon: 'mapa'
  },
  {
    text: 'Karty BDO',
    viewPermission: 'view-bdo-cards',
    link: '/bdo',
    icon: 'karty_bdo',
    nested: [{ name: 'singleBdo' }]
  },
  {
    text: 'Zadania',
    viewPermission: 'view-tasks',
    link: '/tasks',
    icon: 'zadania',
    notificationsEnabled: true
  },
  {
    text: 'Zlecenia',
    viewPermission: 'view-orders',
    link: '/orders',
    icon: 'zlecenia',
    nested: [{ name: 'singleOrder' }]
  },
  {
    text: 'Zamówienia',
    link: '',
    icon: 'zamowienia',
    children: [
      {
        text: 'Sklep',
        viewPermission: 'view-order-templates',
        link: '/order-templates',
        nested: [{ name: 'singleOrderTemplate' }]
      },
      {
        text: 'Aplikacja kliencka',
        viewPermission: 'view-client-app-order',
        link: '/client-app-orders',
        nested: [{ name: 'singleClientAppOrder' }]
      }
    ]
  },
  {
    text: 'Faktury',
    viewPermission: 'view-invoices',
    link: '/invoices',
    icon: 'faktury',
    nested: [{ name: 'singleInvoice' }]
  },
  {
    text: 'Rozliczenia',
    link: '',
    icon: 'rozliczenia',
    children: [
      {
        text: 'Rozliczenia',
        viewPermission: 'view-cod-deposits',
        link: '/cod-deposits',
        nested: [{ name: 'codDeposits' }]
      },
      {
        text: 'Utarg dzienny',
        viewPermission: 'view-revenue',
        link: '/revenue-report',
        nested: [{ name: 'revenueRepoer' }]
      }
    ]
  },
  {
    text: 'Raporty',
    link: '/reports',
    icon: 'raporty',
    children: [
      {
        text: 'Raporty zleceń',
        viewPermission: 'view-reports-orders',
        link: '/reports',
        nested: [{ name: 'reports' }]
      },
      {
        text: 'Raporty klientów',
        viewPermission: 'view-reports-client-analysis',
        link: '/clients-reports',
        nested: [{ name: 'clientsReports' }]
      },
      {
        text: 'Zlecenia szczegółowo',
        viewPermission: 'view-reports-order-details',
        link: '/custom-report',
        nested: [{ name: 'customReport' }]
      }
    ]
  },
  {
    text: 'Kontenery',
    link: '',
    icon: 'kontenery',
    children: [
      {
        text: 'Kontenery',
        viewPermission: 'view-containers',
        link: '/containers',
        nested: [{ name: 'containers' }, { name: 'defaultSingleView', tableName: 'containers' }]
      },
      {
        text: 'Typy kontenerów',
        viewPermission: 'view-containers-types',
        link: '/container-types',
        nested: [{ name: 'containerTypes' }, { name: 'defaultSingleView', tableName: 'containerTypes' }]
      },
      {
        text: 'Historia kontenerów',
        viewPermission: 'view-containers-entries',
        link: '/containers-history',
        nested: [{ name: 'containersHistory' }]
      }
    ]
  },
  {
    text: 'Administracja',
    link: '',
    icon: 'administracja',
    children: [
      {
        text: 'Użytkownicy',
        viewPermission: 'view-users',
        link: '/users',
        nested: [{ name: 'defaultSingleView', tableName: 'users' }]
      },
      {
        text: 'Usługi',
        viewPermission: 'view-invoice-item-templates',
        link: '/invoice-item-templates',
        nested: [{ name: 'defaultSingleView', tableName: 'invoiceItemTemplates' }]
      },
      {
        text: 'Magazyn',
        viewPermission: 'view-warehouse',
        link: '/warehouse',
        nested: [
          { name: 'warehouse' },
          { name: 'defaultSingleView', tableName: 'departments' },
          { name: 'defaultSingleView', tableName: 'drivers' },
          { name: 'defaultSingleView', tableName: 'vehicles' },
          { name: 'defaultSingleView', tableName: 'terminals' },
          { name: 'defaultSingleView', tableName: 'debrisTypes' },
          { name: 'defaultSingleView', tableName: 'aggregateTypes' },
          { name: 'defaultSingleView', tableName: 'communes' }
        ]
      },
      {
        text: 'Cennik',
        viewPermission: 'view-prices',
        link: '/pricing',
        nested: [{ name: 'pricing' }]
      },
      {
        text: 'Role',
        viewPermission: 'view-roles',
        link: '/roles',
        nested: [{ name: 'defaultSingleView', tableName: 'roles' }]
      },
      {
        text: 'Ustawienia',
        viewPermission: 'view-settings',
        link: '/settings',
      },
    ]
  }
]

export default isLogisticsApp ? logisticsMenuItems : clientMenuItems
