<template>
  <v-form
    ref="searchClient"
    @submit.prevent="searchForClients"
  >
    <v-text-field
      background-color="#2E3849"
      class="logout-section-input"
      v-model="phoneNumber"
      :rules="[rules.phoneNumber]"
      v-mask="getFieldMask('phoneNumber')"
      placeholder="Wpisz numer telefonu"
      hide-details
    />
  </v-form>
</template>

<script>
import { getFieldMask } from '../../../utils'
import rules from '../../../utils/validators'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    phoneNumber: null,
    rules,
  }),
  methods: {
    ...mapActions({
      searchForClients: function (dispatch) {
        if (this.$refs.searchClient.validate()) {
          return dispatch('clientSearchDialog/searchForClients', this.phoneNumber)
        }
      }
    }),
    getFieldMask,
  }
}
</script>
