<template>
  <div>
    <div
      v-if="value === true"
      class="flex"
    >
      <v-icon
        size="20"
        color="red"
        class="mr-2"
      >
        mdi-alert
      </v-icon>
      <span style="color: red">Wygeneruj ponownie</span>
    </div>
    <div v-else>
      <v-icon
        size="20"
        color="green"
        class="mr-2"
      >
        mdi-check-circle
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String],
      default: false
    }
  }
}
</script>
