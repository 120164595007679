import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import Login from '../views/Login'
import SetPassword from '../views/Client/SetPassword'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { public: true },
    },
    {
      path: '/set-password',
      name: 'setPassword',
      component: SetPassword,
      meta: { title: 'Ustaw hasło', public: true },
    },
  ],
})

router.beforeEach((to, from, next) => {
  store.dispatch('layout/closeDialog')
  if (store.getters['authClient/isLoggedIn']) {
    if (to.name === 'login') {
      next({ name: 'clientOrders' })
    } else {
      next()
    }
  } else if (to.meta.public) {
    next()
  } else {
    next({ name: 'login' })
  }
})

export default router
