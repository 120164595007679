<template>
  <v-snackbar
    v-model="isSnackbarShown"
    color="rgb(56, 68, 87)"
    timeout="15000"
  >
    <div class="d-flex align-center justify-space-between">
      Klient nie potwierdził zamówienia.
      <v-btn
        v-if="$route.name !== 'tasks'"
        plain
        text
        small
        color="primary"
        class="ml-2"
        @click="openTasksView"
      >
        Przejdź
      </v-btn>

      <v-btn
        text
        small
        color="error"
        @click="isSnackbarShown = false"
      >
        Zamknij
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    isSnackbarShown: false,
    taskId: null,
  }),
  channels: {
    TaskTextConfirmationAlertChannel: {
      received () {
        this.isSnackbarShown = true
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),
  },
  mounted () {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
    this.$cable.subscribe({ channel: 'TaskTextConfirmationAlertChannel', userId: this.user.id })
  },
  methods: {
    ...mapActions({
      saveTab: 'tabs/saveTab',
    }),
    openTasksView () {
      const coursesConfirmationsTabIndex = 2
      this.saveTab({ view: 'tasks', tabValue: coursesConfirmationsTabIndex })
      this.$nextTick(() => {
        this.$router.push({ name: 'tasks' })
        this.isSnackbarShown = false
      })
    }
  }
}
</script>
