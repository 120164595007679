export default {
  header: [],
  table: {
    specialFlag: {
      endpoint: 'reports/income'
    },
    maximumDateRange: 92,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    filters: [
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: '', multiple: true },
      { name: 'driverIds', text: 'Kierowca', options: 'drivers', filterBy: '', multiple: true },
      { name: 'containerTypeIds', text: 'Zamówiony typ kontenera', options: 'containerTypes', filterBy: '', multiple: true },
      { name: 'patronIds', text: 'Opiekun', options: 'patrons', filterBy: '', multiple: true },
      { name: 'departmentIds', text: 'Oddział', options: 'departments', filterBy: [], multiple: true },
      { name: 'courseTypes', text: 'Typ kursu', options: 'reportsCourseTypes', filterBy: ['Podstawienie'], default: ['Podstawienie'], notClearable: true, multiple: true },
      { name: 'addressIds', text: 'Adres', options: 'addresses', filterBy: '', multiple: true },
      { name: 'paymentTypes', text: 'Typ płatności', options: 'paymentTypes', filterBy: '', multiple: true },
      { name: 'paymentStatuses', text: 'Status płatności', options: 'paymentStatuses', filterBy: '', multiple: true },
      { name: 'bdoTypes', text: 'Logika BDO', options: 'allBdoTypes', filterBy: '', multiple: true },
      { name: 'debrisTypeIds', text: 'Typ odpadu', options: 'debrisTypes', filterBy: '', multiple: true },
      { name: 'settlementTypes', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: '', multiple: true },
      { name: 'fromTemplate', text: 'Pochodzenie (sklep)', options: '', filterBy: '', falseValue: '', switch: true, propPath: 'fromTemplate' },
    ],
    parameters: [
      { text: 'Lp', value: 'sequence', width: 20 },
      { text: 'Oddział', value: 'department', width: 100 },
      { text: 'Nr zlecenia', value: 'refNumber', width: 80 },
      { text: 'Typ kursu', value: 'courseType', width: 100 },
      { text: 'Klient', value: 'client', width: 240 },
      { text: 'Adres zlecenia', value: 'address', width: 260 },
      { text: 'Kierowca', value: 'driver', width: 120 },
      { text: 'Typ płatności', value: 'paymentType', width: 100 },
      { text: 'Status płatności', value: 'paymentStatus' },
      { text: 'Kwota netto', value: 'netValue', width: 100, summarize: true, summaryLabel: 'netto', sort: (a, b) => Number(a.replace('zł', '')) - Number(b.replace('zł', '')) },
      { text: 'Kwota brutto', value: 'totalGrossValue', width: 100, summarize: true, summaryLabel: 'brutto', sort: (a, b) => Number(a.replace('zł', '')) - Number(b.replace('zł', '')) },
    ],
    actions: [],
    menu: []
  },
  details: {
    title: '',
    actions: [],
    sections: []
  }
}
