<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
      default: null
    }
  },
  computed: {
    formattedValue() {
      return typeof this.value === 'boolean' ? (this.value ? 'Tak' : 'Nie') : this.value
    }
  }
}
</script>
