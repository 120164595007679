export default {
  namespaced: true,
  state: {
    sidebar: {
      size: 0,
      showOverlay: false,
      isEditMode: false
    },
    contextMenu: {
      active: false,
      actions: null,
      item: null,
      position: {
        x: 0,
        y: 0
      }
    },
    dialog: {
      active: false,
      size: null,
      data: null,
      component: null,
      item: null // TODO item probably shouldn't be a part of layout module
    },
    dialogTopIndex: 10,
  },
  mutations: {
    SET_SIDEBAR (state, setup) {
      Object.keys(setup).map((key) => {
        state.sidebar[key] = setup[key]
      })
    },
    SET_CONTEXT_MENU (state, setup) {
      state.contextMenu = setup
    },
    SET_DIALOG (state, setup) {
      state.dialog = setup
    },
    SET_DIALOG_INDEX (state) {
      state.dialogTopIndex = state.dialogTopIndex + 100
    }
  },
  actions: {
    setSidebar ({ commit }, setup) {
      commit('SET_SIDEBAR', setup)
    },
    closeSidebar ({ commit }) {
      commit('SET_SIDEBAR', { size: 0, data: null, showOverlay: false, isEditMode: false })
    },
    setContextMenu ({ state, commit }, setup) {
      if (!state.dialog.active) {
        setup.active = true
        commit('SET_CONTEXT_MENU', setup)
      }
    },
    closeContextMenu ({ commit }) {
      commit('SET_CONTEXT_MENU', { active: false, actions: [], item: null, position: { x: 0, y: 0 } })
    },
    setDialog ({ commit, dispatch }, setup) {
      setup.active = true
      commit('SET_DIALOG', setup)
      dispatch('closeContextMenu')
    },
    setDialogSize ({ state, commit }, size) {
      commit('SET_DIALOG', { ...state.dialog, size })
    },
    closeDialog ({ commit }) {
      commit('SET_DIALOG', { active: false, data: null, component: null, size: null, item: null })
    },
    updateDialogIndex ({ commit }) {
      commit('SET_DIALOG_INDEX')
    }
  }
}
