<template>
  <div class="reports-view">
    <Header
      ref="header"
      title="Utarg dzienny"
      :buttons="[]"
    >
      <v-btn
        class="px-4"
        color="primary"
        outlined
        :loading="isLoading"
        v-shortkey="exportShortkeys"
        @shortkey.native="exportReport('csv')"
        @click="exportReport('csv')"
      >
        {{ getLabelWithShortkey('Eksportuj raport', exportShortkeys.key) }}
      </v-btn>
      <v-btn
        class="px-4 ml-6"
        color="primary"
        :loading="isLoading"
        outlined
        v-shortkey="printShortkeys"
        @shortkey.native="exportReport('pdf')"
        @click="exportReport('pdf')"
      >
        {{ getLabelWithShortkey('Drukuj raport', printShortkeys.key) }}
      </v-btn>
    </Header>

    <template v-if="configured">
      <div
        class="filters"
        ref="tableTop"
      >
        <div class="filters__inputs d-flex justify-end">
          <DateRangePicker
            :table-name="tableName"
            disable-fetch
          />
          <ShowFiltersButton :table-name="tableName" />
        </div>

        <TableFilters
          :table-name="tableName"
          disable-fetch
          @fetch="fetchReport"
        />
      </div>

      <div class="px-4">
        <RevenueReport />
      </div>
    </template>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import DateRangePicker from '../../components/Filters/DateRangePicker'
import TableFilters from '../../components/Table/TableFilters'
import ShowFiltersButton from '../../components/Filters/ShowFiltersButton'
import RevenueReport from '../../components/Reports/RevenueReport'
import { mapState, mapActions, mapGetters } from 'vuex'
import { getLabelWithShortkey, printShortkeys, exportShortkeys } from '../../const/shortKeys'

export default {
  components: {
    Header,
    DateRangePicker,
    TableFilters,
    ShowFiltersButton,
    RevenueReport
  },
  data: () => ({
    tableName: 'revenueReport',
    configured: false,
    printShortkeys,
    exportShortkeys
  }),
  computed: {
    ...mapState({
      isLoading: state => state.core.isLoading,
      department: state => state.core.department,
      reportDate: state => state.revenueReport.dateRange
    }),
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
  },
  watch: {
    reportDate () {
      this.fetchReport()
    },
  },
  async mounted () {
    this.setTableConfig({ tableName: this.tableName, disableFetch: true })
    await this.$nextTick()
    this.fetchReport()
      .then(() => {
        this.configured = true
      })
  },
  methods: {
    ...mapActions({
      fetchReport: 'revenueReport/fetchReport',
      generateReport: 'revenueReport/generateReport',
      setTableFilters: 'tables/setTableFilters',
      setTableConfig: 'tables/setTableConfig',
    }),
    getLabelWithShortkey,
    exportReport (exportType) {
      this.generateReport({ exportType, tabName: 'revenue' })
    }
  }
}
</script>
