import actions from '../actions'
const isFromWordpress = order => !!order?.orderTemplate?.id

export default {
  header: [{ ...actions.addNewTask, create: true }],
  table: {
    maximumDateRange: 31,
    specialFlag: {
      done: false
    },
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    modes: [
      { value: { done: false }, initial: true, text: 'Do wykonania' },
      { value: { done: true }, text: 'Wykonane' },
      { value: { done: undefined }, text: 'Wszystkie' }
    ],
    filters: [
      { name: 'done', filterBy: '', hide: true },
      { name: 'assignedUserId', text: 'Przypisany pracownik', options: 'users', filterBy: '' },
      { name: 'orderingUserId', text: 'Zlecający pracownik', options: 'users', filterBy: '' },
      { name: 'taskTypes', text: 'Typ zadania', options: 'taskTypes', multiple: true, filterBy: [] }
    ],
    parameters: [
      { name: 'id', text: 'Nr zadania', value: 'id', show: true },
      { name: 'taskType', text: 'Typ', value: 'taskType', show: true },
      { name: 'topic', text: 'Temat', value: 'topic', show: true },
      { name: 'client', text: 'Klient', value: 'client.name', show: true },
      { name: 'assignedUser', text: 'Przypisany pracownik', value: 'assignedUser.fullName', show: true },
      { name: 'dueDate', text: 'Termin realizacji', value: 'dueDate', show: true },
      { name: 'description', text: 'Opis zadania', value: 'description', show: true },
      { name: 'orderingUser', text: 'Zlecający pracownik', value: 'orderingUser.fullName', show: true },
      { name: 'doneDate', text: 'Data realizacji', value: 'doneDate', show: true },
      { name: 'comment', text: 'Komentarz', value: 'comment', show: true, sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.deleteTask, icon: 'usun' },
      { ...actions.toggleTaskStatus, name: 'completeTask', icon: 'zadanie_wykonane' },
      {
        ...actions.toggleTaskStatus,
        name: 'revertTask',
        icon: 'cofnij_wyslanie_kursu',
        text: 'Oznacz jako niewykonane'
      }
    ],
    menu: [
      { ...actions.deleteTask },
      { ...actions.editTaskDueDate },
      { ...actions.toggleTaskStatus, name: 'completeTask' },
      { ...actions.toggleTaskStatus, name: 'revertTask', text: 'Oznacz jako niewykonane' }
    ]
  },
  details: {
    title: 'Zadanie nr {{id}}',
    actions: [
      { ...actions.deleteTask, redirect: true },
      { ...actions.toggleTaskStatus, text: 'Zmień status zadania', filled: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'tasks',
        fields: [
          { name: 'orderId', text: 'Nr zadania', value: 'id', type: 'inline', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'date', col: 3 },
          { name: 'dueDate', text: 'Termin realizacji', value: 'dueDate', type: 'date', col: 3, editable: true },
          { name: 'done', text: 'Wykonane', value: 'done', extraClass: 'course-status', col: 3 },
          { name: 'topic', text: 'Temat', value: 'topic', type: 'textField', col: 3, editable: true },
          {
            name: 'orderRefNumber',
            text: 'Zlecenie',
            value: 'order.refNumber',
            to: 'singleOrder',
            idPath: 'order.id',
            col: 3,
            editable: false
          },
          { name: 'orderTemplateId ', value: 'orderTemplate.id', type: 'textField', to: 'defaultSingleView', idPath: 'orderTemplate.id', routeParams: { tableName: 'orderTemplates' }, col: 3, editable: false, text: 'Nr zamówienia', condition: isFromWordpress },
          {
            name: 'client',
            text: 'Klient',
            value: 'client.name',
            to: 'singleClient',
            idPath: 'client.id',
            col: 3,
            editable: false
          },
          {
            name: 'assignedUserId',
            text: 'Przypisany pracownik',
            value: 'assignedUser.fullName',
            type: 'selectFromItems',
            options: 'users',
            itemText: 'fullName',
            col: 3,
            editable: true
          },
          {
            name: 'orderingUserId',
            text: 'Zlecający pracownik',
            value: 'orderingUser.fullName',
            type: 'selectFromItems',
            options: 'users',
            itemText: 'fullName',
            col: 3,
            editable: true
          },
          { name: 'doneDate', text: 'Data realizacji', value: 'doneDate', type: 'date', col: 3 },
          { name: 'comment', text: 'Komentarz', value: 'comment', type: 'textArea', editable: true, col: 6 },
          { name: 'description', text: 'Opis zadania', value: 'description', type: 'textArea', col: 12, editable: true }
        ]
      },
      {
        name: 'taskHistory',
        text: 'Historia zmian',
        multiple: 'taskChanges',
        type: 'right',
        component: 'DetailsSectionOperationHistory'
      }
    ]
  }
}
