<template>
  <div class="d-flex personalization__dialog">
    <v-spacer />
    <v-btn
      @click="togglePersonalization()"
      class="mx-2"
      icon
      small
    >
      <Icon
        name="dostosuj_tabele"
        size="small"
      />
    </v-btn>
    <v-dialog
      v-model="showPersonalization"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Dostosuj kolumny tabeli</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="togglePersonalization()"
          >
            <v-icon big>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 py-6">
          Zaznacz rekordy, które chcesz wyświetlać w tabeli. Poukładaj rekordy przeciągając je na liście wedle
          preferowanej kolejności.
        </div>
        <v-card-text style="height: 500px;">
          <draggable
            :list="parameters"
            class="list-group"
            ghost-class="ghost"
          >
            <v-btn
              v-for="item in parameters"
              :key="item.id"
              v-show="item.text"
              outlined
              block
              class="pl-1 mb-4 personalization__parameter"
            >
              <v-checkbox
                v-model="item.show"
              />
              {{ item.text }}
              <v-spacer />
              <Icon
                name="drag"
                class="icon--drag"
              />
            </v-btn>
          </draggable>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined="outlined"
            color="primary"
            class="ml-5 px-4 hidden-sm-and-down"
            @click="togglePersonalization()"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="ml-5 px-4 hidden-sm-and-down"
            @click="setPersonalization()"
          >
            Zapisz zmiany
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { readJSON } from '../../utils'
import { mapActions } from 'vuex'

export default {
  components: {
    draggable
  },
  props: {
    tableName: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showPersonalization: false,
    parameters: []
  }),
  mounted () {
    this.parameters = readJSON(this.config.parameters)
  },
  methods: {
    ...mapActions({
      setTablePersonalization: 'tables/setTablePersonalization'
    }),
    togglePersonalization () {
      this.showPersonalization = !this.showPersonalization
    },
    setPersonalization () {
      const { parameters, tableName } = this
      this.setTablePersonalization({ parameters, tableName })
      this.togglePersonalization()
    }
  }
}
</script>
