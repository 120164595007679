import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"width":"300","elevation":"0"}},[_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"pt-0 d-flex justify-end"},[_c(VIcon,{attrs:{"size":"20","color":"red"},on:{"click":function($event){return _vm.$emit('hideWindow')}}},[_vm._v(" mdi-close ")])],1)],1),_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c('div',{staticClass:"detail-line"},[_c('span',[_vm._v("Kierowca: ")]),_c('b',[_vm._v(_vm._s(_vm.driver.firstName + ' ' + _vm.driver.lastName))])])])],1),_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c('div',{staticClass:"detail-line"},[_c('span',[_vm._v("Dostępny: ")]),_c('b',[_vm._v(_vm._s(_vm.driver.available ? 'Tak' : 'Nie'))])])])],1),_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c('div',{staticClass:"detail-line"},[_c('span',[_vm._v("Trasa: ")]),_c('b',[_vm._v(_vm._s(_vm.driver.currentRouteId ? 'Tak' : 'Nie'))])])])],1)],1),(_vm.loadingRoute)?_c(VProgressLinear,{attrs:{"indeterminate":"","absolute":"","bottom":"","height":"6"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }