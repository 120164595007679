<template>
  <v-row>
    <v-col
      v-for="column in ['left', 'right', 'bottom']"
      :key="column"
      cols="12"
      :md="detailsColumnSize(column)"
    >
      <div
        v-for="(detail, index) in details[column]"
        :key="detail.name + index"
        class="detail-section__wrapper mb-2"
      >
        <div
          class="detail-section__header"
        >
          <SectionHeader
            :data="data"
            :detail="getHeaderFields(detail)"
          />
        </div>
        <div :class="{'detail-section__content': detail.text}">
          <slot
            :detail="detail"
            :slotData="data"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SectionHeader from './SectionHeader'

export default {
  name: 'SectionsWrapper',
  components: {
    SectionHeader,
  },
  methods: {
    detailsColumnSize (col) {
      const column = {
        left: '8',
        right: '4',
        bottom: '12'
      }
      return column[col]
    },
    getHeaderFields(detail) {
      if (!detail?.fields) return detail
      return {
        ...detail,
        fields: detail?.fields.filter((field) => {
          return field?.header
        })
      }
    },
  },
  props: {
    details: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>
