<template>
  <v-card
    class="d-flex flex-column"
    width="300"
    elevation="0"
  >
    <v-card-text>
      <v-row>
        <v-col class="pt-0 d-flex justify-end">
          <v-icon
            size="20"
            color="red"
            @click="$emit('hideWindow')"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="detail-line">
            <span>Kierowca: </span>
            <b>{{ driver.firstName + ' ' + driver.lastName }}</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="detail-line">
            <span>Dostępny: </span>
            <b>{{ driver.available ? 'Tak' : 'Nie' }}</b>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="detail-line">
            <span>Trasa: </span>
            <b>{{ driver.currentRouteId ? 'Tak' : 'Nie' }}</b>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-progress-linear
      v-if="loadingRoute"
      indeterminate
      absolute
      bottom
      height="6"
    />
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    loadingRoute: false
  }),
  props: {
    driver: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      getSingleRoute: 'route/getSingleRoute',
      resetRoutePlanning: 'route/resetRoutePlanning'
    })
  },
  mounted() {
    if (this.driver.currentRouteId) {
      this.loadingRoute = true
      this.getSingleRoute(this.driver.currentRouteId).then(() => {
        setTimeout(() => {
          this.loadingRoute = false
        }, 1000)
      })
    }
  },
  beforeDestroy() {
    this.resetRoutePlanning()
  }
}
</script>

<style lang="scss" scoped>
.detail-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  width: 100%;
}
</style>
