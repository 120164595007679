import actions from '../../../actions'

export default {
  header: [
    { ...actions.addNewDepartment, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'name', text: 'Oddział', value: 'name', show: true },
      { name: 'baseAddress', text: 'Adres', value: 'address', show: true },
      { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber', show: true },
      { name: 'bankAccount', text: 'Numer konta', value: 'bankAccount', show: true },
      { name: 'invoiceCode', text: 'Symbol oddziału', value: 'invoiceCode', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteDepartment, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteDepartment }
    ]
  },
  details: {
    title: '{{name}}',
    actions: [
      { ...actions.deleteDepartment, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'bottom',
        endpoint: 'departments',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'inline', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'inline', col: 3 },
          { name: 'name', text: 'Oddział', value: 'name', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'address', text: 'Adres', value: 'address', type: 'address', col: 3, editable: true, rules: ['required'] },
          { name: 'phoneNumber', text: 'Telefon', value: 'phoneNumber', type: 'textField', col: 3, editable: true, rules: ['required', 'phoneNumber'] },
          { name: 'bankAccount', text: 'Numer konta', value: 'bankAccount', type: 'textField', col: 3, editable: true, rules: ['bankAccount'] },
          { name: 'invoiceCode', text: 'Symbol oddziału', value: 'invoiceCode', type: 'textField', col: 3, editable: true },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] }
        ]
      },
      {
        name: 'defaultCodes',
        text: 'Domyślne kody odpadów',
        type: 'bottom',
        endpoint: 'departments',
        fields: [
          { name: 'declarationKwuDefaultCode', text: 'Kod dla KWU oświadczenie', value: 'declarationKwuDefaultCode', editable: true, type: 'defaultDebrisTypeCode', col: 3 },
          { name: 'privateKwuDefaultCode', text: 'Kod dla KWU Prywatny', value: 'privateKwuDefaultCode', editable: true, type: 'defaultDebrisTypeCode', col: 3 },
          { name: 'controlKwuDefaultCode', text: 'Kod dla KWU kontrolna', value: 'controlKwuDefaultCode', editable: true, type: 'defaultDebrisTypeCode', col: 3 },
        ]
      },
    ]
  }
}
