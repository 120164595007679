<script>
import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
  mixins: [mixins.reactiveProp, HorizontalBar],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
