import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"width":"300","elevation":"0"}},[_c(VCardText,{staticClass:"pb-0"},[_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Baza ")]),_c('div',{staticClass:"pt-1 pb-2"},[_vm._v(" "+_vm._s(_vm.department.name)+" ")])])],1),_c(VRow,[_c(VCol,{staticClass:"py-1"},[_c('div',{staticClass:"d-flex align-center"},[_c('Icon',{staticClass:"mr-1",attrs:{"name":"location","size":"small"}}),_vm._v(" "+_vm._s(_vm.department.address)+" ")],1)])],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""}},[_vm._v(" Dodaj bazę do adresu ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }