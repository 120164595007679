<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <DebrisTypesDepartments
          v-model="operativeDepartments"
          :debris-code="data.code"
        />
        <div class="mt-4 d-flex justify-end w-100">
          <v-btn
            class="px-8 mr-4 base-hover"
            color="primary"
            outlined
            :disabled="!hasAnyChangeBeenApplied"
            @click="resetKwuTypeValues"
          >
            Anuluj
          </v-btn>
          <v-btn
            class="px-8 base-hover"
            color="primary"
            :disabled="!hasAnyChangeBeenApplied"
            @click="saveKwuTypeValues"
          >
            Zapisz
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DebrisTypesDepartments from '../../Forms/Partials/DebrisTypesDepartments.vue'
import kwuLabels from '../../../const/kwuLabels'
import { mapActions } from 'vuex'

export default {
  components: {
    DebrisTypesDepartments
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    operativeDepartments: []
  }),
  computed: {
    hasAnyChangeBeenApplied() {
      return this.flattenValues(this.operativeDepartments) !== this.flattenValues(this.data.debrisTypesDepartments)
    }
  },
  created() {
    this.resetKwuTypeValues()
  },
  methods: {
    ...mapActions({
      saveKwuTypeValues: function(dispatch) {
        const { tableName } = this.detail
        const payload = {
          tableName,
          id: this.data.id,
          params: { debrisTypesDepartments: this.operativeDepartments },
          notWebSocketHandled: true,
        }
        return dispatch(`${tableName}/editItem`, payload)
      }
    }),
    flattenValues(values) {
      return values
        .map((department) => kwuLabels.map((label) => department[label]))
        .flat()
        .join()
    },
    resetKwuTypeValues () {
      this.operativeDepartments = this.data.debrisTypesDepartments.map((department) => ({
        ...department,
        departmentId: department.department.id
      }))
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  border-radius: 0 !important;
  padding: 0 !important;
  border: none !important;
}
</style>
