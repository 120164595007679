import actions from '../../../actions'

export default {
  header: [
    { ...actions.fetchCommunes, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    parameters: [
      { name: 'name', text: 'Gmina', value: 'name', show: true },
      { name: 'district', text: 'Powiat', value: 'district', show: true },
      { name: 'voivodeship', text: 'Województwo', value: 'voivodeship', show: true },
      { name: 'communeType', text: 'Typ', value: 'communeType', show: true },
      { name: 'businessPlace', text: 'MPD', value: 'businessPlace.name', show: true },
      { name: 'actions', text: '', value: 'actions', show: true },
    ],
    actions: [
      { ...actions.deleteCommune, icon: 'usun' }
    ],
    menu: []
  },
  details: {
    title: 'Gmina {{name}}',
    actions: [
      { ...actions.deleteCommune, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'communes',
        fields: [
          { name: 'name', text: 'Gmina', value: 'name', col: 3 },
          { name: 'district', text: 'Powiat', value: 'district', col: 3 },
          { name: 'voivodeship', text: 'Województwo', value: 'voivodeship', col: 6 },
          { name: 'communeType', text: 'Typ', value: 'communeType', col: 3 },
          { name: 'businessPlaceId', text: 'MPD', value: 'businessPlace.name', col: 3, editable: true, type: 'selectFromItems', options: 'businessPlaces' },
        ]
      }
    ]
  }
}
