<template>
  <v-simple-checkbox
    :value="selected"
    :indeterminate="disabled"
    :disabled="disabled"
    class="pa-2"
    color="primary"
    :ripple="false"
    @input="toggleItemSelection(itemId)"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { getExecutiveMultiselectTableName } from '../../../utils'

export default {
  props: {
    itemId: {
      type: Number,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected () {
      return this.$store.state[this.executiveTableName]?.selectedItems?.includes(this.itemId)
    },
    executiveTableName () {
      return getExecutiveMultiselectTableName(this.tableName)
    }
  },
  methods: {
    ...mapActions({
      toggleItemSelection: function (dispatch, id) {
        return dispatch(`${this.executiveTableName}/toggleItemSelection`, id)
      }
    })
  }
}
</script>
