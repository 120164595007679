import api from '../api/v1'
import humps from 'humps'

const today = new Date().toISOString().substring(0, 10)

const transformTableFilterByValue = filterBy => {
  return (filterBy?.value ?? filterBy) || null
}

const mapTableFilterByArray = arr => {
  return arr.map(filter => transformTableFilterByValue(filter))
}

const getTableFilterBy = filterBy => {
  return Array.isArray(filterBy) ? mapTableFilterByArray(filterBy) : transformTableFilterByValue(filterBy)
}

const getKeyName = key => {
  const keysDictionary = {
    leaveCount: 'Podstawienie',
    transportCount: 'Transport',
    swapCount: 'Wymiana',
    pickupCount: 'Zabranie',
    totalLeaveCount: 'Łącznie podstawień',
    totalPickupCount: 'Łącznie zabrań',
    loopCount: 'Pętla',
    totalLeaveVolume: 'Łączna objętość podstawień',
    totalPickupVolume: 'Łączna objętość zabrań',
    totalDriversCount: 'Kierowcy',
    driverLoopAvg: 'Średnia pętli/kierowca',
    totalLoopCount: 'Łączna liczba pętli',
  }
  return keysDictionary[key] || key
}

const reports = tableName => {
  const rawData = tableName => {
    switch (tableName) {
      case 'mainReport':
        return {
          courseTypesCount: {
            data: []
          },
          driverCourseTypesCount: {
            data: []
          },
          driverRoutesCount: {
            data: []
          },
          dateLoopsCount: {
            data: []
          },
          totalDriverCourseTypesCount: {
            data: []
          }
        }
      case 'incomeReport':
        return {
          income: {
            data: [],
            order: {
              field: 'sequence',
              direction: 'asc'
            }
          }
        }
      case 'revenueReport':
        return {
          driver: {
            data: []
          },
          user: {
            data: []
          }
        }
    }
  }
  return {
    namespaced: true,
    state: {
      ...rawData(tableName),
      dateRange: [today],
      showFilters: false
    },
    mutations: {
      SET_STATISTICS(state, payload) {
        const defaultSet = section => {
          state[section].data = payload[section]
        }

        for (const reportSection in payload) {
          if (tableName === 'mainReport') {
            switch (reportSection) {
              case 'driverCourseTypesCount':
                state[reportSection].data = payload[reportSection].map(({ driverFullName, ...rest }) => {
                  return {
                    driver: driverFullName,
                    data: Object.entries(rest).map(([key, value]) => ({ key: getKeyName(key), value: Number(value) }))
                  }
                })
                break
              case 'courseTypesCount':
                state[reportSection].data = Object.entries(payload[reportSection]).map(([key, value]) => {
                  return {
                    key,
                    value,
                    label: getKeyName(key)
                  }
                })
                break
              case 'totalDriverCourseTypesCount': {
                state[reportSection].data = Object.entries(payload[reportSection]).map(([key, value]) => {
                  return {
                    key,
                    value,
                    label: getKeyName(key)
                  }
                })
                break
              }
              default:
                defaultSet(reportSection)
            }
          } else {
            defaultSet(reportSection)
          }
        }
      },
      SET_ORDER(state, { report, order }) {
        state[report].order = order
      },
      TOGGLE_FILTERS_VISIBILITY(state) {
        state.showFilters = !state.showFilters
      },
      SET_TABLE_DATE(state, date) {
        state.dateRange = [...date].sort()
      }
    },

    actions: {
      storeTableOrder({ commit }, orderData) {
        commit('SET_ORDER', orderData)
      },
      setTableDate({ commit, dispatch }, { date, fetch }) {
        commit('SET_TABLE_DATE', date)
        if (fetch) dispatch('fetchReport')
      },
      fetchReport({ rootGetters, commit, dispatch }) {
        dispatch('core/setLoading', true, { root: true })

        const { departmentId, filters, endpoint } = rootGetters['tables/getTableParameters'](tableName).params
        const params = { departmentId, filters, endpoint }

        api
          .getItems({ params, tableName })
          .then(response => {
            commit('SET_STATISTICS', response.data)
          })
          .finally(() => {
            dispatch('core/setLoading', false, { root: true })
          })
      },
      generateReport({ state, rootState, dispatch }, { exportType, tabName }) {
        dispatch('core/setLoading', true, { root: true })

        const filters = {
          daterange: state.dateRange
        }
        rootState.tables[tableName].filters.forEach(filter => {
          if (filter.filterBy) {
            filters[filter.name] = getTableFilterBy(filter.filterBy)
          }
        })

        let order = []
        for (const dataset in state[tabName]) {
          if (state[tabName][dataset].order) {
            order.push({
              tableName: humps.decamelize(dataset),
              ...state[tabName][dataset].order
            })
          } else {
            order = undefined
          }
        }

        const params = {
          exportType,
          tabName,
          departmentId: rootState.core.department.id,
          filters,
          order
        }

        api
          .exportReport(params)
          .then(resp => {
            const type = resp.headers['content-type']
            const url = window.URL.createObjectURL(new Blob([resp.data], { type }))

            if (process.env.IS_ELECTRON) {
              window.ipcRenderer.send('download-item', { url })

              window.ipcRenderer.on('download-success', () => {
                dispatch(
                  'snackbar/showSnackbar',
                  {
                    message: ['Pobrano raport'],
                    type: 'success'
                  },
                  { root: true }
                )
              })
            } else {
              const link = document.createElement('a')
              link.href = url
              link.download = 'Raport ' + state.dateRange.join(' ')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          })
          .finally(() => {
            dispatch('core/setLoading', false, { root: true })
          })
      },
      toggleFiltersVisibility({ commit }) {
        commit('TOGGLE_FILTERS_VISIBILITY')
      }
    }
  }
}

export default reports
