import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ShortKey from './plugins/shortKey'
import * as VueGoogleMaps from 'gmap-vue'
import commonComponents from './plugins/commonComponents'
import ActionCableVue from 'actioncable-vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import * as VueWindow from '@hscmap/vue-window'
import { axiosErrorInterceptor } from './api/axios'
import { VueMaskDirective } from 'v-mask'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import isLogisticsApp from './const/isLogisticsApp'

axiosErrorInterceptor()
Vue.directive('mask', VueMaskDirective)

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  release: process.env.VUE_APP_VERSION,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true,
})

if (isLogisticsApp) {
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_API_KEY,
      libraries: 'places',
      region: 'PL',
      language: 'pl',
    },
  })
}

if (isLogisticsApp) {
  Vue.use(VueWindow)
}

if (isLogisticsApp) {
  Vue.use(ActionCableVue, {
    debug: true,
    connectionUrl: process.env.VUE_APP_WSS_BASE_URL,
    connectImmediately: false,
    store,
  })
}

if (isLogisticsApp) {
  Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi',
  })
}

commonComponents.forEach(component => {
  Vue.component(component.name, component)
})

Vue.config.productionTip = false
Vue.prototype.$isLogisticsApp = isLogisticsApp

new Vue({
  router,
  store,
  vuetify,
  ShortKey,
  render: h => h(App),
}).$mount('#app')
