import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-version"},[_vm._v(" "+_vm._s(_vm.version)+" ")]),_c('div',{staticClass:"d-flex mt-2",class:[_vm.$isLogisticsApp ? 'justify-space-around' : 'justify-start']},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.clearAll()}}},[(_vm.mini)?_c(VImg,{attrs:{"contain":"","width":"100%","height":"30","max-width":"30","src":require("@/assets/images/logo2.png")}}):_c(VImg,{attrs:{"contain":"","width":"100%","max-width":"90","src":require("@/assets/images/logo.png")}})],1),(!_vm.mini && _vm.$isLogisticsApp)?_c(VBtn,{attrs:{"icon":"","disabled":_vm.$route.name === 'hints'},on:{"click":function($event){return _vm.$router.push({ name: 'hints' })}}},[_c(VIcon,{attrs:{"color":"#67758E"}},[_vm._v(" mdi-information-variant ")])],1):_vm._e()],1),_c(VBtn,{staticClass:"menu-toggler",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('toggle')}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-"+_vm._s(_vm.mini ? 'right' : 'left')+" ")])],1),_c(VListItem,{staticClass:"px-2"},[_c(VListItemContent,{staticClass:"pt-0"},[_c(VListItemTitle,{staticClass:"menu-name mb-2 mx-2 text--white",class:{ 'menu-initials': _vm.mini }},[_vm._v(" "+_vm._s(_vm.mini ? _vm.getInitials : _vm.getFullName)+" ")]),_c(VSelect,{staticClass:"white--text",attrs:{"background-color":"#2E3849","items":_vm.departmentsList,"value":_vm.department ? _vm.department.id : null,"hide-details":"","item-text":"name","item-value":"id","solo":"","dense":"","append-icon":!_vm.mini && _vm.$isLogisticsApp ? 'mdi-chevron-down' : '',"disabled":!_vm.$isLogisticsApp},on:{"input":function($event){return _vm.findNewDepartment($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mini ? item.name.substring(0, 3) : item.name)+" ")]}}])})],1)],1),_c(VDivider)],1)}
var staticRenderFns = []

export { render, staticRenderFns }