<template>
  <div>
    <GmapCluster
      clickable
      :animation="2"
      image-path="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
      :grid-size="60"
      :max-zoom="14"
      :zoom-on-click="true"
    >
      <GmapMarker
        :position="{ lat: +base.lat, lng: +base.lng }"
        :icon="{ url: require('@/assets/icons/map-pins/base.svg') }"
        @click="toggleWindow($event, department, 'department')"
      />

      <GmapMarker
        v-for="(container, index) in controlledContainers"
        :key="index + 'container'"
        :position="{
          lat: +container.spreadLocation.lat,
          lng: +container.spreadLocation.lng
        }"
        @click="toggleWindow($event, container, 'container')"
        :icon="getContainerMarkerIconConfig(container)"
        :label="getContainerMarkerLabelConfig(container)"
      />
    </GmapCluster>
    <template v-for="(course, index) in controlledCourses">
      <GmapMarker
        v-if="standing(course)"
        :key="index + 'course'"
        :position="{ lat: +course.spreadLocation.lat, lng: +course.spreadLocation.lng }"
        :icon="{ url: require(`@/assets/icons/map-pins/simple/${getCourseIconName(course)}.svg`) }"
        @click="toggleWindow($event, course, 'course')"
        :label="{
          text: course.containerIdleDays && course.containerIdleDays.toString(),
          color: '#F86262',
          fontWeight: 'bold',
          fontSize: '11px',
          className: 'idle-days-counter'
        }"
      />
      <GmapMarker
        v-else
        :key="index + 'course'"
        :position="{ lat: +course.spreadLocation.lat, lng: +course.spreadLocation.lng }"
        :icon="{ url: require(`@/assets/icons/map-pins/simple/${getCourseIconName(course)}.svg`) }"
        @click="toggleWindow($event, course, 'course')"
      />
    </template>

    <GmapMarker
      v-for="(driver, index) in controlledDrivers"
      :key="index + 'driver'"
      :position="{ lat: +driver.lastPosLat, lng: +driver.lastPosLng }"
      :label="{ text: driver.firstName + ' ' + driver.lastName, className: 'mt-16 font-weight-black' }"
      :icon="{ url: require(`@/assets/icons/map-pins/driver_${driver.available ? 'available' : 'unavailable'}.svg`) }"
      @click="toggleWindow($event, driver, 'driver')"
    />

    <MarkerWrapper
      v-if="windowData && showWindow"
      :key="windowPosition.x + windowPosition.y"
      :marker="windowDataPosition"
      :z-index="1000002"
      v-click-outside="windowType !== 'driver' ? toggleWindow : null"
      :offset-top="windowOffsetTop"
      :offset-left="windowOffsetLeft"
      ref="markerWindowRef"
    >
      <div id="marker-window">
        <CourseWindow
          v-if="windowType === 'course'"
          :item="windowData"
          @hideWindow="toggleWindow"
        />
        <ContainerWindow
          v-else-if="windowType === 'container'"
          :container="windowData"
          @hideWindow="toggleWindow"
        />
        <DriverWindow
          v-else-if="windowType === 'driver'"
          :driver="windowData"
          @hideWindow="toggleWindow"
        />
        <BaseWindow
          v-else
          :department="windowData"
        />
        <div
          class="marker-window__arrow"
          :class="windowClass"
        />
      </div>
    </MarkerWrapper>
  </div>
</template>

<script>
import MarkerWrapper from './MarkerWrapper'
import CourseWindow from './Windows/CourseWindow'
import ContainerWindow from './Windows/ContainerWindow'
import DriverWindow from './Windows/DriverWindow'
import BaseWindow from './Windows/BaseWindow'
import debounce from 'lodash/debounce'
import { courseIconType, statusColorName } from '../../utils/recordStatuses'
import GmapCluster from 'gmap-vue/dist/components/cluster'

export default {
  components: {
    CourseWindow,
    ContainerWindow,
    DriverWindow,
    BaseWindow,
    MarkerWrapper,
    GmapCluster
  },
  props: {
    base: {
      type: Object,
      required: true
    },
    department: {
      type: Object,
      required: true
    },
    courses: {
      type: Array,
      default: () => []
    },
    containers: {
      type: Array,
      default: () => []
    },
    drivers: {
      type: Array,
      default: () => []
    },
    mapRef: {
      type: Object,
      default: () => {}
    },
    sidebar: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    showWindow: true,
    windowData: null,
    windowType: null,
    windowPosition: {
      x: -500,
      y: -500
    },
    windowOffsetTop: 0,
    windowOffsetLeft: 0,
    windowClass: ''
  }),
  computed: {
    windowDataPosition() {
      if (this.windowType === 'course' || this.windowType === 'container') {
        return this.windowData.spreadLocation
      } else if (this.windowType === 'driver') {
        return { lat: this.windowData.lastPosLat, lng: this.windowData.lastPosLng }
      }
      return this.base
    },
    // gmap-vue apparently has an issue with reactivity - is data is updated (by WS) marker window is rerendered and blinks
    // so we need to freeze data if window is open
    controlledDrivers() {
      return this.windowData ? Object.freeze(this.drivers) : this.drivers
    },
    controlledCourses() {
      return this.windowData ? Object.freeze(this.courses) : this.courses
    },
    controlledContainers() {
      return this.windowData ? Object.freeze(this.containers) : this.containers
    }
  },
  methods: {
    toggleWindow: debounce(
      function(e, item, type) {
        this.windowType = type
        this.windowData = item || null
        this.windowPosition = { x: -500, y: -500 }
        if (item) {
          const { x, y } = e
          const { clientWidth, clientHeight } = this.mapRef.$el
          const offsetLeft = window.innerWidth - clientWidth - (this.sidebar ? 528 : 0)
          const offsetTop = window.innerHeight - clientHeight + 250
          let windowClass = ''
          let windowOffsetLeft = 0
          if (x < offsetLeft + 150) {
            windowClass = 'marker-window__arrow--left'
            windowOffsetLeft = 135
          } else if (x > clientWidth + offsetLeft - 150) {
            windowClass = 'marker-window__arrow--right'
            windowOffsetLeft = -135
          }
          const that = this
          window.setTimeout(function() {
            if (y < offsetTop) {
              windowClass += ' marker-window__arrow--top'
              that.windowOffsetTop = that.$refs.markerWindowRef.$el.clientHeight + 17
            } else {
              that.windowOffsetTop = -45
            }
            that.windowClass = windowClass
            that.windowOffsetLeft = windowOffsetLeft
            that.windowPosition = { x, y }
          }, 50)
        }
      },
      50,
      { leading: true, trailing: false }
    ),
    standing(item) {
      return ['Wysłany', 'Rozpoczęty', 'Wykonany'].includes(item.courseStatus) ? null : item.containerIdleDays
    },
    getCourseIconName(course) {
      const { courseType, courseStatus } = course
      return `pin_${courseIconType(courseType)}_${statusColorName[courseStatus]}`
    },
    getContainerIconColor(days) {
      if (days < 7) {
        return '#5695DF' // blue
      } else if (days < 30) {
        return '#F2C94C' // yellow
      }
      return '#F86262' // red
    },
    getContainerMarkerIconConfig(container) {
      return {
        path:
          'M70.6,5.1v19.3c0,2.2-1.8,4.1-4.1,4.1H40.5L37,34.5c-0.6,1-2,1-2.6,0l-3.5-6.1H4.7c-2.2,0-4.1-1.8-4.1-4.1V5.1c0-2.2,1.8-4.1,4.1-4.1h61.8C68.8,1.1,70.6,2.9,70.6,5.1z',
        scale: 1,
        fillColor: this.getContainerIconColor(container.daysStanding),
        fillOpacity: 1,
        strokeWeight: 0.5,
        strokeColor: 'white',
        anchor: { x: 38, y: 28 }
      }
    },
    getContainerMarkerLabelConfig(container) {
      return {
        text: container.refNumber,
        color: 'white',
        fontWeight: 'bold',
        fontSize: '15px',
        className: 'container-label'
      }
    }
  }
}
</script>

<style lang="scss">
.idle-days-counter {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #f86262;
  border-radius: 50%;
  transform: translate(10px, -14px);
}
.container-label {
  transform: translate(50%, 15px);
  width: 70px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
