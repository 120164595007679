export default {
  header: [],
  table: {
    isWebSocketHandled: true,
    sorting: undefined,
    specialFlag: {
      departmentId: undefined,
      endpoint: 'reports/orders'
    },
    filters: [
      {
        name: 'departmentIds',
        text: 'Oddział',
        options: 'departments',
        filterBy: '',
        multiple: true
      }
    ],
    parameters: [{}],
    actions: [],
    menu: []
  },
  details: {}
}
