export default {
  data () {
    return {
      prevRoute: {},
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from
    })
  },
}
