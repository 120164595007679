
export default {
  header: [],
  table: {
    maximumDateRange: 366,
    noApi: true,
    specialFlag: {
      endpoint: 'container_entries'
    },
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '' },
      { name: 'driverId', text: 'Kierowca', options: 'drivers', filterBy: '' },
      { name: 'containerId', text: 'Kontener', options: 'containers', filterBy: '' },
      { name: 'containerTypeId', text: 'Typ kontenera', options: 'containerTypes', filterBy: '' },
      { name: 'entryType', text: 'Rodzaj wpisu', options: 'containerEntriesTypes', filterBy: '' },
    ],
    parameters: [
      { name: 'containerRefNumber', text: 'Numer referencyjny', value: 'containerRefNumber', width: 300 },
      { name: 'containerType', text: 'Typ', value: 'containerType' },
    ],
    expandedTableParameters: [
      { name: 'createdAt', text: 'Data', value: 'createdAt', width: 150 },
      { name: 'entryType', text: 'Rodzaj wpisu', value: 'entryType', width: 200 },
      { name: 'address', text: 'Adres', value: 'order.address.formattedAddress', width: 400 },
      { name: 'driver', text: 'Kierowca', value: 'changedBy.fullName', width: 250 },
      { name: 'humanizedType', text: 'Typ użytkownika', value: 'changedBy.humanizedType', width: 250 },
      { name: 'refNumber', text: 'Numer referencyjny zlecenia', value: 'order.refNumber' },
    ],
    actions: [],
    menu: []
  },

  details: {}
}
