<template>
  <div :style="{opacity: opacity}">
    <slot />
  </div>
</template>
<script>
import * as VueGoogleMaps from 'gmap-vue'

export default {
  mixins: [VueGoogleMaps.MapElementMixin],
  inject: {
    $clusterPromise: {
      default: null
    }
  },
  provide () {
    const self = this
    return this.$mapPromise.then(map => {
      // eslint-disable-next-line no-undef
      class Overlay extends google.maps.OverlayView {
        constructor (map) {
          super()
          this.setMap(map)
          this.draw = () => this.repaint()
          this.setPosition = () => this.repaint()
        }

        repaint () {
          const div = self.$el
          const projection = this.getProjection()
          if (projection && div) {
            const posPixel = projection.fromLatLngToDivPixel(self.latLng)
            let x, y
            x = posPixel.x - div.offsetWidth / 2
            y = posPixel.y - div.offsetHeight
            if (self.cluster) {
              x -= 11
              y -= 30
            }
            div.style.left = x + +self.offsetLeft + 'px'
            div.style.top = y + self.offsetTop + 'px'
            div.style['z-index'] = self.zIndex
          }
        }

        onAdd () {
          const div = self.$el
          const panes = this.getPanes()
          div.style.position = 'absolute'
          div.style.display = 'inline-block'
          div.style.zIndex = self.zIndex
          panes.overlayLayer.appendChild(div)
          panes.overlayMouseTarget.appendChild(div)
          this.getDraggable = () => false
          this.getPosition = () => {
            // eslint-disable-next-line no-undef
            return new google.maps.LatLng(self.lat, self.lng)
          }
          this.getID = () => self.id
          self.afterCreate(this)
        }

        onRemove () {
          if (self.$el) {
            self.$el.remove()
          }
        }
      }

      this.$overlay = new Overlay(map)
      setTimeout(() => {
        if (this.$overlay) {
          this.$overlay.repaint()
          this.opacity = 1
        }
      }, 100)
    })
  },
  props: {
    marker: {
      type: Object,
      default: undefined
    },
    id: {
      type: Number,
      default: 0
    },
    cluster: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 50
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    offsetLeft: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    opacity: 0.01
  }),
  computed: {
    lat () {
      return parseFloat(
        isNaN(this.marker.lat) ? this.marker.latitude : this.marker.lat
      )
    },
    lng () {
      return parseFloat(
        isNaN(this.marker.lng) ? this.marker.longitude : this.marker.lng
      )
    },
    latLng () {
      // eslint-disable-next-line no-undef
      if (this.marker instanceof google.maps.LatLng) {
        return this.marker
      }
      // eslint-disable-next-line no-undef
      return new google.maps.LatLng(this.lat, this.lng)
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    marker (val) {
      // eslint-disable-next-line no-unused-vars
      this.$mapPromise.then(map => this.$overlay.setPosition())
    }
  },
  beforeCreate (options) {
    if (this.$clusterPromise) {
      options.map = null
    }
    return this.$clusterPromise
  },

  destroyed () {
    if (this.$clusterObject) {
      this.$clusterObject.removeMarker(this.$overlay, true)
    } else {
      this.$overlay.setMap(null)
      this.$overlay = undefined
    }
  },
  methods: {
    afterCreate (inst) {
      if (this.$clusterPromise && !this.isMarkerAdded) {
        this.$clusterPromise.then(co => {
          co.addMarker(inst)
          this.$clusterObject = co
          this.isMarkerAdded = true
        })
      }
    }
  }
}
</script>
