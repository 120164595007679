<template functional>
  <div class="d-flex justify-space-between align-center">
    {{ $options.getValue(props.isSwap, props.courseType) }}
    <img
      class="course-type-icon"
      :src="require(`@/assets/icons/icon_${$options.courseIconType($options.getValue(props.isSwap, props.courseType))}.svg`)"
      :alt="$options.courseIconType($options.getValue(props.isSwap, props.courseType))"
    >
  </div>
</template>
<script>
import { courseIconType } from '../../../utils/recordStatuses'

export default {
  props: {
    courseType: {
      type: String,
      required: true
    },
    isSwap: {
      type: Boolean,
      required: true
    }
  },
  courseIconType,
  getValue (isSwap, courseType) {
    return isSwap ? 'Wymiana' : courseType
  }
}
</script>
