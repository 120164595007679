export default {
  table: {
    maximumDateRange: 1,
    specialFlag: {
      endpoint: 'courses/map'
    },
    filters: [
      {
        name: 'containerIdleDays',
        text: 'Czas stania',
        options: 'containerIdleTimes',
        filterBy: '',
        propPath: 'containerIdleDays',
        topBar: true,
      },
      {
        name: 'containerTypeIds',
        text: 'Typ kontenera',
        filterBy: [],
        propPath: 'orderContainerTypeId',
        options: 'containerTypes',
        multiple: true,
      },
      {
        name: 'courseStatuses',
        text: 'Status kursu',
        filterBy: [],
        propPath: 'courseStatus',
        options: 'courseStatuses',
        multiple: true,
      },
      {
        name: 'courseTypes',
        text: 'Typ kursu',
        filterBy: [],
        propPath: 'courseType',
        options: 'courseTypes',
        multiple: true,
      }
    ]
  }
}
