import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"width":"300","elevation":"0"}},[_c(VCardText,{staticClass:"pb-0"},[_c(VRow,[_c(VCol,{staticClass:"py-0"},_vm._l((_vm.containerDetails),function(detail){return _c('div',{key:detail.text,staticClass:"detail-line"},[_c('span',[_vm._v(_vm._s(detail.text)+": ")]),_c('b',[_vm._v(_vm._s(detail.value || '-'))])])}),0)],1)],1),_c(VSpacer),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[(true)?_c(VBtn,{attrs:{"text":"","color":"primary","disabled":_vm.container.orderId && !_vm.container.orderContainerPickable},on:{"click":function($event){return _vm.commitAction('pickup')}}},[_vm._v(" Zabierz kontener ")]):_vm._e(),(true)?_c(VBtn,{attrs:{"text":"","color":"primary","disabled":_vm.container.orderId && !_vm.container.orderContainerPickable},on:{"click":function($event){return _vm.commitAction('swap')}}},[_vm._v(" Wymień kontener ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }