<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    />
    <Tabs
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      ref="table"
      :key="tableName"
      :table-name="tableName"
      :custom-search="searchAllFields"
      disable-pagination
      @openDetails="(item) => !noDetails && openTableDetails(item)"
      @contextMenu="openContextMenu"
      @configured="setTableConfiguredStatus"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          :show-filters-button="showFilters"
          :table-filters="showFilters"
        />
      </template>
    </Table>
  </div>
</template>

<script>
import Header from '../../../components/Layout/Header'
import Tabs from '../../../components/Layout/Tabs'
import Table from '../../../components/Table/Table'
import defaultTableEventsMixin from '../../../mixins/defaultTableEventsMixin'
import tabs from '../../../helpers/warehouseTabs'
import FiltersBar from '../../../components/Filters/FiltersBar'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar,
  },
  mixins: [defaultTableEventsMixin],
  data: () => ({
    tableName: 'departments',
    tabs,
    tablesWithFilters: ['vehicles', 'drivers', 'trailers'],
    configured: {
      departments: false,
      drivers: false,
      vehicles: false,
      terminals: false,
      debrisTypes: false,
      aggregateTypes: false,
      communes: false,
      businessPlaces: false,
      trailers: false,
    },
  }),
  computed: {
    ...mapState({
      department: state => state.core.department
    }),
    noDetails () {
      return !!this.tabs.find(tab => tab.value === this.tableName)?.noDetails
    },
    showFilters () {
      return this.tablesWithFilters.includes(this.tableName)
    }
  },
  beforeRouteEnter (to, from, next) {
    // Because of department filter, we need full drivers list on enter
    next(vm => vm.getDrivers())
  },
  beforeRouteLeave (to, from, next) {
    // after leaving we need list narrowed to current department for filters
    this.getDrivers({ params: { departmentId: this.departmentId } })
    next()
  },
  methods: {
    ...mapActions({
      setTableFilters: 'tables/setTableFilters',
      getDrivers: 'drivers/getItems',
      getTrailers: 'trailers/getItems',
    }),
    goTo (tab) {
      this.tableName = tab.value
      if (tab.value === 'drivers' && this.configured[tab.value]) {
        const { name, id: value } = this.department
        this.setTableFilters({ filters: [{ name: 'departmentId', filterBy: { value, name } }], tableName: this.tableName })
      }
    },
    searchAllFields (value, search, item) {
      const getObjectValues = (object) => {
        let result = []
        for (const prop in object) {
          if (typeof object[prop] === 'object' && !Array.isArray(object[prop])) {
            result = [...result, ...getObjectValues(object[prop])]
          } else {
            result.push(object[prop])
          }
        }
        return result
      }

      return getObjectValues(item).some(prop => String(prop).toLowerCase().includes(search.toLowerCase()))
    },
    setTableConfiguredStatus(tableName) {
      this.configured[tableName] = true
    },
  }
}
</script>
