<template>
  <div class="details__row py-4">
    <v-row>
      <v-data-table
        :headers="detail.fields"
        :items="invoiceItems"
        item-key="uniqueKey"
        hide-default-footer
        :items-per-page="500"
        width="100%"
        class="w-100 my-n4"
      />
    </v-row>
  </div>
</template>

<script>
import { mapArray } from '../../../utils/typesEnum'
export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    },
    isNetValueParsed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    invoiceItems() {
      if (!this.data) return []

      // 1. When splitting invoice items, the ID is not unique so we need to add index to it

      // 2. Because net value has been parsed in parent component for its own purposes
      //    here we need to multiply it by 100 for proper formatting in mapArray function
      const invoiceItems = this.data.invoiceItems?.map((item, index) => ({
        ...item,
        netValue: item.netValue * (this.isNetValueParsed ? 100 : 1),
        uniqueKey: item.id + index
      }))
      return mapArray(invoiceItems)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__wrapper {
  max-height: 500px;
  overflow-y: auto;
}
</style>
