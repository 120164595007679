import actions from '../../actions'
const debrisName = (type) => `${type.code} - ${type.displayName}`

export default {
  header: [
    { ...actions.NewOrderSchedule, create: true }
  ],
  table: {
    noDateRange: true,
    sorting: {
      sortBy: 'id',
      sortDesc: false
    },
    filters: [
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', propPath: 'client.id' },
      { name: 'containerTypeId', text: 'Typ kontenera', options: 'containerTypes', filterBy: '', propPath: 'containerType.id' },
      { name: 'debrisTypeId', text: 'Typ odpadu', options: 'debrisTypes', nameGetter: debrisName, filterBy: '', propPath: 'debrisType.id' },
      { name: 'paymentType', text: 'Typ płatności', options: 'paymentTypes', filterBy: '', propPath: 'paymentType' },
      { name: 'settlementType', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: '', propPath: 'settlementType' },
      { name: 'day', filterBy: '', hide: true, required: true, propPath: 'formattedDays' }
    ],
    parameters: [
      { name: 'id', text: 'Nr harmonogramu', value: 'id', show: true },
      { name: 'client', text: 'Klient', value: 'client.name', clientLabel: true, show: true },
      { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', show: true },
      { name: 'debrisType', text: 'Typ odpadu', value: 'debrisType.displayName', show: true },
      { name: 'address', text: 'Adres zlecenia', value: 'address.formattedAddress', show: true },
      { name: 'formattedDays', text: 'Czas realizacji', value: 'formattedDays', show: true },
      { name: 'timeSlot', text: 'Godziny realizacji', value: 'timeSlot', show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', show: true },
      { name: 'settlementType', text: 'Typ dokumentu', value: 'settlementType', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true, sortable: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.blockOrderSchedule, icon: 'blockSchedule' },
      { ...actions.unblockOrderSchedule, icon: 'unblockSchedule' }
    ],
    menu: [
      { ...actions.blockOrderSchedule },
      { ...actions.unblockOrderSchedule }
    ]
  },
  details: {
    title: 'Harmonogram nr {{id}}',
    actions: [
      { ...actions.blockOrderSchedule, text: 'Zablokuj' },
      { ...actions.unblockOrderSchedule, text: 'Odblokuj' },
      { ...actions.deleteOrderSchedule, text: 'Usuń', redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        tableName: 'orderSchedules',
        fields: [
          { name: 'id', text: 'Nr harmonogramu', value: 'id', type: 'inline', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'inline', col: 3 },
          { name: 'debrisTypeName', text: 'Typ odpadu', value: 'debrisType.displayName', type: 'inline', col: 3 },
          { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'debrisType.code', type: 'inline', col: 3 },
          { name: 'containerType', text: 'Typ kontenera', value: 'containerType.name', type: 'inline', col: 3 },
          { name: 'department', text: 'Oddział', value: 'address.department.name', type: 'inline', col: 3 },
          { name: 'address', text: 'Adres zlecenia', value: 'address.formattedAddress', type: 'inline', col: 3 },
          { name: 'formattedDays', text: 'Data realizacji', value: 'formattedDays', type: 'inline', col: 3 },
          { name: 'timeSlot', text: 'Przedział godzinowy', value: 'timeSlot', type: 'inline', col: 3 },
          { name: 'nextRealisationDate', text: 'Następna data realizacji', value: 'nextRealisationDate', type: 'inline', col: 3 },
          { name: 'paymentType', text: 'Numer telefonu', value: 'address.phoneNumber', type: 'inline', col: 3 },
          { name: 'notes', text: 'Notatka do lokalizacji', value: 'address.notes', type: 'textArea', col: 3 }
        ]
      },
      {
        name: 'payment',
        text: 'Szczegóły płatności',
        type: 'left',
        tableName: 'orderSchedules',
        fields: [
          { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', type: 'inline', col: 3 },
          { name: 'settlementType', text: 'Typ dokumentu', value: 'settlementType', type: 'inline', col: 3 },
          { name: 'discount', text: 'Rabat', value: 'discount', type: 'inline', col: 3 }
        ]
      },
      {
        name: 'client',
        text: 'Dane klienta',
        type: 'right',
        tableName: 'orderSchedules',
        fields: [
          { name: 'clientName', text: '', value: 'client.name', type: 'client', icon: 'client', col: 12 },
          { name: 'department', text: 'Oddział', value: 'address.department.name', type: 'inline', col: 6 },
          { name: 'invoiceAddress', text: 'Adres lokalizacji', value: 'client.invoiceAddress', type: 'inline', col: 6 },
          { name: 'email', text: 'Email', value: 'client.email', type: 'inline', col: 6 },
          { name: 'phoneNumber', text: 'Telefon', value: 'client.phoneNumber', type: 'inline', col: 6 }
        ]
      },
      {
        name: 'coursesScheduled',
        text: 'Zlecenia',
        type: 'bottom',
        component: 'DetailsSectionCoursesTable'
      }
    ]
  }
}
