import actions from '../../../actions'

export default {
  header: [
    { ...actions.addNewVehicle, create: true }
  ],
  table: {
    noApi: true,
    sorting: {
      sortBy: 'id',
      sortDesc: true
    },
    filters: [
      { name: 'vehicleBrand', text: 'Marka', options: 'vehicleBrands', filterBy: [], propPath: '', multiple: true },
      { name: 'vehicleType', text: 'Typ pojazdu', options: 'vehicleTypes', filterBy: [], propPath: '', multiple: true },
      { name: 'vehicleTrackingType', text: 'Sposób śledzenia', options: 'vehicleTrackingTypes', filterBy: [], propPath: '', multiple: true },
      { name: 'vehicleActive', text: 'Aktywny', options: '', filterBy: { value: true, name: 'Aktywny' }, propPath: 'vehicleActive', switch: true },
    ],
    parameters: [
      { name: 'id', text: 'Nr pozycji', value: 'id', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'licensePlate', text: 'Nr rejestracyjny', value: 'licensePlate', show: true },
      { name: 'brand', text: 'Marka', value: 'brand', show: true },
      { name: 'model', text: 'Model', value: 'model', show: true },
      { name: 'color', text: 'Kolor', value: 'color', show: true },
      { name: 'vehicleType', text: 'Typ pojazdu', value: 'vehicleType', show: true },
      { name: 'trackingType', text: 'Sposób śledzenia', value: 'trackingType', show: true },
      { name: 'maxWeight', text: 'DMC', value: 'maxWeight', show: true },
      { name: 'active', text: 'Aktywny', value: 'active', show: true },
      { name: 'actions', text: '', value: 'actions', sortable: false, show: true }
    ],
    actions: [
      { ...actions.deleteVehicle, icon: 'usun' }
    ],
    menu: [
      { ...actions.deleteVehicle }
    ]
  },
  details: {
    title: '{{brand}} {{model}}',
    actions: [
      { ...actions.deleteVehicle, redirect: true }
    ],
    sections: [
      {
        name: 'general',
        text: 'Informacje ogólne',
        type: 'left',
        endpoint: 'vehicles',
        fields: [
          { name: 'id', text: 'Nr pozycji', value: 'id', type: 'textField', col: 3 },
          { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', type: 'textField', col: 3 },
          { name: 'departmentId', text: 'Departament', value: 'department.name', type: 'selectFromItems', options: 'departments', col: 3, editable: true },
          { name: 'licensePlate', text: 'Nr rejestracyjny', value: 'licensePlate', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'brand', text: 'Marka', value: 'brand', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'model', text: 'Model', value: 'model', type: 'textField', col: 3, editable: true, rules: ['required'] },
          { name: 'color', text: 'Kolor', value: 'color', type: 'textField', col: 3, editable: true },
          { name: 'vehicleType', text: 'Typ pojazdu', value: 'vehicleType', type: 'selectFromFilters', options: 'vehicleTypes', col: 3, editable: true, rules: ['required'] },
          { name: 'trackingType', text: 'Sposób śledzenia', value: 'trackingType', type: 'selectFromFilters', options: 'vehicleTrackingTypes', col: 3, editable: true, rules: ['required'] },
          { name: 'maxWeight', text: 'DMC', value: 'maxWeight', type: 'textField', col: 3, editable: true, rules: ['required', 'number', { name: 'lowerThan', arg: 1001 }], unit: ' [T]' },
          { name: 'active', text: 'Aktywny', value: 'active', type: 'boolean', col: 3, editable: true, rules: ['required'] }
        ]
      }
    ]
  }
}
