<script>
// requires allFilters
import { mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'

export default {
  computed: {
    ...mapState({
      apiFilters: state => state.core.filters,
    }),
    filters () {
      if (isEmpty(this.apiFilters)) return []
      const filters = this.allFilters.filter((filter) => !filter.hide)
      return filters.map((filter) => {
        if (!filter.optionsLabel) filter.optionsLabel = filter.options
        const { optionsLabel, nameGetter } = filter

        const formattedFilters = (items = []) => {
          return items.map((option) => {
            if (typeof option === 'string') return { name: option, value: option }
            const { name, id, value, key } = option
            const names = {
              drivers: 'fullName',
              containers: 'refNumberWithType',
              users: 'fullName',
              patrons: 'fullName',
              debrisTypes: 'displayName',
              addresses: 'formattedAddress',
              senderCities: 'value'
            }

            let label = name
            if (nameGetter) {
              label = nameGetter(option)
            } else if (names[optionsLabel]) {
              label = option[names[optionsLabel]]
            }
            return {
              name: label,
              value: id || key || value
            }
          })
        }
        if (optionsLabel) {
          filter.options = this.apiFilters[optionsLabel]
            ? formattedFilters(this.apiFilters[optionsLabel])
            : formattedFilters(this.$store.state[optionsLabel]?.items)
        }

        return filter
      })
    }
  }
}
</script>
