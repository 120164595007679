import { Course } from '../models'

const isCancellableCourseStatus = status => {
  const courseStatuses = ['Zaplanowany', 'Wysłany', 'Rozpoczęty']
  return courseStatuses.includes(status)
}
const isCourseStatusToPlan = status => {
  return status === 'Zaplanuj'
}

const courseIconType = type => {
  const icons = {
    Podstawienie: 'substitution',
    Zabranie: 'take_away',
    Transport: 'transport',
    Wymiana: 'exchange'
  }
  return icons[type] || icons.Podstawienie
}

const statusColor = {
  Zaplanuj: '#F86262',
  Wysłany: '#5695DF',
  Zaplanowany: '#FA9B56',
  Wykonany: '#2CB56B',
  Rozpoczęty: '#A370DD',
  default: '#2CB56B'
}

const statusColorName = {
  Zaplanuj: 'red',
  Wysłany: 'blue',
  Zaplanowany: 'orange',
  Wykonany: 'green',
  Rozpoczęty: 'purple',
  Zapłacone: 'green',
  'Zapłacone online [Link]': 'green',
  'Zapłacone online [Sklep]': 'green',
  Stare: 'green',
  true: 'green',
  false: 'orange',
  default: 'red',
}

const scheduleStatus = status => (status ? 'UnblockedSchedule' : 'BlockedSchedule')
const clientStatus = status => (status ? 'BlockedClient' : 'UnblockedClient')
const anyContainerValue = item => {
  const course = new Course(item)
  const { anyContainer } = course.swapCourse ? course.swapCourse : course
  const value = anyContainer || item.id === -1
  return value ? 'isAnyContainer' : 'notAnyContainer'
}

export {
  isCancellableCourseStatus,
  isCourseStatusToPlan,
  courseIconType,
  statusColor,
  statusColorName,
  scheduleStatus,
  clientStatus,
  anyContainerValue
}
