import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{ref:"header",attrs:{"title":_vm.$route.meta.title,"table-name":_vm.tableName}}),(_vm.isConfigSet)?[_c('FiltersBar',{ref:"tableTop",attrs:{"table-name":_vm.tableName,"date-range-picker":"","show-filters-button":"","table-filters":"","search-bar":""}}),_c(VDataTable,{ref:"containersHistoryTable",staticClass:"containers-history-table",attrs:{"headers":_vm.tableParams,"items":_vm.containersHistory,"items-per-page":-1,"search":_vm.containersHistorySearchQuery,"fixed-header":"","hide-default-footer":"","expanded":_vm.expandedContainers,"show-expand":"","item-key":"containerRefNumber"},on:{"update:expanded":function($event){_vm.expandedContainers=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VDataTable,{staticClass:"expanded-table",attrs:{"headers":_vm.expandedTableParams,"items":item.entries,"items-per-page":-1,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var expandableItem = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateStringFormat(expandableItem.createdAt))+" ")]}}],null,true)})],1)]}},{key:"header.data-table-expand",fn:function(){return [_c('th',[_c(VSwitch,{staticClass:"ma-0",attrs:{"input-value":!!_vm.expandedContainers.length,"hide-details":"","dense":"","title":"Przełącz wszystkie"},on:{"change":_vm.toggleAllContainersHistory}})],1)]},proxy:true}],null,true)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }